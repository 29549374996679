<div class="login-page-container">
  <div class="content">
    <section class="u-clearfix u-section-1"
             id="sec-8e4b"
             style="height: 500px; overflow-y: hidden">
      <div class="u-clearfix u-sheet u-sheet-1">
        <div class="u-expanded-width-xs u-hover-feature u-image u-image-circle u-preserve-proportions u-image-1"
             data-image-width="1000"
             data-image-height="1006"
             data-animation-name="customAnimationIn"
             data-animation-duration="1000"
             data-animation-direction="">
          <div class="u-preserve-proportions-child"
               style="padding-top: 100%"></div>
        </div>
        <h2 class="u-align-center u-hidden-sm u-hidden-xs u-text u-text-default u-text-4"
            data-animation-name="customAnimationIn"
            data-animation-duration="2000"
            data-animation-delay="1000">
          {{ heading }}
        </h2>
        <div class="u-border-3 u-border-custom-color-3 u-line u-line-horizontal u-line-1"
             data-animation-name="customAnimationIn"
             data-animation-duration="2500"
             data-animation-direction="X"
             data-animation-delay="750"></div>
        <h2 class="u-align-center u-hidden-sm u-hidden-xs u-text u-text-default u-text-5"
            data-animation-name="customAnimationIn"
            data-animation-duration="2250"
            data-animation-delay="1250">
          {{ detail }}
        </h2>
      </div>
    </section>
    <div class="btns">
      <a *ngIf="!loginDisable"
         (click)="login()">{{ loginText }}</a>
      <!-- <a *ngIf="loginDisable" class="disabled">{{ loginText }}</a> -->
    </div>
  </div>
</div>

<!-- Validate ID Modal -->
<verde-modal modalTitle="Welcome. Please validate ID Number"
             id="validateUserIDModal"
             submitButton="Submit"
             [submitActive]="validateIDNumberForm.valid"
             (actionButton)="checkIDValid(validateIDNumberForm)"
             [hasCancelButton]="false"
             modalMinWidth="400">
  <form #validateIDNumberForm="ngForm"
        novalidate
        autocomplete="off">
    <div class="form-group">
      <label for="validateIDNo">ID Number:</label>
      <input type="text"
             class="form-control"
             required
             id="addLicevalidateIDNonseNo"
             name="validateIDNo"
             #varValidateIDNo="ngModel"
             [ngModel] />
      <div class="alert alert-danger"
           *ngIf="varValidateIDNo.touched && !varValidateIDNo.valid">
        <div *ngIf="varValidateIDNo.errors?.required">Field is required!</div>
      </div>
    </div>

    <div class="form-group">
      <label *ngIf="noIDMatchError"
             style="font-size: 15px; color: #cc4a31"> ID number entered does not match on the system. Please try again.
      </label>
    </div>
  </form>
</verde-modal>