/* tslint:disable */
/* eslint-disable */
export enum UserCalendarRoleType {
  None = 'none',
  FreeBusyRead = 'freeBusyRead',
  LimitedRead = 'limitedRead',
  Read = 'read',
  Write = 'write',
  DelegateWithoutPrivateEventAccess = 'delegateWithoutPrivateEventAccess',
  DelegateWithPrivateEventAccess = 'delegateWithPrivateEventAccess',
  Custom = 'custom',
}
