/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { TimeOffBaseService } from '../time-off-base-service';
import { TimeOffApiConfiguration } from '../time-off-api-configuration';
import { TimeOffStrictHttpResponse } from '../time-off-strict-http-response';
import { TimeOffRequestBuilder } from '../time-off-request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { TimeOffBooleanStringValueTuple } from '../models/time-off-boolean-string-value-tuple';
import { TimeOffEmployeeTimeOffCycleSummary } from '../models/time-off-employee-time-off-cycle-summary';
import { TimeOffGetCycleSummaryByEmpAndLegalEIdCommand } from '../models/time-off-get-cycle-summary-by-emp-and-legal-e-id-command';
import { TimeOffGetTimeOffBalancesCommand } from '../models/time-off-get-time-off-balances-command';
import { TimeOffGetTimeOffQuery } from '../models/time-off-get-time-off-query';
import { TimeOffPostTimeOffCommand } from '../models/time-off-post-time-off-command';
import { TimeOffTimeOffBalances } from '../models/time-off-time-off-balances';
import { TimeOffTimeOffExample } from '../models/time-off-time-off-example';
import { TimeOffTimeOffRequestDateCheckerCommand } from '../models/time-off-time-off-request-date-checker-command';

@Injectable({
  providedIn: 'root',
})
export class TimeOffApiTimeOffService extends TimeOffBaseService {
  constructor(
    config: TimeOffApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getTimeOff
   */
  static readonly GetTimeOffPath = '/api/TimeOff/get/timeoff';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTimeOff()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getTimeOff$Response(params?: {
    body?: TimeOffGetTimeOffQuery
  }): Observable<TimeOffStrictHttpResponse<Array<TimeOffTimeOffExample>>> {

    const rb = new TimeOffRequestBuilder(this.rootUrl, TimeOffApiTimeOffService.GetTimeOffPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as TimeOffStrictHttpResponse<Array<TimeOffTimeOffExample>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTimeOff$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getTimeOff(params?: {
    body?: TimeOffGetTimeOffQuery
  }): Observable<Array<TimeOffTimeOffExample>> {

    return this.getTimeOff$Response(params).pipe(
      map((r: TimeOffStrictHttpResponse<Array<TimeOffTimeOffExample>>) => r.body as Array<TimeOffTimeOffExample>)
    );
  }

  /**
   * Path part for operation postTimeOff
   */
  static readonly PostTimeOffPath = '/api/TimeOff/post/timeoff';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postTimeOff()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postTimeOff$Response(params?: {
    body?: TimeOffPostTimeOffCommand
  }): Observable<TimeOffStrictHttpResponse<string>> {

    const rb = new TimeOffRequestBuilder(this.rootUrl, TimeOffApiTimeOffService.PostTimeOffPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as TimeOffStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postTimeOff$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postTimeOff(params?: {
    body?: TimeOffPostTimeOffCommand
  }): Observable<string> {

    return this.postTimeOff$Response(params).pipe(
      map((r: TimeOffStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation timeOffRequestBalances
   */
  static readonly TimeOffRequestBalancesPath = '/api/TimeOff/get/timeoffrequestbalances';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timeOffRequestBalances()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  timeOffRequestBalances$Response(params?: {
    body?: TimeOffTimeOffRequestDateCheckerCommand
  }): Observable<TimeOffStrictHttpResponse<TimeOffBooleanStringValueTuple>> {

    const rb = new TimeOffRequestBuilder(this.rootUrl, TimeOffApiTimeOffService.TimeOffRequestBalancesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as TimeOffStrictHttpResponse<TimeOffBooleanStringValueTuple>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `timeOffRequestBalances$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  timeOffRequestBalances(params?: {
    body?: TimeOffTimeOffRequestDateCheckerCommand
  }): Observable<TimeOffBooleanStringValueTuple> {

    return this.timeOffRequestBalances$Response(params).pipe(
      map((r: TimeOffStrictHttpResponse<TimeOffBooleanStringValueTuple>) => r.body as TimeOffBooleanStringValueTuple)
    );
  }

  /**
   * Path part for operation timeOffBalances
   */
  static readonly TimeOffBalancesPath = '/api/TimeOff/get/timeoffbalances';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timeOffBalances()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  timeOffBalances$Response(params?: {
    body?: TimeOffGetTimeOffBalancesCommand
  }): Observable<TimeOffStrictHttpResponse<Array<TimeOffTimeOffBalances>>> {

    const rb = new TimeOffRequestBuilder(this.rootUrl, TimeOffApiTimeOffService.TimeOffBalancesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as TimeOffStrictHttpResponse<Array<TimeOffTimeOffBalances>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `timeOffBalances$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  timeOffBalances(params?: {
    body?: TimeOffGetTimeOffBalancesCommand
  }): Observable<Array<TimeOffTimeOffBalances>> {

    return this.timeOffBalances$Response(params).pipe(
      map((r: TimeOffStrictHttpResponse<Array<TimeOffTimeOffBalances>>) => r.body as Array<TimeOffTimeOffBalances>)
    );
  }

  /**
   * Path part for operation cycleSummaryByEmpAndLegalEId
   */
  static readonly CycleSummaryByEmpAndLegalEIdPath = '/api/TimeOff/get/cycleSummaryByEmpAndLegalEId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cycleSummaryByEmpAndLegalEId()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  cycleSummaryByEmpAndLegalEId$Response(params?: {
    body?: TimeOffGetCycleSummaryByEmpAndLegalEIdCommand
  }): Observable<TimeOffStrictHttpResponse<Array<TimeOffEmployeeTimeOffCycleSummary>>> {

    const rb = new TimeOffRequestBuilder(this.rootUrl, TimeOffApiTimeOffService.CycleSummaryByEmpAndLegalEIdPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as TimeOffStrictHttpResponse<Array<TimeOffEmployeeTimeOffCycleSummary>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `cycleSummaryByEmpAndLegalEId$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  cycleSummaryByEmpAndLegalEId(params?: {
    body?: TimeOffGetCycleSummaryByEmpAndLegalEIdCommand
  }): Observable<Array<TimeOffEmployeeTimeOffCycleSummary>> {

    return this.cycleSummaryByEmpAndLegalEId$Response(params).pipe(
      map((r: TimeOffStrictHttpResponse<Array<TimeOffEmployeeTimeOffCycleSummary>>) => r.body as Array<TimeOffEmployeeTimeOffCycleSummary>)
    );
  }

}
