<!-- side bar main wrapper -->
<div class="side-bar" [ngClass]="{ 'side-bar-collapsed': !(showSidebar | async) }">
  <!-- overlay -->
  <div
    class="side-bar-overlay"
    [ngStyle]="{ transition: 'background-color ' + duration + 's, visibility ' + duration + 's' }"
    [ngClass]="{ 'side-bar-overlay-collapsed': !(showSidebar | async) }"
  ></div>

  <!-- side bar-->
  <div class="side-bar-menu-container" [ngStyle]="getSidebarStyle(showSidebar | async)">
    <!-- close button -->
    <div class="side-bar-header">     
      <p *ngIf="sidebarType === 'verdeDynamicForm'" class="side-bar-title">{{ universalTitleVar }}</p>     
      <p class="side-bar-title" *ngIf="sidebarType === 'dynamic-form'">Dynamic Form</p>
      <span class="material-icons side-bar-close" (click)="onSidebarClose()"> clear </span>
    </div>

    <!-- side bar content -->
    <div class="side-bar-content-container">

      <!-- verdeDynamicForm -->
      <div *ngIf="sidebarType === 'verdeDynamicForm'" style="height: 100%">
        <verde-dynamic-form-SidePanel style="height: inherit"></verde-dynamic-form-SidePanel>
      </div>

      <!-- Dynamic Form -->
      <div *ngIf="sidebarType === 'dynamic-form'">
        <verde-dynamic-form></verde-dynamic-form>
      </div>

    <!-- footer -->
    <!--<div class="footer-sidebar">
  </div>-->
  </div>
</div>
