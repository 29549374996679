import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'verde-kendo-formly-object',
  template: ` <kendo-label *ngIf="props.label" class="form-label">{{ props.label }}</kendo-label>
    <hr />
    <kendo-label *ngIf="props.description" class="form-description">{{ props.description }}</kendo-label>
    <ng-container *ngFor="let f of field.fieldGroup">
      <formly-field [style.display]="isCompletelyHidden(f) ? 'none' : 'block'" [style]="f.props?.style" [field]="f"> </formly-field>
    </ng-container>`,
  styleUrls: ['./object.type.scss'],
})
export class KendoFormlyObject extends FieldType {
  isCompletelyHidden(field: any): boolean {
    if (field.props.hidden) {
      return true;
    }
    return field.fieldGroup?.length ? field.fieldGroup.every((child) => child.props?.hidden) : false;
  }
}
