import { Component, OnInit, AfterViewInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { BookingsApiBookingsService, BookingsGetBookingItemsFilteredDto, UserApiUserService, UserBtOpenforRooms } from '@verde/api';
import { UserService } from '@verde/core';
import { BookingsDataService, VerdeApprovalService } from '@verde/shared';
import { map, Observable, Subject, switchMap, takeUntil, tap, take } from 'rxjs';
import moment from 'moment';
import { EventStyleArgs, SchedulerEvent } from '@progress/kendo-angular-scheduler';

@Component({
  selector: 'verde-booking-boardroom-calendar',
  templateUrl: './booking-boardroom-calendar.component.html',
  styleUrls: ['./booking-boardroom-calendar.component.scss'],
})
export class BookingBoardroomCalendarComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() backToSearch = new EventEmitter<void>();
  @Output() setSidePanelTitleText = new EventEmitter<string>();

  private onDestroy$ = new Subject<boolean>();
  disableAnimation: boolean;
  isLoading: boolean = false;

  scrollTime = new Date();

  availabilityDetails: any = {};

  bookingItems: Array<BookingsGetBookingItemsFilteredDto> = [];
  calendarWithEvents: Array<SchedulerEvent> = [];
  searchDate = new Date();

  fromDate: Date = new Date();
  toDate: Date = new Date();

  element: any;

  constructor(
    private bookingsApiBookingsService: BookingsApiBookingsService,
    private sidebarService: VerdeApprovalService,
    private userService: UserService,
    public bookingsDataService: BookingsDataService,
    private userApiUserService: UserApiUserService,
  ) {}

  ngOnInit(): void {
    //console.log(this.scrollTime);
    this.isLoading = true;
    this.setSidePanelTitleText.emit('Boardrooms');

    this.sidebarService
      .getBookingAvailabilitySearchDetails()
      .pipe(
        takeUntil(this.onDestroy$), // Unsubscribe on destroy
        // 1. Use the search details to fetch booking items.
        switchMap((searchDetails) =>
          this.fetchBookingItems(searchDetails).pipe(
            tap((bookingItems: BookingsGetBookingItemsFilteredDto[]) => {
              this.searchDate = searchDetails.bookingFrom;
              this.fromDate = searchDetails.bookingFrom;
              this.toDate = searchDetails.bookingTo;
              this.bookingItems = bookingItems;
            }),
            map((bookingItems) => ({ bookingItems, searchDetails })),
          ),
        ),
        // 2. Use the first booking item's email to fetch calendar data.
        switchMap(({ bookingItems, searchDetails }) => {
          const bookingEmails = bookingItems.map((item) => item.email);
          return this.fetchCalendarData$(bookingEmails, searchDetails?.bookingDurationMinutes, searchDetails?.bookingFrom, searchDetails?.bookingTo);
        }),
        // 3. Mark loading complete once all observables have completed.
        tap(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (calendarData) => {
          // Optionally, further process calendar data if needed.
          console.log('Calendar data loaded.', calendarData);
        },
        error: (err) => {
          console.error('Error:', err);
          this.isLoading = false;
        },
      });
  }

  private fetchBookingItems(data: any): Observable<BookingsGetBookingItemsFilteredDto[]> {
    return this.bookingsApiBookingsService.getBookingItemsFiltered({
      body: {
        bookingTypeId: data.bookingType,
        legalEntityId: this.userService.user?.legalEntityId,
        officeId: data.office,
        primaryLocationId: data.primaryLocation,
      },
    });
  }

  private fetchCalendarData$(emails: Array<string>, durationMinutes: string, bookingFrom: Date, bookingTo: Date): Observable<any> {
    this.searchDate ?? new Date();
    const startOfDay = new Date(this.searchDate.getFullYear(), this.searchDate.getMonth(), this.searchDate.getDate(), 0, 0, 0, 0);
    const endOfDay = new Date(this.searchDate.getFullYear(), this.searchDate.getMonth(), this.searchDate.getDate(), 23, 59, 59, 999);

    return this.userApiUserService
      .getUserCalendarWithUsersAvailability({
        body: {
          userEmail: this.userService?.user?.email,
          userStartDateTime: startOfDay.toISOString(),
          userEndDateTime: endOfDay.toISOString(),
          availabilityEmails: emails,
          availabilityStartDateTime: bookingFrom.toISOString(),
          availabilityEndDateTime: bookingTo.toISOString(),
          duration: durationMinutes,
        },
      })
      .pipe(
        take(1),
        tap((data: any) => {
          if (data) {
            this.calendarWithEvents = data.userCalendar.value.map((event) => ({
              start: moment.utc(event.start.dateTime).local().toDate(),
              end: moment.utc(event.end.dateTime).local().toDate(),
              title: event.subject,
              id: event.id,
            }));

            const mappedAvailabilities = data.availableRooms.map((room) => {
              const matchingBooking = this.bookingItems.find((item) => item.email === room.roomEmail);
              return {
                ...room,
                bookingName: matchingBooking ? matchingBooking.bookingItemName : null,
              };
            });

            this.availabilityDetails = {
              availabilties: mappedAvailabilities,
              isAvailabaleRooms: data.isAnyBoardroomAvailable,
            };
          }
        }),
      );
  }

  getEventClass = (args: EventStyleArgs) => {
    return args.event.dataItem.type;
  };

  cancel() {
    this.sidebarService.setShowSidebar(false);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      //this.scrollToCurrentTime();
    }, 500); // Small delay ensures the scheduler is fully rendered
  }

  ngOnDestroy(): void {
    this.bookingsDataService.bookingAvailabilitySearchDetails = undefined;
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  bookRoom(event: any): void {
    const openIn = this.userService.legalEntityConfig?.openForRooms;
    if (openIn === UserBtOpenforRooms.Outlook) {
      this.bookInOutlookWeb(event);
    } else {
      //default to Teams if not set
      this.bookInTeams(event);
    }
  }

  bookInTeams(event: any): void {
    const teamsUrl = new URL('msteams://teams.microsoft.com/l/meeting/new');

    // Set the meeting subject
    //teamsUrl.searchParams.set('subject', `Available: ${event?.bookingName}` || 'Default Meeting Subject');

    event.attendees = [this.userService?.user?.email, event.roomEmail];

    // Set the attendees (comma-separated email addresses)
    if (event.attendees) {
      teamsUrl.searchParams.set('attendees', event.attendees.join(','));
    }

    // Set the start time in ISO format
    if (event.startDateTime) {
      teamsUrl.searchParams.set('startTime', new Date(event.startDateTime).toISOString());
    }

    // Set the end time in ISO format
    if (event.endDateTime) {
      teamsUrl.searchParams.set('endTime', new Date(event.endDateTime).toISOString());
    }

    window.location.href = teamsUrl.toString();

    this.sidebarService.setShowSidebar(false);
  }

  bookInOutlookWeb(event) {
    // Base URL for composing a new event in Outlook Web
    const outlookUrl = new URL('https://outlook.office.com/calendar/0/deeplink/compose');

    // Set the event subject
    // const subject = event?.bookingName ? `Available: ${event.bookingName}` : 'Default Meeting Subject';
    // outlookUrl.searchParams.set('subject', subject);

    // Set the event location
    if (event.location) {
      outlookUrl.searchParams.set('location', event.location);
    }

    // Set the event body/description
    if (event.description) {
      outlookUrl.searchParams.set('body', event.description);
    }

    // Set the start time in ISO format (UTC)
    if (event.startDateTime) {
      outlookUrl.searchParams.set('startdt', new Date(event.startDateTime).toISOString());
    }

    // Set the end time in ISO format (UTC)
    if (event.endDateTime) {
      outlookUrl.searchParams.set('enddt', new Date(event.endDateTime).toISOString());
    }

    event.attendees = [this.userService?.user?.email, event.roomEmail];

    // Set the attendees (comma-separated email addresses)
    if (event.attendees && event.attendees.length > 0) {
      outlookUrl.searchParams.set('to', event.attendees.join(','));
    }

    // Open the constructed URL
    //window.location.href = outlookUrl.toString();
    window.open(outlookUrl.toString(), '_blank');

    this.sidebarService.setShowSidebar(false);
  }
}
