/* tslint:disable */
/* eslint-disable */
export enum UserResponseType {
  None = 'none',
  Organizer = 'organizer',
  TentativelyAccepted = 'tentativelyAccepted',
  Accepted = 'accepted',
  Declined = 'declined',
  NotResponded = 'notResponded',
}
