/* tslint:disable */
/* eslint-disable */
import { UserAttendeeType } from './user-attendee-type';
import { UserEmailAddress } from './user-email-address';
import { UserIBackingStore } from './user-i-backing-store';
import { UserResponseStatus } from './user-response-status';
import { UserTimeSlot } from './user-time-slot';
export interface UserAttendee {
  additionalData?: null | {
    [key: string]: any;
  };
  backingStore?: UserIBackingStore;
  emailAddress?: UserEmailAddress;
  odataType?: null | string;
  proposedNewTime?: UserTimeSlot;
  status?: UserResponseStatus;
  type?: UserAttendeeType;
}
