import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { WebDynamicFormAction, WebDynamicFormModel, WebDynamicFormType } from '../../../../../public-api';
import { WebDynamicService } from '../../../../web-dynamic/services/web-dynamic.service';

@Component({
  selector: 'verde-workspace-grid',
  templateUrl: './workspace-grid.component.html',
  styleUrls: ['./workspace-grid.component.scss'],
})
export class WorkspaceGridComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<boolean>();
  @Input() gridContext: any;
  gridConfig: WebDynamicFormModel | undefined;
  gridName: string;

  @HostBinding('class.k-card')
  cssClass = true;
  constructor(private webDynamicService: WebDynamicService) {}

  ngOnInit(): void {
    this.gridName = this.gridContext?.jsonFileName;
    this.webDynamicService.setDynamicForm({
      formFile: this.gridName,
      formType: WebDynamicFormType.Workspace,
      action: WebDynamicFormAction.Read,
    });

    this.webDynamicService.webDynamicFormModel$.subscribe((config: WebDynamicFormModel | undefined) => {
      if (config?.formType === WebDynamicFormType.Workspace) {
        this.gridConfig = config;
      }
    });
  }
  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
