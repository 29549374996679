<verde-loader [loading]="isLoading"></verde-loader>
<div class="scroll-wrapper">
  <div class="tile-container">
    <kendo-tilelayout [columns]="calendarWithEvents.length">
      <kendo-tilelayout-item *ngFor="let item of calendarWithEvents; let i = index" title="{{ item.bookingItemName }}" [col]="i + 1" [colSpan]="1">
        <kendo-tilelayout-item-body>
          <kendo-scheduler [kendoSchedulerBinding]="item.calendarEvents" [selectedDate]="today" [views]="['day']" startTime="07:00" [min]="today" [max]="today">
            <kendo-scheduler-day-view> </kendo-scheduler-day-view>
          </kendo-scheduler>
        </kendo-tilelayout-item-body>
      </kendo-tilelayout-item>
    </kendo-tilelayout>
  </div>

  <div class="side-panel-bottom-buttons">
    <button kendoButton style="background-color: lightgrey; color: black" (click)="backToSearch.emit()">Back</button>

    <button kendoButton style="background-color: lightgrey; color: black" (click)="cancel()">Cancel</button>
  </div>
</div>
