/* eslint-disable no-console */
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyJsonschema } from '@ngx-formly/core/json-schema';
import { IntlService } from '@progress/kendo-angular-intl';
import { WebDynamicApiWebService, WebDynamicGetDynamicFormLookupQuery } from '@verde/api';
import { DeviceTypeService, UserService } from '@verde/core';
import { SidePanelWidth, VerdeApprovalService, verdeFormlyTypes } from '@verde/shared';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, map, Observable, Subject, take, takeUntil, tap } from 'rxjs';
import { VerdeApprovalOverlayService } from '../../layout/verde-approval-panel (2)/services/verde-approval-overlay.service';
import { WebDynamicFormAction } from '../enums/web-dynamic-form-action.enum';
import { WebDynamicFormType } from '../enums/web-dynamic-form-type.enum';
import { WebDynamicFormModel, WebDynamicModel, WebDynamicSidePanelArgs } from '../models/web-dynamic-form.model';

@Injectable({
  providedIn: 'root',
})
export class WebDynamicService implements OnDestroy {
  private onDestroy$ = new Subject<boolean>();
  private sasToken = `si=web-dynamic&spr=https&sv=2022-11-02&sr=c&sig=sj6Gc4lRtj77AGcsoqYS8br7oxmBMzPJsVEpIpOR1v4%3D`;
  webDynamicFormModel$: BehaviorSubject<WebDynamicFormModel | undefined> = new BehaviorSubject<WebDynamicFormModel | undefined>(undefined);
  private modelSubject = new BehaviorSubject<any>({});
  sharedModel$ = this.modelSubject.asObservable();

  constructor(
    private http: HttpClient,
    private sidebarService: VerdeApprovalService,
    public userService: UserService,
    private webDynamicApiWebService: WebDynamicApiWebService,
    private formlyJsonschema: FormlyJsonschema,
    public deviceTypeService: DeviceTypeService,
    private router: Router,
    private intl: IntlService,
    private sidebarOverlayService: VerdeApprovalOverlayService,
    @Inject('environment') private environment: any,
    private toastr: ToastrService,
  ) {}

  setDynamicForm(webDynamicModel: WebDynamicModel) {
    if (!webDynamicModel || !webDynamicModel.formFile) {
      this.toastr.error('Invalid dynamic form model. Please check the configuration.');
      return;
    }

    if (!Object.values(WebDynamicFormType).includes(webDynamicModel.formType)) {
      this.toastr.error('Invalid form type.');
      return;
    }

    if (webDynamicModel.formType === WebDynamicFormType.SidePanel && webDynamicModel.action !== WebDynamicFormAction.Create && !webDynamicModel.args?.model) {
      this.toastr.error('Invalid model. Data not passed into SidePanel to load existing values.');
      return;
    }

    let file = `https://verdedynamicformsjson.blob.core.windows.net/$web/${this.environment.env}/${webDynamicModel.formFile}.json?${this.sasToken}`;
    //if (webDynamicModel.formType === WebDynamicFormType.SidePanel) {
    //  file = '/assets/web-dynamic/example-test.json';
    //}

    this.http
      .get<any>(file)
      .pipe(
        tap(({ schema, additional }) => {
          if (!schema || typeof schema !== 'object') {
            this.toastr.error('The retrieved schema is invalid or missing.');
            return;
          }

          if (webDynamicModel.formType === WebDynamicFormType.SidePanel) {
            if (!additional || typeof additional !== 'object') {
              this.toastr.error("Validation failed: 'additional' object is required for SidePanel form type to ensure proper entity creates or updates.");
              //return false;
            }

            if (!additional?.entityName || !additional?.primaryEntityId) {
              this.toastr.error("Validation failed: 'additional' must contain 'entityName' and 'primaryEntityId' for a SidePanel form type.");
              //return false;
            }
          }

          const form = new FormGroup({});
          schema = this.replacePlaceholdersRecursively(schema, this.userService.config$.getValue());
          const fields = [
            this.formlyJsonschema.toFieldConfig(schema, {
              map: (mappedField: FormlyFieldConfig, mapSource: any) => {
                // Prepare default options
                let extraProps = {};

                if (!mapSource || !mapSource.type) {
                  this.toastr.error(`Invalid field mapping: '${mappedField.key}'`);
                }

                if (!this.isValidFieldType(mapSource.type)) {
                  this.toastr.error(`Invalid field type: '${mapSource.type}' for '${mappedField.key}'`);
                  return mappedField;
                }

                if (mappedField.props.options === undefined) {
                  if (mapSource.type === 'boolean') {
                    extraProps = {
                      options: [
                        { label: 'Yes', value: true },
                        { label: 'No', value: false },
                      ],
                    };
                  }

                  if (mapSource.type === 'select') {
                    if (typeof mapSource.default === 'object' && mapSource.default !== null) {
                      extraProps = {
                        options: mapSource.default.data,
                        lookUp: {
                          label: mapSource.default.label,
                          value: mapSource.default.value,
                        },
                      };
                      mapSource.default = mapSource.default.data?.[0]?.[mapSource.default.value] ?? null;
                    } else {
                      if (mapSource.props?.lookUp) {
                        extraProps = {
                          options: this.webDynamicApiWebService.dynamicFormLookupData({
                            body: mapSource.props.lookUp as WebDynamicGetDynamicFormLookupQuery,
                          }),
                        };
                      } else {
                        this.toastr.error(`Select '${mappedField.key}' has no lookup configuration.`);
                      }
                    }
                  }

                  if (mapSource.type === 'grid') {
                    if (mapSource.props?.lookUp) {
                      extraProps = {
                        options: this.webDynamicApiWebService.dynamicFormLookupData({
                          body: mapSource.props.lookUp as WebDynamicGetDynamicFormLookupQuery,
                        }),
                      };
                    } else {
                      this.toastr.error(`Grid '${mappedField.key}' has no lookup configuration.`);
                    }
                  }

                  if (mapSource.type === 'subgrid' && mapSource.props?.lookUp?.fetchXml) {
                    const lookUpConfig = JSON.parse(JSON.stringify(mapSource.props.lookUp));
                    lookUpConfig.fetchXml = this.injectDynamicFilters(lookUpConfig.fetchXml, webDynamicModel.args.model);

                    extraProps = {
                      options: this.webDynamicApiWebService.dynamicFormLookupData({
                        body: lookUpConfig as WebDynamicGetDynamicFormLookupQuery,
                      }),
                    };
                  }
                }

                if (mapSource.type === 'select') {
                  mappedField.hooks = {
                    ...mappedField.hooks,
                    onChanges: (field: FormlyFieldConfig) => this.onFieldChanges(form, fields, field),
                  };
                }

                if (mapSource.type === 'object' && mappedField?.key?.toString()?.includes('Section')) {
                  mappedField.props.label = '';
                }

                if (mapSource.type === 'textarea' || mapSource.type === 'object') {
                  mappedField.className = 'web-dynamic-width-100';
                }

                if (mapSource.type === 'date') {
                  if (mapSource.default === 'today') {
                    mapSource.default = new Date();
                  } else if (mapSource.default) {
                    const customDate = this.intl.formatDate(mapSource.default, 'yyyy/MM/dd');
                    if (customDate) {
                      mapSource.default = new Date(customDate);
                    }
                  }
                }

                if (mapSource.type === 'timeline') {
                  mappedField = {
                    ...mappedField,
                    props: {
                      ...mappedField.props,
                      processInstanceId: webDynamicModel.args.model?.bt_verdeprocessinstanceid,
                      loading: true,
                    },
                  };
                }

                // Setting dynamic expressions
                if (mapSource.props?.expressions && Object.keys(mapSource.props?.expressions)?.length > 0) {
                  Object.entries(mapSource.props?.expressions).forEach(([key, value]) => {
                    if (key === 'props.style') {
                      mapSource.props.style = value;
                      delete mapSource.props.expressions[key];
                      return;
                    }

                    if (typeof value !== 'string') {
                      this.toastr.error(`Invalid expression format for field '${mappedField.key}'.`);
                      return;
                    }

                    mapSource.props.expressions[key] = (field) => this.createDynamicExpression(field, value as string);
                  });
                }

                const props = {
                  ...mappedField.props,
                  ...mapSource.props,
                  ...extraProps,
                  readonly: (mapSource.readOnly ?? false) || webDynamicModel.action === WebDynamicFormAction.Read,
                };

                // Ensure we can use custom properties in our schema.
                mappedField = {
                  ...mappedField,
                  props,
                  expressions: {
                    ...(mapSource.props?.expressions ?? {}),
                  },
                  defaultValue: mapSource.default,
                  className: props.style !== undefined ? undefined : mappedField.className ?? mapSource.className ?? 'web-dynamic-default',
                };

                mappedField.templateOptions = {
                  ...mappedField.props,
                };

                return mappedField;
              },
            }),
          ];
          const title = fields[0].props.label;

          fields[0].props.label = null; // Clear the title that is shown at the wrong place
          fields[0].templateOptions.label = null;

          this.webDynamicFormModel$.next({
            ...webDynamicModel,
            form,
            schema,
            fields,
            additional: additional,
          });

          switch (webDynamicModel.formType) {
            case WebDynamicFormType.TimeLine:
            case WebDynamicFormType.SidePanel: {
              const args = webDynamicModel.args as WebDynamicSidePanelArgs;
              if (args.overlay == true) {
                this.sidebarOverlayService.setShowSidebar(args.visible);
                this.sidebarOverlayService.setTitleTag(schema.title);
                const size = this.deviceTypeService.isMobile() === false ? additional?.formWidth || args.size || SidePanelWidth.Half : SidePanelWidth.Full;
                this.sidebarOverlayService.setSidebarSize(Number(size));
                this.sidebarOverlayService.setSidebarType('verdeDynamicForm');
                break;
              } else {
                this.sidebarService.setShowSidebar(args.visible);
                this.sidebarService.setTitleTag(schema.title);
                const size = this.deviceTypeService.isMobile() === false ? additional?.formWidth || args.size || SidePanelWidth.Half : SidePanelWidth.Full;
                this.sidebarService.setSidebarSize(Number(size));
                this.sidebarService.setSidebarType('verdeDynamicForm');
                break;
              }
            }
            case WebDynamicFormType.Grid: {
              if (webDynamicModel.pathURL != null) {
                this.router.navigate([webDynamicModel.pathURL, 'web_dynamic'], {
                  skipLocationChange: true,
                  state: { breadcrumb: { title, path: 'web_dynamic' } },
                });
              } else {
                this.router.navigate([this.router.url, 'web_dynamic'], {
                  skipLocationChange: true,
                  state: { breadcrumb: { title, path: 'web_dynamic' } },
                });
              }
              break;
            }
          }
        }),
        take(1),
      )
      .subscribe();
  }

  getDynamicWorkspace(fileName: string): Observable<any> {
    return this.http.get<any>(`https://verdedynamicformsjson.blob.core.windows.net/$web/${this.environment.env}/${fileName}.json?${this.sasToken}`).pipe(
      map((data) => data?.schema?.properties),
      take(1),
    );
  }

  getJsonFileFromBlobStorage(fileName: string): Observable<any> {
    return this.http
      .get<any>(`https://verdedynamicformsjson.blob.core.windows.net/$web/${this.environment.env}/${fileName}.json?${this.sasToken}`)
      .pipe(take(1));
  }

  // Function to check if the field type exists in verdeFormlyTypes
  isValidFieldType(fieldType: string): boolean {
    return verdeFormlyTypes.some((type) => type.name === fieldType);
  }

  // Function to generate dynamic expression
  private createDynamicExpression(field: FormlyFieldConfig, expression: string): any {
    let formGroup = field.formControl?.parent; // Start with the parent of the current field

    // Traverse upwards until we reach the root form group (the highest parent without a parent)
    while (formGroup?.parent) {
      formGroup = formGroup.parent;
    }

    const model = formGroup?.getRawValue(); // Access the full model from the root form group

    if (!model) {
      return null; // If no model exists, return null
    }

    // Flatten the model (this ensures there are no nested sections)
    const flattenedModel = this.flattenModel(model);

    // Dynamically evaluate the expression using the model and return the value
    const evaluate = this.evaluateExpression(expression, flattenedModel);
    return evaluate;
  }

  // Function to flatten the model
  private flattenModel(model: any): any {
    const flattened: any = {};

    const flatten = (obj: any, parentKey = '') => {
      for (const [key, value] of Object.entries(obj)) {
        const newKey = parentKey ? `${parentKey}.${key}` : key;

        // Remove section names (e.g., Section_1. or Section_2.) from the key
        const simplifiedKey = newKey.replace(/^[^.]+\./, ''); // Remove the section name (before the first dot)

        if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
          flatten(value, simplifiedKey); // Recursively flatten nested objects, keeping the simplified key
        } else {
          flattened[simplifiedKey] = value; // Store the flattened value with the simplified key
        }
      }
    };

    flatten(model);

    return flattened;
  }

  private evaluateExpression(expression: string, model: any): any {
    try {
      if (!expression) return undefined;

      // Trim whitespace from the entire expression
      // Remove spaces around `model.` and replace it with direct variable references
      const cleanedExpression = expression.trim().replace(/\bmodel\.\s*/g, '');

      // Extract model keys and values dynamically
      const modelKeys = Object.keys(model);
      const modelValues = Object.values(model);

      // Evaluate the cleaned expression dynamically
      return new Function(...modelKeys, `return ${cleanedExpression};`)(...modelValues);
    } catch (error) {
      console.error('Error evaluating expression:', expression, error);
      return undefined;
    }
  }

  private replacePlaceholdersRecursively(obj: any, config: any): any {
    if (obj && typeof obj === 'object') {
      for (const key of Object.keys(obj)) {
        if (typeof obj[key] === 'string') {
          obj[key] = this.replacePlaceholders(obj[key], config);
        } else if (Array.isArray(obj[key])) {
          obj[key] = obj[key].map((item) => this.replacePlaceholdersRecursively(item, config));
        } else if (typeof obj[key] === 'object') {
          obj[key] = this.replacePlaceholdersRecursively(obj[key], config);
        }
      }
    }
    return obj;
  }

  private getNestedValue(obj: any, path: string): any {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  }

  private replacePlaceholders(template: string, config: any): string | boolean {
    // Replace {none} with an empty string
    template = template.replace(/\{none\}/g, '');

    let isObject = false;

    // Replace placeholders
    template = template.replace(/\$\{(.*?)\}/g, (_, path) => {
      const value = this.getNestedValue(config, path);

      if (value !== undefined && value !== null) {
        if (typeof value === 'object') {
          isObject = true;
          return JSON.stringify(value); // Convert arrays to a JSON string
        }
        return value;
      }

      return '';
    });

    if (isObject) {
      if (template.includes('<fetch')) {
        template = template.trim();

        const regex = /<condition attribute="([^"]+)" operator="in" value="(.*?)"\/>/;

        template = template.replace(regex, (match, attributeName, jsonString) => {
          // Parse the JSON string from the value attribute
          const jsonData = JSON.parse(jsonString);

          // Extract the IDs based on the value key
          const ids = jsonData.data.map((item) => item[jsonData.value]);

          // Format the <value> tags
          const valueTags = ids.map((id) => `<value>${id}</value>`).join('');

          // Return the new <condition> block with the formatted values
          return `<condition attribute="${attributeName}" operator="in">${valueTags}\n</condition>`;
        });

        template = template.trim();
      } else {
        template = JSON.parse(template);
      }
    } else {
      // Trim whitespace
      template = template.trim();
    }

    // Cast "true" or "false" to boolean
    if (template === 'true') return true;
    if (template === 'false') return false;

    return template; // Return the final string if not boolean
  }

  private findControlByKey(control: AbstractControl, key: string): AbstractControl | null {
    if (control instanceof FormGroup) {
      if (key in control.controls) {
        return control.controls[key];
      }
      // Recursively search within nested FormGroups
      for (const child of Object.values(control.controls)) {
        const result = this.findControlByKey(child, key);
        if (result) {
          return result;
        }
      }
    } else if (control instanceof FormArray) {
      // Recursively search within FormArray elements
      for (const child of control.controls) {
        const result = this.findControlByKey(child, key);
        if (result) {
          return result;
        }
      }
    }
    return null;
  }

  private onFieldChanges(form: FormGroup, fields: FormlyFieldConfig[], field: FormlyFieldConfig) {
    if (field.key) {
      const key = field.key as string;
      const control = this.findControlByKey(form, key);
      if (control) {
        control.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((value) => {
          if (value) {
            this.updateDependentFields(fields, key, value);
          }
        });
      }
    }
  }

  private updateDependentFields(fields: FormlyFieldConfig[], property: string, value: any) {
    fields.forEach((field) => {
      if (
        field.props.lookUp &&
        field.props.lookUp.dependency &&
        Object.keys(field.props.lookUp.dependency)?.length > 0 &&
        field.props.lookUp.dependency.property === property
      ) {
        let parsedValue = value;

        if (typeof value === 'string') {
          try {
            const parsedJson = JSON.parse(value);
            if (parsedJson?.value) {
              parsedValue = parsedJson?.value;
            }
          } catch (error) {
            //
          }
        }

        const updatedLookUp = {
          ...field.props.lookUp,
          dependency: {
            ...field.props.lookUp.dependency,
            value: parsedValue,
          },
        };
        field.props.lookUp = updatedLookUp;
        field.templateOptions.lookUp = updatedLookUp;
      }

      if (field.fieldGroup) {
        this.updateDependentFields(field.fieldGroup, property, value);
      }
    });
  }

  updateModel(newModel: any) {
    this.modelSubject.next(newModel);
  }

  getCurrentModel() {
    return this.modelSubject.getValue();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  private injectDynamicFilters(fetchXml: string, model: any): string {
    if (!fetchXml) return fetchXml;
    return fetchXml.replace(/\{([^}]+)\}/g, (match, content) => {
      if (/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(content)) {
        return match;
      }
      const value = this.getNestedModelValue(model, content);
      if (value && typeof value === 'object' && value.id) {
        return value.id;
      }
      if (typeof value === 'string' && /^[0-9a-f]{8}-/i.test(value)) {
        return value;
      }
      if (typeof value === 'string' && content.includes('.id') && /^[0-9a-f]{8}-/i.test(value)) {
        return value;
      }
      console.warn(`Invalid filter value for ${content}`, value);
      return '00000000-0000-0000-0000-000000000000';
    });
  }

  private getNestedModelValue(model: any, path: string): any {
    if (!model || !path) return null;
    return model.hasOwnProperty(path) ? model[path] : null;
  }
}
