/* tslint:disable */
/* eslint-disable */
import { UserIBackingStore } from './user-i-backing-store';
import { UserResponseType } from './user-response-type';
export interface UserResponseStatus {
  additionalData?: null | {
    [key: string]: any;
  };
  backingStore?: UserIBackingStore;
  odataType?: null | string;
  response?: UserResponseType;
  time?: null | string;
}
