/* tslint:disable */
/* eslint-disable */
export enum UserFreeBusyStatus {
  Unknown = 'unknown',
  Free = 'free',
  Tentative = 'tentative',
  Busy = 'busy',
  Oof = 'oof',
  WorkingElsewhere = 'workingElsewhere',
}
