import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { registerMgtPersonCardComponent, registerMgtPersonComponent } from '@microsoft/mgt-components';
import { Providers, TeamsHelper, TemplateHelper } from '@microsoft/mgt-element';
import { Msal2Provider } from '@microsoft/mgt-msal2-provider';
import { TeamsFxProvider } from '@microsoft/mgt-teamsfx-provider';
import { Client } from '@microsoft/microsoft-graph-client';
import { TeamsUserCredential } from '@microsoft/teamsfx';
import { User, UserAppConfigDto } from '@verde/api';
import { AuthenticationService, UserService } from '@verde/core';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { AuthState } from '../models/auth-state';
import { UserCalendarEvents } from '../models/user-calendar-events';

@Injectable({
  providedIn: 'root',
})
export class GraphService implements OnDestroy {
  private onDestroy$ = new Subject<boolean>();

  private graphClient: Client | null = null;
  user: User;
  manager: User;
  loaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  supported$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject('environment') private environment: any,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private http: HttpClient,
  ) {
    combineLatest([this.authenticationService.authState$, this.userService.config$])
      .pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.onDestroy$))
      .subscribe((data) => {
        this.initializeGraphClient(data[0], data[1]);
      });
  }

  private async initializeGraphClient(authState: AuthState | undefined, config: UserAppConfigDto | undefined) {
    this.loaded$.next(false);
    this.supported$.next(false);

    if (config && authState) {
      if ((authState.isMicrosoftAuthed || authState.isTeamsAuthed) && config?.legalEntityConfig?.graphIntegration) {
        if (TeamsHelper.isAvailable && authState?.isTeamsAuthed) {
          const provider = new TeamsFxProvider(
            new TeamsUserCredential({
              clientId: this.environment.msalConfig.auth.clientId,
              initiateLoginEndpoint: this.environment.msalConfig.auth.redirectUri,
            }),
            this.environment.graphConfig.scopes,
          );

          Providers.globalProvider = provider;
        } else {
          Providers.globalProvider = new Msal2Provider({
            clientId: this.environment.msalConfig.auth.clientId,
            scopes: this.environment.graphConfig.scopes,
            redirectUri: this.environment.msalConfig.auth.redirectUri,
          });
        }

        TemplateHelper.setBindingSyntax('[[', ']]');
        registerMgtPersonComponent();
        registerMgtPersonCardComponent();

        this.graphClient = Client.initWithMiddleware({
          authProvider: Providers.globalProvider,
        });

        setTimeout(() => {
          this.supported$.next(true);
          this.loaded$.next(true);
        });
      } else {
        this.graphClient = null;

        setTimeout(() => {
          this.supported$.next(false);
          this.loaded$.next(true);
        });
      }
    }
  }

  // API Calls

  async getGraphUserCalendarEvents(): Promise<UserCalendarEvents | null> {
    if (this.graphClient === null) {
      return null;
    }

    try {
      return await this.graphClient.api('/me/events').select('subject,start').get();
    } catch (err) {
      return null;
    }
  }

  async getPerson(email: string) {
    if (this.graphClient === null) {
      return null;
    }

    try {
      return await this.graphClient.api('/me/people?$search=%22' + email + '%22&$top=1&$filter=personType/class%20eq%20%27Person%27').get();
    } catch (err) {
      return null;
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
