import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
import {
  BookingDurationsFiltered,
  BookingsBookingAvailabilitySearchDataDto,
  BookingsBookingOfficeDto,
  BookingsBookingSubOfficeDto,
  BookingsBtBookingCategory,
  BookingTypesFiltered,
  LocationsFiltered,
  OfficesFiltered,
  SubOfficesFiltered,
  UserUserDto,
} from '@verde/api';

import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, take, takeUntil } from 'rxjs';
import { VerdeApprovalService } from '../../../services/verde-approval.service';
import { DeviceTypeService } from 'libs/core/src/lib/services/device-type.service';
import { UserService } from '@verde/core';
import { SidePanelWidth } from '../../../enums/sidepanel-width.enum';
import { BookingsDataService } from '@verde/shared';

@Component({
  selector: 'verde-booking-availability-search',
  templateUrl: './booking-availability-search.component.html',
  styleUrls: ['./booking-availability-search.component.scss'],
})
export class BookingAvailabilitySearchComponent implements OnInit, AfterViewInit, OnDestroy {
  private onDestroy$ = new Subject<boolean>();
  disableAnimation: boolean;

  // Api data fields
  bookingAvailabilities: GridDataResult;
  bookingData: BookingsBookingAvailabilitySearchDataDto[] = [];
  primaryLocations: LocationsFiltered[] = [];
  offices: BookingsBookingOfficeDto[] = [];
  subOffices: BookingsBookingSubOfficeDto[] = [];
  bookingDurations: BookingDurationsFiltered[] = [];

  isLoading = false;

  // Search criteria fields
  fromDate: Date;
  toDate: Date;

  //Booking Recurring Fields
  isRecurring: boolean;
  isRecurringOptions = [
    { text: 'Yes', value: true },
    { text: 'No', value: false },
  ];
  numberOfDays: number;
  includeWeekends = false;
  maxNumberOfDays: number;
  daysOptions: Array<{ text: string; value: number }> = [];
  filteredDaysOptions: Array<{ text: string; value: number }> = [];
  selectedNumberOfDays: number | null = null;

  bookingDuration: any;
  bookingType: any;

  selectedBookingDuration: BookingDurationsFiltered;

  primaryLocation: LocationsFiltered;
  office: BookingsBookingOfficeDto;
  subOffice: BookingsBookingSubOfficeDto;
  SelectedSubOffice: BookingsBookingSubOfficeDto;
  bookingItem: string;
  primaryLocObject: LocationsFiltered;
  primaryOfficeObject: OfficesFiltered;
  // Loading indicators
  bookingDurationsLoading: boolean;
  bookingTypesLoading: boolean;
  primaryLocationsLoading: boolean;
  officesLoading: boolean;
  subOfficesLoading: boolean;

  // Not found indicators
  subOfficesNotFound = false;
  officesNotFound = false;
  bookingAvailabilitiesNotFound = false;
  displayOfficeSelection = false;
  displaySubOfficeSelection = false;

  showAvailabilityResultsModal: boolean;
  MinDate: any;

  user: UserUserDto;

  PrefSet = false;

  PrimaryLocName: string;
  OfficeLocName: string;
  SubOfficeLocName: string;

  UserDepartment: string;
  DepartmentToPass: string;
  BookingTypeName: string;

  BookingReason: string;

  NeedDepartment: boolean;
  NeedOffice: boolean;
  NeedReason: boolean;
  NeedSubOffice: boolean;
  NeedLocation: boolean;

  format = 'yyyy/MM/dd HH:mm';

  steps: any = {
    minute: 15,
  };

  get BookingSubmitActive(): boolean {
    if (this.bookingType?.bt_bookingcategory === BookingsBtBookingCategory.Rooms) {
      return (
        this.bookingDuration != null &&
        this.primaryLocation != null &&
        this.office != null &&
        ((this.isRecurring == true && this.selectedNumberOfDays != null) || this.isRecurring == false)
      );
    } else {
      return (
        this.bookingDuration != null &&
        this.primaryLocation != null &&
        ((this.office != null && this.subOffice != null) || this.BookingReason?.length > 10) &&
        ((this.isRecurring == true && this.selectedNumberOfDays != null) || this.isRecurring == false)
      );
    }
  }

  @Input() openModal: boolean;

  constructor(
    private sidebarService: VerdeApprovalService,
    private cdr: ChangeDetectorRef,
    private userService: UserService,
    private spinner: NgxSpinnerService,
    public deviceTypeService: DeviceTypeService,
    public bookingsDataService: BookingsDataService,
  ) {}

  ngOnInit(): void {
    this.isRecurring = false;
    this.user = this.userService.proxyUser;
    this.UserDepartment = this.user?.divisionName + ' -- ' + this.user?.departmentName;
    this.DepartmentToPass = this.user?.departmentId;
    this.userService.disableAnimation$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => (this.disableAnimation = data));

    this.open();
  }

  resetBookingFields() {
    this.bookingType = null;
    this.bookingDurations = [];
    this.fromDate = new Date(moment().format('YYYY-MM-DDT07:00'));
    this.toDate = new Date(moment().format('YYYY-MM-DDT07:00'));
    this.primaryLocation = null;
    this.office = null;
    this.subOffice = null;
    this.displayOfficeSelection = false;
    this.displaySubOfficeSelection = false;
    this.MinDate = new Date(moment().format('YYYY-MM-DDT07:00'));
    this.selectedNumberOfDays = null;
    this.offices = [];
    this.subOffices = [];
    this.primaryLocations = [];
  }

  SetBookingType(e: BookingTypesFiltered) {
    this.resetBookingFields();
    this.bookingType = e;
    this.BookingTypeName = e.bt_bookingtype;
    this.maxNumberOfDays = e.bt_maxrecurringdays;
    this.primaryLocations = this.bookingType.bookingItemLocations || [];

    if (this.primaryLocations && this.primaryLocations.length === 1) {
      this.primaryLocation = this.primaryLocations[0];
      this.updatePrimaryLocation(this.primaryLocation);
    }

    this.getAllBookingDurations(e.bt_bookingtype);

    const SelectedType = this.bookingData.find((y) => y.bt_bookingtype === this.BookingTypeName);

    this.NeedDepartment = SelectedType.bt_department;
    this.NeedOffice = SelectedType.bt_office;
    this.NeedReason = SelectedType.bt_reasonrequired;
    this.NeedSubOffice = SelectedType.bt_suboffice;
    this.NeedLocation = SelectedType.bt_location;

    if (!this.NeedLocation) {
      this.primaryLocation = null;
    }
    if (!this.NeedOffice) {
      this.office = null;
    }
    if (!this.NeedSubOffice) {
      this.subOffice = null;
    }
    if (this.maxNumberOfDays == null) {
      this.isRecurring = false;
    }

    this.BookingReason = null;
  }

  open() {
    this.resetBookingFields();
    this.getAllBookingTypes();
  }

  private resetBookingTypes(): void {
    this.bookingData = null;
  }

  private resetBookingDurations(): void {
    this.bookingDurations = null;
  }

  private resetForPrimaryLocations(): void {
    this.offices = [];
    this.office = null;
    this.subOffices = [];
    this.subOffice = null;
  }

  private resetForOffices(): void {
    this.subOffices = [];
    this.subOffice = null;
  }

  getAllBookingTypes() {
    this.resetBookingTypes();

    this.bookingTypesLoading = true;
    this.primaryLocationsLoading = true;

    this.bookingsDataService
      .getBookingTypeWithLocations({ legalEntityId: this.userService.user?.legalEntityId })
      .pipe(take(1), takeUntil(this.onDestroy$))
      .subscribe({
        next: (allBookingTypes) => {
          this.bookingData = [...allBookingTypes];

          this.primaryLocations = [
            ...new Map(
              allBookingTypes
                .flatMap((bt) => bt.bookingItemLocations) // Flatten all locations into a single array
                .map((loc) => [loc.bthr_locationid, loc]), // Use Map to store unique locations by ID
            ).values(),
          ];
        },
        error: (error) => console.error('Error fetching booking types:', error),
        complete: () => {
          if (this.bookingData && this.bookingData.length === 1) {
            this.bookingType = this.bookingData[0];
            this.SetBookingType(this.bookingType);
          }

          this.finalizeLoading('bookingtypes', 'bookingTypesLoading');

          //For cases when clicking the back button on the availabilties side bar
          if (this.bookingsDataService.bookingAvailabilitySearchDetails) {
            this.SetBookingType(this.bookingData.find((y) => y.bt_bookingtypeid === this.bookingsDataService.bookingAvailabilitySearchDetails.bookingType));
            this.fromDate = this.bookingsDataService.bookingAvailabilitySearchDetails.bookingFrom;
            this.toDate = this.bookingsDataService.bookingAvailabilitySearchDetails.bookingTo;
            this.selectedBookingDuration = this.bookingDurations.find(
              (d) => d.bt_verdebookingdurationid === this.bookingsDataService.bookingAvailabilitySearchDetails.bookingDuration,
            );
          }
        },
      });

    this.BookingSubmitActive;
  }

  private finalizeLoading(spinnerName: string, loadingFlag: string): void {
    this.spinner.hide(spinnerName);
    this[loadingFlag] = false;
    this.bookingTypesLoading = false;
    this.primaryLocationsLoading = false;
  }

  getAllBookingDurations(bookingType: string) {
    this.resetBookingDurations();

    this.bookingDurations = this.bookingType?.bookingDurations.filter((duration) => duration.bt_bookingtype === bookingType);

    this.spinner.show('bookingdurations');
    this.bookingDurationsLoading = true;

    if (this.bookingDurations && this.bookingDurations.length === 1) {
      this.selectedBookingDuration = this.bookingDurations[0];
      this.SwapIDAndMin(this.selectedBookingDuration.bt_verdebookingdurationid, true);
    }
    this.spinner.hide('bookingdurations');
    this.bookingDurationsLoading = false;
  }

  getAllOffices() {
    this.officesLoading = true;
    this.offices = this.bookingType.bookingItemOffices.filter((office) => office.bthr_locationid === this.primaryLocObject.bthr_locationid);

    if (!this.offices || this.offices.length == 0) {
      this.officesNotFound = true;
      this.subOfficesNotFound = false;
    } else {
      this.officesNotFound = false;
      this.subOfficesNotFound = false;
    }

    if (this.offices && this.offices.length === 1) {
      this.office = this.offices[0];
      this.updateOffice(this.office);
      this.getAllSubOffices();
    }

    this.officesLoading = false;
  }

  getAllSubOffices() {
    this.subOfficesLoading = true;

    this.subOffices = this.bookingType.bookingItemSubOffices.filter((subOffice) => subOffice.bt_verdeofficeid === this.primaryOfficeObject.bt_verdeofficeid);

    if (!this.subOffices || this.subOffices.length == 0) {
      this.subOfficesNotFound = true;
    } else {
      this.subOfficesNotFound = false;

      this.subOffices.forEach((t) => {
        if (t.bt_verdesubofficeid === this.user?.subOfficeId) {
          this.SelectedSubOffice = t;
        }
      });
    }

    if (this.subOffices && this.subOffices.length === 1) {
      this.subOffice = this.subOffices[0];
    }

    this.subOfficesLoading = false;
  }

  searchForAvailability() {
    if (this.deviceTypeService.isMobile() || this.deviceTypeService.isTablet()) {
      this.sidebarService.setSidebarSize(SidePanelWidth.Full);
    } else {
      if (this.bookingType?.bt_bookingcategory === BookingsBtBookingCategory.Rooms) {
        this.sidebarService.setSidebarSize(SidePanelWidth.Full);
      } else {
        this.sidebarService.setSidebarSize(SidePanelWidth.TwoThirds);
      }
    }

    if (this.bookingType?.bt_bookingcategory === BookingsBtBookingCategory.Rooms) {
      this.sidebarService.setSidebarType('bookingBoardroomCalendar');
    } else {
      this.sidebarService.setSidebarType('bookingAvailabilityResults');
    }

    this.sidebarService.setBookingAvailabilitySearchDetails({
      bookingFrom: this.fromDate,
      bookingTo: this.toDate,
      bookingDuration: this.bookingDuration,
      bookingDurationMinutes: this.selectedBookingDuration.bt_minutes,
      bookingType: this.bookingType?.bt_bookingtypeid,
      bookingTypeName: this.bookingType?.bt_bookingtype,
      primaryLocation: this.primaryLocation?.bthr_locationid,
      office: this.office?.bt_verdeofficeid,
      subOffice: this.subOffice?.bt_verdesubofficeid,
      bookingItem: this.bookingItem,
      PrimaryLocName: this.PrimaryLocName,
      OfficeLocName: this.OfficeLocName,
      SubOfficeLocName: this.subOffice?.bt_name,
      SelectedSubOffice: this.SelectedSubOffice,
      BookingReason: this.BookingReason,
      DepartmentToPass: this.DepartmentToPass,
      isRecurring: this.isRecurring ?? false,
      numberOfDays: this.selectedNumberOfDays ?? 0,
      includeWeekends: this.includeWeekends ?? false,
      displayTitleMessage: '',
      officeIsInteractive: this.office?.bt_interactive ?? false,
      subOfficeIsInteractive: this.subOffice?.bt_interactive ?? false,
    });

    this.sidebarService.setShowSidebar(true);
  }

  toggleOfficeSelection() {
    this.displayOfficeSelection = !this.displayOfficeSelection;

    if (this.displayOfficeSelection) {
      this.getAllOffices();
    }
  }

  toggleSubOfficeSelection() {
    this.displaySubOfficeSelection = !this.displaySubOfficeSelection;

    if (this.displaySubOfficeSelection) {
      this.getAllSubOffices();
    }
  }

  updatePrimaryLocation(e: LocationsFiltered) {
    this.primaryLocObject = e;

    this.PrimaryLocName = e.bthr_locationname;
    this.resetForPrimaryLocations();

    if (this.primaryLocObject) {
      this.getAllOffices();
    }

    if (this.primaryLocation != null && !this.displayOfficeSelection && !this.PrefSet) {
      this.toggleOfficeSelection();
    }
  }

  updateOffice(e: OfficesFiltered) {
    this.primaryOfficeObject = e;
    this.OfficeLocName = e.bt_officename;
    this.resetForOffices();
    if (this.office != null && !this.displaySubOfficeSelection && this.PrefSet) {
      this.toggleSubOfficeSelection();
    }
  }

  updateSubOffice(e: SubOfficesFiltered) {
    this.SubOfficeLocName = e.bt_name;
    this.subOffices.forEach((t) => {
      if (t.bt_verdesubofficeid == this.subOffice?.bt_verdesubofficeid) {
        this.SelectedSubOffice = t;
      }
    });
  }

  recalculateDateValues(from: boolean) {
    if (!this.bookingDuration) {
      return;
    }
    if (from) {
      this.toDate = new Date(moment(this.fromDate).add(this.bookingDuration, 'minutes').format('YYYY-MM-DDTHH:mm'));
    } else {
      this.fromDate = new Date(moment(this.toDate).subtract(this.bookingDuration, 'minutes').format('YYYY-MM-DDTHH:mm'));
    }
  }

  updateFromDate(e) {
    this.fromDate = new Date(moment(e).format('YYYY-MM-DDTHH:mm'));
    this.SwapIDAndMin(e, true);
  }

  updateToDate(e) {
    this.toDate = new Date(moment(e).format('YYYY-MM-DDTHH:mm'));
    this.SwapIDAndMin(e, false);
  }

  updateFromDatePicker(e) {
    this.SwapIDAndMin(e.bt_verdebookingdurationid, true);
  }

  SwapIDAndMin(ID, bool) {
    this.bookingDurations.forEach((t) => {
      if (t.bt_verdebookingdurationid === ID) {
        this.selectedBookingDuration = t;
      }
    });

    this.bookingDuration = this.selectedBookingDuration.bt_minutes.toString();
    if (this.bookingDuration === '540') {
      const date = new Date(this.fromDate);
      date.setHours(8, 0);
      this.fromDate = new Date(moment(date).format('YYYY-MM-DDTHH:mm'));
    }
    this.recalculateDateValues(bool);
    this.bookingDuration = this.selectedBookingDuration.bt_verdebookingdurationid.toString();
  }

  onRecurringChange(event: any) {
    this.isRecurring = event;

    if (this.isRecurring == false) {
      this.selectedNumberOfDays = null;
      this.includeWeekends = false;
    }
  }

  onInputChange(event: Event): void {
    const input = (event.target as HTMLInputElement).value;
    const numericValue = input.replace(/[^0-9]/g, ''); // Remove non-numeric characters
    const parsedValue = parseInt(numericValue, 10);

    if (!isNaN(parsedValue)) {
      if (parsedValue > this.maxNumberOfDays) {
        this.selectedNumberOfDays = this.maxNumberOfDays;
      } else if (parsedValue < 1) {
        this.selectedNumberOfDays = 1;
      } else {
        this.selectedNumberOfDays = parsedValue;
      }
    } else {
      this.selectedNumberOfDays = null;
    }

    (event.target as HTMLInputElement).value = this.selectedNumberOfDays.toString();
  }

  cancel() {
    this.sidebarService.setShowSidebar(false);
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.bookingsDataService.bookingAvailabilitySearchDetails = undefined;
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
