/* tslint:disable */
/* eslint-disable */
import { TimeOffBthrCalculationStatus } from './time-off-bthr-calculation-status';
import { TimeOffBthrRequestsStatus } from './time-off-bthr-requests-status';
import { TimeOffBthrPartdayrequest } from './time-off-bthr-partdayrequest';
export interface TimeOffTimeOffExample {
  bthr_calculationstatus?: TimeOffBthrCalculationStatus;
  bthr_enddate?: string;
  bthr_partdayrequestchoice?: TimeOffBthrPartdayrequest;
  bthr_proxy?: boolean;
  bthr_requestdate?: string;
  bthr_requestno?: null | string;
  bthr_requestsstatus?: TimeOffBthrRequestsStatus;
  bthr_startdate?: string;
  odataPostEmployee?: null | string;
  odataPostLegalEntity?: null | string;
  odataPostManager?: null | string;
  odataPostManagerLegalEntity?: null | string;
  odataPostProxy?: null | string;
  odataPostTimeOffCheck?: null | string;
  odataPostTxnType?: null | string;
}
