<verde-loader [loading]="isLoading"></verde-loader>

<div class="outer-container">
  <div class="scroll-wrapper">
    <div class="tile-container">
      <kendo-tilelayout>
        <!-- Calendar Tile (Fixed Size) -->
        <kendo-tilelayout-item title="My Calendar" [col]="1" [colSpan]="1" class="calendar-tile">
          <kendo-tilelayout-item-body>
            <kendo-scheduler
              #schedulerComponent
              [kendoSchedulerBinding]="calendarWithEvents"
              [selectedDate]="searchDate"
              [min]="searchDate"
              [max]="searchDate"
              startTime="06:00"
              endTime="23:59"
              slotHeight="10"
              style="height: 100%; width: 100%"
              [eventClass]="getEventClass"
            >
              <kendo-scheduler-day-view [showWorkHours]="true" [ngClass]="{ 'my-full-day': false }"> </kendo-scheduler-day-view>
            </kendo-scheduler>
          </kendo-tilelayout-item-body>
        </kendo-tilelayout-item>

        <!-- Booking Details Tile -->
        <kendo-tilelayout-item title="Booking Details" [col]="2" [colSpan]="2" class="booking-tile">
          <kendo-tilelayout-item-body>
            <div class="form-container">
              <div class="date-fields">
                <!-- From Field -->
                <div class="date-field">
                  <label for="bookingFrom">From</label>
                  <kendo-datetimepicker
                    [readonly]="true"
                    [disabled]="true"
                    id="bookingFrom"
                    name="bookingFrom"
                    class="small-datepicker"
                    [format]="'dd/MM/yyyy HH:mm'"
                    [ngModel]="fromDate"
                  >
                  </kendo-datetimepicker>
                </div>

                <!-- To Field -->
                <div class="date-field">
                  <label for="bookingTo">To</label>
                  <kendo-datetimepicker
                    [readonly]="true"
                    [disabled]="true"
                    id="bookingTo"
                    name="bookingTo"
                    class="small-datepicker"
                    [format]="'dd/MM/yyyy HH:mm'"
                    [ngModel]="toDate"
                  >
                  </kendo-datetimepicker>
                </div>
              </div>

              <!-- Booking List -->
              <div class="booking-list" *ngIf="!isLoading">
                <br />
                <span *ngIf="availabilityDetails?.isAvailabaleRooms" class="heading-text">Rooms Available<br /> </span>
                <span *ngIf="!availabilityDetails?.isAvailabaleRooms" class="heading-text"
                  >No rooms available on selected date and time <br />
                  Other available options:<br />
                </span>
                <div class="booking-item" *ngFor="let booking of availabilityDetails.availabilties">
                  <span class="booking-name">{{ booking.bookingName }}</span>
                  <span *ngIf="!availabilityDetails?.isAvailabaleRooms" class="booking-dates">
                    {{ booking.startDateTime | date : 'dd/MM/yyyy HH:mm' }} - {{ booking.endDateTime | date : 'dd/MM/yyyy HH:mm' }}
                  </span>
                  <button kendoButton themeColor="primary" (click)="bookRoom(booking)">Book</button>
                </div>
              </div>
            </div>
          </kendo-tilelayout-item-body>
        </kendo-tilelayout-item>
      </kendo-tilelayout>
    </div>
  </div>

  <!-- Bottom Buttons -->
  <div class="side-panel-bottom-buttons">
    <button kendoButton style="background-color: lightgrey; color: black" (click)="backToSearch.emit()">Back</button>
    <div class="right-buttons">
      <button kendoButton style="background-color: lightgrey; color: black" (click)="cancel()">Cancel</button>
    </div>
  </div>
</div>
