/* eslint-disable no-console */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DoCheck, OnDestroy, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldProps } from '@ngx-formly/core';
import { FormlyFieldSelectProps } from '@ngx-formly/core/select';
import { WebDynamicGetDynamicFormLookupQuery } from '@verde/api';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, map, startWith, takeUntil, tap } from 'rxjs/operators';

interface SelectProps extends FormlyFieldProps, FormlyFieldSelectProps {
  primitive?: boolean;
  loading?: boolean;
  readOnly?: boolean;
  required?: boolean;
  tabindex?: number;
  disabled?: boolean;
  change?: (field: any, event: any) => void;
  lookUp?: WebDynamicGetDynamicFormLookupQuery;
}

@Component({
  selector: 'verde-kendo-formly-select',
  template: `
    <kendo-dropdownlist
      class="verde-custom-field"
      [id]="field.key"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [data]="filteredOptions$ | async"
      [textField]="textField"
      [valueField]="valueField"
      [value]="formControl.value"
      [readonly]="props.readOnly"
      [required]="props.required"
      [tabIndex]="props.tabindex || 0"
      [disabled]="props.disabled"
      [valuePrimitive]="props.primitive ?? true"
      [attr.data-selected-value]="formControl.value"
      [filterable]="true"
      (filterChange)="onFilterChange($event)"
      (valueChange)="onValueChange($event)"
    >
      <!-- Template for selected value -->
      <ng-template kendoDropDownListValueTemplate let-dataItem>
        <ng-container *ngIf="props.loading; else checkValue">
          <kendo-loader style="padding-block: unset !important;" type="pulsing" themeColor="primary"></kendo-loader>
        </ng-container>

        <ng-template #checkValue>
          <ng-container *ngIf="dataItem; else placeholder">
            {{ dataItem?.[textField] }}
          </ng-container>
        </ng-template>

        <ng-template #placeholder>
          <span class="k-placeholder">Select ...</span>
        </ng-template>
      </ng-template>

      <!-- Template for dropdown list items -->
      <ng-template kendoDropDownListItemTemplate let-dataItem>
        {{ renderDataItem(dataItem) }}
      </ng-template>
    </kendo-dropdownlist>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KendoFormlySelect extends FieldType<FieldTypeConfig<SelectProps>> implements OnInit, OnDestroy, DoCheck {
  private onDestroy$ = new Subject<boolean>();
  private lookUpSubject = new BehaviorSubject<WebDynamicGetDynamicFormLookupQuery | undefined>(undefined);
  private filterQuery$ = new BehaviorSubject<string | null>(null); // Holds the current filter query
  options$: Observable<any[]>;
  filteredOptions$: Observable<any[]>;
  textField = 'label';
  valueField = 'value';

  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.textField = this.props.lookUp?.label || 'label';
    this.valueField = this.props.lookUp?.value || 'value';

    /// Parse initial default value if it's a JSON string
    let parsedDefault: any;
    let hasParsedDefaultValue = false;

    if (typeof this.formControl.defaultValue === 'string') {
      try {
        parsedDefault = JSON.parse(this.formControl.defaultValue);
        if (parsedDefault?.value) {
          this.formControl.setValue(parsedDefault.value); // Set the temporary value
          hasParsedDefaultValue = true;
        }
      } catch (error) {
        console.warn('Invalid default value JSON:', this.formControl.defaultValue);
      }
    }

    let initialLoad = true; // Track first emission (default value vs real options)

    if (this.props.options instanceof Observable) {
      this.props.loading = !hasParsedDefaultValue; // Show loader only if no default value

      this.options$ = this.props.options.pipe(
        startWith(hasParsedDefaultValue ? [{ [this.valueField]: parsedDefault.value, [this.textField]: parsedDefault.label }] : []),
        tap(() => {
          if (!initialLoad && !hasParsedDefaultValue) {
            this.props.loading = false; // Set loading false only for real backend data
            if (this.formControl.defaultValue && this.formControl.defaultValue !== '') {
              this.formControl.setValue(this.formControl.defaultValue);
              this.formControl.markAsTouched();
              this.cd.detectChanges();
            }
          }
          initialLoad = false; // Mark that we've received the first actual backend data
        }),
        catchError((error) => {
          console.error('Error loading options:', error);
          this.props.loading = false;
          this.cd.detectChanges();
          return of([]);
        }),
        takeUntil(this.onDestroy$),
      );

      this.filteredOptions$ = combineLatest([this.options$, this.lookUpSubject.asObservable(), this.filterQuery$]).pipe(
        map(([options, lookUp, filterQuery]) => {
          let filtered = options;

          // Apply dependency-based filtering
          if (lookUp) {
            filtered = filtered.filter((option) => {
              if (lookUp.dependency && Object.keys(lookUp.dependency).length > 0) {
                if (lookUp.dependency?.property === null) {
                  return true;
                }

                const optionKeys = Object.keys(option);
                if (optionKeys.length > 0) {
                  return Object.keys(option).some((key) => {
                    const isObj = typeof option[key] === 'object';
                    const hasValue = lookUp.dependency.value !== undefined && lookUp.dependency.value !== null;
                    return hasValue && ((isObj && option[key].id === lookUp.dependency.value) || (!isObj && option[key] === lookUp.dependency.value));
                  });
                } else {
                  return true;
                }
              }
              return true;
            });
          }

          // Apply search filter
          if (filterQuery) {
            const query = filterQuery.toLowerCase();
            filtered = filtered.filter((option) => option[this.textField]?.toLowerCase().includes(query));
          }

          return filtered;
        }),
        takeUntil(this.onDestroy$),
      );
    } else {
      this.options$ = of(this.props.options);
      this.filteredOptions$ = this.options$;
      if (this.formControl.defaultValue && this.formControl.defaultValue !== '') {
        this.formControl.setValue(this.formControl.defaultValue);
        this.cd.detectChanges();
      } else {
        if (this.props?.options && this.props?.options?.length > 0) {
          this.formControl.setValue(this.props.options[0][this.valueField]);
          this.cd.detectChanges();
        }
      }
    }
  }

  renderDataItem(dataItem: any): string {
    if (!dataItem || typeof dataItem !== 'object') {
      return '';
    }

    const values: string[] = [];
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i; // UUID v4 pattern

    for (const key in dataItem) {
      if (key === 'value') {
        continue; // Skip fields with the key 'value'
      }

      if (Object.prototype.hasOwnProperty.call(dataItem, key)) {
        const value = dataItem[key];

        if (typeof value === 'string' || typeof value === 'number') {
          // Exclude UUIDs from rendering
          if (typeof value === 'string' && uuidRegex.test(value)) {
            continue;
          }
          values.push(String(value));
        } else if (typeof value === 'object' && value !== null) {
          // If the object has a "name" field, extract it (but exclude UUIDs)
          if ('name' in value && typeof value.name === 'string' && !uuidRegex.test(value.name)) {
            values.push(value.name);
          }
        }
      }
    }

    return values.join(' | ');
  }

  onFilterChange(query: string) {
    this.filterQuery$.next(query); // Update the current filter query
    this.cd.detectChanges();
  }

  ngDoCheck() {
    this.filterOptions();
  }

  filterOptions() {
    this.lookUpSubject.next(this.props.lookUp);
  }

  onValueChange(event: any) {
    if (this.props.change) {
      this.props.change(this.field, event);
    }
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
