/* tslint:disable */
/* eslint-disable */
import { UserAttachment } from './user-attachment';
import { UserAttendee } from './user-attendee';
import { UserCalendar } from './user-calendar';
import { UserDateTimeTimeZone } from './user-date-time-time-zone';
import { UserEventType } from './user-event-type';
import { UserExtension } from './user-extension';
import { UserFreeBusyStatus } from './user-free-busy-status';
import { UserIBackingStore } from './user-i-backing-store';
import { UserImportance } from './user-importance';
import { UserItemBody } from './user-item-body';
import { UserLocation } from './user-location';
import { UserMultiValueLegacyExtendedProperty } from './user-multi-value-legacy-extended-property';
import { UserOnlineMeetingInfo } from './user-online-meeting-info';
import { UserOnlineMeetingProviderType } from './user-online-meeting-provider-type';
import { UserPatternedRecurrence } from './user-patterned-recurrence';
import { UserRecipient } from './user-recipient';
import { UserResponseStatus } from './user-response-status';
import { UserSensitivity } from './user-sensitivity';
import { UserSingleValueLegacyExtendedProperty } from './user-single-value-legacy-extended-property';
export interface UserEvent {
  additionalData?: null | {
    [key: string]: any;
  };
  allowNewTimeProposals?: null | boolean;
  attachments?: null | Array<UserAttachment>;
  attendees?: null | Array<UserAttendee>;
  backingStore?: UserIBackingStore;
  body?: UserItemBody;
  bodyPreview?: null | string;
  calendar?: UserCalendar;
  categories?: null | Array<string>;
  changeKey?: null | string;
  createdDateTime?: null | string;
  end?: UserDateTimeTimeZone;
  extensions?: null | Array<UserExtension>;
  hasAttachments?: null | boolean;
  hideAttendees?: null | boolean;
  iCalUId?: null | string;
  id?: null | string;
  importance?: UserImportance;
  instances?: null | Array<UserEvent>;
  isAllDay?: null | boolean;
  isCancelled?: null | boolean;
  isDraft?: null | boolean;
  isOnlineMeeting?: null | boolean;
  isOrganizer?: null | boolean;
  isReminderOn?: null | boolean;
  lastModifiedDateTime?: null | string;
  location?: UserLocation;
  locations?: null | Array<UserLocation>;
  multiValueExtendedProperties?: null | Array<UserMultiValueLegacyExtendedProperty>;
  odataType?: null | string;
  onlineMeeting?: UserOnlineMeetingInfo;
  onlineMeetingProvider?: UserOnlineMeetingProviderType;
  onlineMeetingUrl?: null | string;
  organizer?: UserRecipient;
  originalEndTimeZone?: null | string;
  originalStart?: null | string;
  originalStartTimeZone?: null | string;
  recurrence?: UserPatternedRecurrence;
  reminderMinutesBeforeStart?: null | number;
  responseRequested?: null | boolean;
  responseStatus?: UserResponseStatus;
  sensitivity?: UserSensitivity;
  seriesMasterId?: null | string;
  showAs?: UserFreeBusyStatus;
  singleValueExtendedProperties?: null | Array<UserSingleValueLegacyExtendedProperty>;
  start?: UserDateTimeTimeZone;
  subject?: null | string;
  transactionId?: null | string;
  type?: UserEventType;
  webLink?: null | string;
}
