<div class="main">
  <verde-loader [loading]="bookingsDataService.bookingAvailabilitiesLoading"></verde-loader>

  <div class="scrollable-content" *ngIf="!bookingsDataService.bookingAvailabilitiesLoading">
    <kendo-tabstrip (tabSelect)="onTabSelect($event)">
      <kendo-tabstrip-tab [title]="getBookingsTitle" id="bookingTab" [selected]="selectedTabId === 'bookingTab'">
        <ng-template kendoTabContent>
          <div style="display: inline-flex">
            <div *ngIf="!isOfficeInteractive">
              <kendo-grid
                [kendoGridBinding]="bookingsDataService.bookingsGridView"
                [resizable]="true"
                [sortable]="true"
                [height]="360"
                [sort]="bookingsDataService.gridSort"
                [loading]="bookingsDataService.bookingAvailabilitiesLoading"
              >
                <ng-template kendoGridToolbarTemplate>
                  <input
                    placeholder="Search all columns"
                    kendoTextBox
                    style="text-transform: none !important"
                    (input)="onBookingsFilter($event.target.value)"
                  />
                  <kendo-grid-spacer></kendo-grid-spacer>
                  <img
                    title="Refresh"
                    class="refresh_icon"
                    style="float: right"
                    src="https://engageimages.z1.web.core.windows.net/Images/Verde/Other/reload.svg"
                    alt=""
                    (click)="refresh()"
                  />
                </ng-template>
                <kendo-grid-column field="bookingItem" title="Booking Item" [width]="120">
                  <ng-template kendoGridCellTemplate let-ListTable>
                    {{ ListTable.bookingItem }}
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="Booked By" [width]="350">
                  <ng-template kendoGridCellTemplate let-ListTable>
                    <span *ngIf="ListTable?.isBooked">
                      {{ ListTable?.fullName }} from
                      {{ ListTable?.fromDate | date : 'HH:mm' }}
                      to
                      {{ ListTable?.toDate | date : 'HH:mm' }} on {{ ListTable?.toDate | formatDate : 'MMM D' }}
                    </span>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="Actions" [width]="100" [sticky]="true">
                  <ng-template kendoGridCellTemplate let-ListTable>
                    <span *ngIf="!ListTable?.isBooked">
                      <button kendoButton themeColor="primary" (click)="bookingsDataService.openConfirmModel(ListTable)">Book</button>
                    </span>
                  </ng-template>
                </kendo-grid-column>
              </kendo-grid>
            </div>

            <div
              *ngIf="!bookingsDataService.bookingAvailabilitiesLoading && bookingsDataService.bookingAvailabilitySearchDetails?.bookingTypeName === 'Hot Desk'"
            >
              <img
                *ngIf="!isOfficeInteractive"
                style="margin-left: 10px"
                width="520"
                height="360"
                [src]="bookingsDataService.bookingAvailabilitySearchDetails?.SelectedSubOffice"
              />
              <verde-client *ngIf="isOfficeInteractive" [clientId]="userService?.user?.legalEntityId" [officeId]="getOfficeId"></verde-client>
            </div>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab [title]="getAllBookingsTitle" id="allBookingsForTheDayTab" [selected]="selectedTabId === 'allBookingsForTheDayTab'">
        <ng-template kendoTabContent>
          <kendo-grid
            [kendoGridBinding]="bookingsDataService.allBookingsForTheDayGridView"
            [resizable]="true"
            [sortable]="true"
            [groupable]="true"
            [group]="bookingsDataService.gridGroups"
            [height]="540"
            [sort]="bookingsDataService.gridSort"
            [loading]="bookingsDataService.allBookingsForTheDayLoading"
          >
            <ng-template kendoGridToolbarTemplate>
              <input
                placeholder="Search all columns"
                kendoTextBox
                style="text-transform: none !important"
                (input)="onAllBookingsForTheDayFilter($event.target.value)"
              />
              <kendo-grid-spacer></kendo-grid-spacer>
              <img
                title="Refresh"
                class="refresh_icon"
                style="float: right"
                src="https://engageimages.z1.web.core.windows.net/Images/Verde/Other/reload.svg"
                alt=""
                (click)="refresh()"
              />
            </ng-template>
            <kendo-grid-column field="bookingItem" title="Booking Item" [width]="120">
              <ng-template kendoGridCellTemplate let-ListTable>
                {{ ListTable?.bookingItem }}
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="Booked By" field="fullName" [width]="200">
              <ng-template kendoGridCellTemplate let-ListTable>
                {{ ListTable?.fullName }}
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="From" field="fromDate" [width]="100">
              <ng-template kendoGridCellTemplate let-ListTable>
                {{ ListTable?.fromDate | date : 'HH:mm' }}
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="To" field="toDate" [width]="100">
              <ng-template kendoGridCellTemplate let-ListTable>
                {{ ListTable?.toDate | date : 'HH:mm' }}
              </ng-template>
            </kendo-grid-column>
          </kendo-grid>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>
  </div>
  <div class="side-panel-bottom-buttons">
    <button kendoButton style="background-color: lightgrey; color: black" (click)="backToSearch.emit()">Back</button>

    <button kendoButton style="background-color: lightgrey; color: black" (click)="cancel()">Cancel</button>
  </div>
</div>

<verde-modal
  id="bookingDetailsModal"
  class="booking-details-modal"
  [modalTitle]="'Booking Details'"
  submitButton="Book"
  [hasCancelButton]="true"
  (actionButton)="bookingsDataService.bookItem()"
>
  <div style="position: relative">
    <p>
      Confirm Booking: Would you like to book {{ bookingsDataService.bookingAvailabilitySearchDetails?.BookingItemName }}
      on
      {{ bookingsDataService.bookingDate }} from {{ bookingsDataService.bookingStartDate }} to {{ bookingsDataService.bookingEndDate }}?
    </p>
  </div>
</verde-modal>
