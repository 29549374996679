<!-- User Card -->
<ng-container *ngIf="selectedTile(tiles?.userCard, 'body')">
    <div class="centered">
        <verde-workspace-user-card fullName="Bhekanani Cele"
                                   jobTitle="Software Engineer: ETS"></verde-workspace-user-card>
    </div>
</ng-container>

<!-- User Mobile Card -->
<ng-container *ngIf="selectedTile(tiles?.userMobileCard, 'body')">
    <div class="k-card">
        <img [src]="userMobileCardBackgroundImage | safe"
             alt="User Mobile Card" />
    </div>
</ng-container>

<!-- User Calendar -->
<ng-container *ngIf="selectedTile(tiles?.userCalendar, 'body')">
    <div class="centered">
        <kendo-calendar class="k-card"
                        type="classic"
                        [min]="min"
                        [max]="max">
            <!-- <ng-template kendoCalendarMonthCellTemplate
                         let-date>
                <span [style.color]="getColor(date)"
                      [ngClass]="isLeave(date)">{{ date.getDate() }}</span>
            </ng-template> -->
        </kendo-calendar>
    </div>
</ng-container>

<!-- User Tasks -->
<ng-container *ngIf="selectedTile(tiles?.userTasks, 'body')">
    <p>You do not have any current active tasks.</p>
</ng-container>

<!-- User Bookings -->
<ng-container *ngIf="selectedTile(tiles?.userBookings, 'body')">
    <p>You do not have any current bookings.</p>
</ng-container>

<!-- User Time Off -->
<ng-container *ngIf="selectedTile(tiles?.userTimeOff, 'body')">
    <p>Your time off has not been configured.</p>
</ng-container>

<!-- User Team -->
<ng-container *ngIf="selectedTile(tiles?.userTeam, 'badge')">
    <kendo-badge class="badge"
                 [align]="badgeAlign"
                 position="inside">10</kendo-badge>
</ng-container>

<!-- User Fleet -->
<ng-container *ngIf="selectedTile(tiles?.userFleet, 'badge')">
    <kendo-badge class="badge"
                 [align]="badgeAlign"
                 position="inside">6</kendo-badge>
</ng-container>

<!-- Org Logo -->
<ng-container *ngIf="selectedTile(tiles?.orgLogo, 'body')">
    <div *ngIf="tile?.data?.org?.logo && tile?.data?.org?.website"
         class="center-text">
        <img [src]="tile?.data?.org?.logo | safe"
             class="entity-image" />
        <h3 class="entity-title"
            kendoTooltip
            position="bottom"
            [callout]="false"
            [title]="tile?.toolTip"
            [ngClass]="{'clickable': tile?.navigation?.navigable, 'non-clickable': !tile?.navigation?.navigable}"
            (click)=" this.navigationService.navigateSomewhere(tile?.data?.org?.website, tile?.navigation?.isMenuItem, tile?.navigation?.isExternal)">
            {{tile?.data?.org?.name}}
        </h3>
    </div>
</ng-container>

<!-- User Manager -->
<ng-container *ngIf="selectedTile(tiles?.userManager, 'body')">
    <p>Pieter Cornelius.</p>
</ng-container>

<!-- Org Birthdays -->
<ng-container *ngIf="selectedTile(tiles?.orgBirthdays, 'body')">
    <p>No birthdays coming up in the next 5 days.</p>
</ng-container>

<!-- Vox Weather -->
<ng-container *ngIf="selectedTile(tiles?.userWeather, 'body')">
    <div class="k-card">
        <div class="tile-item-header">
            <h3 class="tile-item-header-left">{{tile?.title}}</h3>
        </div>
        <div class="tile-item-body">
            <iframe scrolling="no"
                    class="iframe"
                    title="{{tile?.title || 'Embedded content'}}"
                    [src]="tile?.data?.resourceUrl | safe"
                    frameborder="0"></iframe>
        </div>
    </div>
</ng-container>