<ngx-spinner
  [disableAnimation]="disableAnimation"
  name="dynamicFormSpinner"
  bdColor="rgb(255, 255, 255)"
  size="default"
  color="#6264a7"
  type="ball-clip-rotate"
  [fullScreen]="false"
>
  <p>Loading Data...</p>
</ngx-spinner>
<div class="main">
  <div class="scrollable-content">
    <form class="k-form" autocomplete="off">
      <kendo-gridlayout gap="12px 31px" [rows]="[]" [cols]="[{ width: '1fr' }, { width: '1fr' }]">
        <kendo-gridlayout-item [row]="1" [col]="1" [colSpan]="2">
          <kendo-formfield>
            <label for="bookingTypeDropdown">Booking Type:</label>
            <kendo-dropdownlist
              [data]="bookingData"
              textField="bt_bookingtype"
              valueField="bt_bookingtypeid"
              id="bookingTypeDropdown"
              name="bookingTypeDropdown"
              #bookingTypeDropdown="ngModel"
              required
              [ngModel]="bookingType"
              (valueChange)="SetBookingType($event)"
            >
              <ng-template kendoDropDownListValueTemplate let-dataItem>
                <ng-container *ngIf="bookingTypesLoading; else content">
                  <kendo-loader type="pulsing" themeColor="primary"></kendo-loader>
                </ng-container>
                <ng-template #content>
                  {{ dataItem?.bt_bookingtype }}
                </ng-template>
              </ng-template>
            </kendo-dropdownlist>
            <div class="alert alert-danger" *ngIf="bookingTypeDropdown.touched && !bookingTypeDropdown.valid">
              <div *ngIf="bookingTypeDropdown.errors.required">Booking type is required!</div>
            </div>
          </kendo-formfield>
        </kendo-gridlayout-item>

        <!-- Duration Dropdown -->
        <kendo-gridlayout-item [row]="2" [col]="1" [colSpan]="2">
          <kendo-formfield>
            <label for="bookingDurationDropdown">Duration:</label>
            <kendo-dropdownlist
              [data]="bookingDurations"
              textField="bt_duration"
              valueField="bt_verdebookingdurationid"
              id="bookingDurationDropdown"
              name="bookingDurationDropdown"
              #bookingDurationDropdown="ngModel"
              required
              [ngModel]="selectedBookingDuration"
              [disabled]="bookingType == null"
              (valueChange)="updateFromDatePicker($event)"
            >
              <ng-template kendoDropDownListValueTemplate let-dataItem>
                <ng-container *ngIf="bookingDurationsLoading; else content">
                  <kendo-loader type="pulsing" themeColor="primary"></kendo-loader>
                </ng-container>
                <ng-template #content>
                  {{ dataItem?.bt_duration }}
                </ng-template>
              </ng-template>
            </kendo-dropdownlist>

            <div class="alert alert-danger" *ngIf="bookingDurationDropdown.touched && !bookingDurationDropdown.valid">
              <div *ngIf="bookingDurationDropdown.errors.required">Duration is required!</div>
            </div>
          </kendo-formfield>
        </kendo-gridlayout-item>

        <!-- From Date -->
        <kendo-gridlayout-item [row]="3" [col]="1" [colSpan]="2">
          <kendo-formfield>
            <label for="bookingFrom">From</label>
            <kendo-datetimepicker
              [readonly]="bookingDuration == null"
              id="bookingFrom"
              name="bookingFrom"
              #bookingFrom="ngModel"
              required
              [ngModel]="fromDate"
              (valueChange)="updateFromDate($event)"
              [min]="MinDate"
              [format]="format"
              [steps]="steps"
            >
            </kendo-datetimepicker>

            <div class="alert alert-danger" *ngIf="bookingFrom.touched && !bookingFrom.valid">
              <div *ngIf="bookingFrom.errors?.required">To date is required!</div>
            </div>
          </kendo-formfield>
        </kendo-gridlayout-item>

        <!-- To Date -->
        <kendo-gridlayout-item [row]="4" [col]="1" [colSpan]="2">
          <kendo-formfield>
            <label for="bookingTo">To</label>
            <kendo-datetimepicker
              id="bookingTo"
              name="bookingTo"
              #bookingTo="ngModel"
              required
              [ngModel]="toDate"
              (valueChange)="updateToDate($event)"
              [min]="MinDate"
              [format]="format"
              [steps]="steps"
              [readonly]="true"
              [disabled]="true"
            >
            </kendo-datetimepicker>

            <div class="alert alert-danger" *ngIf="bookingTo.touched && !bookingTo.valid">
              <div *ngIf="bookingTo.errors?.required">From date is required!</div>
            </div>
          </kendo-formfield>
        </kendo-gridlayout-item>

        <!-- Is Recurring -->
        <kendo-gridlayout-item [row]="5" [col]="1" [colSpan]="2" *ngIf="maxNumberOfDays != null && this.maxNumberOfDays > 0">
          <kendo-formfield>
            <label for="isRecurring">Recurring:</label>
            <kendo-dropdownlist
              [data]="isRecurringOptions"
              textField="text"
              valueField="value"
              id="isRecurringDropdown"
              name="isRecurringDropdown"
              #isRecurringDropdown="ngModel"
              [(ngModel)]="isRecurring"
              [valuePrimitive]="true"
              (valueChange)="onRecurringChange($event)"
            >
            </kendo-dropdownlist>
          </kendo-formfield>
        </kendo-gridlayout-item>

        <!-- Number of Days: -->
        <kendo-gridlayout-item [row]="6" [col]="1" [colSpan]="2" *ngIf="isRecurring">
          <kendo-formfield>
            <label for="numberOfDays">Number of Additional Days:</label>
            <kendo-numerictextbox
              [(ngModel)]="selectedDay"
              (input)="onInputChange($event)"
              id="daysInput"
              name="daysInput"
              #daysInput="ngModel"
              required
              [min]="1"
              [max]="maxNumberOfDays"
              [format]="'n0'"
              placeholder="Max number ({{ maxNumberOfDays }})"
            ></kendo-numerictextbox>
            <div class="alert alert-danger" *ngIf="daysInput.touched && !daysInput.valid">
              <div *ngIf="daysInput.errors.required">Number of Days is required</div>
            </div>
          </kendo-formfield>
        </kendo-gridlayout-item>

        <!-- Include Weekends -->
        <kendo-gridlayout-item [row]="7" [col]="1" [colSpan]="2" *ngIf="isRecurring">
          <kendo-formfield>
            <label for="includeWeekends">Include Weekends:</label>
            <kendo-dropdownlist
              [data]="isRecurringOptions"
              textField="text"
              valueField="value"
              id="includeWeekendsDropdown"
              name="includeWeekendsDropdown"
              #includeWeekendsDropdown="ngModel"
              [(ngModel)]="includeWeekends"
              [valuePrimitive]="true"
            >
            </kendo-dropdownlist>
          </kendo-formfield>
        </kendo-gridlayout-item>

        <!-- Location Dropdown -->
        <kendo-gridlayout-item [row]="isRecurring !== true ? 6 : 8" [col]="1" [colSpan]="2" *ngIf="NeedOffice">
          <kendo-formfield>
            <label for="bookingLocationDropdown">Location:</label>
            <kendo-dropdownlist
              [data]="primaryLocations"
              textField="bthr_locationname"
              valueField="bthr_locationid"
              id="bookingLocationDropdown"
              name="bookingLocationDropdown"
              #bookingLocationDropdown="ngModel"
              required
              [(ngModel)]="primaryLocation"
              (valueChange)="updatePrimaryLocation($event)"
            >
              <ng-template kendoDropDownListValueTemplate let-dataItem>
                <ng-container *ngIf="primaryLocationsLoading; else content">
                  <kendo-loader type="pulsing" themeColor="primary"></kendo-loader>
                </ng-container>
                <ng-template #content>
                  {{ dataItem?.bthr_locationname }}
                </ng-template>
              </ng-template>
            </kendo-dropdownlist>

            <div class="alert alert-danger" *ngIf="bookingLocationDropdown.touched && !bookingLocationDropdown.valid">
              <div *ngIf="bookingLocationDropdown.errors.required">Primary location is required!</div>
            </div>
          </kendo-formfield>
        </kendo-gridlayout-item>

        <!-- Office Dropdown -->
        <kendo-gridlayout-item [row]="isRecurring !== true ? 7 : 9" [col]="1" [colSpan]="2" *ngIf="NeedOffice">
          <kendo-formfield>
            <label for="bookingOfficeDropdown">Office:</label>
            <kendo-dropdownlist
              [data]="offices"
              textField="bt_officename"
              valueField="bt_verdeofficeid"
              id="bt_verdeofficeid"
              name="bookingOfficeDropdown"
              #bookingOfficeDropdown="ngModel"
              required
              [(ngModel)]="office"
              [disabled]="primaryLocation == null"
              (valueChange)="updateOffice($event)"
            >
              <ng-template kendoDropDownListValueTemplate let-dataItem>
                <ng-container *ngIf="officesLoading; else content">
                  <kendo-loader type="pulsing" themeColor="primary"></kendo-loader>
                </ng-container>
                <ng-template #content>
                  {{ dataItem?.bt_officename }}
                </ng-template>
              </ng-template>
            </kendo-dropdownlist>

            <div class="alert alert-danger" *ngIf="bookingOfficeDropdown.touched && !bookingOfficeDropdown.valid">
              <div *ngIf="bookingOfficeDropdown.errors.required">Office is required!</div>
            </div>
          </kendo-formfield>
        </kendo-gridlayout-item>

        <!-- Sub Office Dropdown -->
        <kendo-gridlayout-item [row]="isRecurring !== true ? 8 : 10" [col]="1" [colSpan]="2" *ngIf="NeedSubOffice">
          <kendo-formfield>
            <label for="bookingSubOfficeDropdown">Sub Office:</label>
            <kendo-dropdownlist
              [data]="subOffices"
              textField="bt_name"
              valueField="bt_verdesubofficeid"
              id="bookingSubOfficeDropdown"
              name="bookingSubOfficeDropdown"
              #bookingSubOfficeDropdown="ngModel"
              required
              [(ngModel)]="subOffice"
              [disabled]="office == null"
              (valueChange)="updateSubOffice($event)"
            >
              <ng-template kendoDropDownListValueTemplate let-dataItem>
                <ng-container *ngIf="subOfficesLoading; else content">
                  <kendo-loader type="pulsing" themeColor="primary"></kendo-loader>
                </ng-container>
                <ng-template #content>
                  {{ dataItem?.bt_name }}
                </ng-template>
              </ng-template>
            </kendo-dropdownlist>

            <div class="alert alert-danger" *ngIf="bookingSubOfficeDropdown.touched && !bookingSubOfficeDropdown.valid">
              <div *ngIf="bookingSubOfficeDropdown.errors.required">Sub office is required!</div>
            </div>
          </kendo-formfield>
        </kendo-gridlayout-item>

        <!-- Department -->
        <!-- <kendo-gridlayout-item [row]="isRecurring !== true ? 8 : 9" [col]="1" [colSpan]="2" *ngIf="NeedDepartment">
          <kendo-formfield>
            <label>Department: </label>
            <kendo-textbox id="Department" name="Department" #varDescriptionGroup="ngModel" required [ngModel]="UserDepartment" [clearButton]="true">
            </kendo-textbox>
          </kendo-formfield>
        </kendo-gridlayout-item> -->

        <!-- Booking Reason -->
        <!-- <kendo-gridlayout-item [row]="isRecurring !== true ? 9 : 10" [col]="1" [colSpan]="2" *ngIf="NeedReason">
          <label>Booking Reason: </label>
          <textarea class="form-control" type="text" id="BookingReason" name="BookingReason" [(ngModel)]="BookingReason" rows="2"> </textarea>
        </kendo-gridlayout-item> -->
      </kendo-gridlayout>
    </form>
  </div>
  <div class="side-panel-bottom-buttons">
    <button kendoButton style="background-color: lightgrey; color: black" (click)="cancel()">Cancel</button>
    <button kendoButton themeColor="primary" [disabled]="!BookingSubmitActive" (click)="searchForAvailability()">Search For Availability</button>
  </div>
</div>
