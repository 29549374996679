import { Component, OnInit, AfterViewInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { BookingsApiBookingsService, BookingsGetBookingItemsFilteredDto, UserApiUserService } from '@verde/api';
import { UserService } from '@verde/core';
import { BookingsDataService, VerdeApprovalService } from '@verde/shared';
import { Observable, Subject, switchMap, takeUntil, tap, take } from 'rxjs';
import { SchedulerEvent } from '@progress/kendo-angular-scheduler';
import moment from 'moment';
import { UserCalendarView } from 'libs/shared/src/lib/shared/models/user-calendar-view';

@Component({
  selector: 'verde-booking-boardroom-calendar',
  templateUrl: './booking-boardroom-calendar.component.html',
  styleUrls: ['./booking-boardroom-calendar.component.scss'],
})
export class BookingBoardroomCalendarComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() backToSearch = new EventEmitter<void>();
  @Output() setSidePanelTitleText = new EventEmitter<string>();

  private onDestroy$ = new Subject<boolean>();
  disableAnimation: boolean;
  isLoading: boolean = false;

  scrollTime = new Date();

  bookingItems: Array<BookingsGetBookingItemsFilteredDto> = [];
  calendarWithEvents: Array<UserCalendarView> = [];

  //Calendar
  selectedDate: Date = new Date('2018-10-21T00:00:00');
  today: Date = new Date(); // Set today's date
  element: any;

  constructor(
    private bookingsApiBookingsService: BookingsApiBookingsService,
    private sidebarService: VerdeApprovalService,
    private userService: UserService,
    public bookingsDataService: BookingsDataService,
    private userApiUserService: UserApiUserService,
  ) {}

  ngOnInit(): void {
    //console.log(this.scrollTime);
    this.isLoading = true;
    this.setSidePanelTitleText.emit('Boardrooms');

    this.sidebarService
      .getBookingAvailabilitySearchDetails()
      .pipe(
        takeUntil(this.onDestroy$), // Unsubscribe on destroy
        // 1. Use the search details to fetch booking items.
        switchMap((searchDetails) =>
          this.fetchBookingItems(searchDetails).pipe(
            tap((bookingItems: BookingsGetBookingItemsFilteredDto[]) => {
              // Save booking items locally.
              this.bookingItems = bookingItems;
            }),
          ),
        ),
        // 2. Use the first booking item's email to fetch calendar data.
        switchMap((bookingItems: BookingsGetBookingItemsFilteredDto[]) => {
          return this.fetchCalendarData$(bookingItems.map((item) => item.email));
        }),
        // 3. Mark loading complete once all observables have completed.
        tap(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (calendarData) => {
          // Optionally, further process calendar data if needed.
          console.log('Calendar data loaded.', calendarData);
        },
        error: (err) => {
          console.error('Error:', err);
          this.isLoading = false;
        },
      });
  }

  private fetchBookingItems(data: any): Observable<BookingsGetBookingItemsFilteredDto[]> {
    return this.bookingsApiBookingsService.getBookingItemsFiltered({
      body: {
        bookingTypeId: data.bookingType,
        legalEntityId: this.userService.user?.legalEntityId,
        officeId: data.office,
        primaryLocationId: data.primaryLocation,
      },
    });
  }

  private fetchCalendarData$(emails: Array<string>): Observable<any> {
    const now = new Date();
    const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
    const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);

    return this.userApiUserService
      .getMultiCalendarView({
        body: {
          userIds: emails,
          startDateTime: startOfDay.toISOString(),
          endDateTime: endOfDay.toISOString(),
        },
      })
      .pipe(
        take(1),
        tap((data: any) => {
          if (data) {
            const events = data?.flatMap(
              (user) =>
                user.calendarEvents?.value?.map((event) => ({
                  start: moment.utc(event.start.dateTime).local().toDate(),
                  end: moment.utc(event.end.dateTime).local().toDate(),
                  title: event.subject,
                  id: event.id,
                  email: user.userEmail,
                })) || [],
            );

            this.calendarWithEvents = this.bookingItems?.map((item) => {
              const calendarEvents = events.filter((event) => event.email === item.email).map(({ email, ...rest }) => rest); // removes email

              return {
                ...item,
                calendarEvents,
              };
            });
          }
        }),
      );
  }

  cancel() {
    this.sidebarService.setShowSidebar(false);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      //this.scrollToCurrentTime();
    }, 500); // Small delay ensures the scheduler is fully rendered
  }

  // private scrollToCurrentTime(): void {
  //   if (!this.scheduler) return;

  //   const now = new Date();
  //   this.scheduler.scrollToTime(now);

  //   // Find the scheduler content scroll container
  //   const schedulerElement = this.scheduler.element.nativeElement;
  //   const scrollableContainer = schedulerElement.querySelector('.k-scheduler-content');

  //   if (scrollableContainer) {
  //     const currentHour = now.getHours();
  //     const currentMinutes = now.getMinutes();

  //     // Total number of hours displayed (e.g., 24 hours if full-day view)
  //     const totalHours = 24;

  //     // Get the total height of the scrollable area
  //     const totalHeight = scrollableContainer.scrollHeight;

  //     // Calculate the position of the current time in pixels
  //     const timePosition = (currentHour + currentMinutes / 60) * (totalHeight / totalHours);

  //     // Calculate the new scroll position to place the current time at 20% from the top
  //     const viewportHeight = scrollableContainer.clientHeight;
  //     const scrollOffset = viewportHeight * 0.2; // 20% of visible height

  //     // Set new scroll position
  //     scrollableContainer.scrollTop = timePosition - scrollOffset;
  //   }
  // }

  ngOnDestroy(): void {
    this.bookingsDataService.bookingAvailabilitySearchDetails = undefined;
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
