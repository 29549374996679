/* tslint:disable */
/* eslint-disable */
export interface UserGetUserCalendarWithUsersAvailabilityQuery {
  availabilityEmails?: null | Array<string>;
  availabilityEndDateTime?: null | string;
  availabilityStartDateTime?: null | string;
  duration?: null | string;
  userEmail?: null | string;
  userEndDateTime?: null | string;
  userStartDateTime?: null | string;
}
