import { Injectable, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import {
  BookingsBookingsFilteredDto,
  Counter,
  DvRiskManagement,
  EmployeeStatus,
  EmployeeTimeOffCycleSummary,
  StringArrayParamaters,
  TimeEntryApiModel,
  User,
  VerdeTask,
  WorkspaceShortcut,
} from '@verde/api';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

// Store
import * as BirthdaysActions from '../store/actions/birthdays.actions';
import * as fromBirthdaysReducer from '../store/reducers/birthdays.reducer';

import * as ManagerActions from '../store/actions/manager.actions';
import * as fromManagerReducer from '../store/reducers/manager.reducer';

import * as ShortcutsActions from '../store/actions/shortcuts.actions';
import * as fromShortcutsReducer from '../store/reducers/shortcuts.reducer';

import * as TasksActions from '../store/actions/tasks.actions';
import * as fromTasksReducer from '../store/reducers/tasks.reducer';

import * as TimeOffActions from '../store/actions/timeoff.actions';
import * as fromTimeOffReducer from '../store/reducers/timeoff.reducer';

import * as BookingsActions from '../store/actions/bookings.actions';
import * as fromBookingsReducer from '../store/reducers/bookings.reducer';

import * as HealthRiskActions from '../store/actions/healthrisk.actions';
import * as fromHealthRiskReducer from '../store/reducers/healthrisk.reducer';

import * as TimeTrackingActions from '../store/actions/timetracking.actions';
import * as fromTimeTrackingReducer from '../store/reducers/timetracking.reducer';

import * as EngageActions from '../store/actions/engage.actions';
import * as fromEngageReducer from '../store/reducers/engage.reducer';

import * as CountersActions from '../store/actions/counters.actions';
import * as fromCountersReducer from '../store/reducers/counters.reducer';

@Injectable({
  providedIn: 'root',
})
export class DashboardService implements OnDestroy {
  private onDestroy$ = new Subject<boolean>();

  private refreshMyWorkspace$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private legalEntityImageBase64$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  birthdaysLoading$: Observable<boolean>;
  allBirthdays$: Observable<User[]>;

  managerLoading$: Observable<boolean>;
  manager$: Observable<User[]>;

  shortcutsLoading$: Observable<boolean>;
  shortcuts$: Observable<WorkspaceShortcut[]>;

  tasksLoading$: Observable<boolean>;
  tasks$: Observable<VerdeTask[]>;

  timeOffLoading$: Observable<boolean>;
  timeOff$: Observable<EmployeeTimeOffCycleSummary[]>;

  bookingsLoading$: Observable<boolean>;
  bookings$: Observable<BookingsBookingsFilteredDto[]>;

  healthRiskLoading$: Observable<boolean>;
  healthRisk$: Observable<DvRiskManagement[]>;

  timeTrackingLoading$: Observable<boolean>;
  timeTracking$: Observable<TimeEntryApiModel[]>;

  engageLoading$: Observable<boolean>;
  engage$: Observable<EmployeeStatus[]>;

  votingCounterLoading$: Observable<boolean>;
  votingCounter$: Observable<Counter[]>;

  constructor(
    private birthdaysStore: Store<fromBirthdaysReducer.BirthdaysState>,
    private managerStore: Store<fromManagerReducer.ManagerState>,
    private shortcutsStore: Store<fromShortcutsReducer.ShortcutsState>,
    private tasksStore: Store<fromTasksReducer.TasksState>,
    private timeOffStore: Store<fromTimeOffReducer.TimeOffState>,
    private bookingsStore: Store<fromBookingsReducer.BookingsState>,
    private healthRiskStore: Store<fromHealthRiskReducer.HealthRiskState>,
    private timeTrackingStore: Store<fromTimeTrackingReducer.TimeTrackingState>,
    private engageStore: Store<fromEngageReducer.EngageState>,
    private countersStore: Store<fromCountersReducer.CountersState>,
  ) {
    this.birthdaysLoading$ = this.birthdaysStore.pipe(select(fromBirthdaysReducer.isLoading));
    this.allBirthdays$ = this.birthdaysStore.pipe(select(fromBirthdaysReducer.getBirthdays));

    this.managerLoading$ = this.managerStore.pipe(select(fromManagerReducer.isLoading));
    this.manager$ = this.managerStore.pipe(select(fromManagerReducer.getManager));

    this.shortcutsLoading$ = this.shortcutsStore.pipe(select(fromShortcutsReducer.isLoading));
    this.shortcuts$ = this.shortcutsStore.pipe(select(fromShortcutsReducer.getShortcuts));

    this.tasksLoading$ = this.tasksStore.pipe(select(fromTasksReducer.isLoading));
    this.tasks$ = this.tasksStore.pipe(select(fromTasksReducer.getTasks));

    this.timeOffLoading$ = this.timeOffStore.pipe(select(fromTimeOffReducer.isLoading));
    this.timeOff$ = this.timeOffStore.pipe(select(fromTimeOffReducer.getTimeOff));

    this.bookingsLoading$ = this.bookingsStore.pipe(select(fromBookingsReducer.isLoading));
    this.bookings$ = this.bookingsStore.pipe(select(fromBookingsReducer.getBookings));

    this.healthRiskLoading$ = this.healthRiskStore.pipe(select(fromHealthRiskReducer.isLoading));
    this.healthRisk$ = this.healthRiskStore.pipe(select(fromHealthRiskReducer.getHealthRisk));

    this.timeTrackingLoading$ = this.timeTrackingStore.pipe(select(fromTimeTrackingReducer.isLoading));
    this.timeTracking$ = this.timeTrackingStore.pipe(select(fromTimeTrackingReducer.getTimeTracking));

    this.engageLoading$ = this.engageStore.pipe(select(fromEngageReducer.isLoading));
    this.engage$ = this.engageStore.pipe(select(fromEngageReducer.getEngage));

    this.votingCounterLoading$ = this.countersStore.pipe(select(fromCountersReducer.isVotingCounterLoading));
    this.votingCounter$ = this.countersStore.pipe(select(fromCountersReducer.getVotingCounter));
  }

  // Refresh My Workspace
  getRefreshMyWorkspace() {
    return this.refreshMyWorkspace$.asObservable();
  }

  setRefreshMyWorkspace(refresh: boolean) {
    this.refreshMyWorkspace$.next(refresh);
  }

  // LegalEntityImage
  getLegalEntityImageBase64() {
    return this.legalEntityImageBase64$.asObservable();
  }

  setLegalEntityImageBase64(base64: string) {
    this.legalEntityImageBase64$.next(base64);
  }

  // Get All Birthdays
  getAllBirthdays(legalEntityID: string) {
    this.birthdaysStore.dispatch(new BirthdaysActions.BirthdaysGetAll(legalEntityID));
  }

  // Clear Birthdays
  clearBirthdays() {
    this.birthdaysStore.dispatch(new BirthdaysActions.BirthdaysClear(null));
  }

  // Get Manager
  getManager(managerID: string) {
    this.managerStore.dispatch(new ManagerActions.ManagerGet(managerID));
  }

  // Clear Manager
  clearManager() {
    this.managerStore.dispatch(new ManagerActions.ManagerClear(null));
  }

  // Get Shortcuts
  getShortcuts(body: StringArrayParamaters) {
    this.shortcutsStore.dispatch(new ShortcutsActions.ShortcutsGet(body));
  }

  // Clear Shortcuts
  clearShortcuts() {
    this.shortcutsStore.dispatch(new ShortcutsActions.ShortcutsClear(null));
  }

  // Get Tasks
  getTasks(employeeID: string, legalEntityID: string) {
    this.tasksStore.dispatch(new TasksActions.TasksGetAll(employeeID, legalEntityID));
  }

  // Clear Tasks
  clearTasks() {
    this.tasksStore.dispatch(new TasksActions.TasksClear(null));
  }

  // Get TimeOff
  getTimeOff(employeeID: string, legalEntityID: string) {
    this.timeOffStore.dispatch(new TimeOffActions.TimeOffGetAll(employeeID, legalEntityID));
  }

  // Clear TimeOff
  clearTimeOff() {
    this.timeOffStore.dispatch(new TimeOffActions.TimeOffClear(null));
  }

  // Get Bookings
  getBookings(employeeID: string, legalEntityID: string) {
    this.bookingsStore.dispatch(new BookingsActions.BookingsGetAll(employeeID, legalEntityID));
  }

  // Clear Bookings
  clearBookings() {
    this.bookingsStore.dispatch(new BookingsActions.BookingsClear(null));
  }

  // Get HealthRisk
  getHealthRisk(employeeID: string) {
    this.healthRiskStore.dispatch(new HealthRiskActions.HealthRiskGetAll(employeeID));
  }

  // Clear HealthRisk
  clearHealthRisk() {
    this.healthRiskStore.dispatch(new HealthRiskActions.HealthRiskClear(null));
  }

  // Get TimeTracking
  getTimeTracking(employeeID: string) {
    this.timeTrackingStore.dispatch(new TimeTrackingActions.TimeTrackingGetAll(employeeID));
  }

  // Clear TimeTracking
  clearTimeTracking() {
    this.timeTrackingStore.dispatch(new TimeTrackingActions.TimeTrackingClear(null));
  }

  // Get Engage
  getEngage(employeeEmail: string) {
    this.engageStore.dispatch(new EngageActions.EngageGet(employeeEmail));
  }

  // Clear Engage
  clearEngage() {
    this.engageStore.dispatch(new EngageActions.EngageClear(null));
  }

  // Get Voting Counter
  getVotingCounter(employeeID: string, legalEntityID: string) {
    this.countersStore.dispatch(new CountersActions.VotingCounterGet(employeeID, legalEntityID));
  }

  // Clear Counters
  clearCounters() {
    this.countersStore.dispatch(new CountersActions.CountersClear(null));
  }

  // Destroy
  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }
}
