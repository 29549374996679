import { Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, Router } from '@angular/router';
import { filter, startWith, Subject, takeUntil } from 'rxjs';
import { Params } from '@angular/router';

export interface BreadcrumbOption {
  label: string;
  params: Params;
  url: string;
  routable: boolean;
}

@Component({
  selector: 'verde-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  @Input() separator: string | TemplateRef<void> = '>';
  @Input() routeLabel = 'breadcrumb';

  private destroy$ = new Subject<boolean>();
  breadcrumbs: BreadcrumbOption[] | undefined = [];

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.registerRouterChange();
  }

  private registerRouterChange() {
    try {
      this.router.events
        .pipe(
          filter((e) => e instanceof NavigationEnd),
          takeUntil(this.destroy$),
          startWith(true), // Trigger initial render.
        )
        .subscribe(() => {
          this.breadcrumbs = this.getBreadcrumbs(this.route.root);
        });
    } catch (e) {
      throw new Error(`You should import RouterModule`);
    }
  }

  private getBreadcrumbs(route: ActivatedRoute, url = '', breadcrumbs: BreadcrumbOption[] = []): BreadcrumbOption[] | undefined {
    const children: ActivatedRoute[] = route.children;

    // Stop if no children or if there are secondary outlets
    if (children.length === 0 || route.snapshot.data.hasSecondaryOutletChildren) {
      return breadcrumbs.length > 0 ? [{ label: 'My Workspace', url: '/', params: {}, routable: true }, ...breadcrumbs] : breadcrumbs;
    }

    for (const child of children) {
      if (child.outlet === PRIMARY_OUTLET) {
        const routeURL: string = child.snapshot.url.map((segment) => segment.path).join('/');
        const nextUrl = url + `/${routeURL}`;
        let breadcrumbLabel = child.snapshot.data[this.routeLabel];

        // Check if state was passed during navigation
        const navigation = this.router.getCurrentNavigation();
        const stateLabel = navigation?.extras.state?.breadcrumb?.title;
        const stateURL = navigation?.extras.state?.breadcrumb?.path;
        const useStateLabel = routeURL === stateURL && stateLabel;
        if (useStateLabel) {
          breadcrumbLabel = stateLabel;
        }

        if (routeURL && breadcrumbLabel) {
          const isWebDynamic = child.snapshot.params?.web_dynamic !== undefined;

          const breadcrumb: BreadcrumbOption = {
            label: breadcrumbLabel,
            params: isWebDynamic && !useStateLabel ? {} : child.snapshot.params,
            url: nextUrl.replace('//', '/').replace(isWebDynamic && !useStateLabel ? '/web_dynamic' : '', ''),
            routable: child.snapshot.data['breadcrumbRoutable'] ?? true,
          };

          breadcrumbs.push(breadcrumb);

          if (isWebDynamic && !useStateLabel) {
            const webDynamicBreadcrumb = {
              label: stateLabel || breadcrumbLabel,
              params: child.snapshot.params,
              url: nextUrl.replace('//', '/'),
              routable: child.snapshot.data['breadcrumbRoutable'] ?? true,
            };

            breadcrumbs.push(webDynamicBreadcrumb);
          }
        }
        return this.getBreadcrumbs(child, nextUrl, breadcrumbs);
      }
    }
    return undefined;
  }

  navigate(url: string, e: MouseEvent): void {
    e.preventDefault();
    this.router.navigate([url]);
  }

  ngOnDestroy(): void {
    this.destroy$.unsubscribe();
  }
}
