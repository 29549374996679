/* tslint:disable */
/* eslint-disable */
import { UserIBackingStore } from './user-i-backing-store';
import { UserPhoneType } from './user-phone-type';
export interface UserPhone {
  additionalData?: null | {
    [key: string]: any;
  };
  backingStore?: UserIBackingStore;
  language?: null | string;
  number?: null | string;
  odataType?: null | string;
  region?: null | string;
  type?: UserPhoneType;
}
