<!-- ====================Begin Example Template====================

<div *ngIf="selectedOffice([offices[1]['dev'], offices[1]['prod']])">
    <div *ngIf="(deviceTypeService.isMobile$ | async)">
        Dev/Prod Mobile Map
    </div>
    <div *ngIf="(deviceTypeService.isMobile$ | async) === false">
        Dev/Prod Tablet and Desktop Map
    </div>
</div>
<div *ngIf="selectedOffice([offices[1]['uat']])">
    <div *ngIf="(deviceTypeService.isMobile$ | async)">
        UAT Mobile Map
    </div>
    <div *ngIf="(deviceTypeService.isMobile$ | async) === false">
        UAT Tablet and Desktop Map
    </div>
</div>

====================End Example Template==================== -->

<!-- Offices Interactive Floor Maps -->

<!-- SubOffices Interactive Floor Maps -->
<!-- 29 Scott Street -->
<div *ngIf="selectedOffice([offices[1]['dev'], offices[1]['prod']])">
  <div *ngIf="deviceTypeService.isMobile$ | async">
    <!-- Created with Inkscape (http://www.inkscape.org/) -->
    <svg
      xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
      xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:svg="http://www.w3.org/2000/svg"
      width="400"
      height="1270.5"
      viewBox="0 0 211.66664 672.30617"
      version="1.1"
      id="svg1"
      xml:space="preserve"
      inkscape:version="1.3.2 (091e20e, 2023-11-25, custom)"
      sodipodi:docname="hotdesk-mobile.svg"
    >
      <sodipodi:namedview
        id="namedview1"
        pagecolor="#ffffff"
        bordercolor="#000000"
        borderopacity="0.25"
        inkscape:showpageshadow="2"
        inkscape:pageopacity="0.0"
        inkscape:pagecheckerboard="0"
        inkscape:deskcolor="#d1d1d1"
        inkscape:document-units="mm"
        showguides="true"
        inkscape:zoom="0.35355339"
        inkscape:cx="-5.6568542"
        inkscape:cy="974.39314"
        inkscape:window-width="1920"
        inkscape:window-height="1009"
        inkscape:window-x="-8"
        inkscape:window-y="-8"
        inkscape:window-maximized="1"
        inkscape:current-layer="projectRoom"
      >
        <sodipodi:guide position="69.38698,-11.790496" orientation="0,-1" id="guide19" inkscape:locked="false" />
        <sodipodi:guide position="69.172005,-11.980666" orientation="0,-1" id="guide21" inkscape:locked="false" />
        <sodipodi:guide position="31.460125,184.79253" orientation="1,0" id="guide23" inkscape:locked="false" />
        <sodipodi:guide position="15.729333,85.018684" orientation="1,0" id="guide1" inkscape:locked="false" />
        <sodipodi:guide position="44.965587,-23.723624" orientation="1,0" id="guide24" inkscape:locked="false" />
        <sodipodi:guide position="78.51252,3.9429142" orientation="0,-1" id="guide28" inkscape:locked="false" />
        <sodipodi:guide position="60.48829,168.85376" orientation="1,0" id="guide30" inkscape:locked="false" />
        <sodipodi:guide position="157.00538,170.11053" orientation="0,-1" id="guide2" inkscape:locked="false" />
        <sodipodi:guide position="59.994263,167.87398" orientation="1,0" id="guide3" inkscape:locked="false" />
        <sodipodi:guide position="157.05086,169.67231" orientation="0,-1" id="guide4" inkscape:locked="false" />
        <sodipodi:guide position="59.96119,136.29348" orientation="0,-1" id="guide5" inkscape:locked="false" />
        <sodipodi:guide position="60.356551,136.76336" orientation="0,-1" id="guide6" inkscape:locked="false" />
        <sodipodi:guide position="60.048007,58.149417" orientation="0,-1" id="guide7" inkscape:locked="false" />
        <sodipodi:guide position="60.220606,57.693627" orientation="0,-1" id="guide8" inkscape:locked="false" />
        <sodipodi:guide position="157.02399,92.440857" orientation="0,-1" id="guide9" inkscape:locked="false" />
        <sodipodi:guide position="156.95991,92.184547" orientation="1,0" id="guide10" inkscape:locked="false" />
        <sodipodi:guide position="157.17798,92.055357" orientation="0,-1" id="guide11" inkscape:locked="false" />
        <sodipodi:guide position="157.40639,92.115297" orientation="1,0" id="guide12" inkscape:locked="false" />
        <sodipodi:guide position="157.08393,60.683627" orientation="0,-1" id="guide13" inkscape:locked="false" />
        <sodipodi:guide position="157.12114,60.264017" orientation="0,-1" id="guide14" inkscape:locked="false" />
        <sodipodi:guide position="157.1139,-23.498304" orientation="0,-1" id="guide15" inkscape:locked="false" />
        <sodipodi:guide position="157.23379,-23.922054" orientation="0,-1" id="guide16" inkscape:locked="false" />
        <sodipodi:guide position="45.477322,-23.771154" orientation="1,0" id="guide18" inkscape:locked="false" />
        <sodipodi:guide position="75.363867,-23.602694" orientation="1,0" id="guide20" inkscape:locked="false" />
        <sodipodi:guide position="75.82172,-24.168034" orientation="1,0" id="guide22" inkscape:locked="false" />
      </sodipodi:namedview>
      <defs id="defs1">
        <style id="style1">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-5">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-1">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-7">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-6">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-3">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-19">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-9">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-18">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-0">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-8">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-80">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-07">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-67">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-77">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-37">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-39">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-2">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-11">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-072">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-27">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-08">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-4">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-22">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-09">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-90">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-60">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-603">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-12">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-06">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
      </defs>
      <g inkscape:groupmode="layer" id="floorPlanMobile" inkscape:label="floorPlanMobile" style="display: inline">
        <image
          preserveAspectRatio="none"
          inkscape:svg-dpi="96"
          width="211.66664"
          height="672.30621"
          xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/floorPlanMobile.svg"
          id="image1"
          x="1.0583367"
          y="1.706516"
        />
      </g>
      <a
        id="interactiveDesk1"
        (click)="bookingsDataService.bookDesk('Desk 01')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 01')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 01')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk1" inkscape:label="desk1">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="28.534691"
            height="31.373796"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskLeft.svg"
            id="imgDesk1"
            x="31.459581"
            y="136.19427"
            inkscape:label="imgDesk1"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 01')"
          />
          <text id="_1" data-name="1" class="cls-1" transform="matrix(0.09710208,0,0,0.09710208,48.829211,155.47864)">1</text>
        </g>
      </a>
      <a
        id="interactiveDesk2"
        (click)="bookingsDataService.bookDesk('Desk 02')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 02')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 02')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk2" inkscape:label="desk2">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="28.535"
            height="31.374001"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskRight.svg"
            id="image17"
            x="60.488297"
            y="136.19426"
            inkscape:label="imgDesk2"
            style="display: inline"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 02')"
          />
          <text id="_2" data-name="2" class="cls-1" transform="matrix(0.09710208,0,0,0.09710208,65.97128,155.52387)">2</text>
        </g>
      </a>
      <a
        id="interactiveDesk3"
        (click)="bookingsDataService.bookDesk('Desk 03')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 03')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 03')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk3" inkscape:label="desk3">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="28.534691"
            height="31.373796"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskLeft.svg"
            id="imgDesk3"
            x="31.458567"
            y="168.02052"
            inkscape:label="imgDesk3"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 03')"
          />
          <text id="_3" data-name="3" class="cls-1" transform="matrix(0.09710208,0,0,0.09710208,48.769233,187.83317)">3</text>
        </g>
      </a>
      <a
        id="interactiveDesk4"
        (click)="bookingsDataService.bookDesk('Desk 04')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 04')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 04')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk4" inkscape:label="desk4">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="28.535"
            height="31.374001"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskRight.svg"
            id="imgDesk4"
            x="60.488293"
            y="168.02074"
            inkscape:label="imgDesk4"
            style="display: inline"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 04')"
          />
          <text id="_4" data-name="4" class="cls-1" transform="matrix(0.09710208,0,0,0.09710208,65.881935,187.88506)">4</text>
        </g>
      </a>
      <a
        id="interactiveDesk5"
        (click)="bookingsDataService.bookDesk('Desk 05')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 05')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 05')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk5" inkscape:label="desk5">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="28.534691"
            height="31.373796"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskLeft.svg"
            id="imgDesk5"
            x="31.458548"
            y="199.85864"
            inkscape:label="imgDesk5"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 05')"
          />
          <text id="_5" data-name="5" class="cls-1" transform="matrix(0.09710208,0,0,0.09710208,48.701164,219.86317)">5</text>
        </g>
      </a>
      <a
        id="interactiveDesk6"
        (click)="bookingsDataService.bookDesk('Desk 06')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 06')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 06')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk6" inkscape:label="desk6">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="28.535"
            height="31.374001"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskRight.svg"
            id="imgDesk6"
            x="60.488293"
            y="199.8589"
            inkscape:label="imgDesk6"
            style="display: inline"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 06')"
          />
          <text id="_6" data-name="6" class="cls-1" transform="matrix(0.09710208,0,0,0.09710208,65.951727,219.85888)">6</text>
        </g>
      </a>
      <a
        id="interactiveDesk7"
        (click)="bookingsDataService.bookDesk('Desk 07')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 07')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 07')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk7" inkscape:label="desk7">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="29.885719"
            height="27.311302"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskTop.svg"
            id="imgDesk7"
            x="127.07458"
            y="138.72937"
            inkscape:label="imgDesk7"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 07')"
          />
          <text id="_7" data-name="7" class="cls-1" transform="matrix(0.09260417,0,0,0.09260417,139.00042,161.37037)">7</text>
        </g>
      </a>
      <a
        id="interactiveDesk8"
        (click)="bookingsDataService.bookDesk('Desk 08')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 08')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 08')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk8" inkscape:label="desk8">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="29.885719"
            height="27.311302"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskTop.svg"
            id="imgDesk8"
            x="157.40619"
            y="138.73042"
            inkscape:label="imgDesk8"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 08')"
          />
          <text id="_8" data-name="8" class="cls-1" transform="matrix(0.09260417,0,0,0.09260417,169.7658,161.37102)">8</text>
        </g>
      </a>
      <a
        id="interactiveDesk9"
        (click)="bookingsDataService.bookDesk('Desk 09')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 09')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 09')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk9" inkscape:label="desk9">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="29.886"
            height="27.311001"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskBottom.svg"
            id="imgDesk9"
            x="127.07442"
            y="166.48183"
            inkscape:label="imgDesk9"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 09')"
          />
          <text id="_9" data-name="9" class="cls-1" transform="matrix(0.09260417,0,0,0.09260417,138.9663,177.8506)">9</text>
        </g>
      </a>
      <a
        id="interactiveDesk10"
        (click)="bookingsDataService.bookDesk('Desk 10')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 10')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 10')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk10" inkscape:label="desk10">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="29.886"
            height="27.311001"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskBottom.svg"
            id="imgDesk10"
            x="157.40538"
            y="166.48183"
            inkscape:label="imgDesk10"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 10')"
          />
          <text id="_10" data-name="10" class="cls-1" transform="matrix(0.09260417,0,0,0.09260417,166.71491,177.86645)">10</text>
        </g>
      </a>
      <a
        id="interactiveDesk11"
        (click)="bookingsDataService.bookDesk('Desk 11')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 11')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 11')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk11" inkscape:label="desk11" style="display: inline">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="28.534691"
            height="31.373796"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskLeft.svg"
            id="imgDesk11"
            x="31.458548"
            y="246.63081"
            inkscape:label="imgDesk11"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 11')"
          />
          <text id="_11" data-name="11" class="cls-1" transform="matrix(0.09921875,0,0,0.09921875,46.077262,266.07082)">11</text>
        </g>
      </a>
      <a
        id="interactiveDesk12"
        (click)="bookingsDataService.bookDesk('Desk 12')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 12')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 12')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk12" inkscape:label="desk12">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="28.535"
            height="31.374001"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskRight.svg"
            id="imgDesk12"
            x="60.488293"
            y="246.62918"
            inkscape:label="imgDesk12"
            style="display: inline"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 12')"
          />
          <text id="_12" data-name="12" class="cls-1" transform="matrix(0.09921875,0,0,0.09921875,63.083998,266.06762)">12</text>
        </g>
      </a>
      <a
        id="interactiveDesk13"
        (click)="bookingsDataService.bookDesk('Desk 13')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 13')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 13')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk13" inkscape:label="desk13">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="28.534691"
            height="31.373796"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskLeft.svg"
            id="imgDesk13"
            x="31.458548"
            y="278.46039"
            inkscape:label="imgDesk13"
            style="display: inline"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 13')"
          />
          <text id="_13" data-name="13" class="cls-1" transform="matrix(0.09921875,0,0,0.09921875,45.487532,299.02116)">13</text>
        </g>
      </a>
      <a
        id="interactiveDesk14"
        (click)="bookingsDataService.bookDesk('Desk 14')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 14')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 14')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk14" inkscape:label="desk14">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="28.535"
            height="31.374001"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskRight.svg"
            id="imgDesk14"
            x="60.488293"
            y="278.45956"
            inkscape:label="imgDesk14"
            style="display: inline"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 14')"
          />
          <text id="_14" data-name="14" class="cls-1" transform="matrix(0.09921875,0,0,0.09921875,62.556017,299.02423)">14</text>
        </g>
      </a>
      <a
        id="interactiveDesk15"
        (click)="bookingsDataService.bookDesk('Desk 15')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 15')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 15')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk15" inkscape:label="desk15">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="28.534691"
            height="31.373796"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskLeft.svg"
            id="imgDesk15"
            x="128.42534"
            y="212.33849"
            inkscape:label="imgDesk15"
            style="display: inline"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 15')"
          />
          <text id="_15" data-name="15" class="cls-1" transform="matrix(0.10027708,0,0,0.10027708,142.02946,231.18467)">15</text>
        </g>
      </a>
      <a
        id="interactiveDesk16"
        (click)="bookingsDataService.bookDesk('Desk 16')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 16')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 16')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk16" inkscape:label="desk16">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="28.535"
            height="31.374001"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskRight.svg"
            id="imgDesk16"
            x="157.40541"
            y="212.3387"
            inkscape:label="imgDesk16"
            style="display: inline"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 16')"
          />
          <text id="_16" data-name="16" class="cls-1" transform="matrix(0.10027708,0,0,0.10027708,160.01514,231.15503)">16</text>
        </g>
      </a>
      <a
        id="interactiveDesk17"
        (click)="bookingsDataService.bookDesk('Desk 17')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 17')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 17')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk17" inkscape:label="desk17">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="28.534691"
            height="31.373796"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskLeft.svg"
            id="imgDesk17"
            x="128.42535"
            y="244.09706"
            inkscape:label="imgDesk17"
            style="display: inline"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 17')"
          />
          <text id="_17" data-name="17" class="cls-1" transform="matrix(0.10027708,0,0,0.10027708,142.05248,264.1084)">17</text>
        </g>
      </a>
      <a
        id="interactiveDesk18"
        (click)="bookingsDataService.bookDesk('Desk 18')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 18')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 18')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk18" inkscape:label="desk18">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="28.535"
            height="31.374001"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskRight.svg"
            id="imgDesk18"
            x="157.40541"
            y="244.09697"
            inkscape:label="imgDesk18"
            style="display: inline"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 18')"
          />
          <text id="_18" data-name="18" class="cls-1" transform="matrix(0.10027708,0,0,0.10027708,160.02695,264.06727)">18</text>
        </g>
      </a>
      <a
        id="interactiveDesk19"
        (click)="bookingsDataService.bookDesk('Desk 19')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 19')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 19')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk19" inkscape:label="desk19">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="28.534691"
            height="31.373796"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskLeft.svg"
            id="imgDesk19"
            x="128.42535"
            y="275.8887"
            inkscape:label="imgDesk19"
            style="display: inline"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 19')"
          />
          <text id="_19" data-name="19" class="cls-1" transform="matrix(0.10027708,0,0,0.10027708,142.0146,296.70949)">19</text>
        </g>
      </a>
      <a
        id="interactiveDesk20"
        (click)="bookingsDataService.bookDesk('Desk 20')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 20')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 20')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk20" inkscape:label="desk20">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="28.535"
            height="31.374001"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskRight.svg"
            id="imgDesk20"
            x="157.40541"
            y="275.88812"
            inkscape:label="imgDesk20"
            style="display: inline"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 20')"
          />
          <text id="_20" data-name="20" class="cls-1" transform="matrix(0.10027708,0,0,0.10027708,160.27174,296.74725)">20</text>
        </g>
      </a>
      <a
        id="interactiveDesk21"
        (click)="bookingsDataService.bookDesk('Desk 21')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 21')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 21')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk21" inkscape:label="desk21">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="29.885719"
            height="27.311302"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskTop.svg"
            id="imgDesk21"
            x="15.080024"
            y="332.33945"
            inkscape:label="imgDesk21"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 21')"
          />
          <text id="_21" data-name="21" class="cls-1" transform="matrix(0.09286875,0,0,0.09286875,24.644026,355.53721)">21</text>
        </g>
      </a>
      <a
        id="interactiveDesk22"
        (click)="bookingsDataService.bookDesk('Desk 22')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 22')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 22')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk22" inkscape:label="desk22">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="29.885719"
            height="27.311302"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskTop.svg"
            id="imgDesk22"
            x="45.478325"
            y="332.33997"
            inkscape:label="imgDesk22"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 22')"
          />
          <text id="_22" data-name="22" class="cls-1" transform="matrix(0.09286875,0,0,0.09286875,55.058731,355.55798)">22</text>
        </g>
      </a>
      <a
        id="interactiveDesk23"
        (click)="bookingsDataService.bookDesk('Desk 23')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 23')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 23')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk23" inkscape:label="desk23">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="29.885719"
            height="27.311302"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskTop.svg"
            id="imgDesk23"
            x="75.821693"
            y="332.33997"
            inkscape:label="imgDesk23"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 23')"
          />
          <text id="_23" data-name="23" class="cls-1" transform="matrix(0.09286875,0,0,0.09286875,86.051223,355.54767)">23</text>
        </g>
      </a>
      <a
        id="interactiveDesk24"
        (click)="bookingsDataService.bookDesk('Desk 24')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 24')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 24')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk24" inkscape:label="desk24">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="29.886"
            height="27.311001"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskBottom.svg"
            id="imgDesk24"
            x="15.079185"
            y="360.0762"
            inkscape:label="imgDesk24"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 24')"
          />
          <text id="_24" data-name="24" class="cls-1" transform="matrix(0.09286875,0,0,0.09286875,24.201875,371.42767)">24</text>
        </g>
      </a>
      <a
        id="interactiveDesk25"
        (click)="bookingsDataService.bookDesk('Desk 25')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 25')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 25')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk25" inkscape:label="desk25">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="29.886"
            height="27.311001"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskBottom.svg"
            id="imgDesk25"
            x="45.477345"
            y="360.07584"
            inkscape:label="imgDesk25"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 25')"
          />
          <text id="_25" data-name="25" class="cls-1" transform="matrix(0.09286875,0,0,0.09286875,55.041236,371.43621)">25</text>
        </g>
      </a>
      <a
        id="interactiveDesk26"
        (click)="bookingsDataService.bookDesk('Desk 26')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 26')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 26')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk26" inkscape:label="desk26">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="29.886"
            height="27.311001"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskBottom.svg"
            id="image22"
            x="75.820709"
            y="360.07584"
            inkscape:label="imgDesk25"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 26')"
          />
          <text id="_26" data-name="26" class="cls-1" transform="matrix(0.09286875,0,0,0.09286875,86.033554,371.44463)">26</text>
        </g>
      </a>
      <a
        id="interactiveDesk27"
        (click)="bookingsDataService.bookDesk('Desk 27')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 27')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 27')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk27" inkscape:label="desk27">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="28.534691"
            height="31.373796"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskLeft.svg"
            id="imgDesk27"
            x="128.42535"
            y="328.27826"
            inkscape:label="imgDesk27"
            style="display: inline"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 27')"
          />
          <text id="_27" data-name="27" class="cls-1" transform="matrix(0.1000125,0,0,0.1000125,142.35067,347.63644)">27</text>
        </g>
      </a>
      <a
        id="interactiveDesk28"
        (click)="bookingsDataService.bookDesk('Desk 28')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 28')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 28')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk28" inkscape:label="desk28">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="28.535"
            height="31.374001"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskRight.svg"
            id="imgDesk28"
            x="157.40436"
            y="328.27594"
            inkscape:label="imgDesk28"
            style="display: inline"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 28')"
          />
          <text id="_28" data-name="28" class="cls-1" transform="matrix(0.1000125,0,0,0.1000125,159.91751,347.55889)">28</text>
        </g>
      </a>
      <a
        id="interactiveDesk29"
        (click)="bookingsDataService.bookDesk('Desk 29')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 29')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 29')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk29" inkscape:label="desk29">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="28.534691"
            height="31.373796"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskLeft.svg"
            id="imgDesk29"
            x="128.42535"
            y="360.07581"
            inkscape:label="imgDesk29"
            style="display: inline"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 29')"
          />
          <text id="_29" data-name="29" class="cls-1" transform="matrix(0.1000125,0,0,0.1000125,142.16602,380.48147)">29</text>
        </g>
      </a>
      <a
        id="interactiveDesk30"
        (click)="bookingsDataService.bookDesk('Desk 30')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 30')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 30')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk30" inkscape:label="desk30">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="28.535"
            height="31.374001"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskRight.svg"
            id="imgDesk30"
            x="157.4054"
            y="360.07556"
            inkscape:label="imgDesk30"
            style="display: inline"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 30')"
          />
          <text id="_30" data-name="30" class="cls-1" transform="matrix(0.1000125,0,0,0.1000125,159.90821,380.54391)">30</text>
        </g>
      </a>
      <a
        id="interactiveBoardroom"
        (click)="bookingsDataService.bookDesk('Board Room')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Board Room')"
        [attr.title]="bookingsDataService.getDeskToolTip('Board Room')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="boardRoom" inkscape:label="boardRoom">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="70.643753"
            height="47.095833"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/boardRoom.svg"
            id="imgBoardRoom"
            x="20.804724"
            y="433.5097"
            inkscape:label="imgBoardRoom"
            [attr.style]="bookingsDataService.getDeskImageStyle('Board Room')"
          />
        </g>
      </a>
      <a
        id="interactiveProjectroom"
        (click)="bookingsDataService.bookDesk('Project Room')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Project Room')"
        [attr.title]="bookingsDataService.getDeskToolTip('Project Room')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="projectRoom" inkscape:label="projectRoom" inkscape:highlight-color="#3555f3">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="39.931866"
            height="52.608646"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/projectRoom.svg"
            id="imgProjectRoom"
            x="145.52773"
            y="428.83633"
            inkscape:label="imgProjectRoom"
            [attr.style]="bookingsDataService.getDeskImageStyle('Project Room')"
          />
        </g>
      </a>
      <a
        id="interactiveOffice"
        (click)="bookingsDataService.bookDesk('Office')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Office')"
        [attr.title]="bookingsDataService.getDeskToolTip('Office')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="office" inkscape:label="office" inkscape:highlight-color="#cb35f3">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="42.284195"
            height="58.687542"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/office.svg"
            id="imgOffice"
            x="46.206333"
            y="548.76013"
            inkscape:label="imgOffice"
            [attr.style]="bookingsDataService.getDeskImageStyle('Office')"
          />
        </g>
      </a>
      <a
        id="interactivePod"
        (click)="bookingsDataService.bookDesk('Working Pod')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Working Pod')"
        [attr.title]="bookingsDataService.getDeskToolTip('Working Pod')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="pod" inkscape:label="pod">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="11.278504"
            height="64.052139"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/pod.svg"
            id="imgPod"
            x="-561.01196"
            y="129.89917"
            transform="rotate(-90)"
            inkscape:label="imgPod"
            [attr.style]="bookingsDataService.getDeskImageStyle('Working Pod')"
          />
        </g>
      </a>
    </svg>
  </div>
  <div *ngIf="(deviceTypeService.isMobile$ | async) === false">
    <!-- Created with Inkscape (http://www.inkscape.org/) -->
    <svg
      xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
      xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:svg="http://www.w3.org/2000/svg"
      width="1086"
      height="540"
      viewBox="0 0 574.67495 285.74998"
      version="1.1"
      id="svg1"
      xml:space="preserve"
      inkscape:version="1.3.2 (091e20e, 2023-11-25, custom)"
      sodipodi:docname="hotdesk-edited-linked-1.svg"
    >
      <sodipodi:namedview
        id="namedview1"
        pagecolor="#ffffff"
        bordercolor="#000000"
        borderopacity="0.25"
        inkscape:showpageshadow="2"
        inkscape:pageopacity="0.0"
        inkscape:pagecheckerboard="0"
        inkscape:deskcolor="#d1d1d1"
        inkscape:document-units="mm"
        inkscape:zoom="1"
        inkscape:cx="516"
        inkscape:cy="232.5"
        inkscape:window-width="1920"
        inkscape:window-height="1009"
        inkscape:window-x="1912"
        inkscape:window-y="-8"
        inkscape:window-maximized="1"
        inkscape:current-layer="boardRoom"
        showguides="true"
      >
        <sodipodi:guide position="583.31614,127.93114" orientation="0,-1" id="guide9" inkscape:locked="false" />
        <sodipodi:guide position="583.00814,127.11465" orientation="0,-1" id="guide10" inkscape:locked="false" />
        <sodipodi:guide position="721.27626,174.50081" orientation="0,-1" id="guide11" inkscape:locked="false" />
        <sodipodi:guide position="695.02198,585.01948" orientation="1,0" id="guide23" inkscape:locked="false" />
        <sodipodi:guide position="696.11286,584.7349" orientation="1,0" id="guide24" inkscape:locked="false" />
        <sodipodi:guide position="835.1457,602.79412" orientation="0,-1" id="guide25" inkscape:locked="false" />
        <sodipodi:guide position="584.9367,366.30003" orientation="0,-1" id="guide27" inkscape:locked="false" />
        <sodipodi:guide position="834.74262,695.46026" orientation="1,0" id="guide28" inkscape:locked="false" />
        <sodipodi:guide position="835.7162,695.06546" orientation="1,0" id="guide29" inkscape:locked="false" />
        <sodipodi:guide position="834.81498,697.53352" orientation="0,-1" id="guide30" inkscape:locked="false" />
        <sodipodi:guide position="835.31934,650.15244" orientation="0,-1" id="guide31" inkscape:locked="false" />
        <sodipodi:guide position="998.51825,127.57664" orientation="1,0" id="guide32" inkscape:locked="false" />
        <sodipodi:guide position="999.41885,127.31929" orientation="1,0" id="guide33" inkscape:locked="false" />
        <sodipodi:guide position="581.84232,174.42958" orientation="1,0" id="guide1" inkscape:locked="false" />
        <sodipodi:guide position="834.36844,556.22725" orientation="0,-1" id="guide2" inkscape:locked="false" />
      </sodipodi:namedview>
      <defs id="defs1">
        <style id="style1">
          .cls-1,
          .cls-2,
          .cls-3,
          .cls-4 {
            fill: none;
          }

          .cls-1,
          .cls-2,
          .cls-3,
          .cls-4,
          .cls-5,
          .cls-6,
          .cls-7 {
            stroke: #323232;
          }

          .cls-1 {
            stroke-width: 1.086px;
          }

          .cls-1,
          .cls-2,
          .cls-3,
          .cls-4,
          .cls-6,
          .cls-7 {
            fill-rule: evenodd;
          }

          .cls-2,
          .cls-5,
          .cls-6 {
            stroke-width: 0.543px;
          }

          .cls-3 {
            stroke-width: 0.814px;
          }

          .cls-4 {
            stroke-width: 1.221px;
          }

          .cls-5,
          .cls-6,
          .cls-7 {
            fill: #f6f6f6;
          }

          .cls-7 {
            stroke-width: 0.532px;
          }

          .cls-8 {
            opacity: 0.74;
          }

          .cls-9 {
            opacity: 0.72;
          }
        </style>
        <style id="style1-9">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-0">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-95">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-02">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-07">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-7">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-8">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-80">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-4">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-2">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-86">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-79">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-75">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-5">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-09">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-05">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-799">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-055">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-3">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-49">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-27">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-74">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-50">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-39">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-76">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-97">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-34">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-87">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-70">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-755">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-54">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-78">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-26">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-43">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
      </defs>
      <g inkscape:groupmode="layer" id="floorPlan" inkscape:label="floorPlan" style="display: inline" transform="translate(-2.6412578,0.01605072)">
        <image
          preserveAspectRatio="none"
          inkscape:svg-dpi="96"
          width="574.675"
          height="285.75"
          xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/floorPlan.svg"
          id="imgFloorPlan"
          x="2.6412578"
          y="-0.016050719"
          style="display: inline"
          inkscape:label="imgFloorPlan"
        />
      </g>
      <a
        id="interactiveDesk1"
        (click)="bookingsDataService.bookDesk('Desk 01')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 01')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 01')"
        kendoTooltip
      >
        <g inkscape:label="desk1" inkscape:groupmode="layer" id="desk1" transform="translate(-2.6422913,0.09149831)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="21.43125"
            height="23.547916"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskLeft.svg"
            id="imgDesk1"
            x="272.14255"
            y="174.29155"
            inkscape:label="imgDesk1"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 01')"
          />
          <text id="_1" data-name="1" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,285.29042,188.8685)">1</text>
        </g>
      </a>
      <a
        id="interactiveDesk2"
        (click)="bookingsDataService.bookDesk('Desk 02')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 02')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 02')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk2" inkscape:label="desk2" transform="translate(130.97332,-4.1010736)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="21.166666"
            height="23.547916"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskRight.svg"
            id="imgDesk2"
            x="160.46536"
            y="178.48427"
            inkscape:label="imgDesk2"
            style="display: inline"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 02')"
          />
          <text id="_2" data-name="2" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,164.52467,192.98615)">2</text>
        </g>
      </a>
      <a
        id="interactiveDesk3"
        (click)="bookingsDataService.bookDesk('Desk 03')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 03')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 03')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk3" inkscape:label="desk3" transform="translate(-12.83759,12.08327)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="21.43125"
            height="23.547916"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskLeft.svg"
            id="imgDesk3"
            x="282.32855"
            y="186.19356"
            inkscape:label="imgDesk3"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 03')"
          />
          <text id="_3" data-name="3" class="cls-1" style="display: inline" transform="matrix(0.07170208,0,0,0.07170208,295.40748,200.98984)">3</text>
        </g>
      </a>
      <a
        id="interactiveDesk4"
        (click)="bookingsDataService.bookDesk('Desk 04')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 04')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 04')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk4" inkscape:label="desk4" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="21.166666"
            height="23.547916"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskRight.svg"
            id="imgDesk4"
            x="294.07919"
            y="198.25842"
            inkscape:label="imgDesk4"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 04')"
          />
          <text id="_4" data-name="4" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,298.16541,213.09435)">4</text>
        </g>
      </a>
      <a
        id="interactiveDesk5"
        (click)="bookingsDataService.bookDesk('Desk 05')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 05')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 05')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk5" inkscape:label="desk5" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="21.43125"
            height="23.547916"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskLeft.svg"
            id="imgDesk5"
            x="272.13907"
            y="222.17485"
            inkscape:label="imgDesk5"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 05')"
          />
          <text id="_5" data-name="5" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,285.16625,236.94938)">5</text>
        </g>
      </a>
      <a
        id="interactiveDesk6"
        (click)="bookingsDataService.bookDesk('Desk 06')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 06')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 06')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk6" inkscape:label="desk6" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="21.166666"
            height="23.547916"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskRight.svg"
            id="imgDesk6"
            x="294.07095"
            y="222.1756"
            inkscape:label="imgDesk6"
            style="display: inline"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 06')"
          />
          <text id="_6" data-name="6" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,298.21698,236.94672)">
            <tspan
              style="
                font-style: normal;
                font-variant: normal;
                font-weight: bold;
                font-stretch: normal;
                font-family: Arial;
                -inkscape-font-specification: 'Arial Bold';
              "
              id="tspan2"
            >
              6
            </tspan>
          </text>
        </g>
      </a>
      <a
        id="interactiveDesk7"
        (click)="bookingsDataService.bookDesk('Desk 07')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 07')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 07')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk7" inkscape:label="desk7" transform="translate(-2.1354772,-81.57512)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="23.283333"
            height="21.166666"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskTop.svg"
            id="imgDesk7"
            x="326.36234"
            y="196.45258"
            inkscape:label="imgDesk7"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 07')"
          />
          <text id="_7" data-name="7" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,336.04994,213.98521)">7</text>
        </g>
      </a>
      <a
        id="interactiveDesk8"
        (click)="bookingsDataService.bookDesk('Desk 08')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 08')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 08')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk8" inkscape:label="desk8" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="23.283333"
            height="21.166666"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskTop.svg"
            id="imgDesk8"
            x="350.69754"
            y="114.8595"
            inkscape:label="imgDesk8"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 08')"
          />
          <text id="_8" data-name="8" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,360.19306,132.39441)">8</text>
        </g>
      </a>
      <a
        id="interactiveDesk9"
        (click)="bookingsDataService.bookDesk('Desk 09')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 09')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 09')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk9" inkscape:label="desk9" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="23.283333"
            height="21.166666"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskBottom.svg"
            id="imgDesk9"
            x="326.86896"
            y="136.5002"
            inkscape:label="imgDesk9"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 09')"
          />
          <text id="_9" data-name="9" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,336.52846,145.5009)">9</text>
        </g>
      </a>
      <a
        id="interactiveDesk10"
        (click)="bookingsDataService.bookDesk('Desk 10')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 10')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 10')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk10" inkscape:label="desk10" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="23.283333"
            height="21.166666"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskBottom.svg"
            id="imgDesk10"
            x="350.69855"
            y="136.50002"
            inkscape:label="imgDesk10"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 10')"
          />
          <text id="_10" data-name="10" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,357.82991,145.51357)">10</text>
        </g>
      </a>
      <a
        id="interactiveDesk11"
        (click)="bookingsDataService.bookDesk('Desk 11')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 11')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 11')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk11" inkscape:label="desk11" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="21.43125"
            height="23.547916"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskLeft.svg"
            id="imgDesk11"
            x="331.33722"
            y="198.38222"
            inkscape:label="imgDesk11"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 11')"
          />
          <text id="_11" data-name="11" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,342.67061,213.11292)">11</text>
        </g>
      </a>
      <a
        id="interactiveDesk12"
        (click)="bookingsDataService.bookDesk('Desk 12')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 12')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 12')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk12" inkscape:label="desk12" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="21.166666"
            height="23.547916"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskRight.svg"
            id="imgDesk12"
            x="353.29767"
            y="198.36571"
            inkscape:label="imgDesk12"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 12')"
          />
          <text id="_12" data-name="12" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,355.03911,213.10921)">12</text>
        </g>
      </a>
      <a
        id="interactiveDesk13"
        (click)="bookingsDataService.bookDesk('Desk 13')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 13')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 13')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk13" inkscape:label="desk13" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="21.43125"
            height="23.547916"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskLeft.svg"
            id="imgDesk13"
            x="331.33865"
            y="222.17848"
            inkscape:label="imgDesk13"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 13')"
          />
          <text id="_13" data-name="13" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,342.2414,236.97632)">13</text>
        </g>
      </a>
      <a
        id="interactiveDesk14"
        (click)="bookingsDataService.bookDesk('Desk 14')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 14')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 14')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk14" inkscape:label="desk14" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="21.166666"
            height="23.547916"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskRight.svg"
            id="imgDesk14"
            x="353.30325"
            y="222.17566"
            inkscape:label="imgDesk14"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 14')"
          />
          <text id="_14" data-name="14" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,354.65742,236.97832)">14</text>
        </g>
      </a>
      <a
        id="interactiveDesk15"
        (click)="bookingsDataService.bookDesk('Desk 15')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 15')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 15')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk15" inkscape:label="desk15" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="21.43125"
            height="23.547916"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskLeft.svg"
            id="imgDesk15"
            x="398.58179"
            y="79.842415"
            inkscape:label="imgDesk15"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 15')"
          />
          <text id="_15" data-name="15" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,409.27004,94.609266)">15</text>
        </g>
      </a>
      <a
        id="interactiveDesk16"
        (click)="bookingsDataService.bookDesk('Desk 16')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 16')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 16')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk16" inkscape:label="desk16" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="21.166666"
            height="23.547916"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskRight.svg"
            id="imgDesk16"
            x="420.49936"
            y="79.843178"
            inkscape:label="imgDesk16"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 16')"
          />
          <text id="_16" data-name="16" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,422.16525,94.58837)">16</text>
        </g>
      </a>
      <a
        id="interactiveDesk17"
        (click)="bookingsDataService.bookDesk('Desk 17')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 17')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 17')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk17" inkscape:label="desk17" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="21.43125"
            height="23.547916"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskLeft.svg"
            id="imgDesk17"
            x="398.58087"
            y="103.5918"
            inkscape:label="imgDesk17"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 17')"
          />
          <text id="_17" data-name="17" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,409.28607,118.19914)">17</text>
        </g>
      </a>
      <a
        id="interactiveDesk18"
        (click)="bookingsDataService.bookDesk('Desk 18')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 18')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 18')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk18" inkscape:label="desk18" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="21.166666"
            height="23.547916"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskRight.svg"
            id="imgDesk18"
            x="420.49854"
            y="103.59216"
            inkscape:label="imgDesk18"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 18')"
          />
          <text id="_18" data-name="18" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,422.17427,118.17013)">18</text>
        </g>
      </a>
      <a
        id="interactiveDesk19"
        (click)="bookingsDataService.bookDesk('Desk 19')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 19')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 19')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk19" inkscape:label="desk19" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="21.43125"
            height="23.547916"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskLeft.svg"
            id="imgDesk19"
            x="398.58133"
            y="127.45992"
            inkscape:label="imgDesk19"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 19')"
          />
          <text id="_19" data-name="19" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,409.25784,141.66293)">19</text>
        </g>
      </a>
      <a
        id="interactiveDesk20"
        (click)="bookingsDataService.bookDesk('Desk 20')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 20')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 20')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk20" inkscape:label="desk20" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="21.166666"
            height="23.547916"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskRight.svg"
            id="imgDesk20"
            x="420.49854"
            y="127.45914"
            inkscape:label="imgDesk20"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 20')"
          />
          <text id="_20" data-name="20" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,422.34988,141.68935)">20</text>
        </g>
      </a>
      <a
        id="interactiveDesk21"
        (click)="bookingsDataService.bookDesk('Desk 21')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 21')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 21')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk21" inkscape:label="desk21" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="23.283333"
            height="21.166666"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskTop.svg"
            id="imgDesk21"
            x="391.91922"
            y="190.54031"
            inkscape:label="imgDesk21"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 21')"
          />
          <text id="_21" data-name="21" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,399.35899,208.15416)">21</text>
        </g>
      </a>
      <a
        id="interactiveDesk22"
        (click)="bookingsDataService.bookDesk('Desk 22')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 22')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 22')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk22" inkscape:label="desk22" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="23.283333"
            height="21.166666"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskTop.svg"
            id="imgDesk22"
            x="415.69037"
            y="190.53928"
            inkscape:label="imgDesk22"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 22')"
          />
          <text id="_22" data-name="22" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,423.0778,208.1684)">22</text>
        </g>
      </a>
      <a
        id="interactiveDesk23"
        (click)="bookingsDataService.bookDesk('Desk 23')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 23')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 23')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk23" inkscape:label="desk23" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="23.283333"
            height="21.166666"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskTop.svg"
            id="imgDesk23"
            x="439.51978"
            y="190.54085"
            inkscape:label="imgDesk23"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 23')"
          />
          <text id="_23" data-name="23" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,447.03545,208.0808)">23</text>
        </g>
      </a>
      <a
        id="interactiveDesk24"
        (click)="bookingsDataService.bookDesk('Desk 24')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 24')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 24')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk24" inkscape:label="desk24" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="23.283333"
            height="21.166666"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskBottom.svg"
            id="imgDesk24"
            x="391.91855"
            y="212.14354"
            inkscape:label="imgDesk24"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 24')"
          />
          <text id="_24" data-name="24" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,398.8144,220.47117)">24</text>
        </g>
      </a>
      <a
        id="interactiveDesk25"
        (click)="bookingsDataService.bookDesk('Desk 25')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 25')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 25')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk25" inkscape:label="desk25" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="23.283333"
            height="21.166666"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskBottom.svg"
            id="imgDesk25"
            x="415.69037"
            y="212.14311"
            inkscape:label="imgDesk25"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 25')"
          />
          <text id="_25" data-name="25" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,423.06298,220.39649)">25</text>
        </g>
      </a>
      <a
        id="interactiveDesk26"
        (click)="bookingsDataService.bookDesk('Desk 26')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 26')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 26')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk26" inkscape:label="desk26" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="23.283333"
            height="21.166666"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskBottom.svg"
            id="imgDesk26"
            x="439.51828"
            y="212.14449"
            inkscape:label="imgDesk26"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 26')"
          />
          <text id="_26" data-name="26" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,447.02066,220.48632)">26</text>
        </g>
      </a>
      <a
        id="interactiveDesk27"
        (click)="bookingsDataService.bookDesk('Desk 27')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 27')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 27')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk27" inkscape:label="desk27" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="21.43125"
            height="23.547916"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskLeft.svg"
            id="imgDesk27"
            x="480.46777"
            y="198.27547"
            inkscape:label="imgDesk27"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 27')"
          />
          <text id="_27" data-name="27" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,491.46166,213.02266)">27</text>
        </g>
      </a>
      <a
        id="interactiveDesk28"
        (click)="bookingsDataService.bookDesk('Desk 28')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 28')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 28')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk28" inkscape:label="desk28" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="21.166666"
            height="23.547916"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskRight.svg"
            id="imgDesk28"
            x="502.34869"
            y="198.27451"
            inkscape:label="imgDesk28"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 28')"
          />
          <text id="_28" data-name="28" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,504.30681,212.96651)">28</text>
        </g>
      </a>
      <a
        id="interactiveDesk29"
        (click)="bookingsDataService.bookDesk('Desk 29')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 29')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 29')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk29" inkscape:label="desk29" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="21.43125"
            height="23.547916"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskLeft.svg"
            id="imgDesk29"
            x="480.47031"
            y="222.17563"
            inkscape:label="imgDesk29"
            style="display: inline"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 29')"
          />
          <text id="_29" data-name="29" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,491.33072,236.5218)">29</text>
        </g>
      </a>
      <a
        id="interactiveDesk30"
        (click)="bookingsDataService.bookDesk('Desk 30')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 30')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 30')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk30" inkscape:label="desk30" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="21.166666"
            height="23.547916"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskRight.svg"
            id="imgDesk30"
            x="502.3497"
            y="222.17662"
            inkscape:label="imgDesk30"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 30')"
          />
          <text id="_30" data-name="30" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,504.29941,236.5686)">30</text>
        </g>
      </a>
      <a
        id="interactivePod"
        (click)="bookingsDataService.bookDesk('Working Pod')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Working Pod')"
        [attr.title]="bookingsDataService.getDeskToolTip('Working Pod')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="pod" inkscape:label="pod" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="5.2916665"
            height="32.808332"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/pod.svg"
            id="imgPod"
            x="209.11386"
            y="129.35645"
            inkscape:label="imgPod"
            style="display: inline"
            [attr.style]="bookingsDataService.getDeskImageStyle('Working Pod')"
          />
        </g>
      </a>
      <a
        id="interactiveOffice"
        (click)="bookingsDataService.bookDesk('Office')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Office')"
        [attr.title]="bookingsDataService.getDeskToolTip('Office')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="office" inkscape:label="office" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="30.691668"
            height="42.597918"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/office.svg"
            id="imgOffice"
            x="214.21327"
            y="46.136719"
            inkscape:label="imgOffice"
            [attr.style]="bookingsDataService.getDeskImageStyle('Office')"
          />
        </g>
      </a>
      <a
        id="interactiveProjectroom"
        (click)="bookingsDataService.bookDesk('Project Room')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Project Room')"
        [attr.title]="bookingsDataService.getDeskToolTip('Project Room')"
        kendoTooltip
      >
        <g
          inkscape:groupmode="layer"
          id="projectRoom"
          inkscape:label="projectRoom"
          inkscape:highlight-color="#35cbf3"
          transform="translate(-2.6412578,0.01605072)"
        >
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="33.337502"
            height="43.920835"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/projectRoom.svg"
            id="imgProjectRoom"
            x="146.94604"
            y="43.660446"
            inkscape:label="imgProjectRoom"
            [attr.style]="bookingsDataService.getDeskImageStyle('Project Room')"
          />
        </g>
      </a>
      <a
        id="interactiveBoardroom"
        (click)="bookingsDataService.bookDesk('Board Room')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Board Room')"
        [attr.title]="bookingsDataService.getDeskToolTip('Board Room')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="boardRoom" inkscape:label="boardRoom" inkscape:highlight-color="#f335e3" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="70.643749"
            height="47.095833"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/boardRoom.svg"
            id="imgBoardRoom"
            x="42.333336"
            y="50.403126"
            inkscape:label="imgBoardRoom"
            [attr.style]="bookingsDataService.getDeskImageStyle('Board Room')"
          />
        </g>
      </a>
    </svg>
  </div>
</div>
<div *ngIf="selectedOffice([offices[1]['uat']])">
  <div *ngIf="deviceTypeService.isMobile$ | async">
    <!-- Created with Inkscape (http://www.inkscape.org/) -->
    <svg
      xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
      xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:svg="http://www.w3.org/2000/svg"
      width="400"
      height="1270.5"
      viewBox="0 0 211.66664 672.30617"
      version="1.1"
      id="svg1"
      xml:space="preserve"
      inkscape:version="1.3.2 (091e20e, 2023-11-25, custom)"
      sodipodi:docname="hotdesk-mobile.svg"
    >
      <sodipodi:namedview
        id="namedview1"
        pagecolor="#ffffff"
        bordercolor="#000000"
        borderopacity="0.25"
        inkscape:showpageshadow="2"
        inkscape:pageopacity="0.0"
        inkscape:pagecheckerboard="0"
        inkscape:deskcolor="#d1d1d1"
        inkscape:document-units="mm"
        showguides="true"
        inkscape:zoom="0.35355339"
        inkscape:cx="-5.6568542"
        inkscape:cy="974.39314"
        inkscape:window-width="1920"
        inkscape:window-height="1009"
        inkscape:window-x="-8"
        inkscape:window-y="-8"
        inkscape:window-maximized="1"
        inkscape:current-layer="projectRoom"
      >
        <sodipodi:guide position="69.38698,-11.790496" orientation="0,-1" id="guide19" inkscape:locked="false" />
        <sodipodi:guide position="69.172005,-11.980666" orientation="0,-1" id="guide21" inkscape:locked="false" />
        <sodipodi:guide position="31.460125,184.79253" orientation="1,0" id="guide23" inkscape:locked="false" />
        <sodipodi:guide position="15.729333,85.018684" orientation="1,0" id="guide1" inkscape:locked="false" />
        <sodipodi:guide position="44.965587,-23.723624" orientation="1,0" id="guide24" inkscape:locked="false" />
        <sodipodi:guide position="78.51252,3.9429142" orientation="0,-1" id="guide28" inkscape:locked="false" />
        <sodipodi:guide position="60.48829,168.85376" orientation="1,0" id="guide30" inkscape:locked="false" />
        <sodipodi:guide position="157.00538,170.11053" orientation="0,-1" id="guide2" inkscape:locked="false" />
        <sodipodi:guide position="59.994263,167.87398" orientation="1,0" id="guide3" inkscape:locked="false" />
        <sodipodi:guide position="157.05086,169.67231" orientation="0,-1" id="guide4" inkscape:locked="false" />
        <sodipodi:guide position="59.96119,136.29348" orientation="0,-1" id="guide5" inkscape:locked="false" />
        <sodipodi:guide position="60.356551,136.76336" orientation="0,-1" id="guide6" inkscape:locked="false" />
        <sodipodi:guide position="60.048007,58.149417" orientation="0,-1" id="guide7" inkscape:locked="false" />
        <sodipodi:guide position="60.220606,57.693627" orientation="0,-1" id="guide8" inkscape:locked="false" />
        <sodipodi:guide position="157.02399,92.440857" orientation="0,-1" id="guide9" inkscape:locked="false" />
        <sodipodi:guide position="156.95991,92.184547" orientation="1,0" id="guide10" inkscape:locked="false" />
        <sodipodi:guide position="157.17798,92.055357" orientation="0,-1" id="guide11" inkscape:locked="false" />
        <sodipodi:guide position="157.40639,92.115297" orientation="1,0" id="guide12" inkscape:locked="false" />
        <sodipodi:guide position="157.08393,60.683627" orientation="0,-1" id="guide13" inkscape:locked="false" />
        <sodipodi:guide position="157.12114,60.264017" orientation="0,-1" id="guide14" inkscape:locked="false" />
        <sodipodi:guide position="157.1139,-23.498304" orientation="0,-1" id="guide15" inkscape:locked="false" />
        <sodipodi:guide position="157.23379,-23.922054" orientation="0,-1" id="guide16" inkscape:locked="false" />
        <sodipodi:guide position="45.477322,-23.771154" orientation="1,0" id="guide18" inkscape:locked="false" />
        <sodipodi:guide position="75.363867,-23.602694" orientation="1,0" id="guide20" inkscape:locked="false" />
        <sodipodi:guide position="75.82172,-24.168034" orientation="1,0" id="guide22" inkscape:locked="false" />
      </sodipodi:namedview>
      <defs id="defs1">
        <style id="style1">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-5">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-1">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-7">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-6">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-3">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-19">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-9">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-18">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-0">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-8">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-80">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-07">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-67">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-77">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-37">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-39">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-2">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-11">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-072">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-27">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-08">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-4">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-22">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-09">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-90">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-60">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-603">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-12">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-06">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
      </defs>
      <g inkscape:groupmode="layer" id="floorPlanMobile" inkscape:label="floorPlanMobile" style="display: inline">
        <image
          preserveAspectRatio="none"
          inkscape:svg-dpi="96"
          width="211.66664"
          height="672.30621"
          xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/floorPlanMobile.svg"
          id="image1"
          x="1.0583367"
          y="1.706516"
        />
      </g>
      <a
        id="interactiveDesk1"
        (click)="bookingsDataService.bookDesk('1')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('1')"
        [attr.title]="bookingsDataService.getDeskToolTip('1')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk1" inkscape:label="desk1">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="28.534691"
            height="31.373796"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskLeft.svg"
            id="imgDesk1"
            x="31.459581"
            y="136.19427"
            inkscape:label="imgDesk1"
            [attr.style]="bookingsDataService.getDeskImageStyle('1')"
          />
          <text id="_1" data-name="1" class="cls-1" transform="matrix(0.09710208,0,0,0.09710208,48.829211,155.47864)">1</text>
        </g>
      </a>
      <a
        id="interactiveDesk2"
        (click)="bookingsDataService.bookDesk('2')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('2')"
        [attr.title]="bookingsDataService.getDeskToolTip('2')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk2" inkscape:label="desk2">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="28.535"
            height="31.374001"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskRight.svg"
            id="image17"
            x="60.488297"
            y="136.19426"
            inkscape:label="imgDesk2"
            style="display: inline"
            [attr.style]="bookingsDataService.getDeskImageStyle('2')"
          />
          <text id="_2" data-name="2" class="cls-1" transform="matrix(0.09710208,0,0,0.09710208,65.97128,155.52387)">2</text>
        </g>
      </a>
      <a
        id="interactiveDesk3"
        (click)="bookingsDataService.bookDesk('3')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('3')"
        [attr.title]="bookingsDataService.getDeskToolTip('3')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk3" inkscape:label="desk3">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="28.534691"
            height="31.373796"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskLeft.svg"
            id="imgDesk3"
            x="31.458567"
            y="168.02052"
            inkscape:label="imgDesk3"
            [attr.style]="bookingsDataService.getDeskImageStyle('3')"
          />
          <text id="_3" data-name="3" class="cls-1" transform="matrix(0.09710208,0,0,0.09710208,48.769233,187.83317)">3</text>
        </g>
      </a>
      <a
        id="interactiveDesk4"
        (click)="bookingsDataService.bookDesk('4')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('4')"
        [attr.title]="bookingsDataService.getDeskToolTip('4')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk4" inkscape:label="desk4">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="28.535"
            height="31.374001"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskRight.svg"
            id="imgDesk4"
            x="60.488293"
            y="168.02074"
            inkscape:label="imgDesk4"
            style="display: inline"
            [attr.style]="bookingsDataService.getDeskImageStyle('4')"
          />
          <text id="_4" data-name="4" class="cls-1" transform="matrix(0.09710208,0,0,0.09710208,65.881935,187.88506)">4</text>
        </g>
      </a>
      <a
        id="interactiveDesk5"
        (click)="bookingsDataService.bookDesk('5')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('5')"
        [attr.title]="bookingsDataService.getDeskToolTip('5')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk5" inkscape:label="desk5">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="28.534691"
            height="31.373796"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskLeft.svg"
            id="imgDesk5"
            x="31.458548"
            y="199.85864"
            inkscape:label="imgDesk5"
            [attr.style]="bookingsDataService.getDeskImageStyle('5')"
          />
          <text id="_5" data-name="5" class="cls-1" transform="matrix(0.09710208,0,0,0.09710208,48.701164,219.86317)">5</text>
        </g>
      </a>
      <a
        id="interactiveDesk6"
        (click)="bookingsDataService.bookDesk('6')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('6')"
        [attr.title]="bookingsDataService.getDeskToolTip('6')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk6" inkscape:label="desk6">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="28.535"
            height="31.374001"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskRight.svg"
            id="imgDesk6"
            x="60.488293"
            y="199.8589"
            inkscape:label="imgDesk6"
            style="display: inline"
            [attr.style]="bookingsDataService.getDeskImageStyle('6')"
          />
          <text id="_6" data-name="6" class="cls-1" transform="matrix(0.09710208,0,0,0.09710208,65.951727,219.85888)">6</text>
        </g>
      </a>
      <a
        id="interactiveDesk7"
        (click)="bookingsDataService.bookDesk('7')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('7')"
        [attr.title]="bookingsDataService.getDeskToolTip('7')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk7" inkscape:label="desk7">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="29.885719"
            height="27.311302"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskTop.svg"
            id="imgDesk7"
            x="127.07458"
            y="138.72937"
            inkscape:label="imgDesk7"
            [attr.style]="bookingsDataService.getDeskImageStyle('7')"
          />
          <text id="_7" data-name="7" class="cls-1" transform="matrix(0.09260417,0,0,0.09260417,139.00042,161.37037)">7</text>
        </g>
      </a>
      <a
        id="interactiveDesk8"
        (click)="bookingsDataService.bookDesk('8')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('8')"
        [attr.title]="bookingsDataService.getDeskToolTip('8')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk8" inkscape:label="desk8">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="29.885719"
            height="27.311302"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskTop.svg"
            id="imgDesk8"
            x="157.40619"
            y="138.73042"
            inkscape:label="imgDesk8"
            [attr.style]="bookingsDataService.getDeskImageStyle('8')"
          />
          <text id="_8" data-name="8" class="cls-1" transform="matrix(0.09260417,0,0,0.09260417,169.7658,161.37102)">8</text>
        </g>
      </a>
      <a
        id="interactiveDesk9"
        (click)="bookingsDataService.bookDesk('9')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('9')"
        [attr.title]="bookingsDataService.getDeskToolTip('9')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk9" inkscape:label="desk9">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="29.886"
            height="27.311001"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskBottom.svg"
            id="imgDesk9"
            x="127.07442"
            y="166.48183"
            inkscape:label="imgDesk9"
            [attr.style]="bookingsDataService.getDeskImageStyle('9')"
          />
          <text id="_9" data-name="9" class="cls-1" transform="matrix(0.09260417,0,0,0.09260417,138.9663,177.8506)">9</text>
        </g>
      </a>
      <a
        id="interactiveDesk10"
        (click)="bookingsDataService.bookDesk('10')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('10')"
        [attr.title]="bookingsDataService.getDeskToolTip('10')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk10" inkscape:label="desk10">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="29.886"
            height="27.311001"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskBottom.svg"
            id="imgDesk10"
            x="157.40538"
            y="166.48183"
            inkscape:label="imgDesk10"
            [attr.style]="bookingsDataService.getDeskImageStyle('10')"
          />
          <text id="_10" data-name="10" class="cls-1" transform="matrix(0.09260417,0,0,0.09260417,166.71491,177.86645)">10</text>
        </g>
      </a>
      <a
        id="interactiveDesk11"
        (click)="bookingsDataService.bookDesk('11')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('11')"
        [attr.title]="bookingsDataService.getDeskToolTip('11')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk11" inkscape:label="desk11" style="display: inline">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="28.534691"
            height="31.373796"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskLeft.svg"
            id="imgDesk11"
            x="31.458548"
            y="246.63081"
            inkscape:label="imgDesk11"
            [attr.style]="bookingsDataService.getDeskImageStyle('11')"
          />
          <text id="_11" data-name="11" class="cls-1" transform="matrix(0.09921875,0,0,0.09921875,46.077262,266.07082)">11</text>
        </g>
      </a>
      <a
        id="interactiveDesk12"
        (click)="bookingsDataService.bookDesk('12')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('12')"
        [attr.title]="bookingsDataService.getDeskToolTip('12')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk12" inkscape:label="desk12">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="28.535"
            height="31.374001"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskRight.svg"
            id="imgDesk12"
            x="60.488293"
            y="246.62918"
            inkscape:label="imgDesk12"
            style="display: inline"
            [attr.style]="bookingsDataService.getDeskImageStyle('12')"
          />
          <text id="_12" data-name="12" class="cls-1" transform="matrix(0.09921875,0,0,0.09921875,63.083998,266.06762)">12</text>
        </g>
      </a>
      <a
        id="interactiveDesk13"
        (click)="bookingsDataService.bookDesk('13')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('13')"
        [attr.title]="bookingsDataService.getDeskToolTip('13')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk13" inkscape:label="desk13">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="28.534691"
            height="31.373796"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskLeft.svg"
            id="imgDesk13"
            x="31.458548"
            y="278.46039"
            inkscape:label="imgDesk13"
            style="display: inline"
            [attr.style]="bookingsDataService.getDeskImageStyle('13')"
          />
          <text id="_13" data-name="13" class="cls-1" transform="matrix(0.09921875,0,0,0.09921875,45.487532,299.02116)">13</text>
        </g>
      </a>
      <a
        id="interactiveDesk14"
        (click)="bookingsDataService.bookDesk('14')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('14')"
        [attr.title]="bookingsDataService.getDeskToolTip('14')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk14" inkscape:label="desk14">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="28.535"
            height="31.374001"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskRight.svg"
            id="imgDesk14"
            x="60.488293"
            y="278.45956"
            inkscape:label="imgDesk14"
            style="display: inline"
            [attr.style]="bookingsDataService.getDeskImageStyle('14')"
          />
          <text id="_14" data-name="14" class="cls-1" transform="matrix(0.09921875,0,0,0.09921875,62.556017,299.02423)">14</text>
        </g>
      </a>
      <a
        id="interactiveDesk15"
        (click)="bookingsDataService.bookDesk('15')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('15')"
        [attr.title]="bookingsDataService.getDeskToolTip('15')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk15" inkscape:label="desk15">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="28.534691"
            height="31.373796"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskLeft.svg"
            id="imgDesk15"
            x="128.42534"
            y="212.33849"
            inkscape:label="imgDesk15"
            style="display: inline"
            [attr.style]="bookingsDataService.getDeskImageStyle('15')"
          />
          <text id="_15" data-name="15" class="cls-1" transform="matrix(0.10027708,0,0,0.10027708,142.02946,231.18467)">15</text>
        </g>
      </a>
      <a
        id="interactiveDesk16"
        (click)="bookingsDataService.bookDesk('16')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('16')"
        [attr.title]="bookingsDataService.getDeskToolTip('16')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk16" inkscape:label="desk16">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="28.535"
            height="31.374001"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskRight.svg"
            id="imgDesk16"
            x="157.40541"
            y="212.3387"
            inkscape:label="imgDesk16"
            style="display: inline"
            [attr.style]="bookingsDataService.getDeskImageStyle('16')"
          />
          <text id="_16" data-name="16" class="cls-1" transform="matrix(0.10027708,0,0,0.10027708,160.01514,231.15503)">16</text>
        </g>
      </a>
      <a
        id="interactiveDesk17"
        (click)="bookingsDataService.bookDesk('17')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('17')"
        [attr.title]="bookingsDataService.getDeskToolTip('17')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk17" inkscape:label="desk17">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="28.534691"
            height="31.373796"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskLeft.svg"
            id="imgDesk17"
            x="128.42535"
            y="244.09706"
            inkscape:label="imgDesk17"
            style="display: inline"
            [attr.style]="bookingsDataService.getDeskImageStyle('17')"
          />
          <text id="_17" data-name="17" class="cls-1" transform="matrix(0.10027708,0,0,0.10027708,142.05248,264.1084)">17</text>
        </g>
      </a>
      <a
        id="interactiveDesk18"
        (click)="bookingsDataService.bookDesk('18')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('18')"
        [attr.title]="bookingsDataService.getDeskToolTip('18')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk18" inkscape:label="desk18">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="28.535"
            height="31.374001"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskRight.svg"
            id="imgDesk18"
            x="157.40541"
            y="244.09697"
            inkscape:label="imgDesk18"
            style="display: inline"
            [attr.style]="bookingsDataService.getDeskImageStyle('18')"
          />
          <text id="_18" data-name="18" class="cls-1" transform="matrix(0.10027708,0,0,0.10027708,160.02695,264.06727)">18</text>
        </g>
      </a>
      <a
        id="interactiveDesk19"
        (click)="bookingsDataService.bookDesk('19')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('19')"
        [attr.title]="bookingsDataService.getDeskToolTip('19')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk19" inkscape:label="desk19">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="28.534691"
            height="31.373796"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskLeft.svg"
            id="imgDesk19"
            x="128.42535"
            y="275.8887"
            inkscape:label="imgDesk19"
            style="display: inline"
            [attr.style]="bookingsDataService.getDeskImageStyle('19')"
          />
          <text id="_19" data-name="19" class="cls-1" transform="matrix(0.10027708,0,0,0.10027708,142.0146,296.70949)">19</text>
        </g>
      </a>
      <a
        id="interactiveDesk20"
        (click)="bookingsDataService.bookDesk('20')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('20')"
        [attr.title]="bookingsDataService.getDeskToolTip('20')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk20" inkscape:label="desk20">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="28.535"
            height="31.374001"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskRight.svg"
            id="imgDesk20"
            x="157.40541"
            y="275.88812"
            inkscape:label="imgDesk20"
            style="display: inline"
            [attr.style]="bookingsDataService.getDeskImageStyle('20')"
          />
          <text id="_20" data-name="20" class="cls-1" transform="matrix(0.10027708,0,0,0.10027708,160.27174,296.74725)">20</text>
        </g>
      </a>
      <a
        id="interactiveDesk21"
        (click)="bookingsDataService.bookDesk('21')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('21')"
        [attr.title]="bookingsDataService.getDeskToolTip('21')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk21" inkscape:label="desk21">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="29.885719"
            height="27.311302"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskTop.svg"
            id="imgDesk21"
            x="15.080024"
            y="332.33945"
            inkscape:label="imgDesk21"
            [attr.style]="bookingsDataService.getDeskImageStyle('21')"
          />
          <text id="_21" data-name="21" class="cls-1" transform="matrix(0.09286875,0,0,0.09286875,24.644026,355.53721)">21</text>
        </g>
      </a>
      <a
        id="interactiveDesk22"
        (click)="bookingsDataService.bookDesk('22')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('22')"
        [attr.title]="bookingsDataService.getDeskToolTip('22')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk22" inkscape:label="desk22">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="29.885719"
            height="27.311302"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskTop.svg"
            id="imgDesk22"
            x="45.478325"
            y="332.33997"
            inkscape:label="imgDesk22"
            [attr.style]="bookingsDataService.getDeskImageStyle('22')"
          />
          <text id="_22" data-name="22" class="cls-1" transform="matrix(0.09286875,0,0,0.09286875,55.058731,355.55798)">22</text>
        </g>
      </a>
      <a
        id="interactiveDesk23"
        (click)="bookingsDataService.bookDesk('23')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('23')"
        [attr.title]="bookingsDataService.getDeskToolTip('23')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk23" inkscape:label="desk23">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="29.885719"
            height="27.311302"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskTop.svg"
            id="imgDesk23"
            x="75.821693"
            y="332.33997"
            inkscape:label="imgDesk23"
            [attr.style]="bookingsDataService.getDeskImageStyle('23')"
          />
          <text id="_23" data-name="23" class="cls-1" transform="matrix(0.09286875,0,0,0.09286875,86.051223,355.54767)">23</text>
        </g>
      </a>
      <a
        id="interactiveDesk24"
        (click)="bookingsDataService.bookDesk('24')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('24')"
        [attr.title]="bookingsDataService.getDeskToolTip('24')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk24" inkscape:label="desk24">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="29.886"
            height="27.311001"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskBottom.svg"
            id="imgDesk24"
            x="15.079185"
            y="360.0762"
            inkscape:label="imgDesk24"
            [attr.style]="bookingsDataService.getDeskImageStyle('24')"
          />
          <text id="_24" data-name="24" class="cls-1" transform="matrix(0.09286875,0,0,0.09286875,24.201875,371.42767)">24</text>
        </g>
      </a>
      <a
        id="interactiveDesk25"
        (click)="bookingsDataService.bookDesk('25')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('25')"
        [attr.title]="bookingsDataService.getDeskToolTip('25')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk25" inkscape:label="desk25">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="29.886"
            height="27.311001"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskBottom.svg"
            id="imgDesk25"
            x="45.477345"
            y="360.07584"
            inkscape:label="imgDesk25"
            [attr.style]="bookingsDataService.getDeskImageStyle('25')"
          />
          <text id="_25" data-name="25" class="cls-1" transform="matrix(0.09286875,0,0,0.09286875,55.041236,371.43621)">25</text>
        </g>
      </a>
      <a
        id="interactiveDesk26"
        (click)="bookingsDataService.bookDesk('26')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('26')"
        [attr.title]="bookingsDataService.getDeskToolTip('26')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk26" inkscape:label="desk26">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="29.886"
            height="27.311001"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskBottom.svg"
            id="image22"
            x="75.820709"
            y="360.07584"
            inkscape:label="imgDesk25"
            [attr.style]="bookingsDataService.getDeskImageStyle('26')"
          />
          <text id="_26" data-name="26" class="cls-1" transform="matrix(0.09286875,0,0,0.09286875,86.033554,371.44463)">26</text>
        </g>
      </a>
      <a
        id="interactiveDesk27"
        (click)="bookingsDataService.bookDesk('27')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('27')"
        [attr.title]="bookingsDataService.getDeskToolTip('27')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk27" inkscape:label="desk27">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="28.534691"
            height="31.373796"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskLeft.svg"
            id="imgDesk27"
            x="128.42535"
            y="328.27826"
            inkscape:label="imgDesk27"
            style="display: inline"
            [attr.style]="bookingsDataService.getDeskImageStyle('27')"
          />
          <text id="_27" data-name="27" class="cls-1" transform="matrix(0.1000125,0,0,0.1000125,142.35067,347.63644)">27</text>
        </g>
      </a>
      <a
        id="interactiveDesk28"
        (click)="bookingsDataService.bookDesk('28')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('28')"
        [attr.title]="bookingsDataService.getDeskToolTip('28')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk28" inkscape:label="desk28">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="28.535"
            height="31.374001"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskRight.svg"
            id="imgDesk28"
            x="157.40436"
            y="328.27594"
            inkscape:label="imgDesk28"
            style="display: inline"
            [attr.style]="bookingsDataService.getDeskImageStyle('28')"
          />
          <text id="_28" data-name="28" class="cls-1" transform="matrix(0.1000125,0,0,0.1000125,159.91751,347.55889)">28</text>
        </g>
      </a>
      <a
        id="interactiveDesk29"
        (click)="bookingsDataService.bookDesk('29')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('29')"
        [attr.title]="bookingsDataService.getDeskToolTip('29')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk29" inkscape:label="desk29">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="28.534691"
            height="31.373796"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskLeft.svg"
            id="imgDesk29"
            x="128.42535"
            y="360.07581"
            inkscape:label="imgDesk29"
            style="display: inline"
            [attr.style]="bookingsDataService.getDeskImageStyle('29')"
          />
          <text id="_29" data-name="29" class="cls-1" transform="matrix(0.1000125,0,0,0.1000125,142.16602,380.48147)">29</text>
        </g>
      </a>
      <a
        id="interactiveDesk30"
        (click)="bookingsDataService.bookDesk('30')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('30')"
        [attr.title]="bookingsDataService.getDeskToolTip('30')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk30" inkscape:label="desk30">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="28.535"
            height="31.374001"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskRight.svg"
            id="imgDesk30"
            x="157.4054"
            y="360.07556"
            inkscape:label="imgDesk30"
            style="display: inline"
            [attr.style]="bookingsDataService.getDeskImageStyle('30')"
          />
          <text id="_30" data-name="30" class="cls-1" transform="matrix(0.1000125,0,0,0.1000125,159.90821,380.54391)">30</text>
        </g>
      </a>
      <a
        id="interactiveBoardroom"
        (click)="bookingsDataService.bookDesk('Board Room')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Board Room')"
        [attr.title]="bookingsDataService.getDeskToolTip('Board Room')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="boardRoom" inkscape:label="boardRoom">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="70.643753"
            height="47.095833"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/boardRoom.svg"
            id="imgBoardRoom"
            x="20.804724"
            y="433.5097"
            inkscape:label="imgBoardRoom"
            [attr.style]="bookingsDataService.getDeskImageStyle('Board Room')"
          />
        </g>
      </a>
      <a
        id="interactiveProjectroom"
        (click)="bookingsDataService.bookDesk('Project Room')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Project Room')"
        [attr.title]="bookingsDataService.getDeskToolTip('Project Room')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="projectRoom" inkscape:label="projectRoom" inkscape:highlight-color="#3555f3">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="39.931866"
            height="52.608646"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/projectRoom.svg"
            id="imgProjectRoom"
            x="145.52773"
            y="428.83633"
            inkscape:label="imgProjectRoom"
            [attr.style]="bookingsDataService.getDeskImageStyle('Project Room')"
          />
        </g>
      </a>
      <a
        id="interactiveOffice"
        (click)="bookingsDataService.bookDesk('Office')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Office')"
        [attr.title]="bookingsDataService.getDeskToolTip('Office')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="office" inkscape:label="office" inkscape:highlight-color="#cb35f3">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="42.284195"
            height="58.687542"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/office.svg"
            id="imgOffice"
            x="46.206333"
            y="548.76013"
            inkscape:label="imgOffice"
            [attr.style]="bookingsDataService.getDeskImageStyle('Office')"
          />
        </g>
      </a>
      <a
        id="interactivePod"
        (click)="bookingsDataService.bookDesk('Working Pod')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Working Pod')"
        [attr.title]="bookingsDataService.getDeskToolTip('Working Pod')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="pod" inkscape:label="pod">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="11.278504"
            height="64.052139"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/pod.svg"
            id="imgPod"
            x="-561.01196"
            y="129.89917"
            transform="rotate(-90)"
            inkscape:label="imgPod"
            [attr.style]="bookingsDataService.getDeskImageStyle('Working Pod')"
          />
        </g>
      </a>
    </svg>
  </div>
  <div *ngIf="(deviceTypeService.isMobile$ | async) === false">
    <!-- Created with Inkscape (http://www.inkscape.org/) -->
    <svg
      xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
      xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:svg="http://www.w3.org/2000/svg"
      width="1086"
      height="540"
      viewBox="0 0 574.67495 285.74998"
      version="1.1"
      id="svg1"
      xml:space="preserve"
      inkscape:version="1.3.2 (091e20e, 2023-11-25, custom)"
      sodipodi:docname="hotdesk-edited-linked-1.svg"
    >
      <sodipodi:namedview
        id="namedview1"
        pagecolor="#ffffff"
        bordercolor="#000000"
        borderopacity="0.25"
        inkscape:showpageshadow="2"
        inkscape:pageopacity="0.0"
        inkscape:pagecheckerboard="0"
        inkscape:deskcolor="#d1d1d1"
        inkscape:document-units="mm"
        inkscape:zoom="1"
        inkscape:cx="516"
        inkscape:cy="232.5"
        inkscape:window-width="1920"
        inkscape:window-height="1009"
        inkscape:window-x="1912"
        inkscape:window-y="-8"
        inkscape:window-maximized="1"
        inkscape:current-layer="boardRoom"
        showguides="true"
      >
        <sodipodi:guide position="583.31614,127.93114" orientation="0,-1" id="guide9" inkscape:locked="false" />
        <sodipodi:guide position="583.00814,127.11465" orientation="0,-1" id="guide10" inkscape:locked="false" />
        <sodipodi:guide position="721.27626,174.50081" orientation="0,-1" id="guide11" inkscape:locked="false" />
        <sodipodi:guide position="695.02198,585.01948" orientation="1,0" id="guide23" inkscape:locked="false" />
        <sodipodi:guide position="696.11286,584.7349" orientation="1,0" id="guide24" inkscape:locked="false" />
        <sodipodi:guide position="835.1457,602.79412" orientation="0,-1" id="guide25" inkscape:locked="false" />
        <sodipodi:guide position="584.9367,366.30003" orientation="0,-1" id="guide27" inkscape:locked="false" />
        <sodipodi:guide position="834.74262,695.46026" orientation="1,0" id="guide28" inkscape:locked="false" />
        <sodipodi:guide position="835.7162,695.06546" orientation="1,0" id="guide29" inkscape:locked="false" />
        <sodipodi:guide position="834.81498,697.53352" orientation="0,-1" id="guide30" inkscape:locked="false" />
        <sodipodi:guide position="835.31934,650.15244" orientation="0,-1" id="guide31" inkscape:locked="false" />
        <sodipodi:guide position="998.51825,127.57664" orientation="1,0" id="guide32" inkscape:locked="false" />
        <sodipodi:guide position="999.41885,127.31929" orientation="1,0" id="guide33" inkscape:locked="false" />
        <sodipodi:guide position="581.84232,174.42958" orientation="1,0" id="guide1" inkscape:locked="false" />
        <sodipodi:guide position="834.36844,556.22725" orientation="0,-1" id="guide2" inkscape:locked="false" />
      </sodipodi:namedview>
      <defs id="defs1">
        <style id="style1">
          .cls-1,
          .cls-2,
          .cls-3,
          .cls-4 {
            fill: none;
          }

          .cls-1,
          .cls-2,
          .cls-3,
          .cls-4,
          .cls-5,
          .cls-6,
          .cls-7 {
            stroke: #323232;
          }

          .cls-1 {
            stroke-width: 1.086px;
          }

          .cls-1,
          .cls-2,
          .cls-3,
          .cls-4,
          .cls-6,
          .cls-7 {
            fill-rule: evenodd;
          }

          .cls-2,
          .cls-5,
          .cls-6 {
            stroke-width: 0.543px;
          }

          .cls-3 {
            stroke-width: 0.814px;
          }

          .cls-4 {
            stroke-width: 1.221px;
          }

          .cls-5,
          .cls-6,
          .cls-7 {
            fill: #f6f6f6;
          }

          .cls-7 {
            stroke-width: 0.532px;
          }

          .cls-8 {
            opacity: 0.74;
          }

          .cls-9 {
            opacity: 0.72;
          }
        </style>
        <style id="style1-9">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-0">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-95">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-02">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-07">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-7">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-8">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-80">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-4">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-2">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-86">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-79">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-75">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-5">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-09">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-05">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-799">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-055">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-3">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-49">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-27">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-74">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-50">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-39">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-76">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-97">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-34">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-87">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-70">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-755">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-54">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-78">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-26">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
        <style id="style1-43">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
      </defs>
      <g inkscape:groupmode="layer" id="floorPlan" inkscape:label="floorPlan" style="display: inline" transform="translate(-2.6412578,0.01605072)">
        <image
          preserveAspectRatio="none"
          inkscape:svg-dpi="96"
          width="574.675"
          height="285.75"
          xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/floorPlan.svg"
          id="imgFloorPlan"
          x="2.6412578"
          y="-0.016050719"
          style="display: inline"
          inkscape:label="imgFloorPlan"
        />
      </g>
      <a
        id="interactiveDesk1"
        (click)="bookingsDataService.bookDesk('1')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('1')"
        [attr.title]="bookingsDataService.getDeskToolTip('1')"
        kendoTooltip
      >
        <g inkscape:label="desk1" inkscape:groupmode="layer" id="desk1" transform="translate(-2.6422913,0.09149831)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="21.43125"
            height="23.547916"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskLeft.svg"
            id="imgDesk1"
            x="272.14255"
            y="174.29155"
            inkscape:label="imgDesk1"
            [attr.style]="bookingsDataService.getDeskImageStyle('1')"
          />
          <text id="_1" data-name="1" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,285.29042,188.8685)">1</text>
        </g>
      </a>
      <a
        id="interactiveDesk2"
        (click)="bookingsDataService.bookDesk('2')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('2')"
        [attr.title]="bookingsDataService.getDeskToolTip('2')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk2" inkscape:label="desk2" transform="translate(130.97332,-4.1010736)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="21.166666"
            height="23.547916"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskRight.svg"
            id="imgDesk2"
            x="160.46536"
            y="178.48427"
            inkscape:label="imgDesk2"
            style="display: inline"
            [attr.style]="bookingsDataService.getDeskImageStyle('2')"
          />
          <text id="_2" data-name="2" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,164.52467,192.98615)">2</text>
        </g>
      </a>
      <a
        id="interactiveDesk3"
        (click)="bookingsDataService.bookDesk('3')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('3')"
        [attr.title]="bookingsDataService.getDeskToolTip('3')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk3" inkscape:label="desk3" transform="translate(-12.83759,12.08327)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="21.43125"
            height="23.547916"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskLeft.svg"
            id="imgDesk3"
            x="282.32855"
            y="186.19356"
            inkscape:label="imgDesk3"
            [attr.style]="bookingsDataService.getDeskImageStyle('3')"
          />
          <text id="_3" data-name="3" class="cls-1" style="display: inline" transform="matrix(0.07170208,0,0,0.07170208,295.40748,200.98984)">3</text>
        </g>
      </a>
      <a
        id="interactiveDesk4"
        (click)="bookingsDataService.bookDesk('4')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('4')"
        [attr.title]="bookingsDataService.getDeskToolTip('4')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk4" inkscape:label="desk4" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="21.166666"
            height="23.547916"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskRight.svg"
            id="imgDesk4"
            x="294.07919"
            y="198.25842"
            inkscape:label="imgDesk4"
            [attr.style]="bookingsDataService.getDeskImageStyle('4')"
          />
          <text id="_4" data-name="4" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,298.16541,213.09435)">4</text>
        </g>
      </a>
      <a
        id="interactiveDesk5"
        (click)="bookingsDataService.bookDesk('5')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('5')"
        [attr.title]="bookingsDataService.getDeskToolTip('5')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk5" inkscape:label="desk5" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="21.43125"
            height="23.547916"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskLeft.svg"
            id="imgDesk5"
            x="272.13907"
            y="222.17485"
            inkscape:label="imgDesk5"
            [attr.style]="bookingsDataService.getDeskImageStyle('5')"
          />
          <text id="_5" data-name="5" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,285.16625,236.94938)">5</text>
        </g>
      </a>
      <a
        id="interactiveDesk6"
        (click)="bookingsDataService.bookDesk('6')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('6')"
        [attr.title]="bookingsDataService.getDeskToolTip('6')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk6" inkscape:label="desk6" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="21.166666"
            height="23.547916"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskRight.svg"
            id="imgDesk6"
            x="294.07095"
            y="222.1756"
            inkscape:label="imgDesk6"
            style="display: inline"
            [attr.style]="bookingsDataService.getDeskImageStyle('6')"
          />
          <text id="_6" data-name="6" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,298.21698,236.94672)">
            <tspan
              style="
                font-style: normal;
                font-variant: normal;
                font-weight: bold;
                font-stretch: normal;
                font-family: Arial;
                -inkscape-font-specification: 'Arial Bold';
              "
              id="tspan2"
            >
              6
            </tspan>
          </text>
        </g>
      </a>
      <a
        id="interactiveDesk7"
        (click)="bookingsDataService.bookDesk('7')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('7')"
        [attr.title]="bookingsDataService.getDeskToolTip('7')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk7" inkscape:label="desk7" transform="translate(-2.1354772,-81.57512)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="23.283333"
            height="21.166666"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskTop.svg"
            id="imgDesk7"
            x="326.36234"
            y="196.45258"
            inkscape:label="imgDesk7"
            [attr.style]="bookingsDataService.getDeskImageStyle('7')"
          />
          <text id="_7" data-name="7" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,336.04994,213.98521)">7</text>
        </g>
      </a>
      <a
        id="interactiveDesk8"
        (click)="bookingsDataService.bookDesk('8')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('8')"
        [attr.title]="bookingsDataService.getDeskToolTip('8')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk8" inkscape:label="desk8" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="23.283333"
            height="21.166666"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskTop.svg"
            id="imgDesk8"
            x="350.69754"
            y="114.8595"
            inkscape:label="imgDesk8"
            [attr.style]="bookingsDataService.getDeskImageStyle('8')"
          />
          <text id="_8" data-name="8" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,360.19306,132.39441)">8</text>
        </g>
      </a>
      <a
        id="interactiveDesk9"
        (click)="bookingsDataService.bookDesk('9')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('9')"
        [attr.title]="bookingsDataService.getDeskToolTip('9')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk9" inkscape:label="desk9" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="23.283333"
            height="21.166666"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskBottom.svg"
            id="imgDesk9"
            x="326.86896"
            y="136.5002"
            inkscape:label="imgDesk9"
            [attr.style]="bookingsDataService.getDeskImageStyle('9')"
          />
          <text id="_9" data-name="9" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,336.52846,145.5009)">9</text>
        </g>
      </a>
      <a
        id="interactiveDesk10"
        (click)="bookingsDataService.bookDesk('10')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('10')"
        [attr.title]="bookingsDataService.getDeskToolTip('10')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk10" inkscape:label="desk10" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="23.283333"
            height="21.166666"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskBottom.svg"
            id="imgDesk10"
            x="350.69855"
            y="136.50002"
            inkscape:label="imgDesk10"
            [attr.style]="bookingsDataService.getDeskImageStyle('10')"
          />
          <text id="_10" data-name="10" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,357.82991,145.51357)">10</text>
        </g>
      </a>
      <a
        id="interactiveDesk11"
        (click)="bookingsDataService.bookDesk('11')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('11')"
        [attr.title]="bookingsDataService.getDeskToolTip('11')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk11" inkscape:label="desk11" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="21.43125"
            height="23.547916"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskLeft.svg"
            id="imgDesk11"
            x="331.33722"
            y="198.38222"
            inkscape:label="imgDesk11"
            [attr.style]="bookingsDataService.getDeskImageStyle('11')"
          />
          <text id="_11" data-name="11" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,342.67061,213.11292)">11</text>
        </g>
      </a>
      <a
        id="interactiveDesk12"
        (click)="bookingsDataService.bookDesk('12')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('12')"
        [attr.title]="bookingsDataService.getDeskToolTip('12')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk12" inkscape:label="desk12" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="21.166666"
            height="23.547916"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskRight.svg"
            id="imgDesk12"
            x="353.29767"
            y="198.36571"
            inkscape:label="imgDesk12"
            [attr.style]="bookingsDataService.getDeskImageStyle('12')"
          />
          <text id="_12" data-name="12" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,355.03911,213.10921)">12</text>
        </g>
      </a>
      <a
        id="interactiveDesk13"
        (click)="bookingsDataService.bookDesk('13')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('13')"
        [attr.title]="bookingsDataService.getDeskToolTip('13')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk13" inkscape:label="desk13" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="21.43125"
            height="23.547916"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskLeft.svg"
            id="imgDesk13"
            x="331.33865"
            y="222.17848"
            inkscape:label="imgDesk13"
            [attr.style]="bookingsDataService.getDeskImageStyle('13')"
          />
          <text id="_13" data-name="13" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,342.2414,236.97632)">13</text>
        </g>
      </a>
      <a
        id="interactiveDesk14"
        (click)="bookingsDataService.bookDesk('14')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('14')"
        [attr.title]="bookingsDataService.getDeskToolTip('14')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk14" inkscape:label="desk14" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="21.166666"
            height="23.547916"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskRight.svg"
            id="imgDesk14"
            x="353.30325"
            y="222.17566"
            inkscape:label="imgDesk14"
            [attr.style]="bookingsDataService.getDeskImageStyle('14')"
          />
          <text id="_14" data-name="14" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,354.65742,236.97832)">14</text>
        </g>
      </a>
      <a
        id="interactiveDesk15"
        (click)="bookingsDataService.bookDesk('15')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('15')"
        [attr.title]="bookingsDataService.getDeskToolTip('15')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk15" inkscape:label="desk15" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="21.43125"
            height="23.547916"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskLeft.svg"
            id="imgDesk15"
            x="398.58179"
            y="79.842415"
            inkscape:label="imgDesk15"
            [attr.style]="bookingsDataService.getDeskImageStyle('15')"
          />
          <text id="_15" data-name="15" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,409.27004,94.609266)">15</text>
        </g>
      </a>
      <a
        id="interactiveDesk16"
        (click)="bookingsDataService.bookDesk('16')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('16')"
        [attr.title]="bookingsDataService.getDeskToolTip('16')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk16" inkscape:label="desk16" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="21.166666"
            height="23.547916"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskRight.svg"
            id="imgDesk16"
            x="420.49936"
            y="79.843178"
            inkscape:label="imgDesk16"
            [attr.style]="bookingsDataService.getDeskImageStyle('16')"
          />
          <text id="_16" data-name="16" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,422.16525,94.58837)">16</text>
        </g>
      </a>
      <a
        id="interactiveDesk17"
        (click)="bookingsDataService.bookDesk('17')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('17')"
        [attr.title]="bookingsDataService.getDeskToolTip('17')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk17" inkscape:label="desk17" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="21.43125"
            height="23.547916"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskLeft.svg"
            id="imgDesk17"
            x="398.58087"
            y="103.5918"
            inkscape:label="imgDesk17"
            [attr.style]="bookingsDataService.getDeskImageStyle('17')"
          />
          <text id="_17" data-name="17" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,409.28607,118.19914)">17</text>
        </g>
      </a>
      <a
        id="interactiveDesk18"
        (click)="bookingsDataService.bookDesk('18')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('18')"
        [attr.title]="bookingsDataService.getDeskToolTip('18')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk18" inkscape:label="desk18" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="21.166666"
            height="23.547916"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskRight.svg"
            id="imgDesk18"
            x="420.49854"
            y="103.59216"
            inkscape:label="imgDesk18"
            [attr.style]="bookingsDataService.getDeskImageStyle('18')"
          />
          <text id="_18" data-name="18" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,422.17427,118.17013)">18</text>
        </g>
      </a>
      <a
        id="interactiveDesk19"
        (click)="bookingsDataService.bookDesk('19')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('19')"
        [attr.title]="bookingsDataService.getDeskToolTip('19')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk19" inkscape:label="desk19" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="21.43125"
            height="23.547916"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskLeft.svg"
            id="imgDesk19"
            x="398.58133"
            y="127.45992"
            inkscape:label="imgDesk19"
            [attr.style]="bookingsDataService.getDeskImageStyle('19')"
          />
          <text id="_19" data-name="19" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,409.25784,141.66293)">19</text>
        </g>
      </a>
      <a
        id="interactiveDesk20"
        (click)="bookingsDataService.bookDesk('20')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('20')"
        [attr.title]="bookingsDataService.getDeskToolTip('20')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk20" inkscape:label="desk20" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="21.166666"
            height="23.547916"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskRight.svg"
            id="imgDesk20"
            x="420.49854"
            y="127.45914"
            inkscape:label="imgDesk20"
            [attr.style]="bookingsDataService.getDeskImageStyle('20')"
          />
          <text id="_20" data-name="20" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,422.34988,141.68935)">20</text>
        </g>
      </a>
      <a
        id="interactiveDesk21"
        (click)="bookingsDataService.bookDesk('21')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('21')"
        [attr.title]="bookingsDataService.getDeskToolTip('21')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk21" inkscape:label="desk21" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="23.283333"
            height="21.166666"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskTop.svg"
            id="imgDesk21"
            x="391.91922"
            y="190.54031"
            inkscape:label="imgDesk21"
            [attr.style]="bookingsDataService.getDeskImageStyle('21')"
          />
          <text id="_21" data-name="21" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,399.35899,208.15416)">21</text>
        </g>
      </a>
      <a
        id="interactiveDesk22"
        (click)="bookingsDataService.bookDesk('22')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('22')"
        [attr.title]="bookingsDataService.getDeskToolTip('22')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk22" inkscape:label="desk22" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="23.283333"
            height="21.166666"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskTop.svg"
            id="imgDesk22"
            x="415.69037"
            y="190.53928"
            inkscape:label="imgDesk22"
            [attr.style]="bookingsDataService.getDeskImageStyle('22')"
          />
          <text id="_22" data-name="22" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,423.0778,208.1684)">22</text>
        </g>
      </a>
      <a
        id="interactiveDesk23"
        (click)="bookingsDataService.bookDesk('23')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('23')"
        [attr.title]="bookingsDataService.getDeskToolTip('23')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk23" inkscape:label="desk23" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="23.283333"
            height="21.166666"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskTop.svg"
            id="imgDesk23"
            x="439.51978"
            y="190.54085"
            inkscape:label="imgDesk23"
            [attr.style]="bookingsDataService.getDeskImageStyle('23')"
          />
          <text id="_23" data-name="23" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,447.03545,208.0808)">23</text>
        </g>
      </a>
      <a
        id="interactiveDesk24"
        (click)="bookingsDataService.bookDesk('24')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('24')"
        [attr.title]="bookingsDataService.getDeskToolTip('24')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk24" inkscape:label="desk24" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="23.283333"
            height="21.166666"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskBottom.svg"
            id="imgDesk24"
            x="391.91855"
            y="212.14354"
            inkscape:label="imgDesk24"
            [attr.style]="bookingsDataService.getDeskImageStyle('24')"
          />
          <text id="_24" data-name="24" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,398.8144,220.47117)">24</text>
        </g>
      </a>
      <a
        id="interactiveDesk25"
        (click)="bookingsDataService.bookDesk('25')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('25')"
        [attr.title]="bookingsDataService.getDeskToolTip('25')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk25" inkscape:label="desk25" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="23.283333"
            height="21.166666"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskBottom.svg"
            id="imgDesk25"
            x="415.69037"
            y="212.14311"
            inkscape:label="imgDesk25"
            [attr.style]="bookingsDataService.getDeskImageStyle('25')"
          />
          <text id="_25" data-name="25" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,423.06298,220.39649)">25</text>
        </g>
      </a>
      <a
        id="interactiveDesk26"
        (click)="bookingsDataService.bookDesk('26')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('26')"
        [attr.title]="bookingsDataService.getDeskToolTip('26')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk26" inkscape:label="desk26" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="23.283333"
            height="21.166666"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskBottom.svg"
            id="imgDesk26"
            x="439.51828"
            y="212.14449"
            inkscape:label="imgDesk26"
            [attr.style]="bookingsDataService.getDeskImageStyle('26')"
          />
          <text id="_26" data-name="26" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,447.02066,220.48632)">26</text>
        </g>
      </a>
      <a
        id="interactiveDesk27"
        (click)="bookingsDataService.bookDesk('27')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('27')"
        [attr.title]="bookingsDataService.getDeskToolTip('27')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk27" inkscape:label="desk27" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="21.43125"
            height="23.547916"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskLeft.svg"
            id="imgDesk27"
            x="480.46777"
            y="198.27547"
            inkscape:label="imgDesk27"
            [attr.style]="bookingsDataService.getDeskImageStyle('27')"
          />
          <text id="_27" data-name="27" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,491.46166,213.02266)">27</text>
        </g>
      </a>
      <a
        id="interactiveDesk28"
        (click)="bookingsDataService.bookDesk('28')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('28')"
        [attr.title]="bookingsDataService.getDeskToolTip('28')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk28" inkscape:label="desk28" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="21.166666"
            height="23.547916"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskRight.svg"
            id="imgDesk28"
            x="502.34869"
            y="198.27451"
            inkscape:label="imgDesk28"
            [attr.style]="bookingsDataService.getDeskImageStyle('28')"
          />
          <text id="_28" data-name="28" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,504.30681,212.96651)">28</text>
        </g>
      </a>
      <a
        id="interactiveDesk29"
        (click)="bookingsDataService.bookDesk('29')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('29')"
        [attr.title]="bookingsDataService.getDeskToolTip('29')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk29" inkscape:label="desk29" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="21.43125"
            height="23.547916"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskLeft.svg"
            id="imgDesk29"
            x="480.47031"
            y="222.17563"
            inkscape:label="imgDesk29"
            style="display: inline"
            [attr.style]="bookingsDataService.getDeskImageStyle('29')"
          />
          <text id="_29" data-name="29" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,491.33072,236.5218)">29</text>
        </g>
      </a>
      <a
        id="interactiveDesk30"
        (click)="bookingsDataService.bookDesk('30')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('30')"
        [attr.title]="bookingsDataService.getDeskToolTip('30')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="desk30" inkscape:label="desk30" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="21.166666"
            height="23.547916"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/deskRight.svg"
            id="imgDesk30"
            x="502.3497"
            y="222.17662"
            inkscape:label="imgDesk30"
            [attr.style]="bookingsDataService.getDeskImageStyle('30')"
          />
          <text id="_30" data-name="30" class="cls-1" transform="matrix(0.07170208,0,0,0.07170208,504.29941,236.5686)">30</text>
        </g>
      </a>
      <a
        id="interactivePod"
        (click)="bookingsDataService.bookDesk('Working Pod')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Working Pod')"
        [attr.title]="bookingsDataService.getDeskToolTip('Working Pod')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="pod" inkscape:label="pod" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="5.2916665"
            height="32.808332"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/pod.svg"
            id="imgPod"
            x="209.11386"
            y="129.35645"
            inkscape:label="imgPod"
            style="display: inline"
            [attr.style]="bookingsDataService.getDeskImageStyle('Working Pod')"
          />
        </g>
      </a>
      <a
        id="interactiveOffice"
        (click)="bookingsDataService.bookDesk('Office')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Office')"
        [attr.title]="bookingsDataService.getDeskToolTip('Office')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="office" inkscape:label="office" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="30.691668"
            height="42.597918"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/office.svg"
            id="imgOffice"
            x="214.21327"
            y="46.136719"
            inkscape:label="imgOffice"
            [attr.style]="bookingsDataService.getDeskImageStyle('Office')"
          />
        </g>
      </a>
      <a
        id="interactiveProjectroom"
        (click)="bookingsDataService.bookDesk('Project Room')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Project Room')"
        [attr.title]="bookingsDataService.getDeskToolTip('Project Room')"
        kendoTooltip
      >
        <g
          inkscape:groupmode="layer"
          id="projectRoom"
          inkscape:label="projectRoom"
          inkscape:highlight-color="#35cbf3"
          transform="translate(-2.6412578,0.01605072)"
        >
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="33.337502"
            height="43.920835"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/projectRoom.svg"
            id="imgProjectRoom"
            x="146.94604"
            y="43.660446"
            inkscape:label="imgProjectRoom"
            [attr.style]="bookingsDataService.getDeskImageStyle('Project Room')"
          />
        </g>
      </a>
      <a
        id="interactiveBoardroom"
        (click)="bookingsDataService.bookDesk('Board Room')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Board Room')"
        [attr.title]="bookingsDataService.getDeskToolTip('Board Room')"
        kendoTooltip
      >
        <g inkscape:groupmode="layer" id="boardRoom" inkscape:label="boardRoom" inkscape:highlight-color="#f335e3" transform="translate(-2.6412578,0.01605072)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="70.643749"
            height="47.095833"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/29scottjhb/boardRoom.svg"
            id="imgBoardRoom"
            x="42.333336"
            y="50.403126"
            inkscape:label="imgBoardRoom"
            [attr.style]="bookingsDataService.getDeskImageStyle('Board Room')"
          />
        </g>
      </a>
    </svg>
  </div>
</div>

<!-- Paardevlei -->
<div *ngIf="selectedOffice([offices[2]['dev'], offices[2]['uat'], offices[2]['prod']])">
  <div *ngIf="deviceTypeService.isMobile$ | async">
    <svg
      xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
      xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:svg="http://www.w3.org/2000/svg"
      width="399.88901"
      height="487.12399"
      viewBox="0 0 3979 4847"
      version="1.1"
      id="svg1"
      sodipodi:docname="Paardevlei mobile Latest.svg"
      xml:space="preserve"
      inkscape:export-filename="Paardevlei mobile Latest.svg"
      inkscape:export-xdpi="96"
      inkscape:export-ydpi="96"
      inkscape:version="1.3.2 (091e20e, 2023-11-25, custom)"
    >
      <sodipodi:namedview
        id="namedview1"
        pagecolor="#ffffff"
        bordercolor="#000000"
        borderopacity="0.25"
        inkscape:showpageshadow="2"
        inkscape:pageopacity="0.0"
        inkscape:pagecheckerboard="0"
        inkscape:deskcolor="#d1d1d1"
        showguides="true"
        inkscape:zoom="2"
        inkscape:cx="73.75"
        inkscape:cy="190.75"
        inkscape:window-width="1920"
        inkscape:window-height="1009"
        inkscape:window-x="1912"
        inkscape:window-y="-8"
        inkscape:window-maximized="1"
        inkscape:current-layer="svg1"
      />
      <defs id="defs1">
        <style id="style1">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
      </defs>
      <image
        preserveAspectRatio="none"
        inkscape:svg-dpi="96"
        width="3979"
        height="4847.0107"
        xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/18gardnerwilliamsave/floorPlanMobile.svg"
        id="floorPlanMobile"
        x="-4.4408921e-16"
        y="-0.0054366891"
        inkscape:label="floorPlanMobile"
      />
      <a
        id="interactiveDesk1"
        (click)="bookingsDataService.bookDesk('Desk 1')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 1')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 1')"
        kendoTooltip
      >
        <g id="desk1" inkscape:label="desk1" transform="translate(-2.3823264,-6.5595488e-5)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="591.00568"
            height="648.00079"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/18gardnerwilliamsave/deskLeftMobile.svg"
            id="imgDesk1"
            x="1120.5891"
            y="1495.734"
            inkscape:label="imgDesk1"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 1')"
          />
          <text id="_1" data-name="1" class="cls-1" transform="matrix(1.976,0,0,1.976,1483.765,1891.731)">1</text>
        </g>
      </a>
      <a
        id="interactiveDesk2"
        (click)="bookingsDataService.bookDesk('Desk 2')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 2')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 2')"
        kendoTooltip
      >
        <g id="desk2" inkscape:label="desk2" transform="translate(-2.3823264,-6.5595488e-5)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="583.00568"
            height="648.00079"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/18gardnerwilliamsave/deskRightMobile.svg"
            id="imgDesk2"
            x="1719.0718"
            y="1495.6481"
            inkscape:label="imgDesk2"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 2')"
          />
          <text id="_2" data-name="2" class="cls-1" transform="matrix(1.976,0,0,1.976,1830.706,1889.69)">2</text>
        </g>
      </a>
      <a
        id="interactiveDesk3"
        (click)="bookingsDataService.bookDesk('Desk 3')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 3')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 3')"
        kendoTooltip
      >
        <g id="desk3" inkscape:label="desk3" transform="translate(-2.3823264,-6.5595488e-5)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="591.00568"
            height="648.00079"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/18gardnerwilliamsave/deskLeftMobile.svg"
            id="imgDesk3"
            x="1120.822"
            y="2148.0427"
            inkscape:label="imgDesk3"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 3')"
          />
          <text id="_3" data-name="3" class="cls-1" transform="matrix(1.976,0,0,1.976,1481.557,2549.498)">3</text>
        </g>
      </a>
      <a
        id="interactiveDesk4"
        (click)="bookingsDataService.bookDesk('Desk 4')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 4')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 4')"
        kendoTooltip
      >
        <g id="desk4" inkscape:label="desk4" transform="translate(-2.3823264,-6.5595488e-5)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="583.00568"
            height="648.00079"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/18gardnerwilliamsave/deskRightMobile.svg"
            id="imgDesk4"
            x="1719.0719"
            y="2147.9731"
            inkscape:label="imgDesk4"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 4')"
          />
          <text id="_4" data-name="4" class="cls-1" transform="matrix(1.976,0,0,1.976,1831.386,2550.53)">4</text>
        </g>
      </a>
      <a
        id="interactiveDesk5"
        (click)="bookingsDataService.bookDesk('Desk 5')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 5')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 5')"
        kendoTooltip
      >
        <g id="desk5" inkscape:label="desk5" transform="translate(-2.3823264,-6.5595488e-5)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="591.00568"
            height="648.00079"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/18gardnerwilliamsave/deskLeftMobile.svg"
            id="imgDesk5"
            x="2260.1687"
            y="2660.6265"
            inkscape:label="imgDesk5"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 5')"
          />
          <text id="_5" data-name="5" class="cls-1" transform="matrix(1.976,0,0,1.976,2623.343,3072.067)">5</text>
        </g>
      </a>
    </svg>
  </div>
  <div *ngIf="(deviceTypeService.isMobile$ | async) === false">
    <svg
      xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
      xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:svg="http://www.w3.org/2000/svg"
      width="399.88901"
      height="487.12399"
      viewBox="0 0 3979 4847"
      version="1.1"
      id="svg1"
      sodipodi:docname="Paardevlei mobile Latest.svg"
      xml:space="preserve"
      inkscape:export-filename="Paardevlei mobile Latest.svg"
      inkscape:export-xdpi="96"
      inkscape:export-ydpi="96"
      inkscape:version="1.3.2 (091e20e, 2023-11-25, custom)"
    >
      <sodipodi:namedview
        id="namedview1"
        pagecolor="#ffffff"
        bordercolor="#000000"
        borderopacity="0.25"
        inkscape:showpageshadow="2"
        inkscape:pageopacity="0.0"
        inkscape:pagecheckerboard="0"
        inkscape:deskcolor="#d1d1d1"
        showguides="true"
        inkscape:zoom="2"
        inkscape:cx="73.75"
        inkscape:cy="190.75"
        inkscape:window-width="1920"
        inkscape:window-height="1009"
        inkscape:window-x="1912"
        inkscape:window-y="-8"
        inkscape:window-maximized="1"
        inkscape:current-layer="svg1"
      />
      <defs id="defs1">
        <style id="style1">
          .cls-1 {
            font-size: 108.333px;
            fill: #292929;
            font-family: Arial;
            font-weight: 700;
          }
        </style>
      </defs>
      <image
        preserveAspectRatio="none"
        inkscape:svg-dpi="96"
        width="3979"
        height="4847.0107"
        xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/18gardnerwilliamsave/floorPlanMobile.svg"
        id="floorPlanMobile"
        x="-4.4408921e-16"
        y="-0.0054366891"
        inkscape:label="floorPlanMobile"
      />
      <a
        id="interactiveDesk1"
        (click)="bookingsDataService.bookDesk('Desk 1')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 1')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 1')"
        kendoTooltip
      >
        <g id="desk1" inkscape:label="desk1" transform="translate(-2.3823264,-6.5595488e-5)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="591.00568"
            height="648.00079"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/18gardnerwilliamsave/deskLeftMobile.svg"
            id="imgDesk1"
            x="1120.5891"
            y="1495.734"
            inkscape:label="imgDesk1"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 1')"
          />
          <text id="_1" data-name="1" class="cls-1" transform="matrix(1.976,0,0,1.976,1483.765,1891.731)">1</text>
        </g>
      </a>
      <a
        id="interactiveDesk2"
        (click)="bookingsDataService.bookDesk('Desk 2')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 2')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 2')"
        kendoTooltip
      >
        <g id="desk2" inkscape:label="desk2" transform="translate(-2.3823264,-6.5595488e-5)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="583.00568"
            height="648.00079"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/18gardnerwilliamsave/deskRightMobile.svg"
            id="imgDesk2"
            x="1719.0718"
            y="1495.6481"
            inkscape:label="imgDesk2"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 2')"
          />
          <text id="_2" data-name="2" class="cls-1" transform="matrix(1.976,0,0,1.976,1830.706,1889.69)">2</text>
        </g>
      </a>
      <a
        id="interactiveDesk3"
        (click)="bookingsDataService.bookDesk('Desk 3')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 3')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 3')"
        kendoTooltip
      >
        <g id="desk3" inkscape:label="desk3" transform="translate(-2.3823264,-6.5595488e-5)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="591.00568"
            height="648.00079"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/18gardnerwilliamsave/deskLeftMobile.svg"
            id="imgDesk3"
            x="1120.822"
            y="2148.0427"
            inkscape:label="imgDesk3"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 3')"
          />
          <text id="_3" data-name="3" class="cls-1" transform="matrix(1.976,0,0,1.976,1481.557,2549.498)">3</text>
        </g>
      </a>
      <a
        id="interactiveDesk4"
        (click)="bookingsDataService.bookDesk('Desk 4')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 4')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 4')"
        kendoTooltip
      >
        <g id="desk4" inkscape:label="desk4" transform="translate(-2.3823264,-6.5595488e-5)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="583.00568"
            height="648.00079"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/18gardnerwilliamsave/deskRightMobile.svg"
            id="imgDesk4"
            x="1719.0719"
            y="2147.9731"
            inkscape:label="imgDesk4"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 4')"
          />
          <text id="_4" data-name="4" class="cls-1" transform="matrix(1.976,0,0,1.976,1831.386,2550.53)">4</text>
        </g>
      </a>
      <a
        id="interactiveDesk5"
        (click)="bookingsDataService.bookDesk('Desk 5')"
        [attr.style]="bookingsDataService.getDeskCursorStyle('Desk 5')"
        [attr.title]="bookingsDataService.getDeskToolTip('Desk 5')"
        kendoTooltip
      >
        <g id="desk5" inkscape:label="desk5" transform="translate(-2.3823264,-6.5595488e-5)">
          <image
            preserveAspectRatio="none"
            inkscape:svg-dpi="96"
            width="591.00568"
            height="648.00079"
            xlink:href="https://verdeimages.z1.web.core.windows.net/Images/Verde/Clients/deskmaps/braintree/18gardnerwilliamsave/deskLeftMobile.svg"
            id="imgDesk5"
            x="2260.1687"
            y="2660.6265"
            inkscape:label="imgDesk5"
            [attr.style]="bookingsDataService.getDeskImageStyle('Desk 5')"
          />
          <text id="_5" data-name="5" class="cls-1" transform="matrix(1.976,0,0,1.976,2623.343,3072.067)">5</text>
        </g>
      </a>
    </svg>
  </div>
</div>
