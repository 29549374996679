/* tslint:disable */
/* eslint-disable */
import { UserDate } from './user-date';
import { UserIBackingStore } from './user-i-backing-store';
import { UserRecurrenceRangeType } from './user-recurrence-range-type';
export interface UserRecurrenceRange {
  additionalData?: null | {
    [key: string]: any;
  };
  backingStore?: UserIBackingStore;
  endDate?: UserDate;
  numberOfOccurrences?: null | number;
  odataType?: null | string;
  recurrenceTimeZone?: null | string;
  startDate?: UserDate;
  type?: UserRecurrenceRangeType;
}
