/* tslint:disable */
/* eslint-disable */
import { UserCalendarColor } from './user-calendar-color';
import { UserCalendarPermission } from './user-calendar-permission';
import { UserEmailAddress } from './user-email-address';
import { UserEvent } from './user-event';
import { UserIBackingStore } from './user-i-backing-store';
import { UserMultiValueLegacyExtendedProperty } from './user-multi-value-legacy-extended-property';
import { UserOnlineMeetingProviderType } from './user-online-meeting-provider-type';
import { UserSingleValueLegacyExtendedProperty } from './user-single-value-legacy-extended-property';
export interface UserCalendar {
  additionalData?: null | {
    [key: string]: any;
  };
  allowedOnlineMeetingProviders?: null | Array<UserOnlineMeetingProviderType>;
  backingStore?: UserIBackingStore;
  calendarPermissions?: null | Array<UserCalendarPermission>;
  calendarView?: null | Array<UserEvent>;
  canEdit?: null | boolean;
  canShare?: null | boolean;
  canViewPrivateItems?: null | boolean;
  changeKey?: null | string;
  color?: UserCalendarColor;
  defaultOnlineMeetingProvider?: UserOnlineMeetingProviderType;
  events?: null | Array<UserEvent>;
  hexColor?: null | string;
  id?: null | string;
  isDefaultCalendar?: null | boolean;
  isRemovable?: null | boolean;
  isTallyingResponses?: null | boolean;
  multiValueExtendedProperties?: null | Array<UserMultiValueLegacyExtendedProperty>;
  name?: null | string;
  odataType?: null | string;
  owner?: UserEmailAddress;
  singleValueExtendedProperties?: null | Array<UserSingleValueLegacyExtendedProperty>;
}
