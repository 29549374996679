import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, HostBinding, Input, ViewChild } from '@angular/core';
import { TileAdaptiveCardModel, WebDynamicService } from '../../../../../public-api';
import { take } from 'rxjs';
import { AdaptiveCard, HostConfig, OpenUrlAction } from 'adaptivecards';
import { NavigationService } from '@verde/core';
import { ToastrService } from 'ngx-toastr'; // Import ToastrService

@Component({
  selector: 'verde-workspace-adaptive-card',
  templateUrl: './workspace-adaptive-card.component.html',
  styleUrls: ['./workspace-adaptive-card.component.scss'],
})
export class WorkspaceAdaptiveCardComponent implements AfterViewInit {
  @Input() adaptiveCard: TileAdaptiveCardModel;

  @HostBinding('class.k-card')
  cssClass = true;

  @ViewChild('body')
  body: ElementRef;

  private card: AdaptiveCard = new AdaptiveCard();

  constructor(
    private http: HttpClient,
    private navigationService: NavigationService,
    private webDynamicService: WebDynamicService,
    private toastr: ToastrService, // Inject ToastrService
  ) {
    this.card.hostConfig = new HostConfig({
      fontFamily: 'Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif',
      fontSizes: {
        default: 12,
        small: 12,
        medium: 16,
        large: 21,
        extraLarge: 26,
      },
    });

    this.card.onExecuteAction = (action) => {
      if (action instanceof OpenUrlAction) {
        this.navigationService.autoNavigateSomewhere(action?.url);
      }
    };
  }

  ngAfterViewInit() {
    this.processAdaptiveCard();
  }

  processAdaptiveCard() {
    if (this.adaptiveCard) {
      if (this.adaptiveCard?.template) {
        this.renderAdaptiveCard(this.adaptiveCard.template);
      } else if (this.adaptiveCard?.templateUrl && this.adaptiveCard?.template == null) {
        this.http.get<any>(this.adaptiveCard.templateUrl).pipe(take(1)).subscribe(this.handleAdaptiveCardResponse());
      } else if (this.adaptiveCard?.templateFileName && this.adaptiveCard?.template == null && this.adaptiveCard?.templateUrl == null) {
        const adaptiveCardsPath = 'adaptivecards/';
        const filePath = this.adaptiveCard.templateFileName.includes(adaptiveCardsPath)
          ? this.adaptiveCard.templateFileName
          : `${adaptiveCardsPath}${this.adaptiveCard.templateFileName}`;
        this.webDynamicService.getJsonFileFromBlobStorage(filePath).subscribe(this.handleAdaptiveCardResponse());
      }
    }
  }

  private handleAdaptiveCardResponse() {
    return {
      next: (data: any) => {
        if (data) this.renderAdaptiveCard(data);
      },
      error: (error: any) => {
        this.toastr.error(error?.message);
      },
    };
  }

  renderAdaptiveCard(data: any) {
    this.card.parse(data);

    if (this.body) {
      const content = this.card.render();
      this.body.nativeElement.append(content);
    }
  }
}
