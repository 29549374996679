/* tslint:disable */
/* eslint-disable */
export enum UserBthrTerminationreason {
  Retrenched = 'Retrenched',
  Dismissed = 'Dismissed',
  Deceased = 'Deceased',
  Resign = 'Resign',
  Retire = 'Retire',
  EndContract = 'EndContract',
  IntergroupTransfer = 'IntergroupTransfer',
  EmployeeTypeTransfer = 'EmployeeTypeTransfer',
}
