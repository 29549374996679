/* tslint:disable */
/* eslint-disable */
import { UserIBackingStore } from './user-i-backing-store';
import { UserLocationType } from './user-location-type';
import { UserLocationUniqueIdType } from './user-location-unique-id-type';
import { UserOutlookGeoCoordinates } from './user-outlook-geo-coordinates';
import { UserPhysicalAddress } from './user-physical-address';
export interface UserLocation {
  additionalData?: null | {
    [key: string]: any;
  };
  address?: UserPhysicalAddress;
  backingStore?: UserIBackingStore;
  coordinates?: UserOutlookGeoCoordinates;
  displayName?: null | string;
  locationEmailAddress?: null | string;
  locationType?: UserLocationType;
  locationUri?: null | string;
  odataType?: null | string;
  uniqueId?: null | string;
  uniqueIdType?: UserLocationUniqueIdType;
}
