/* tslint:disable */
/* eslint-disable */
import { BookingsBtLocationEnum } from './bookings-bt-location-enum';
import { BookingsBtProcessStatus } from './bookings-bt-process-status';
import { BookingsBtRequestProcessStatus } from './bookings-bt-request-process-status';
import { BookingsBtRequestSource } from './bookings-bt-request-source';
import { BookingsBtRequestStatus } from './bookings-bt-request-status';
import { BookingsBtShipmentType } from './bookings-bt-shipment-type';
import { BookingsBtVerdeStandardProcess } from './bookings-bt-verde-standard-process';
import { BookingsBthrRequestType } from './bookings-bthr-request-type';
export interface BookingsPostBookingsCommand {
  bt_Departmental?: null | boolean;
  bt_ProxyEmployee?: null | string;
  bt_address?: null | string;
  bt_approvaldate?: null | string;
  bt_booking?: null | string;
  bt_bookingeventid?: null | string;
  bt_bookingno?: null | string;
  bt_bookingreason?: null | string;
  bt_employee?: null | string;
  bt_empname?: null | string;
  bt_fromdate?: null | string;
  bt_itemcategory?: null | string;
  bt_legalentity?: null | string;
  bt_location?: BookingsBtLocationEnum;
  bt_locationlookup?: null | string;
  bt_nooflines?: null | number;
  bt_office?: null | string;
  bt_originalrequireddate?: null | string;
  bt_processqueue?: null | string;
  bt_processstatus?: BookingsBtProcessStatus;
  bt_proxy?: null | string;
  bt_proxyyn?: null | boolean;
  bt_prxyname?: null | string;
  bt_rejectiondate?: null | string;
  bt_requestdate?: null | string;
  bt_requestprocessstatus?: BookingsBtRequestProcessStatus;
  bt_requestsource?: BookingsBtRequestSource;
  bt_requeststatus?: BookingsBtRequestStatus;
  bt_requesttype?: BookingsBthrRequestType;
  bt_requireddate?: null | string;
  bt_requireddatechanged?: null | boolean;
  bt_room?: null | string;
  bt_standardprocess?: BookingsBtVerdeStandardProcess;
  bt_suboffice?: null | string;
  bt_todate?: null | string;
  bt_type?: BookingsBtShipmentType;
  bt_verdebookingsid?: null | string;
  bt_verdeitemrequestheader1?: null | string;
  bt_verdeitemrequestheaderid?: null | string;
  cancelRecurring?: null | boolean;
  colour?: null | string;
  employeeName?: null | string;
  icon?: null | string;
  includeWeekends?: null | boolean;
  isGraphBooking?: null | boolean;
  isProxy?: null | boolean;
  isRecurring?: null | boolean;
  linkedId?: null | string;
  numberOfDays?: null | number;
  odataBookingItem?: null | string;
  odataBookingType?: null | string;
  odataDuration?: null | string;
  odataEmployee?: null | string;
  odataOffice?: null | string;
  odataPostBookingItem?: null | string;
  odataPostBookingType?: null | string;
  odataPostDepartment?: null | string;
  odataPostDuration?: null | string;
  odataPostEmployee?: null | string;
  odataPostLegalEntity?: null | string;
  odataPostOffice?: null | string;
  odataPostPrimaryLocation?: null | string;
  odataPostSubOffice?: null | string;
  odataPrimaryLocation?: null | string;
  odataSubOffice?: null | string;
  requestNoId?: null | string;
  stateCode?: null | number;
}
