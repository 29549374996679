import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { checkIcon, chevronDoubleRightIcon, xIcon } from '@progress/kendo-svg-icons';
import { DeviceTypeService, UserService } from '@verde/core';
import { Observable, Subject, take, takeUntil } from 'rxjs';
import { SidePanelOverlayWidth } from './enums/sidepanel-overlay-width.enum';
import { VerdeApprovalOverlayService } from './services/verde-approval-overlay.service';

@Component({
  selector: 'verde-approval-panel-overlay',
  templateUrl: './verde-approval-panel-overlay.component.html',
  styleUrls: ['./verde-approval-panel-overlay.component.scss'],
})
export class VerdeApprovalPanelOverlayComponent implements OnInit, AfterViewInit, AfterContentChecked, OnDestroy {
  private onDestroy$ = new Subject<boolean>();

  showSidebar: Observable<boolean>;
  sidebarType: string;

  @Input() duration: number = SidePanelOverlayWidth.Quater;
  @Input() navWidth: number = window.innerWidth;
  @Input() direction: string = 'right';

  //Universal title variable
  universalTitleVar: string = '';
  universalTitleHeadingVar: string = '';

  constructor(
    private userService: UserService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    public deviceTypeService: DeviceTypeService,
    private sidebarOverlayService: VerdeApprovalOverlayService,
  ) {}

  ngOnInit(): void {
    this.sidebarOverlayService
      .getTitleTag()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((item) => {
        if (Object.keys(item).length > 0) {
          this.universalTitleVar = item.toString();
        }
      });

    this.sidebarOverlayService
      .getTitleHeading()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((item) => {
        if (Object.keys(item).length > 0) {
          this.universalTitleHeadingVar = item.toString();
          console.log('this.universalTitleHeadingVar', this.universalTitleHeadingVar);
        }
      });

    this.sidebarOverlayService
      .getSidebarSize()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((size) => {
        this.navWidth = size;
      });

    this.showSidebar = this.sidebarOverlayService.getShowSidebar();
    this.sidebarOverlayService
      .getSidebarType()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((type) => {
        this.sidebarType = type;
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  ngAfterViewInit(): void {}

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }


  onSidebarClose() {
     this.sidebarOverlayService.setShowSidebar(false);
  }

  getSidebarStyle(showNav: boolean) {
    let sidebarStyle: any = {};

    sidebarStyle.transition = this.direction + ' ' + this.duration + 's, visibility ' + this.duration + 's';
    sidebarStyle.width = this.navWidth + 'vw';
    sidebarStyle[this.direction] = (showNav ? 0 : this.navWidth * -1) + 'vw';

    return sidebarStyle;
  }

}
