/* tslint:disable */
/* eslint-disable */
export enum BookingsBtLocationEnum {
  OfficeLocation = 'OfficeLocation',
  SelectedRoom = 'SelectedRoom',
  AddressfromEmployee = 'AddressfromEmployee',
  SpecifiedAddress = 'SpecifiedAddress',
  RoomfromBookings = 'RoomfromBookings',
  NotApplicable = 'NotApplicable',
  SubOffice = 'SubOffice'
}
