/* tslint:disable */
/* eslint-disable */
import { UserEvent } from './user-event';
import { UserIBackingStore } from './user-i-backing-store';
export interface UserEventCollectionResponse {
  additionalData?: null | {
    [key: string]: any;
  };
  backingStore?: UserIBackingStore;
  odataCount?: null | number;
  odataNextLink?: null | string;
  value?: null | Array<UserEvent>;
}
