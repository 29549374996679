/* tslint:disable */
/* eslint-disable */
import { UserCalendarRoleType } from './user-calendar-role-type';
import { UserEmailAddress } from './user-email-address';
import { UserIBackingStore } from './user-i-backing-store';
export interface UserCalendarPermission {
  additionalData?: null | {
    [key: string]: any;
  };
  allowedRoles?: null | Array<UserCalendarRoleType>;
  backingStore?: UserIBackingStore;
  emailAddress?: UserEmailAddress;
  id?: null | string;
  isInsideOrganization?: null | boolean;
  isRemovable?: null | boolean;
  odataType?: null | string;
  role?: UserCalendarRoleType;
}
