/* tslint:disable */
/* eslint-disable */
import { UserIBackingStore } from './user-i-backing-store';
export interface UserAttachment {
  additionalData?: null | {
    [key: string]: any;
  };
  backingStore?: UserIBackingStore;
  contentType?: null | string;
  id?: null | string;
  isInline?: null | boolean;
  lastModifiedDateTime?: null | string;
  name?: null | string;
  odataType?: null | string;
  size?: null | number;
}
