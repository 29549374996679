<div class="user-card k-card"
     [style.height]="cardHeight"
     [style.background-image]="'url(' + userCardBackgroundImage + ')'">
    <div *ngIf="showAvatar"
         [ngClass]="{'user-avatar': (deviceTypeService.isDesktop$ | async) || !dynamicSize , 'user-avatar-tablet': (deviceTypeService.isTablet$ | async) && dynamicSize}">
        <kendo-avatar *ngIf="imageUrl"
                      [imageSrc]="imageUrl"
                      [ngClass]="{'avatar': (deviceTypeService.isDesktop$ | async) || !dynamicSize , 'avatar-tablet': (deviceTypeService.isTablet$ | async) && dynamicSize}"></kendo-avatar>
        <kendo-avatar *ngIf="!imageUrl"
                      imageSrc="https://verdeimages.z1.web.core.windows.net/Images/Verde/MyWorkspace/myprofile.svg"
                      [ngClass]="{'avatar': (deviceTypeService.isDesktop$ | async) || !dynamicSize , 'avatar-tablet': (deviceTypeService.isTablet$ | async) && dynamicSize}"></kendo-avatar>
    </div>
    <div
         [ngClass]="{'user-info-no-avatar': !showAvatar && !iconsEnabled, 'user-info-no-avatar-with-icons': !showAvatar && iconsEnabled}">
        <h3 class="user-name">{{ fullName }}</h3>
        <p class="user-title">{{ jobTitle }}</p>
    </div>
    <div class="user-icons"
         *ngIf="iconsEnabled">
        <kendo-avatar [svgIcon]="icons.pencil"
                      [border]="true"
                      fillMode="outline"
                      size="small"
                      class="user-icon"
                      (click)="userIconClicked('left')"></kendo-avatar>
        <kendo-avatar [svgIcon]="icons.bell"
                      [border]="true"
                      fillMode="outline"
                      size="small"
                      class="user-icon"
                      (click)="userIconClicked('right')"></kendo-avatar>
    </div>
</div>