import { WebDynamicFormType } from '../enums/web-dynamic-form-type.enum';
import { WebDynamicFormAction } from '../enums/web-dynamic-form-action.enum';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormGroup } from '@angular/forms';

export interface WebDynamicArgs {
  model?: any;
}

export interface WebDynamicSidePanelArgs extends WebDynamicArgs {
  visible?: boolean;
  size?: number;
  overlay?: boolean;
}

export interface WebDynamicGridArgs extends WebDynamicArgs {
  //
}

export interface WebDynamicModel {
  formFile: string;
  formType: WebDynamicFormType;
  action: WebDynamicFormAction;
  args?: WebDynamicSidePanelArgs | WebDynamicGridArgs;
  pathURL?: string;
}

export interface WebDynamicFormModel extends WebDynamicModel {
  additional?: any;
  fields?: FormlyFieldConfig[];
  form?: FormGroup;
  schema?: any;
}
