import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldProps } from '@ngx-formly/core';

export interface KendoFormlyInputProps extends FormlyFieldProps {
  description?: string;
  type?: string;
  readOnly?: boolean;
  required?: boolean;
  tabindex?: number;
  disabled?: boolean;
  min?: number;
  max?: number;
  placeholder?: string;
}

@Component({
  selector: 'verde-kendo-formly-input',
  template: `
    <kendo-label *ngIf="props.description" [for]="field.key">{{ props.description }}</kendo-label>
    <input
      class="verde-custom-field"
      [id]="field.key"
      *ngIf="props.type !== 'number'; else numberTmp"
      kendoTextBox
      [type]="props.type || 'text'"
      [formlyAttributes]="field"
      [formControl]="formControl"
      [readonly]="props.readOnly ?? false"
      [required]="props.required ?? false"
      [tabIndex]="props.tabindex ?? 0"
      [placeholder]="props.placeholder"
      [disabled]="props.disabled ?? false"
    />
    <ng-template #numberTmp>
      <kendo-numerictextbox
        class="verde-custom-field"
        [id]="field.key"
        type="number"
        [min]="props.min ?? 0"
        [max]="props.max ?? 100"
        [formlyAttributes]="field"
        [formControl]="formControl"
        [readonly]="props.readOnly ?? false"
        [required]="props.required ?? false"
        [tabIndex]="props.tabindex ?? 0"
        [placeholder]="props.placeholder"
        [disabled]="props.disabled ?? false"
      >
      </kendo-numerictextbox>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KendoFormlyInput extends FieldType<FieldTypeConfig<KendoFormlyInputProps>> {
  get type() {
    return this.props.type || 'text';
  }
}
