<kendo-grid
  [kendoGridBinding]="(options$ | async)!"
  [resizable]="true"
  [scrollable]="'scrollable'"
  [sortable]="true"
  [sort]="sort"
  [groupable]="true"
  [loading]="props.loading"
>
  <ng-template kendoGridToolbarTemplate style="height: 57px">
    <kendo-grid-spacer></kendo-grid-spacer>

      <ng-container *ngFor="let button of functions">
        <button kendoButton themeColor="primary" (click)="actionClicked(button)" *ngIf="button.bt_GridMenu">
          {{ button.menuName }}
        </button>
      </ng-container>

      <button style="height: 36px; margin-left: auto" kendoGridExcelCommand icon="file-excel">Export to Excel</button>
      <img title="Refresh" class="refresh_icon" src="https://engageimages.z1.web.core.windows.net/Images/Verde/Other/reload.svg" alt="" (click)="refresh()" />
  </ng-template>

  <kendo-grid-column *ngFor="let column of getVisibleColumns()" [field]="column.field" [title]="column.title" [width]="column.width">
    <ng-template kendoGridCellTemplate let-dataItem>
      <ng-container *ngIf="isString(dataItem[column.field]); else nonStringTemplate">
        <ng-container *ngIf="isDateString(dataItem[column.field]); else stringContent">
          {{ dataItem[column.field] | date : 'yyyy/MM/dd' }}
        </ng-container>
        <ng-template #stringContent>
          {{ dataItem[column.field] }}
        </ng-template>
      </ng-container>
      <ng-template #nonStringTemplate>
        <ng-container *ngIf="isBoolean(dataItem[column.field])">
          {{ dataItem[column.field] ? 'Yes' : 'No' }}
        </ng-container>
        <ng-container *ngIf="isNumber(dataItem[column.field])">
          <span class="numeric-cell">{{ dataItem[column.field] }}</span>
        </ng-container>
        <ng-container *ngIf="isObjectWithName(dataItem[column.field])">
          {{ dataItem[column.field].name }}
        </ng-container>
      </ng-template>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-command-column title="" [width]="40" [sticky]="true">
    <ng-template kendoGridCellTemplate let-ListTable>
      <verde-dots-menu-button
        [items]="dotMenuItems"
        (menuClicked)="menuClicked(ListTable)"
        (menuItemClicked)="menuItemClicked($event)"
      ></verde-dots-menu-button>
    </ng-template>
  </kendo-grid-command-column>

  <kendo-grid-excel fileName="Data.xlsx"></kendo-grid-excel>
</kendo-grid>
