/* eslint-disable no-console */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { WebDynamicApiWebService } from '@verde/api';
import { UserService } from '@verde/core';
import { ModalService, VerdeApprovalService, WebDynamicFormAction, WebDynamicFormType, WebDynamicService } from '@verde/shared';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import * as uuid from 'uuid';

@Component({
  selector: 'verde-web-dynamic-form',
  templateUrl: './web-dynamic-form.component.html',
  styleUrls: ['./web-dynamic-form.component.scss'],
})
export class WebDynamicFormComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<boolean>();
  id: string;
  form: FormGroup;
  model: any;
  additional: any;
  options: FormlyFormOptions;
  fields: FormlyFieldConfig[];
  disableAnimation: boolean;
  errorMessage = '';
  formType: WebDynamicFormType;
  action: WebDynamicFormAction;
  WebDynamicFormType: typeof WebDynamicFormType = WebDynamicFormType;

  constructor(
    public userService: UserService,
    private webDynamicService: WebDynamicService,
    private spinner: NgxSpinnerService,
    private modalService: ModalService,
    private webDynamicApiWebService: WebDynamicApiWebService,
    private sidebarService: VerdeApprovalService,
  ) {
    this.spinner?.show('dynamicFormSpinner');
    this.webDynamicService.webDynamicFormModel$.pipe(take(1)).subscribe((webDynamicFormModel) => {
      this.spinner?.hide('dynamicFormSpinner');

      if (webDynamicFormModel) {
        this.id = uuid.v4();
        this.form = webDynamicFormModel.form;
        this.options = {};
        this.fields = webDynamicFormModel.fields;
        this.formType = webDynamicFormModel.formType;
        this.action = webDynamicFormModel.action;
        this.additional = webDynamicFormModel.additional;

        setTimeout(() => {
          this.model = this.transformFlatModel(webDynamicFormModel?.args?.model ?? {});
          this.form.patchValue(this.model);

          // Louis: Please don't re-add this unless you have spoken with me

           //this.form.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((formValue) => {
           //  this.model = formValue;
           //  this.webDynamicService.updateModel(this.model);
           //});
        });
      } else {
        this.throwError();
      }
    });
  }

  ngOnInit(): void {
    this.userService.disableAnimation$.pipe(takeUntil(this.onDestroy$)).subscribe((disableAnimation) => {
      this.disableAnimation = disableAnimation;
    });
  }

  private transformFlatModel(flatModel: any): any {
    const transformedModel: any = {};

    // Recursive function to build the nested model
    const buildNestedModel = (fields: FormlyFieldConfig[], model: any, currentParent: any) => {
      fields.forEach((field) => {
        const key = field.key as string;

        if (field.fieldGroup) {
          // If key is defined, initialize the section
          if (key) {
            currentParent[key] = currentParent[key] || {};
            // Recursively build the nested model for fieldGroup
            buildNestedModel(field.fieldGroup, model, currentParent[key]);
          } else {
            // If key is undefined, just go deeper into fieldGroup without creating a parent
            buildNestedModel(field.fieldGroup, model, currentParent);
          }
        } else {
          // For non-group fields, check for an id property
          if (key && model[key] !== undefined) {
            if (field.type === 'enum') {
              const options = field.props.options;
              if (Array.isArray(options)) {
                const enumOption = options.find((option) => option.label === model[key]);
                currentParent[key] = enumOption ? enumOption.value : model[key]; // Fallback to original if label not found
              } else {
                // Handle case where options is not an array (if necessary)
                currentParent[key] = model[key];
              }
            } else if (model[key] && typeof model[key] === 'object' && model[key].id) {
              // Use the id if it exists
              currentParent[key] = model[key].id;
            } else if (model[key] && field.type === 'date') {
              // If the field type is 'date', ensure the value is a JavaScript Date object
              currentParent[key] = new Date(model[key]);
            } else {
              // Assign the value directly if no id
              currentParent[key] = model[key];
            }
          }
        }
      });
    };

    // Start building the nested model from the top level
    buildNestedModel(this.fields, flatModel, transformedModel);
    return transformedModel;
  }

  openSubmitModal() {
    if (this.form.valid && this.formType === WebDynamicFormType.SidePanel) {
      this.modalService.open('confirmModal_' + this.id);
    }
  }

  private flattenObject(obj: any): any {
    const result: any = {};
    for (const section in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, section)) {
        Object.assign(result, obj[section]);
      }
    }
    return result;
  }

  private getFieldTypes(form: FormGroup, fields: FormlyFieldConfig[]) {
    const mapping = {};

    fields.forEach((field) => {
      const key = field.key as string;
      if (key) {
        mapping[key] = field.type;
      }

      // Check for nested fields (if the field has a fieldGroup)
      if (field.fieldGroup) {
        const nestedMapping = this.getFieldTypes(form, field.fieldGroup);
        Object.assign(mapping, nestedMapping);
      }
    });

    return mapping;
  }

  submit() {
    this.webDynamicApiWebService
      .submitDynamicForm({
        body: {
          entityName: this.additional['entityName'],
          entityId: this.additional['primaryEntityId'],
          model: this.flattenObject(this.model),
          types: this.getFieldTypes(this.form, this.fields),
        },
      })
      .pipe()
      .subscribe(
        (response) => {
          if (response) {
            this.modalService.open('submittedModal_' + this.id);
          } else {
            this.throwError();
          }
        },
        () => {
          this.throwError();
        },
      );
  }

  isEditable() {
    return this.formType === WebDynamicFormType.SidePanel && (this.action === WebDynamicFormAction.Update || this.action === WebDynamicFormAction.Create);
  }

  closeVerdeDynamicModal() {
    if (this.formType === WebDynamicFormType.SidePanel) {
      this.sidebarService.setShowSidebar(false);
    }
  }

  throwError(errorMessage?: string) {
    this.errorMessage = errorMessage ?? 'Please try again, and if the issue persists, please log a support request';
    this.modalService?.open('errorModal_' + this.id);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
