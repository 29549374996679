/* tslint:disable */
/* eslint-disable */
import { BookingsBtRequestSource } from './bookings-bt-request-source';
export interface BookingsCreateRequestHeaderCommand {
  booking?: string;
  bookingDate?: string;
  bt_bookingeventid?: null | string;
  bt_employee?: null | string;
  bt_proxy?: null | string;
  bt_proxyyn?: boolean;
  bt_requestsource?: BookingsBtRequestSource;
  category?: string;
  legalEntity?: null | string;
  room?: string;
}
