import { Inject, Injectable } from '@angular/core';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
  MsalService,
} from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, PopupRequest, RedirectRequest, SilentRequest } from '@azure/msal-browser';
import { environment } from '@environments-verde/environment';
import * as microsoftTeams from '@microsoft/teams-js';
import { UserApiConfigService, UserUserDto } from '@verde/api';
import { StorageService } from '@verde/core';
import { BehaviorSubject, filter, interval, lastValueFrom, Subscription } from 'rxjs';
import { AuthState } from '../models/auth-state';
import { createClaimsTable } from '../utils/claim-utils';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  authState$: BehaviorSubject<AuthState | undefined> = new BehaviorSubject<AuthState | undefined>(undefined);
  loaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  claims: any = [];
  private sessionCheckInterval = 1000; // Check every second
  private sessionTimer?: Subscription;
  public sessionExpiry$ = new BehaviorSubject<number | null>(null);
  isRefreshingToken = false;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    @Inject(MSAL_INTERCEPTOR_CONFIG) private msalInterceptorConfig: MsalInterceptorConfiguration,
    private userApiConfigService: UserApiConfigService,
    private storageService: StorageService,
  ) {
    this.msalService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window

    this.msalBroadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED))
      .subscribe(() => {
        if (this.msalService.instance.getAllAccounts().length === 0) {
          window.location.pathname = '/';
        }
      });

    this.msalBroadcastService.inProgress$.pipe(filter((status: InteractionStatus) => status === InteractionStatus.None)).subscribe(async () => {
      await this.checkAndSetActiveAccount();
      this.getClaims(this.msalService.instance.getActiveAccount()?.idTokenClaims);
    });

    this.msalService.handleRedirectObservable().subscribe({
      next: (result: AuthenticationResult) => {
        if (!this.msalService.instance.getActiveAccount() && this.msalService.instance.getAllAccounts().length > 0) {
          this.checkAndSetActiveAccount();
        }
      },
      error: (error) => console.log(error),
    });
  }

  protectedResourcesLoaded() {
    return this.msalInterceptorConfig && this.msalInterceptorConfig.protectedResourceMap;
  }

  async checkAndSetActiveAccount(isTeamsAuthed = false) {
    return new Promise<boolean>(async (resolve) => {
      if (!this.protectedResourcesLoaded()) {
        console.warn('Protected resources are not loaded.');
        return false;
      }

      await this.msalService.instance.initialize();

      const accounts = this.msalService.instance.getAllAccounts();

      if (accounts.length === 0) {
        await this.signIn();
        return false;
      }

      let activeAccount = this.msalService.instance.getActiveAccount();
      if (!activeAccount) {
        activeAccount = accounts[0];
      }

      if (activeAccount) {
        this.msalService.instance.setActiveAccount(activeAccount);

        const currentAuthState = this.authState$.getValue();
        const newAuthState = {
          isMicrosoftAuthed: true,
          isTeamsAuthed,
          tenantId: activeAccount.tenantId,
          userEmail: activeAccount.username,
          azureObjectId: activeAccount.localAccountId,
        };

        // Only update the state if there is a change in any property
        if (
          !currentAuthState ||
          currentAuthState.isMicrosoftAuthed !== newAuthState.isMicrosoftAuthed ||
          currentAuthState.isTeamsAuthed !== newAuthState.isTeamsAuthed ||
          currentAuthState.tenantId !== newAuthState.tenantId ||
          currentAuthState.userEmail !== newAuthState.userEmail ||
          currentAuthState.azureObjectId !== newAuthState.azureObjectId
        ) {
          this.authState$.next({
            ...currentAuthState,
            ...newAuthState, // Merge the new state, ensuring it only updates on change
          });
        }

        this.loaded$.next(true);

        this.startSessionTimer();
      }

      resolve(true);
    });
  }

  getClaims(claims: any) {
    if (claims) {
      const claimsTable = createClaimsTable(claims);
      this.claims = [...claimsTable];
    }
  }

  private checkInTeams() {
    const microsoftTeamsLib = microsoftTeams || window['microsoftTeams'];

    // Check if the Microsoft Teams library is loaded
    if (!microsoftTeamsLib) {
      return false;
    }

    if (
      (window.parent === window.self && (window as any).nativeInterface) ||
      window.navigator.userAgent.includes('Teams/') ||
      window.name === 'embedded-page-container' ||
      window.name === 'extension-tab-frame'
    ) {
      return true;
    }
    return false;
  }

  async inTeams() {
    const self = this;
    return new Promise<boolean>((resolve) => {
      if (self.checkInTeams()) {
        microsoftTeams.initialize(() => {
          microsoftTeams.getContext((context) => {
            self.authState$.next({
              ...self.authState$.getValue(),
              isMicrosoftAuthed: true,
              isTeamsAuthed: true,
              tenantId: context.tid ?? '',
              userEmail: context.userPrincipalName ?? '',
              azureObjectId: context.userObjectId ?? '',
            });
            resolve(true);
          });
        });
      } else {
        resolve(false);
      }
    });
  }

  // Getters

  get teamsAuthenticated() {
    return this.authState$.getValue()?.isTeamsAuthed;
  }

  startSessionTimer(): void {
    // If we're in Teams, don't start or update the session timer
    if (this.checkInTeams()) {
      console.log('In Teams, session timer disabled.');
      return;
    }

    const expirationTime = this.getTokenExpirationTime();
    if (!expirationTime) return;

    this.sessionTimer?.unsubscribe();

    this.sessionTimer = interval(this.sessionCheckInterval).subscribe(() => {
      const now = Date.now();
      const remainingTime = Math.floor((expirationTime - now) / 1000);
      console.log('Remaining time in seconds:', remainingTime);

      if (this.isRefreshingToken) {
        return;
      }

      if (remainingTime <= 0) {
        this.sessionExpiry$.next(null);
        this.signOut();
      } else if (remainingTime <= 30) {
        this.sessionExpiry$.next(remainingTime);
      } else {
        this.sessionExpiry$.next(null);
      }
    });
  }

  getTokenExpirationTime(): number | null {
    const account = this.msalService.instance.getActiveAccount();
    if (!account) return null;

    const idTokenClaims = account.idTokenClaims as any;
    if (!idTokenClaims || !idTokenClaims.exp) return null;

    return idTokenClaims.exp * 1000; // Convert to milliseconds
  }

  // Prompt the user to sign in and
  // grant consent to the requested permission scopes
  async signIn() {
    this.loaded$.next(false);
    await this.msalService.instance.initialize();
    // This ensures that MSAL processes the redirect result (if any).
    const redirectResult = await this.msalService.instance.handleRedirectPromise();
    console.log('Redirect result processed:', redirectResult);

    if (this.msalService.instance.getAllAccounts().length === 0) {
      if (this.checkInTeams()) {
        const popupRequest: PopupRequest = this.msalGuardConfig.authRequest as PopupRequest;
        await this.msalService.loginPopup(popupRequest);
      } else {
        const redirectRequest: RedirectRequest = this.msalGuardConfig.authRequest as RedirectRequest;
        redirectRequest.prompt = 'select_account'; // This forces the account picker
        this.msalService.loginRedirect(redirectRequest);
      }
    } else {
      await this.checkAndSetActiveAccount();
    }
  }

  async handleSessionExpiry(): Promise<void> {
    const account = this.msalService.instance.getActiveAccount();
    if (!account) {
      console.warn('No active account found, redirecting to login.');
      await this.signIn();
      return;
    }

    this.isRefreshingToken = true; // Set the flag to prevent dialog reopening

    this.msalService
      .acquireTokenSilent({
        ...(this.msalGuardConfig.authRequest as SilentRequest),
        account,
        forceRefresh: true,
      })
      .subscribe({
        next: (tokenResponse) => {
          console.log('Token refreshed successfully:', tokenResponse);
          this.sessionExpiry$.next(null);
          this.startSessionTimer(); // Restart session timer
          this.isRefreshingToken = false; // Reset flag after token refresh
        },
        error: (error) => {
          console.error('Re-authentication failed:', error);
          if (error?.errorCode === 'interaction_required') {
            console.warn('User interaction required for token renewal. Redirecting to login.');
            this.signIn();
          } else {
            console.warn('Unexpected error during token refresh. Logging out.');
            this.signOut();
          }
        },
      });
  }

  // Sign out
  async signOut(user: UserUserDto | undefined = undefined) {
    const startTimestamp = Date.now();
    console.log('Sign-out initiated at:', new Date(startTimestamp).toISOString());

    try {
      if (user) {
        console.log('Starting session cleanup...');
        const sessionStart = Date.now();
        await lastValueFrom(
          this.userApiConfigService.removeSecuritySession({
            body: {
              employeeId: user.employeeId,
              userEmail: user.email,
              legalEntityId: user.legalEntityId,
            },
          }),
        );
        console.log('Session cleanup completed at:', new Date(Date.now()).toISOString());
        console.log('Session cleanup duration:', (Date.now() - sessionStart) / 1000, 'seconds');
      }
    } catch (error) {
      console.error('Error during session cleanup:', error);
    }

    // Delay before logout to ensure session cleanup is fully completed
    console.log('Waiting 100ms before logout...');
    await new Promise((resolve) => setTimeout(resolve, 100)); // Small delay to avoid race conditions

    // Proceed to delete user session from storage and update auth state
    console.log('Deleting user session...');
    this.storageService.sessionDeleteKey('user');
    this.authState$.next(undefined);
    this.loaded$.next(false);
    this.sessionTimer?.unsubscribe();

    // Ensure logout is specific to the active account to prevent the selection prompt
    const activeAccount = this.msalService.instance.getActiveAccount();
    if (activeAccount) {
      console.log('Logging out active account...');
      await this.msalService.logoutRedirect({
        account: activeAccount,
        postLogoutRedirectUri: environment.msalConfig.auth.redirectUri,
      });
    } else {
      console.log('No active account found, logging out all users...');
      await this.msalService.logoutRedirect({
        postLogoutRedirectUri: environment.msalConfig.auth.redirectUri,
      });
    }

    const endTimestamp = Date.now();
    console.log('Sign-out process completed at:', new Date(endTimestamp).toISOString());
    console.log('Total sign-out duration:', (endTimestamp - startTimestamp) / 1000, 'seconds');
  }
}
