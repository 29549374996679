/* tslint:disable */
/* eslint-disable */
export enum BookingsBtRequestStatus {
  New = 'New',
  WaitingforSupplierCreation = 'WaitingforSupplierCreation',
  Submitted = 'Submitted',
  Approved = 'Approved',
  Rejected = 'Rejected',
  PoCreated = 'POCreated'
}
