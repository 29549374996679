/* tslint:disable */
/* eslint-disable */
export enum UserCalendarColor {
  Auto = 'auto',
  LightBlue = 'lightBlue',
  LightGreen = 'lightGreen',
  LightOrange = 'lightOrange',
  LightGray = 'lightGray',
  LightYellow = 'lightYellow',
  LightTeal = 'lightTeal',
  LightPink = 'lightPink',
  LightBrown = 'lightBrown',
  LightRed = 'lightRed',
  MaxColor = 'maxColor',
}
