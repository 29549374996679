/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { WebDynamicBaseService } from '../web-dynamic-base-service';
import { WebDynamicApiConfiguration } from '../web-dynamic-api-configuration';
import { WebDynamicStrictHttpResponse } from '../web-dynamic-strict-http-response';
import { WebDynamicRequestBuilder } from '../web-dynamic-request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { WebDynamicCopyJsonToAzureBlobCommand } from '../models/web-dynamic-copy-json-to-azure-blob-command';
import { WebDynamicCreateRegisterCardQuery } from '../models/web-dynamic-create-register-card-query';
import { WebDynamicCreateRegisterColumnQuery } from '../models/web-dynamic-create-register-column-query';
import { WebDynamicCreateRegisterConditionQuery } from '../models/web-dynamic-create-register-condition-query';
import { WebDynamicCreateRegisterFilterQuery } from '../models/web-dynamic-create-register-filter-query';
import { WebDynamicCreateRegisterJsonQuery } from '../models/web-dynamic-create-register-json-query';
import { WebDynamicCreateRegisterSecurityFunctionQuery } from '../models/web-dynamic-create-register-security-function-query';
import { WebDynamicCreateRegisterSystemFormsImportCommand } from '../models/web-dynamic-create-register-system-forms-import-command';
import { WebDynamicCreateVerdeSecurityFunctionsCommand } from '../models/web-dynamic-create-verde-security-functions-command';
import { WebDynamicCreateWorkspaceAdaptiveCardMasterQuery } from '../models/web-dynamic-create-workspace-adaptive-card-master-query';
import { WebDynamicSetRegisterColumnDefaultQuery } from '../models/web-dynamic-set-register-column-default-query';
import { WebDynamicSetRegisterFilterQuery } from '../models/web-dynamic-set-register-filter-query';
import { WebDynamicValidateDynamicFormRegisterCommand } from '../models/web-dynamic-validate-dynamic-form-register-command';

@Injectable({
  providedIn: 'root',
})
export class WebDynamicApiManagementService extends WebDynamicBaseService {
  constructor(
    config: WebDynamicApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation createRegisterSystemFormImports
   */
  static readonly CreateRegisterSystemFormImportsPath = '/api/Management/Create_Register_SystemForm_Imports';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createRegisterSystemFormImports()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createRegisterSystemFormImports$Response(params?: {
    tenantId?: string;
    body?: WebDynamicCreateRegisterSystemFormsImportCommand
  }): Observable<WebDynamicStrictHttpResponse<boolean>> {

    const rb = new WebDynamicRequestBuilder(this.rootUrl, WebDynamicApiManagementService.CreateRegisterSystemFormImportsPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as WebDynamicStrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createRegisterSystemFormImports$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createRegisterSystemFormImports(params?: {
    tenantId?: string;
    body?: WebDynamicCreateRegisterSystemFormsImportCommand
  }): Observable<boolean> {

    return this.createRegisterSystemFormImports$Response(params).pipe(
      map((r: WebDynamicStrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation createRegisterColumns
   */
  static readonly CreateRegisterColumnsPath = '/api/Management/Create_Register_Columns';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createRegisterColumns()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createRegisterColumns$Response(params?: {
    tenantId?: string;
    body?: WebDynamicCreateRegisterColumnQuery
  }): Observable<WebDynamicStrictHttpResponse<boolean>> {

    const rb = new WebDynamicRequestBuilder(this.rootUrl, WebDynamicApiManagementService.CreateRegisterColumnsPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as WebDynamicStrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createRegisterColumns$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createRegisterColumns(params?: {
    tenantId?: string;
    body?: WebDynamicCreateRegisterColumnQuery
  }): Observable<boolean> {

    return this.createRegisterColumns$Response(params).pipe(
      map((r: WebDynamicStrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation createRegisterJson
   */
  static readonly CreateRegisterJsonPath = '/api/Management/Create_Register_Json';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createRegisterJson()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createRegisterJson$Response(params?: {
    tenantId?: string;
    body?: WebDynamicCreateRegisterJsonQuery
  }): Observable<WebDynamicStrictHttpResponse<boolean>> {

    const rb = new WebDynamicRequestBuilder(this.rootUrl, WebDynamicApiManagementService.CreateRegisterJsonPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as WebDynamicStrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createRegisterJson$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createRegisterJson(params?: {
    tenantId?: string;
    body?: WebDynamicCreateRegisterJsonQuery
  }): Observable<boolean> {

    return this.createRegisterJson$Response(params).pipe(
      map((r: WebDynamicStrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation createRegisterSecurityFunction
   */
  static readonly CreateRegisterSecurityFunctionPath = '/api/Management/Create_Register_SecurityFunction';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createRegisterSecurityFunction()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createRegisterSecurityFunction$Response(params?: {
    tenantId?: string;
    body?: WebDynamicCreateRegisterSecurityFunctionQuery
  }): Observable<WebDynamicStrictHttpResponse<boolean>> {

    const rb = new WebDynamicRequestBuilder(this.rootUrl, WebDynamicApiManagementService.CreateRegisterSecurityFunctionPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as WebDynamicStrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createRegisterSecurityFunction$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createRegisterSecurityFunction(params?: {
    tenantId?: string;
    body?: WebDynamicCreateRegisterSecurityFunctionQuery
  }): Observable<boolean> {

    return this.createRegisterSecurityFunction$Response(params).pipe(
      map((r: WebDynamicStrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation createVerdeSecurityFunctions
   */
  static readonly CreateVerdeSecurityFunctionsPath = '/api/Management/Create_Verde_Security_Functions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createVerdeSecurityFunctions()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createVerdeSecurityFunctions$Response(params?: {
    tenantId?: string;
    body?: WebDynamicCreateVerdeSecurityFunctionsCommand
  }): Observable<WebDynamicStrictHttpResponse<boolean>> {

    const rb = new WebDynamicRequestBuilder(this.rootUrl, WebDynamicApiManagementService.CreateVerdeSecurityFunctionsPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as WebDynamicStrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createVerdeSecurityFunctions$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createVerdeSecurityFunctions(params?: {
    tenantId?: string;
    body?: WebDynamicCreateVerdeSecurityFunctionsCommand
  }): Observable<boolean> {

    return this.createVerdeSecurityFunctions$Response(params).pipe(
      map((r: WebDynamicStrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation createRegisterFilters
   */
  static readonly CreateRegisterFiltersPath = '/api/Management/Create_Register_Filters';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createRegisterFilters()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createRegisterFilters$Response(params?: {
    tenantId?: string;
    body?: WebDynamicCreateRegisterFilterQuery
  }): Observable<WebDynamicStrictHttpResponse<boolean>> {

    const rb = new WebDynamicRequestBuilder(this.rootUrl, WebDynamicApiManagementService.CreateRegisterFiltersPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as WebDynamicStrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createRegisterFilters$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createRegisterFilters(params?: {
    tenantId?: string;
    body?: WebDynamicCreateRegisterFilterQuery
  }): Observable<boolean> {

    return this.createRegisterFilters$Response(params).pipe(
      map((r: WebDynamicStrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation createRegisterCards
   */
  static readonly CreateRegisterCardsPath = '/api/Management/Create_Register_Cards';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createRegisterCards()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createRegisterCards$Response(params?: {
    tenantId?: string;
    body?: WebDynamicCreateRegisterCardQuery
  }): Observable<WebDynamicStrictHttpResponse<boolean>> {

    const rb = new WebDynamicRequestBuilder(this.rootUrl, WebDynamicApiManagementService.CreateRegisterCardsPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as WebDynamicStrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createRegisterCards$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createRegisterCards(params?: {
    tenantId?: string;
    body?: WebDynamicCreateRegisterCardQuery
  }): Observable<boolean> {

    return this.createRegisterCards$Response(params).pipe(
      map((r: WebDynamicStrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation createWorkspaceAdaptiveCards
   */
  static readonly CreateWorkspaceAdaptiveCardsPath = '/api/Management/Create_Workspace_AdaptiveCards';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createWorkspaceAdaptiveCards()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createWorkspaceAdaptiveCards$Response(params?: {
    tenantId?: string;
    body?: WebDynamicCreateWorkspaceAdaptiveCardMasterQuery
  }): Observable<WebDynamicStrictHttpResponse<boolean>> {

    const rb = new WebDynamicRequestBuilder(this.rootUrl, WebDynamicApiManagementService.CreateWorkspaceAdaptiveCardsPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as WebDynamicStrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createWorkspaceAdaptiveCards$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createWorkspaceAdaptiveCards(params?: {
    tenantId?: string;
    body?: WebDynamicCreateWorkspaceAdaptiveCardMasterQuery
  }): Observable<boolean> {

    return this.createWorkspaceAdaptiveCards$Response(params).pipe(
      map((r: WebDynamicStrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation createRegisterConditions
   */
  static readonly CreateRegisterConditionsPath = '/api/Management/Create_Register_Conditions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createRegisterConditions()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createRegisterConditions$Response(params?: {
    tenantId?: string;
    body?: WebDynamicCreateRegisterConditionQuery
  }): Observable<WebDynamicStrictHttpResponse<boolean>> {

    const rb = new WebDynamicRequestBuilder(this.rootUrl, WebDynamicApiManagementService.CreateRegisterConditionsPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as WebDynamicStrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createRegisterConditions$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createRegisterConditions(params?: {
    tenantId?: string;
    body?: WebDynamicCreateRegisterConditionQuery
  }): Observable<boolean> {

    return this.createRegisterConditions$Response(params).pipe(
      map((r: WebDynamicStrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation setRegisterFilters
   */
  static readonly SetRegisterFiltersPath = '/api/Management/Set_Register_Filters';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setRegisterFilters()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  setRegisterFilters$Response(params?: {
    tenantId?: string;
    body?: WebDynamicSetRegisterFilterQuery
  }): Observable<WebDynamicStrictHttpResponse<boolean>> {

    const rb = new WebDynamicRequestBuilder(this.rootUrl, WebDynamicApiManagementService.SetRegisterFiltersPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as WebDynamicStrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setRegisterFilters$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  setRegisterFilters(params?: {
    tenantId?: string;
    body?: WebDynamicSetRegisterFilterQuery
  }): Observable<boolean> {

    return this.setRegisterFilters$Response(params).pipe(
      map((r: WebDynamicStrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation setRegisterColumnDefault
   */
  static readonly SetRegisterColumnDefaultPath = '/api/Management/Set_Register_ColumnDefault';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setRegisterColumnDefault()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  setRegisterColumnDefault$Response(params?: {
    tenantId?: string;
    body?: WebDynamicSetRegisterColumnDefaultQuery
  }): Observable<WebDynamicStrictHttpResponse<boolean>> {

    const rb = new WebDynamicRequestBuilder(this.rootUrl, WebDynamicApiManagementService.SetRegisterColumnDefaultPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as WebDynamicStrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setRegisterColumnDefault$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  setRegisterColumnDefault(params?: {
    tenantId?: string;
    body?: WebDynamicSetRegisterColumnDefaultQuery
  }): Observable<boolean> {

    return this.setRegisterColumnDefault$Response(params).pipe(
      map((r: WebDynamicStrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation copyJsonFileAzureBlobStorage
   */
  static readonly CopyJsonFileAzureBlobStoragePath = '/api/Management/Copy_JsonFile_AzureBlobStorage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `copyJsonFileAzureBlobStorage()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  copyJsonFileAzureBlobStorage$Response(params?: {
    tenantId?: string;
    body?: WebDynamicCopyJsonToAzureBlobCommand
  }): Observable<WebDynamicStrictHttpResponse<boolean>> {

    const rb = new WebDynamicRequestBuilder(this.rootUrl, WebDynamicApiManagementService.CopyJsonFileAzureBlobStoragePath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as WebDynamicStrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `copyJsonFileAzureBlobStorage$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  copyJsonFileAzureBlobStorage(params?: {
    tenantId?: string;
    body?: WebDynamicCopyJsonToAzureBlobCommand
  }): Observable<boolean> {

    return this.copyJsonFileAzureBlobStorage$Response(params).pipe(
      map((r: WebDynamicStrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation validateDynamicFormRegister
   */
  static readonly ValidateDynamicFormRegisterPath = '/api/Management/Validate_Dynamic_Form_Register';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validateDynamicFormRegister()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  validateDynamicFormRegister$Response(params?: {
    tenantId?: string;
    body?: WebDynamicValidateDynamicFormRegisterCommand
  }): Observable<WebDynamicStrictHttpResponse<boolean>> {

    const rb = new WebDynamicRequestBuilder(this.rootUrl, WebDynamicApiManagementService.ValidateDynamicFormRegisterPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as WebDynamicStrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `validateDynamicFormRegister$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  validateDynamicFormRegister(params?: {
    tenantId?: string;
    body?: WebDynamicValidateDynamicFormRegisterCommand
  }): Observable<boolean> {

    return this.validateDynamicFormRegister$Response(params).pipe(
      map((r: WebDynamicStrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
