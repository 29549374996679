import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from '@verde/core';
import { WorkspaceModel } from '../../../public-api';

@Component({
  selector: 'verde-workspace',
  templateUrl: './workspace.component.html',
  styleUrls: ['./workspace.component.scss'],
})
export class WorkspaceComponent {
  constructor(public navigationService: NavigationService, private router: Router) {}
  @Input() data: WorkspaceModel;
}
