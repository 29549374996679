import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CommonHelpers, DeviceTypeService } from '@verde/core';
import { bellIcon, pencilIcon } from '@progress/kendo-svg-icons';

@Component({
  selector: 'verde-workspace-user-card',
  templateUrl: './workspace-user-card.component.html',
  styleUrls: ['./workspace-user-card.component.scss'],
})
export class WorkspaceUserCardComponent {
  @Input() fullName: string;
  @Input() jobTitle: string;
  @Input() avatarUrl: string;
  @Input() iconsEnabled = false;
  @Input() showAvatar = true;
  @Input() dynamicSize = true;

  icons = { pencil: pencilIcon, bell: bellIcon };
  userCardBackgroundImage: string;

  constructor(private router: Router, public deviceTypeService: DeviceTypeService) {
    this.determineUserCardBackgroundImage();
  }

  get cardHeight(): string {
    const baseHeight = this.showAvatar ? (this.iconsEnabled ? (this.jobTitle ? 215 : 200) : 180) : this.iconsEnabled ? (this.jobTitle ? 180 : 160) : 140;
    const dynamicHeight = this.showAvatar ? 254.36 : baseHeight;

    return !this.dynamicSize || this.deviceTypeService.isDesktop() ? `${baseHeight}px` : `${dynamicHeight}px`;
  }

  determineUserCardBackgroundImage() {
    const numberOfUserCardBackgrounds = 10;
    this.userCardBackgroundImage = `https://verdeimages.z1.web.core.windows.net/Images/Verde/MyWorkspace/backgrounds/background${CommonHelpers.getRandomInteger(
      numberOfUserCardBackgrounds,
    )}.png`;
  }

  userIconClicked(iconClicked: 'left' | 'right') {
    if (iconClicked === 'left') {
      this.router.navigate(['/profile']);
    } else if (iconClicked === 'right') {
      this.router.navigate(['/info']);
    }
  }
}
