import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { environment } from '@environments-verde/environment';
import {
  BFFApiModule,
  BookingsApiModule,
  CommonApiModule,
  DelegationsApiModule,
  DocumentApiModule,
  FleetApiModule,
  ProcessesApiModule,
  ProfileApiModule,
  QualificationsApiModule,
  ReportingApiModule,
  SharedApiModule,
  StructureApiModule,
  UserApiModule,
  WebDynamicApiModule,
} from '@verde/api';
import { CoreModule } from '@verde/core';
import { VerdeSharedModule } from '@verde/shared';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { ServiceWorkerModule } from '@angular/service-worker';

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule.forRoot(environment),
    AppRoutingModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    VerdeSharedModule,
    StructureApiModule.forRoot({ rootUrl: environment.features.structureApiUrl }),
    CommonApiModule.forRoot({ rootUrl: environment.features.commonApiUrl }),
    ReportingApiModule.forRoot({ rootUrl: environment.features.reportingApiUrl }),
    WebDynamicApiModule.forRoot({ rootUrl: environment.features.webDynamicApiUrl }),
    UserApiModule.forRoot({ rootUrl: environment.features.userApiUrl }),
    DelegationsApiModule.forRoot({ rootUrl: environment.features.delegationsApiUrl }),
    BFFApiModule.forRoot({ rootUrl: environment.redirectUri }),
    ProfileApiModule.forRoot({ rootUrl: environment.features.profileApiUrl }),
    ProcessesApiModule.forRoot({ rootUrl: environment.features.processesApiUrl }),
    DocumentApiModule.forRoot({ rootUrl: environment.features.documentApiUrl }),
    QualificationsApiModule.forRoot({ rootUrl: environment.features.qualificationsApiUrl }),
    FleetApiModule.forRoot({ rootUrl: environment.features.fleetApiUrl }),
    BookingsApiModule.forRoot({ rootUrl: environment.features.bookingsApiUrl }),
    SharedApiModule.forRoot({ rootUrl: environment.features.legacyApiUrl }),
    environment.imports,
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: !isDevMode(),
    //   // Register the ServiceWorker as soon as the application is stable
    //   // or after 30 seconds (whichever comes first).
    //   registrationStrategy: 'registerWhenStable:30000'
    // }),
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
