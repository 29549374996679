<div class="expansion_header_flex">
  <img *ngIf="config?.legalEntityConfig?.helpDocManagement != null"
       title="View Help"
       class="help_icon"
       src="https://engageimages.z1.web.core.windows.net/Images/Verde/Other/help.svg"
       alt=""
       (click)="openHelpOrPolicy(config?.legalEntityConfig?.helpDocManagement)" />
  <img *ngIf="config?.legalEntityConfig?.policyDocManagement != null"
       title="View Policies"
       class="policy_icon"
       src="https://engageimages.z1.web.core.windows.net/Images/Verde/Other/policy.svg"
       alt=""
       (click)="openHelpOrPolicy(config?.legalEntityConfig?.policyDocManagement)" />
  <img *ngIf="config?.legalEntityConfig?.reportIssue"
       title="Report Issue"
       class="issue_icon"
       src="https://engageimages.z1.web.core.windows.net/Images/Verde/issue.svg"
       alt=""
       (click)="reportIssue('Document Management')" />

  <!--<div *ngIf="config?.legalEntityConfig?.managerProxy && config?.legalEntityConfig?.payslipProxy && onSalaryAdvice" class="user_impersonation">
    <kendo-dropdownlist
      [data]="allAvailableTeamMembers"
      textField="fullNameWithEmpId"
      valueField="employeeId"
      id="userDropdown"
      name="userDropdown"
      (valueChange)="applyNewUser($event)"
      [ngModel]="userService?.user"
    >
    </kendo-dropdownlist>
  </div>-->
  <!--HR PROXY-->
  <div *ngIf="hrProxyObj.isHrUser"
       class="user_impersonation">
    <kendo-label class="k-checkbox-label"
                 [for]="hrEmployeeDropdown"
                 text="Proxy for {{ capitalizeFirstLetter(hrProxyObj.legalEntityName) }}:"> </kendo-label>
    <kendo-dropdownlist [data]="allEmployeesFilteredData"
                        textField="fullNameWithEmpId"
                        valueField="employeeId"
                        [filterable]="true"
                        (filterChange)="handleHREmployeeFilter($event)"
                        id="hrEmployeeDropdown"
                        name="hrEmployeeDropdown"
                        (valueChange)="applyNewUser($event)"
                        [ngModel]="selectedHrEmployee"
                        style="width: 300px; margin-left: 8px">
      <ng-template kendoDropDownListNoDataTemplate>
        <p>{{ employeeDropdownMessage }}</p>
      </ng-template>
    </kendo-dropdownlist>
    <kendo-label *ngIf="showManagerApprovals && !proxyUser?.hasSubordinates"
                 class="k-checkbox-label"
                 style="margin-left: 8px"
                 text="This user is not a manager.">
    </kendo-label>
  </div>
  <!--HR EXITED EMPLOYEE PROXY-->
  <div *ngIf="hrProxyObj.isHrUser"
       class="user_impersonation">
    <kendo-label class="k-checkbox-label"
                 [for]="hrExitedEmployeeDropdown"
                 text="Exited  Employee for {{ capitalizeFirstLetter(hrProxyObj.legalEntityName) }}:"> </kendo-label>
    <kendo-dropdownlist [data]="allExitedEmployeesFilteredData"
                        textField="fullNameWithEmpId"
                        valueField="employeeId"
                        [filterable]="true"
                        (filterChange)="handleHRExitedEmployeeFilter($event)"
                        id="hrExitedEmployeeDropdown"
                        name="hrExitedEmployeeDropdown"
                        (valueChange)="applyExitNewUser($event)"
                        [(ngModel)]="selectedExitedEmployee"
                        style="width: 300px; margin-left: 8px">
      <ng-template kendoDropDownListNoDataTemplate>
        <p>{{ employeeDropdownMessage }}</p>
      </ng-template>
    </kendo-dropdownlist>
    <kendo-label *ngIf="showManagerApprovals && !proxyUser?.hasSubordinates"
                 class="k-checkbox-label"
                 style="margin-left: 8px"
                 text="This user is not a manager.">
    </kendo-label>
  </div>
  <!--MANAGER PROXY-->
  <div *ngIf="user?.hasSubordinates && !hrProxyObj.isHrUser"
       class="user_impersonation">
    <kendo-label class="k-checkbox-label"
                 [for]="hrEmployeeDropdown"
                 text="Manager Proxy for:"> </kendo-label>
    <kendo-dropdownlist [data]="allAvailableTeamMembers"
                        textField="fullNameWithEmpId"
                        valueField="employeeId"
                        id="hrEmployeeDropdown"
                        name="hrEmployeeDropdown"
                        (valueChange)="applyNewUser($event)"
                        [ngModel]="userService?.user"
                        style="width: 300px; margin-left: 8px">
      <ng-template kendoDropDownListNoDataTemplate>
        <p>{{ employeeDropdownMessage }}</p>
      </ng-template>
    </kendo-dropdownlist>
  </div>

</div>
<kendo-tabstrip #documentTabstrip
                (tabSelect)="onTabSelect($event)"
                class="document_tabstrip">
  <kendo-tabstrip-tab title="Salary Advice"
                      [selected]="true">
    <ng-template kendoTabContent>
      <verde-payroll-documents></verde-payroll-documents>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab title="WFH">
    <ng-template kendoTabContent>
      <verde-personal-tax-documents></verde-personal-tax-documents>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab title="Personal">
    <ng-template kendoTabContent>
      <verde-bulk-docs GroupingType="Personal"></verde-bulk-docs>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab title="Tax">
    <ng-template kendoTabContent>
      <verde-bulk-docs GroupingType="Tax"></verde-bulk-docs>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab title="Financial">
    <ng-template kendoTabContent>
      <verde-bulk-docs GroupingType="Financial"></verde-bulk-docs>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab title="Other">
    <ng-template kendoTabContent>
      <verde-other-documents></verde-other-documents>
    </ng-template>
  </kendo-tabstrip-tab>
</kendo-tabstrip>

<!-- Report Issue Modal -->
<verde-issue-modal #issueModal
                   *ngIf="showIssueModal"
                   issueLocation="{{ issueLocation }}"
                   (hideModalEvent)="hideIssueModal($event)"> </verde-issue-modal>