/* tslint:disable */
/* eslint-disable */
import { UserDateTimeTimeZone } from './user-date-time-time-zone';
import { UserIBackingStore } from './user-i-backing-store';
export interface UserTimeSlot {
  additionalData?: null | {
    [key: string]: any;
  };
  backingStore?: UserIBackingStore;
  end?: UserDateTimeTimeZone;
  odataType?: null | string;
  start?: UserDateTimeTimeZone;
}
