/* tslint:disable */
/* eslint-disable */
import { UserIBackingStore } from './user-i-backing-store';
export interface UserEmailAddress {
  additionalData?: null | {
    [key: string]: any;
  };
  address?: null | string;
  backingStore?: UserIBackingStore;
  name?: null | string;
  odataType?: null | string;
}
