import { Component, EventEmitter, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { process } from '@progress/kendo-data-query';
import { BookingsApiBookingsService, BookingsGetBookingAvailabilitiesQuery, SharedApiBookingPoolVehicleAvailabilitesService } from '@verde/api';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, take, takeUntil } from 'rxjs';
import { VerdeApprovalService } from '../../../services/verde-approval.service';
import { SidePanelWidth } from '../../../enums/sidepanel-width.enum';
import { UserService } from 'libs/core/src/lib/modules/authentication/services/user.service';
import { BookingsDataService } from 'libs/shared/src/lib/shared/services/booking/bookings-data.service';
import { DeviceTypeService } from 'libs/core/src/lib/services/device-type.service';
import { CommonHelpers } from '@verde/core';

@Component({
  selector: 'verde-booking-availability-results',
  templateUrl: './booking-availability-results.component.html',
  styleUrls: ['./booking-availability-results.component.scss'],
})
export class BookingAvailabilityResultsComponent implements OnInit, OnChanges, OnDestroy {
  @Output() setSidePanelTitleText = new EventEmitter<string>();
  onDestroy$: Subject<any> = new Subject();

  @Output() backToSearch = new EventEmitter<void>();

  selectedTabId = 'bookingTab';

  get getOfficeId(): string {
    return this.bookingsDataService?.bookingAvailabilitySearchDetails?.subOffice ?? this.bookingsDataService?.bookingAvailabilitySearchDetails?.office;
  }
  get isOfficeInteractive(): boolean {
    const subOfficeIsInteractive = this.bookingsDataService?.bookingAvailabilitySearchDetails?.subOfficeIsInteractive;
    const officeIsInteractive = this.bookingsDataService?.bookingAvailabilitySearchDetails?.officeIsInteractive;
    return officeIsInteractive || subOfficeIsInteractive ? true : false;
  }

  get getOfficeSidePanelSize(): number {
    const subOffice = this.bookingsDataService?.bookingAvailabilitySearchDetails?.SubOfficeLocName;
    const office = this.bookingsDataService?.bookingAvailabilitySearchDetails?.OfficeLocName;
    if ((subOffice ?? office) === 'Waverley - Block A - Braintree' || (subOffice ?? office) === '29 Scott Street') return 1160;
    else if ((subOffice ?? office) === 'Cape Town - Paardevlei' || (subOffice ?? office) === 'Paardevlei') return 620;
    else return 1160;
  }

  get getBookingsTitle(): string {
    const additionalText =
      this.bookingsDataService.bookingAvailabilitySearchDetails?.isRecurring === true
        ? ` with ${this.bookingsDataService.bookingAvailabilitySearchDetails?.numberOfDays} additional days`
        : '';

    return (
      `Book from ${moment(this.bookingsDataService.bookingAvailabilitySearchDetails?.bookingFrom).format('HH:mm')} to ${moment(
        this.bookingsDataService.bookingAvailabilitySearchDetails?.bookingTo,
      ).format('HH:mm')} on ${CommonHelpers.formatDate(this.bookingsDataService.bookingAvailabilitySearchDetails?.bookingFrom, 'yyyy/MM/DD')}` + additionalText
    );
  }
  get getAllBookingsTitle(): string {
    return `All Bookings for ${CommonHelpers.formatDate(this.bookingsDataService.bookingAvailabilitySearchDetails?.bookingFrom, 'yyyy/MM/DD')}`;
  }

  get hotDeskBooking(): boolean {
    const bookingTypeName = this.bookingsDataService.bookingAvailabilitySearchDetails?.bookingTypeName?.toLowerCase();
    return ['hot desk', 'desk', 'hot'].some((type) => bookingTypeName?.includes(type));
  }
  get poolVehicleBooking(): boolean {
    const bookingTypeName = this.bookingsDataService.bookingAvailabilitySearchDetails?.bookingTypeName?.toLowerCase();
    return ['pool vehicle', 'vehicle', 'pool'].some((type) => bookingTypeName?.includes(type));
  }

  constructor(
    private spinner: NgxSpinnerService,
    public userService: UserService,
    private sidebarService: VerdeApprovalService,
    public bookingsDataService: BookingsDataService,
    private sharedApiBookingPoolVehicleAvailabilitesService: SharedApiBookingPoolVehicleAvailabilitesService,
    public deviceTypeService: DeviceTypeService,
    public bookingsApiBookingsService: BookingsApiBookingsService,
  ) {}

  ngOnInit(): void {
    this.sidebarService.getBookingAvailabilitySearchDetails().subscribe((data) => {
      this.bookingsDataService.bookingAvailabilitySearchDetails = data;
      if (data) {
        if (this.isOfficeInteractive) {
          if (this.deviceTypeService.isMobile() || this.deviceTypeService.isTablet()) {
            this.sidebarService.setSidebarSize(SidePanelWidth.Full);
          } else {
            this.sidebarService.setSidebarSize(CommonHelpers.pixelsToViewport(this.getOfficeSidePanelSize));
          }
        }
      }
    });

    this.userService.disableAnimation$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      this.bookingsDataService.disableAnimation = data;
    });

    this.open();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.openModal && changes.openModal.currentValue !== undefined) {
      this.open();
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  open() {
    if (this.hotDeskBooking) {
      this.getBookingAvailability();
      this.getAllBookingsForTheDay();
      this.bookingsDataService.DisplayMessage =
        'Availabilities for ' +
        this.bookingsDataService.bookingAvailabilitySearchDetails?.PrimaryLocName +
        ' at ' +
        this.bookingsDataService.bookingAvailabilitySearchDetails?.OfficeLocName +
        ' at ' +
        this.bookingsDataService.bookingAvailabilitySearchDetails?.SubOfficeLocName;
    } else if (this.poolVehicleBooking) {
      this.getPoolVehicleBookingAvailability();
      this.bookingsDataService.DisplayMessage = 'Availabilities for ' + this.bookingsDataService.bookingAvailabilitySearchDetails?.PrimaryLocName;
    } else {
      this.bookingsDataService.DisplayMessage = 'Availabilities';
    }

    this.setSidePanelTitleText.emit(this.bookingsDataService.DisplayMessage);
  }

  private resetBookingAvailabilites(): void {
    this.bookingsDataService.bookingAvailabilities = null;
  }

  getPoolVehicleBookingAvailability() {
    this.bookingsDataService.bookingAvailabilitiesLoading = true;
    this.sharedApiBookingPoolVehicleAvailabilitesService
      .getPoolVehicleBookingAvailabilities({
        legalentityid: this.userService.user?.legalEntityId,
        bookingtypeid: this.bookingsDataService.bookingAvailabilitySearchDetails?.bookingType,
        primarylocationid: this.bookingsDataService.bookingAvailabilitySearchDetails?.primaryLocation,
        fromdate: moment(this.bookingsDataService.bookingAvailabilitySearchDetails?.bookingFrom).format('YYYY-MM-DDTHH:mm:ss[Z]'),
        todate: moment(this.bookingsDataService.bookingAvailabilitySearchDetails?.bookingTo).format('YYYY-MM-DDTHH:mm:ss[Z]'),
        departmentid: this.bookingsDataService.bookingAvailabilitySearchDetails?.DepartmentToPass,
      })
      .pipe(take(1), takeUntil(this.onDestroy$))
      .subscribe(
        (allBookingAvailabilities) => {
          this.bookingsDataService.bookingAvailabilityGridData = [...allBookingAvailabilities];
          this.bookingsDataService.bookingsGridView = this.bookingsDataService.bookingAvailabilityGridData;

          this.bookingsDataService.bookingAvailabilities = {
            data: [...this.bookingsDataService.bookingAvailabilityGridData],
            total: this.bookingsDataService.bookingAvailabilityGridData.length,
          };
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.bookingsDataService.bookingAvailabilitiesLoading = false;
        },
      );
  }

  getBookingAvailability() {
    this.resetBookingAvailabilites();

    this.bookingsDataService.bookingAvailabilitiesLoading = true;

    this.bookingsApiBookingsService
      .getBookingAvailabilities({
        body: {
          legalEntityId: this.userService.user?.legalEntityId,
          bookingTypeId: this.bookingsDataService.bookingAvailabilitySearchDetails?.bookingType,
          primaryLocationId: this.bookingsDataService.bookingAvailabilitySearchDetails?.primaryLocation,
          officeId: this.bookingsDataService.bookingAvailabilitySearchDetails?.office,
          subOfficeId: this.bookingsDataService.bookingAvailabilitySearchDetails?.subOffice,
          fromDate: moment(this.bookingsDataService.bookingAvailabilitySearchDetails?.bookingFrom).format('YYYY-MM-DDTHH:mm:ss[Z]'),
          toDate: moment(this.bookingsDataService.bookingAvailabilitySearchDetails?.bookingTo).format('YYYY-MM-DDTHH:mm:ss[Z]'),
          allBookings: false,
          isRecurring: this.bookingsDataService.bookingAvailabilitySearchDetails?.isRecurring,
          numberOfDays: this.bookingsDataService.bookingAvailabilitySearchDetails?.numberOfDays,
          includeWeekends: this.bookingsDataService.bookingAvailabilitySearchDetails?.includeWeekends,
        } as BookingsGetBookingAvailabilitiesQuery,
      })
      .pipe(take(1), takeUntil(this.onDestroy$))
      .subscribe(
        (allBookingAvailabilities) => {
          this.bookingsDataService.bookingAvailabilityGridData = [...allBookingAvailabilities];
          this.bookingsDataService.bookingsGridView = this.bookingsDataService.bookingAvailabilityGridData;

          this.bookingsDataService.bookingAvailabilities = {
            data: [...this.bookingsDataService.bookingAvailabilityGridData],
            total: this.bookingsDataService.bookingAvailabilityGridData.length,
          };
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.bookingsDataService.bookingAvailabilitiesLoading = false;
        },
      );
  }

  getAllBookingsForTheDay() {
    this.bookingsDataService.allBookingsForTheDayLoading = true;

    const fullDay = 1439; // 24 hours
    const toDate = moment(this.bookingsDataService.bookingAvailabilitySearchDetails?.bookingFrom).format('YYYY-MM-DDT00:00');
    const fromDate = moment(toDate).add(fullDay, 'minutes');

    this.bookingsApiBookingsService
      .getBookingAvailabilities({
        body: {
          legalEntityId: this.userService.user?.legalEntityId,
          bookingTypeId: this.bookingsDataService.bookingAvailabilitySearchDetails?.bookingType,
          primaryLocationId: this.bookingsDataService.bookingAvailabilitySearchDetails?.primaryLocation,
          officeId: this.bookingsDataService.bookingAvailabilitySearchDetails?.office,
          subOfficeId: this.bookingsDataService.bookingAvailabilitySearchDetails?.subOffice,
          fromDate: moment(toDate).format('YYYY-MM-DDTHH:mm:ss[Z]'),
          toDate: moment(fromDate).format('YYYY-MM-DDTHH:mm:ss[Z]'),
          allBookings: true,
          isRecurring: false,
          numberOfDays: null,
          includeWeekends: false,
        } as BookingsGetBookingAvailabilitiesQuery,
      })
      .pipe(take(1), takeUntil(this.onDestroy$))
      .subscribe(
        (allBookingsForTheDay) => {
          this.bookingsDataService.allBookingsForTheDayGridData = [...allBookingsForTheDay];
          this.bookingsDataService.allBookingsForTheDayGridView = this.bookingsDataService.allBookingsForTheDayGridData;

          this.bookingsDataService.allBookingsForTheDay = {
            data: [...this.bookingsDataService.allBookingsForTheDayGridData],
            total: this.bookingsDataService.allBookingsForTheDayGridData.length,
          };
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.bookingsDataService.allBookingsForTheDayLoading = false;
        },
      );
  }

  onBookingsFilter(inputValue: string): void {
    this.bookingsDataService.bookingsGridView = process(this.bookingsDataService.bookingAvailabilityGridData, {
      filter: {
        logic: 'or',
        filters: [
          {
            field: 'bookingItem',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'fullName',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'fromDate',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'toDate',
            operator: 'contains',
            value: inputValue,
          },
        ],
      },
    }).data;
  }

  onAllBookingsForTheDayFilter(inputValue: string): void {
    this.bookingsDataService.allBookingsForTheDayGridView = process(this.bookingsDataService.allBookingsForTheDayGridData, {
      filter: {
        logic: 'or',
        filters: [
          {
            field: 'bookingItem',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'fullName',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'fromDate',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'toDate',
            operator: 'contains',
            value: inputValue,
          },
        ],
      },
    }).data;
  }

  onTabSelect(event: any) {
    if (event.index === 0) this.selectedTabId = 'bookingTab';
    else if (event.index === 1) this.selectedTabId = 'allBookingsForTheDayTab';
  }

  cancel() {
    this.sidebarService.setShowSidebar(false);
  }

  refresh() {
    if (this.hotDeskBooking) {
      this.getBookingAvailability();
      this.getAllBookingsForTheDay();
    }

    if (this.poolVehicleBooking) {
      this.getPoolVehicleBookingAvailability();
    }
  }
}
