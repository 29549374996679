import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldProps } from '@ngx-formly/core';

export interface KendoFormlyDateProps extends FormlyFieldProps {
  format?: string;
  label?: string;
  readOnly?: boolean;
  required?: boolean;
  tabindex?: number;
}

@Component({
  selector: 'verde-kendo-formly-date',
  template: `
    <kendo-datepicker
      [format]="props.format || 'yyyy/MM/dd'"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [readonly]="props.readOnly"
      [required]="props.required"
      [tabIndex]="props.tabindex || 0"
    >
    </kendo-datepicker>
  `,
})
export class KendoFormlyDate extends FieldType<FieldTypeConfig<KendoFormlyDateProps>> {}
