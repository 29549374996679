<div class="workspace-container"
     [ngStyle]="{ 'grid-template-columns': data?.gridTemplateColumns }">
    <ng-container *ngFor="let layout of data?.layouts">
        <kendo-tilelayout *ngIf="layout?.enabled"
                          [columns]="layout?.config?.numberOfEnabledColumns"
                          [columnWidth]="layout?.config?.columnWidth"
                          [rowHeight]="layout?.config?.rowHeight"
                          [resizable]="layout?.config?.resizable"
                          [reorderable]="layout?.config?.reorderable"
                          [autoFlow]="layout?.config?.autoFlow"
                          [gap]="layout?.config?.gap"
                          [ngClass]="{ 'layout-custom-padding': layout?.config?.customPadding }">
            <ng-container *ngFor="let column of layout?.columns">
                <ng-container *ngIf="column?.enabled">
                    <ng-container *ngFor="let tile of column?.tiles">
                        <ng-container *ngIf="tile?.type === 'custom-card' || tile?.type === 'custom'">
                            <!-- <ng-container *ngxPermissionsOnly="tile?.technicalName"> -->
                            <kendo-tilelayout-item *ngIf="tile?.enabled"
                                                   [col]="tile?.config?.col"
                                                   [colSpan]="tile?.config?.colSpan"
                                                   [rowSpan]="tile?.config?.rowSpan"
                                                   [resizable]="tile?.config?.resizable"
                                                   [reorderable]="tile?.config?.reorderable"
                                                   class="tile-item-unboxed">
                                <kendo-tilelayout-item-header class="tile-item-header-unboxed">
                                    <kendo-tilelayout-item-body class="tile-item-body-unboxed">
                                        <verde-workspace-tile-dynamic-data *ngIf="tile"
                                                                           [tile]="tile"></verde-workspace-tile-dynamic-data>
                                    </kendo-tilelayout-item-body>
                                </kendo-tilelayout-item-header>
                            </kendo-tilelayout-item>
                            <!-- </ng-container> -->
                        </ng-container>

                        <ng-container *ngIf="tile?.type === 'menu'">
                            <!-- <ng-container *ngxPermissionsOnly="tile?.technicalName"> -->
                            <kendo-tilelayout-item *ngIf="tile?.enabled"
                                                   [col]="tile?.config?.col"
                                                   [colSpan]="tile?.config?.colSpan"
                                                   [rowSpan]="tile?.config?.rowSpan"
                                                   [resizable]="tile?.config?.resizable"
                                                   [reorderable]="tile?.config?.reorderable"
                                                   class="tile-item-unboxed">
                                <kendo-tilelayout-item-header class="tile-item-header-unboxed">
                                    <kendo-tilelayout-item-body class="tile-item-body-unboxed">
                                        <verde-workspace-tile-menu [menuContext]="tile?.id"></verde-workspace-tile-menu>
                                    </kendo-tilelayout-item-body>
                                </kendo-tilelayout-item-header>
                            </kendo-tilelayout-item>
                            <!-- </ng-container> -->
                        </ng-container>

                        <ng-container *ngIf="tile?.type === 'grid'">
                            <!-- <ng-container *ngxPermissionsOnly="tile?.technicalName"> -->
                            <kendo-tilelayout-item *ngIf="tile?.enabled"
                                                   [col]="tile?.config?.col"
                                                   [colSpan]="tile?.config?.colSpan"
                                                   [rowSpan]="tile?.config?.rowSpan"
                                                   [resizable]="tile?.config?.resizable"
                                                   [reorderable]="tile?.config?.reorderable"
                                                   class="tile-item-unboxed">
                                <kendo-tilelayout-item-header class="tile-item-header-unboxed">
                                    <kendo-tilelayout-item-body class="tile-item-body-unboxed">
                                        <verde-workspace-grid [gridContext]="tile"></verde-workspace-grid>
                                    </kendo-tilelayout-item-body>
                                </kendo-tilelayout-item-header>
                            </kendo-tilelayout-item>
                            <!-- </ng-container> -->
                        </ng-container>

                        <ng-container *ngIf="tile?.type === 'verde-card'">
                            <!-- <ng-container *ngxPermissionsOnly="tile?.technicalName"> -->
                            <kendo-tilelayout-item *ngIf="tile?.enabled"
                                                   [col]="tile?.config?.col"
                                                   [colSpan]="tile?.config?.colSpan"
                                                   [rowSpan]="tile?.config?.rowSpan"
                                                   [resizable]="tile?.config?.resizable"
                                                   [reorderable]="tile?.config?.reorderable"
                                                   [style.background-image]="'url(' + tile?.iconUrl + ')'"
                                                   class="tile-item">
                                <kendo-tilelayout-item-header class="tile-item-header">
                                    <h3 class="tile-item-header-right">{{ tile?.title }}</h3>
                                </kendo-tilelayout-item-header>
                                <kendo-tilelayout-item-body class="tile-item-body">
                                    <div class="tile-item-body-content"
                                         [ngClass]="{ clickable: tile?.navigation?.navigable, 'non-clickable': !tile?.navigation?.navigable }"
                                         (click)="
                      tile?.navigation?.navigable
                        ?  this.navigationService.navigateSomewhere(tile?.navigation?.navigationId, tile?.navigation?.isMenuItem, tile?.navigation?.isMenuItem)
                        : null
                    ">
                                        <verde-workspace-tile-dynamic-data *ngIf="tile"
                                                                           [tile]="tile"></verde-workspace-tile-dynamic-data>
                                    </div>
                                    <div>
                                        <hr class="tile-item-divider" />
                                        <p class="tile-item-footer"
                                           [ngClass]="{ 'tile-item-footer-clickable': tile?.navigation?.navigable, 'tile-item-footer-non-clickable': !tile?.navigation?.navigable }"
                                           (click)="
                        tile?.navigation?.navigable
                          ?  this.navigationService.navigateSomewhere(tile?.navigation?.navigationId, tile?.navigation?.isMenuItem, tile?.navigation?.isExternal)
                          : null
                      ">
                                            {{ tile?.toolTip }}
                                        </p>
                                    </div>
                                </kendo-tilelayout-item-body>
                            </kendo-tilelayout-item>
                            <!-- </ng-container> -->
                        </ng-container>

                        <ng-container *ngIf="tile?.type === 'compact-card'">
                            <!-- <ng-container *ngxPermissionsOnly="tile?.technicalName"> -->
                            <kendo-tilelayout-item *ngIf="tile?.enabled"
                                                   [col]="tile?.config?.col"
                                                   [colSpan]="tile?.config?.colSpan"
                                                   [rowSpan]="tile?.config?.rowSpan"
                                                   [resizable]="tile?.config?.resizable"
                                                   [reorderable]="tile?.config?.reorderable"
                                                   class="tile-item-compact">
                                <div class="tile-item-layout">
                                    <div class="tile-item-layout-left">
                                        <kendo-tilelayout-item-header class="tile-item-header">
                                            <h3 class="tile-item-header-left">{{ tile?.title }}</h3>
                                        </kendo-tilelayout-item-header>
                                        <kendo-tilelayout-item-body class="tile-item-body-compact">
                                            <div class="tile-item-body-content-compact"
                                                 [ngClass]="{ clickable: tile?.navigation?.navigable, 'non-clickable': !tile?.navigation?.navigable }"
                                                 (click)="
                          tile?.navigation?.navigable
                            ?  this.navigationService.navigateSomewhere(tile?.navigation?.navigationId, tile?.navigation?.isMenuItem, tile?.navigation?.isExternal)
                            : null
                        ">
                                                <p *ngIf="tile?.subTitle">{{ tile?.subTitle }}</p>
                                                <verde-workspace-tile-dynamic-data *ngIf="tile && !tile?.subTitle"
                                                                                   [tile]="tile"></verde-workspace-tile-dynamic-data>
                                            </div>
                                        </kendo-tilelayout-item-body>
                                    </div>
                                    <kendo-badge-container class="tile-item-layout-right-compact"
                                                           [ngClass]="{ clickable: tile?.navigation?.navigable, 'non-clickable': !tile?.navigation?.navigable }"
                                                           (click)="
                      tile?.navigation?.navigable
                        ?  this.navigationService.navigateSomewhere(tile?.navigation?.navigationId, tile?.navigation?.isMenuItem, tile?.navigation?.isExternal)
                        : null
                    "
                                                           kendoTooltip
                                                           position="bottom"
                                                           [callout]="false"
                                                           [title]="tile?.toolTip ?? ''">
                                        <verde-workspace-tile-dynamic-data *ngIf="tile"
                                                                           location="badge"
                                                                           [tile]="tile"></verde-workspace-tile-dynamic-data>
                                        <img *ngIf="tile?.iconUrl"
                                             title="{{ tile?.title || 'Image Description Not Available' }}"
                                             [src]="tile?.iconUrl"
                                             alt="{{ tile?.title || 'Image Description Not Available' }}"
                                             class="tile-item-icon-compact" />
                                    </kendo-badge-container>
                                </div>
                            </kendo-tilelayout-item>
                            <!-- </ng-container> -->
                        </ng-container>

                        <ng-container *ngIf="tile?.type === 'dynamic-card'">
                            <!-- <ng-container *ngxPermissionsOnly="tile?.technicalName"> -->
                            <kendo-tilelayout-item *ngIf="tile?.enabled"
                                                   [col]="tile?.config?.col"
                                                   [colSpan]="tile?.config?.colSpan"
                                                   [rowSpan]="tile?.config?.rowSpan"
                                                   [resizable]="tile?.config?.resizable"
                                                   [reorderable]="tile?.config?.reorderable"
                                                   class="tile-item-dynamic">
                                <div class="tile-item-layout">
                                    <div class="tile-item-layout-left">
                                        <kendo-tilelayout-item-header class="tile-item-header">
                                            <h3 class="tile-item-header-left">{{ tile?.title }}</h3>
                                        </kendo-tilelayout-item-header>
                                        <kendo-tilelayout-item-body class="tile-item-body-dynamic">
                                            <div class="tile-item-body-content-dynamic"
                                                 [ngClass]="{ clickable: tile?.navigation?.navigable, 'non-clickable': !tile?.navigation?.navigable }"
                                                 (click)="
                          tile?.navigation?.navigable
                            ?  this.navigationService.navigateSomewhere(tile?.navigation?.navigationId, tile?.navigation?.isMenuItem, tile?.navigation?.isExternal)
                            : null
                        ">
                                                <verde-workspace-tile-dynamic-data *ngIf="tile"
                                                                                   [tile]="tile"></verde-workspace-tile-dynamic-data>
                                            </div>
                                        </kendo-tilelayout-item-body>
                                    </div>
                                    <div class="tile-item-layout-right-dynamic"
                                         [ngClass]="{ clickable: tile?.navigation?.navigable, 'non-clickable': !tile?.navigation?.navigable }"
                                         (click)="
                      tile?.navigation?.navigable
                        ?  this.navigationService.navigateSomewhere(tile?.navigation?.navigationId, tile?.navigation?.isMenuItem, tile?.navigation?.isExternal)
                        : null
                    ">
                                        <img *ngIf="tile?.iconUrl"
                                             title="{{ tile?.title || 'Image Description Not Available' }}"
                                             [src]="tile?.iconUrl"
                                             alt="{{ tile?.title || 'Image Description Not Available' }}"
                                             class="tile-item-icon-dynamic" />
                                    </div>
                                </div>
                            </kendo-tilelayout-item>
                            <!-- </ng-container> -->
                        </ng-container>

                        <ng-container *ngIf="tile?.type === 'adaptive-card' || tile?.type === 'card'">
                            <!-- <ng-container *ngxPermissionsOnly="tile?.technicalName"> -->
                            <kendo-tilelayout-item *ngIf="tile?.enabled"
                                                   [col]="tile?.config?.col"
                                                   [colSpan]="tile?.config?.colSpan"
                                                   [rowSpan]="tile?.config?.rowSpan"
                                                   [resizable]="tile?.config?.resizable"
                                                   [reorderable]="tile?.config?.reorderable"
                                                   class="tile-item-unboxed">
                                <kendo-tilelayout-item-header class="tile-item-header-unboxed">
                                    <kendo-tilelayout-item-body class="tile-item-body-unboxed">
                                        <verde-workspace-adaptive-card
                                                                       [adaptiveCard]="tile?.adaptiveCard"></verde-workspace-adaptive-card>
                                    </kendo-tilelayout-item-body>
                                </kendo-tilelayout-item-header>
                            </kendo-tilelayout-item>
                            <!-- </ng-container> -->
                        </ng-container>
                    </ng-container>
                </ng-container>
            </ng-container>
        </kendo-tilelayout>
    </ng-container>
</div>