import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { VerdeSharedModule } from '../shared/shared.module';
import { WebDynamicModule } from '../web-dynamic/web-dynamic.module';

// Components
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { LayoutComponent } from './layout/layout.component';
import { MainHeaderComponent } from './main-header/main-header.component';
import { ClientComponent } from './verde-approval-panel/components/booking/client/client.component';
import { BraintreeComponent } from './verde-approval-panel/components/booking/client/braintree/braintree.component';

// Sidebar
import { ADummySidepanelComponent } from './verde-approval-panel/components/a-dummy-sidepanel/a-dummy-sidepanel.component';
import { BenchmarkAddComponent } from './verde-approval-panel/components/benchmark/benchmark-add/benchmark-add.component';
import { BenchmarkEditComponent } from './verde-approval-panel/components/benchmark/benchmark-edit/benchmark-edit.component';
import { BenchmarkViewComponent } from './verde-approval-panel/components/benchmark/benchmark-view/benchmark-view.component';
import { BookingAvailabilityResultsComponent } from './verde-approval-panel/components/booking/booking-availability-results/booking-availability-results.component';
import { BookingAvailabilitySearchComponent } from './verde-approval-panel/components/booking/booking-availability-search/booking-availability-search.component';
import { BookingBoardroomCalendarComponent } from './verde-approval-panel/components/booking/booking-boardroom-calendar/booking-boardroom-calendar.component';
import { BulkUploadAdminComponent } from './verde-approval-panel/components/bulk-upload-admin/bulk-upload-admin.component';
import { BulkUploadComponent } from './verde-approval-panel/components/bulk-upload/bulk-upload.component';
import { DynamicFormComponent } from './verde-approval-panel/components/dynamic-form/dynamic-form.component';
import { EmployeeExitEditComponent } from './verde-approval-panel/components/employee-exit-edit/employee-exit-edit.component';
import { EmployeeExitComponent } from './verde-approval-panel/components/employee-exit/employee-exit.component';
import { EmployeeFundsComponent } from './verde-approval-panel/components/employee-funds/employee-funds.component';
import { EmployeeOnboardingAdditionalComponent } from './verde-approval-panel/components/employee-onboarding-additional/employee-onboarding-additional.component';
import { EmployeeOnboardingEditComponent } from './verde-approval-panel/components/employee-onboarding-edit/employee-onboarding-edit.component';
import { OnboardingNationalityEditComponent } from './verde-approval-panel/components/employee-onboarding-edit/onboarding-nationality-edit/onboarding-nationality-edit.component';
import { OnboardingRequiredInfoEditComponent } from './verde-approval-panel/components/employee-onboarding-edit/onboarding-required-info-edit/onboarding-required-info-edit.component';
import { OnboardingStructureEditComponent } from './verde-approval-panel/components/employee-onboarding-edit/onboarding-structure-edit/onboarding-structure-edit.component';
import { OnboardingTaxInfoComponentEdit } from './verde-approval-panel/components/employee-onboarding-edit/onboarding-tax-info-edit/onboarding-tax-info-edit.component';
import { OnboardingpassportInfoEditComponent } from './verde-approval-panel/components/employee-onboarding-edit/passport-info-edit/onboarding-passport-info-edit.component';
import { EmployeeOnboardingComponent } from './verde-approval-panel/components/employee-onboarding/employee-onboarding.component';
import { OnboardingNationalityComponent } from './verde-approval-panel/components/employee-onboarding/onboarding-nationality/onboarding-nationality.component';
import { OnboardingRequiredInfoComponent } from './verde-approval-panel/components/employee-onboarding/onboarding-required-info/onboarding-required-info.component';
import { OnboardingStructureComponent } from './verde-approval-panel/components/employee-onboarding/onboarding-structure/onboarding-structure.component';
import { OnboardingTaxInfoComponent } from './verde-approval-panel/components/employee-onboarding/onboarding-tax-info/onboarding-tax-info.component';
import { OnboardingpassportInfoComponent } from './verde-approval-panel/components/employee-onboarding/passport-info/onboarding-passport-info.component';
import { EmployeeResignationEditComponent } from './verde-approval-panel/components/employee-terminations/employee-resignation-edit/employee-resignation-edit.component';
import { EmployeeResignationViewComponent } from './verde-approval-panel/components/employee-terminations/employee-resignation-view/employee-resignation-view.component';
import { EmployeeResignationComponent } from './verde-approval-panel/components/employee-terminations/employee-resignation/employee-resignation.component';
import { FleetEntityTransferComponent } from './verde-approval-panel/components/fleet-entity-transfer/fleet-entity-transfer.component';
import { FleetOdoUpdateComponent } from './verde-approval-panel/components/fleet-odo-update/fleet-odo-update.component';
import { FleetRequestItemComponent } from './verde-approval-panel/components/fleet-request-item/fleet-request-item.component';
import { FleetReturnItemComponent } from './verde-approval-panel/components/fleet-return-item/fleet-return-item.component';
import { GraphTestComponent } from './verde-approval-panel/components/graph-test/graph-test.component';
import { OpportunitiesComponent } from './verde-approval-panel/components/opportunities/opportunities.component';
import { PayrollUploadComponent } from './verde-approval-panel/components/payroll-upload/payroll-upload.component';
import { EnhancedRatingOptionComponent } from './verde-approval-panel/components/perfromance-review/enhanced-review/enhanced-rating-option/enhanced-rating-option.component';
import { EnhancedReviewComponent } from './verde-approval-panel/components/perfromance-review/enhanced-review/enhanced-review.component';
import { RatingOptionComponent } from './verde-approval-panel/components/perfromance-review/review/rating-option/rating-option.component';
import { ResponseOptionComponent } from './verde-approval-panel/components/perfromance-review/review/response-option/response-option.component';
import { ReviewComponent } from './verde-approval-panel/components/perfromance-review/review/review.component';
import { PersonalDetailsComponent } from './verde-approval-panel/components/personal-details/personal-details.component';
import { ProcessTimelineComponent } from './verde-approval-panel/components/process-instance/process-timeline/process-timeline.component';
import { QualificationsComponent } from './verde-approval-panel/components/qualifications/qualifications.component';
import { StructureActivityComponent } from './verde-approval-panel/components/structure-activity/structure-activity.component';
import { PositionChangeRequestComponent } from './verde-approval-panel/components/structure/position-change-request/position-change-request.component';
import { PositionListComponent } from './verde-approval-panel/components/structure/position-list/position-list.component';
import { StructureChangeRequestComponent } from './verde-approval-panel/components/structure/structure-change-request/structure-change-request.component';
import { StructureDisposeRequestComponent } from './verde-approval-panel/components/structure/structure-dipose-request/structure-dispose-request.component';
import { SuccessorDetailsComponent } from './verde-approval-panel/components/successor-details/successor-details.component';
import { GroupAddComponent } from './verde-approval-panel/components/template-group/group-add/group-add.component';
import { GroupEditComponent } from './verde-approval-panel/components/template-group/group-edit/group-edit.component';
import { GroupViewComponent } from './verde-approval-panel/components/template-group/group-view/group-view.component';
import { TemplateMeasureAddComponent } from './verde-approval-panel/components/template-measure-add/template-measure-add.component';
import { TemplateMeasureEditComponent } from './verde-approval-panel/components/template-measure-edit/template-measure-edit.component';
import { TemplateMeasureViewComponent } from './verde-approval-panel/components/template-measure-view/template-measure-view.component';
import { SubgroupAddComponent } from './verde-approval-panel/components/template-subgroup/subgroup-add/subgroup-add.component';
import { SubgroupEditComponent } from './verde-approval-panel/components/template-subgroup/subgroup-edit/subgroup-edit.component';
import { SubgroupViewComponent } from './verde-approval-panel/components/template-subgroup/subgroup-view/subgroup-view.component';
import { TemplateAddComponent } from './verde-approval-panel/components/template/template-add/template-add.component';
import { TemplateEditComponent } from './verde-approval-panel/components/template/template-edit/template-edit.component';
import { TemplateViewComponent } from './verde-approval-panel/components/template/template-view/template-view.component';
import { UserAccessGridComponent } from './verde-approval-panel/components/user-access-grid/user-access-grid.component';
import { VerdeDynamicFormComponentSidePanel } from './verde-approval-panel/components/verde-dynamic-form-side-panel/verde-dynamic-form-side-panel.component';
import { ViewTaskComponent } from './verde-approval-panel/components/view-task/view-task.component';
import { VerdeApprovalPanelComponent } from './verde-approval-panel/verde-approval-panel.component';
import { SchedulerModule } from '@progress/kendo-angular-scheduler';

export const LAYOUT_COMPONENT_EXPORT = [
  BreadcrumbComponent,
  LayoutComponent,
  MainHeaderComponent,
  // Sidebar
  VerdeApprovalPanelComponent,
  UserAccessGridComponent,
  StructureActivityComponent,
  SuccessorDetailsComponent,
  EmployeeFundsComponent,
  TemplateMeasureViewComponent,
  TemplateMeasureEditComponent,
  TemplateMeasureAddComponent,
  GroupViewComponent,
  GroupEditComponent,
  GroupAddComponent,
  SubgroupAddComponent,
  SubgroupEditComponent,
  SubgroupViewComponent,
  TemplateAddComponent,
  TemplateViewComponent,
  TemplateEditComponent,
  BenchmarkViewComponent,
  BenchmarkEditComponent,
  BenchmarkAddComponent,
  PersonalDetailsComponent,
  PayrollUploadComponent,
  BulkUploadComponent,
  BulkUploadAdminComponent,
  ViewTaskComponent,
  EnhancedReviewComponent,
  EnhancedRatingOptionComponent,
  FleetRequestItemComponent,
  FleetEntityTransferComponent,
  FleetOdoUpdateComponent,
  ADummySidepanelComponent,
  FleetReturnItemComponent,
  QualificationsComponent,
  VerdeDynamicFormComponentSidePanel,
  DynamicFormComponent,
  OpportunitiesComponent,
  EnhancedRatingOptionComponent,
  ReviewComponent,
  RatingOptionComponent,
  ResponseOptionComponent,
  EmployeeExitComponent,
  EmployeeExitEditComponent,
  StructureChangeRequestComponent,
  StructureDisposeRequestComponent,
  PositionListComponent,
  PositionChangeRequestComponent,
  EmployeeResignationComponent,
  EmployeeExitEditComponent,
  EmployeeOnboardingComponent,
  EmployeeOnboardingAdditionalComponent,
  OnboardingRequiredInfoComponent,
  OnboardingNationalityComponent,
  OnboardingTaxInfoComponent,
  OnboardingStructureComponent,
  EmployeeResignationEditComponent,
  EmployeeResignationViewComponent,
  EmployeeOnboardingEditComponent,
  OnboardingNationalityEditComponent,
  OnboardingRequiredInfoEditComponent,
  OnboardingStructureEditComponent,
  OnboardingpassportInfoComponent,
  OnboardingTaxInfoComponentEdit,
  OnboardingpassportInfoEditComponent,
  BookingAvailabilitySearchComponent,
  GraphTestComponent,
  BookingAvailabilityResultsComponent,
  ProcessTimelineComponent,
  BookingBoardroomCalendarComponent,
];

export const BOOKING_COMPONENT_EXPORT = [ClientComponent, BraintreeComponent];

@NgModule({
  declarations: [...LAYOUT_COMPONENT_EXPORT, ...BOOKING_COMPONENT_EXPORT],
  imports: [CommonModule, VerdeSharedModule, RouterModule, WebDynamicModule, SchedulerModule],
  exports: [...LAYOUT_COMPONENT_EXPORT],
})
export class VerdeLayoutModule {}
