/* tslint:disable */
/* eslint-disable */
export interface TimeOffEmployeeTimeOffCycleSummary {
  '_bt_employee_value'?: null | string;
  '_bt_employee_value@OData.Community.Display.V1.FormattedValue'?: null | string;
  '_bt_legalentity_value'?: null | string;
  '_bt_legalentity_value@OData.Community.Display.V1.FormattedValue'?: null | string;
  '_bt_timeofftype_value'?: null | string;
  '_bt_timeofftype_value@OData.Community.Display.V1.FormattedValue'?: null | string;
  bt_LeaveDueToday?: null | number;
  bt_TimeOffTypeName?: null | string;
  bt_accrualactive?: null | boolean;
  bt_accrualmanual?: null | number;
  bt_accrualnext?: null | number;
  bt_accrualprevious?: null | number;
  bt_accrualtoday?: null | number;
  bt_accrualtodayrollup?: null | number;
  bt_activecycledays?: null | number;
  bt_activesubtypes?: null | number;
  bt_capping?: null | boolean;
  bt_colour?: null | string;
  bt_cycle?: null | number;
  bt_cycleactive?: null | boolean;
  bt_cycleclosingbalance?: null | number;
  bt_cycledays?: null | number;
  bt_cycleenddate?: null | string;
  bt_cycleperiods?: null | number;
  bt_cyclestart?: null | string;
  bt_cyclestartdate?: null | string;
  bt_cycletodatepending?: null | number;
  bt_cycletodatetaken?: null | number;
  bt_daysincycle?: null | number;
  bt_effectivedate?: null | string;
  bt_employeetimeoffcyclesummary?: null | string;
  bt_employeetimeoffcyclesummaryid?: string;
  bt_firstdate?: null | string;
  bt_futuredate?: null | string;
  bt_futuredated?: null | number;
  bt_leaveduetoday?: null | number;
  bt_manualaccrualcalculation?: null | number;
  bt_manualaccruedate?: null | string;
  bt_maximumDays?: null | number;
  bt_openingbalance?: null | number;
  bt_showondashboard?: null | boolean;
  bt_showondbfromtype?: null | boolean;
  bt_takenapproved?: null | number;
  bt_takencancelled?: null | number;
  bt_takenpending?: null | number;
  bt_tobecapped?: null | number;
  bt_today?: null | string;
  bt_units?: null | number;
}
