/* tslint:disable */
/* eslint-disable */
export interface UserAvailabilityRoomsDto {
  bookingName?: null | string;
  description?: null | string;
  endDateTime?: null | string;
  location?: null | string;
  roomEmail?: null | string;
  startDateTime?: null | string;
  subject?: null | string;
}
