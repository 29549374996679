/* tslint:disable */
/* eslint-disable */
import { BookingsBookingDurationsDto } from './bookings-booking-durations-dto';
import { BookingsBookingLocationDto } from './bookings-booking-location-dto';
import { BookingsBookingOfficeDto } from './bookings-booking-office-dto';
import { BookingsBookingSubOfficeDto } from './bookings-booking-sub-office-dto';
import { BookingsBtBookingCategory } from './bookings-bt-booking-category';
export interface BookingsBookingAvailabilitySearchDataDto {
  bookingDurations?: null | Array<BookingsBookingDurationsDto>;
  bookingItemLocations?: null | Array<BookingsBookingLocationDto>;
  bookingItemOffices?: null | Array<BookingsBookingOfficeDto>;
  bookingItemSubOffices?: null | Array<BookingsBookingSubOfficeDto>;
  bt_allowdatetime?: null | boolean;
  bt_approvalrequired?: null | boolean;
  bt_bookingcategory?: BookingsBtBookingCategory;
  bt_bookingtype?: null | string;
  bt_bookingtypeid?: null | string;
  bt_department?: null | boolean;
  bt_displayimage?: null | boolean;
  bt_location?: null | boolean;
  bt_maxrecurringdays?: null | number;
  bt_office?: null | boolean;
  bt_reasonrequired?: null | boolean;
  bt_suboffice?: null | boolean;
}
