/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { WebDynamicBaseService } from '../web-dynamic-base-service';
import { WebDynamicApiConfiguration } from '../web-dynamic-api-configuration';
import { WebDynamicStrictHttpResponse } from '../web-dynamic-strict-http-response';
import { WebDynamicRequestBuilder } from '../web-dynamic-request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { WebDynamicGetDynamicFormLookupQuery } from '../models/web-dynamic-get-dynamic-form-lookup-query';
import { WebDynamicSubmitDynamicFormDataCommand } from '../models/web-dynamic-submit-dynamic-form-data-command';

@Injectable({
  providedIn: 'root',
})
export class WebDynamicApiWebService extends WebDynamicBaseService {
  constructor(
    config: WebDynamicApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation dynamicFormLookupData
   */
  static readonly DynamicFormLookupDataPath = '/api/Web/get/dynamicFormLookupData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dynamicFormLookupData()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  dynamicFormLookupData$Response(params?: {
    body?: WebDynamicGetDynamicFormLookupQuery
  }): Observable<WebDynamicStrictHttpResponse<Array<{
[key: string]: any;
}>>> {

    const rb = new WebDynamicRequestBuilder(this.rootUrl, WebDynamicApiWebService.DynamicFormLookupDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as WebDynamicStrictHttpResponse<Array<{
        [key: string]: any;
        }>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `dynamicFormLookupData$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  dynamicFormLookupData(params?: {
    body?: WebDynamicGetDynamicFormLookupQuery
  }): Observable<Array<{
[key: string]: any;
}>> {

    return this.dynamicFormLookupData$Response(params).pipe(
      map((r: WebDynamicStrictHttpResponse<Array<{
[key: string]: any;
}>>) => r.body as Array<{
[key: string]: any;
}>)
    );
  }

  /**
   * Path part for operation submitDynamicForm
   */
  static readonly SubmitDynamicFormPath = '/api/Web/get/submitDynamicForm';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `submitDynamicForm()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  submitDynamicForm$Response(params?: {
    body?: WebDynamicSubmitDynamicFormDataCommand
  }): Observable<WebDynamicStrictHttpResponse<boolean>> {

    const rb = new WebDynamicRequestBuilder(this.rootUrl, WebDynamicApiWebService.SubmitDynamicFormPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as WebDynamicStrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `submitDynamicForm$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  submitDynamicForm(params?: {
    body?: WebDynamicSubmitDynamicFormDataCommand
  }): Observable<boolean> {

    return this.submitDynamicForm$Response(params).pipe(
      map((r: WebDynamicStrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
