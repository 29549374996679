import { Component, OnDestroy } from '@angular/core';
import { AuthenticationService, UserService } from '@verde/core';
import { combineLatest, debounceTime, distinctUntilChanged, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'verde-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnDestroy {
  private onDestroy$ = new Subject<boolean>();

  constructor(private authenticationService: AuthenticationService, private userService: UserService) {
    const self = this;

    combineLatest([self.userService.configLoaded$, self.userService.config$])
      .pipe(debounceTime(500), distinctUntilChanged(), takeUntil(self.onDestroy$))
      .subscribe(async (data) => {
        if (data[0] && data[1]) {
          self.authenticationService.signOut(self.userService.config$.getValue()?.user);
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
