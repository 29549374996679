/* tslint:disable */
/* eslint-disable */
import { UserIBackingStore } from './user-i-backing-store';
export interface UserOutlookGeoCoordinates {
  accuracy?: null | number;
  additionalData?: null | {
    [key: string]: any;
  };
  altitude?: null | number;
  altitudeAccuracy?: null | number;
  backingStore?: UserIBackingStore;
  latitude?: null | number;
  longitude?: null | number;
  odataType?: null | string;
}
