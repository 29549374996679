/* tslint:disable */
/* eslint-disable */
import { UserDayOfWeekObject } from './user-day-of-week-object';
import { UserIBackingStore } from './user-i-backing-store';
import { UserRecurrencePatternType } from './user-recurrence-pattern-type';
import { UserWeekIndex } from './user-week-index';
export interface UserRecurrencePattern {
  additionalData?: null | {
    [key: string]: any;
  };
  backingStore?: UserIBackingStore;
  dayOfMonth?: null | number;
  daysOfWeek?: null | Array<UserDayOfWeekObject>;
  firstDayOfWeek?: UserDayOfWeekObject;
  index?: UserWeekIndex;
  interval?: null | number;
  month?: null | number;
  odataType?: null | string;
  type?: UserRecurrencePatternType;
}
