import { GroupedMenu, UserService } from '@verde/core';
import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserAppConfigDto, UserSecurityFunctionDto } from '@verde/api';
import { WebDynamicFormType, WebDynamicFormAction, WebDynamicService } from '../../../../../public-api';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'verde-workspace-tile-menu',
  templateUrl: './workspace-tile-menu.component.html',
  styleUrls: ['./workspace-tile-menu.component.scss'],
})
export class WorkspaceTileMenuComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<boolean>();
  @Input() menuContext = '';
  securityFunctions: UserSecurityFunctionDto[] = [];
  menuGroup: GroupedMenu[] = [];
  config: UserAppConfigDto = {};
  menuGroupReady = false;

  get loading(): boolean {
    if (this.menuGroupReady) return false;
    return this.menuGroup?.length < 1;
  }
  @HostBinding('class.k-card')
  cssClass = true;

  constructor(private router: Router, private userService: UserService, private webDynamicService: WebDynamicService) {}

  ngOnInit(): void {
    this.userService.config$.pipe(takeUntil(this.onDestroy$)).subscribe((config) => {
      if (config !== null && config !== undefined && Object.keys(config).length > 0) {
        this.config = config;
        if (this.menuContext) {
          this.getMenuFunctions();
        }
      }
    });
  }

  private getMenuFunctions(): void {
    this.securityFunctions = [];

    if (!this.menuContext) return; // Ensure menuContext is valid

    const cleanedMenuName = this.cleanMenuName(this.menuContext);

    const initialFunctions = this.userService.filterUserPermissions(cleanedMenuName, false) || [];

    this.securityFunctions = initialFunctions
      .filter((func) => func?.menuName) // Filter out null/undefined menuName
      .sort((a, b) => (a.menuName?.toLowerCase() || '').localeCompare(b.menuName?.toLowerCase() || ''));

    this.menuGroup = this.userService.sortGroupMenuItems(this.securityFunctions) || [];

    // Set menuGroupReady to true after a delay of 10 seconds
    setTimeout(() => {
      this.menuGroupReady = true;
    }, 10000);
  }

  private cleanMenuName(menuName: string): string {
    return menuName ? menuName.replace(/Menu Context\s*-\s*/i, '').trim() : '';
  }

  navigateSomewhere(item: UserSecurityFunctionDto, isMenuItem: boolean): void {
    if (item.jsonFileName) {
      this.webDynamicService.setDynamicForm({
        formFile: item.jsonFileName,
        formType: WebDynamicFormType.Grid,
        action: WebDynamicFormAction.Read,
      });
      return;
    } else if (item.bt_LinkType == 'Internal') {
      const cleanedString = item.menuGroup.replace(/\\/g, '');
      const pathMatch = cleanedString.match(/path:\s*"([^"]+)"/);
      const paramsMatch = cleanedString.match(/params:\s*"([^"]+)"/);
      const path = pathMatch ? pathMatch[1] : null;
      const id = paramsMatch ? paramsMatch[1] : null;
      this.router.navigate([path], { queryParams: { tab: id } });
    } else if (item.bt_LinkType == 'External') {
      const url = item.menuGroup.replace('{dataverseurl}', this.config.verdeEnvironmentConfig.dataverseUrl);
      window.open(url);
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
