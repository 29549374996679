/* tslint:disable */
/* eslint-disable */
import { UserBodyType } from './user-body-type';
import { UserIBackingStore } from './user-i-backing-store';
export interface UserItemBody {
  additionalData?: null | {
    [key: string]: any;
  };
  backingStore?: UserIBackingStore;
  content?: null | string;
  contentType?: UserBodyType;
  odataType?: null | string;
}
