/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { UserBaseService } from '../user-base-service';
import { UserApiConfiguration } from '../user-api-configuration';
import { UserStrictHttpResponse } from '../user-strict-http-response';
import { UserRequestBuilder } from '../user-request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { UserAppConfigDto } from '../models/user-app-config-dto';
import { UserGetUserAppConfigQuery } from '../models/user-get-user-app-config-query';
import { UserGetUserPeopleManagementConfigQuery } from '../models/user-get-user-people-management-config-query';
import { UserLegalEntityConfigDtoPeopleManagementConfigDto } from '../models/user-legal-entity-config-dto-people-management-config-dto';
import { UserRemoveUserSecurityCacheQuery } from '../models/user-remove-user-security-cache-query';

@Injectable({
  providedIn: 'root',
})
export class UserApiConfigService extends UserBaseService {
  constructor(
    config: UserApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getUserAppConfig
   */
  static readonly GetUserAppConfigPath = '/api/Config/GetUserAppConfig';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserAppConfig()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getUserAppConfig$Response(params?: {
    body?: UserGetUserAppConfigQuery
  }): Observable<UserStrictHttpResponse<UserAppConfigDto>> {

    const rb = new UserRequestBuilder(this.rootUrl, UserApiConfigService.GetUserAppConfigPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as UserStrictHttpResponse<UserAppConfigDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserAppConfig$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getUserAppConfig(params?: {
    body?: UserGetUserAppConfigQuery
  }): Observable<UserAppConfigDto> {

    return this.getUserAppConfig$Response(params).pipe(
      map((r: UserStrictHttpResponse<UserAppConfigDto>) => r.body as UserAppConfigDto)
    );
  }

  /**
   * Path part for operation removeSecuritySession
   */
  static readonly RemoveSecuritySessionPath = '/api/Config/RemoveSecuritySession';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeSecuritySession()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  removeSecuritySession$Response(params?: {
    body?: UserRemoveUserSecurityCacheQuery
  }): Observable<UserStrictHttpResponse<boolean>> {

    const rb = new UserRequestBuilder(this.rootUrl, UserApiConfigService.RemoveSecuritySessionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as UserStrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeSecuritySession$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  removeSecuritySession(params?: {
    body?: UserRemoveUserSecurityCacheQuery
  }): Observable<boolean> {

    return this.removeSecuritySession$Response(params).pipe(
      map((r: UserStrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation getUserPeopleManagementConfig
   */
  static readonly GetUserPeopleManagementConfigPath = '/api/Config/GetUserPeopleManagementConfig';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserPeopleManagementConfig()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getUserPeopleManagementConfig$Response(params?: {
    body?: UserGetUserPeopleManagementConfigQuery
  }): Observable<UserStrictHttpResponse<UserLegalEntityConfigDtoPeopleManagementConfigDto>> {

    const rb = new UserRequestBuilder(this.rootUrl, UserApiConfigService.GetUserPeopleManagementConfigPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as UserStrictHttpResponse<UserLegalEntityConfigDtoPeopleManagementConfigDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserPeopleManagementConfig$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getUserPeopleManagementConfig(params?: {
    body?: UserGetUserPeopleManagementConfigQuery
  }): Observable<UserLegalEntityConfigDtoPeopleManagementConfigDto> {

    return this.getUserPeopleManagementConfig$Response(params).pipe(
      map((r: UserStrictHttpResponse<UserLegalEntityConfigDtoPeopleManagementConfigDto>) => r.body as UserLegalEntityConfigDtoPeopleManagementConfigDto)
    );
  }

  /**
   * Path part for operation apiConfigGet
   */
  static readonly ApiConfigGetPath = '/api/Config';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiConfigGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiConfigGet$Response(params?: {
  }): Observable<UserStrictHttpResponse<void>> {

    const rb = new UserRequestBuilder(this.rootUrl, UserApiConfigService.ApiConfigGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as UserStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiConfigGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiConfigGet(params?: {
  }): Observable<void> {

    return this.apiConfigGet$Response(params).pipe(
      map((r: UserStrictHttpResponse<void>) => r.body as void)
    );
  }

}
