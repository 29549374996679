/* tslint:disable */
/* eslint-disable */
export enum UserRecurrencePatternType {
  Daily = 'daily',
  Weekly = 'weekly',
  AbsoluteMonthly = 'absoluteMonthly',
  RelativeMonthly = 'relativeMonthly',
  AbsoluteYearly = 'absoluteYearly',
  RelativeYearly = 'relativeYearly',
}
