import { AfterViewInit, Directive, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { DeviceTypeService } from '@verde/core';
import { Subject, takeUntil } from 'rxjs';

@Directive({
  selector: '[verdeResponsiveDialog]',
})
export class ResponsiveDialogDirective implements AfterViewInit, OnInit, OnDestroy {
  private onDestroy$ = new Subject<boolean>();

  dialogElement: ElementRef;

  constructor(private el: ElementRef, private renderer: Renderer2, private deviceTypeService: DeviceTypeService) {}

  ngOnInit() {
    this.deviceTypeService.screenSizeDetailed$.pipe(takeUntil(this.onDestroy$)).subscribe((screen) => {
      if (screen) {
        this.adjustWidth(screen.size);
      }
    });
  }

  ngAfterViewInit() {
    this.dialogElement = this.el.nativeElement.querySelector('div.k-window.k-dialog');
    this.adjustWidth(this.deviceTypeService.getScreenSize());
  }

  private adjustWidth(screenSize: number) {
    let dialogWidth = '600px'; // Default width

    if (screenSize < 768) {
      dialogWidth = '90%';
    } else if (screenSize < 1024) {
      dialogWidth = '70%';
    } else if (screenSize < 1440) {
      dialogWidth = '50%';
    }

    if (this.dialogElement) {
      this.renderer.setStyle(this.dialogElement, 'width', dialogWidth);
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
