import { CommonHelpers, NavigationService } from '@verde/core';
import { TileModel } from '../../../../../public-api';
import { Component, Input } from '@angular/core';

type Location = 'header' | 'body' | 'footer' | 'badge';
@Component({
  selector: 'verde-workspace-tile-dynamic-data',
  templateUrl: './workspace-tile-dynamic-data.component.html',
  styleUrls: ['./workspace-tile-dynamic-data.component.scss'],
})
export class WorkspaceTileDynamicDataComponent {
  @Input() tile: TileModel;
  @Input() location: Location = 'body';

  badgeAlign = { vertical: 'top', horizontal: 'start' };
  tiles: { [key: string]: string[] } = {};

  userMobileCardBackgroundImage: string;
  constructor(public navigationService: NavigationService) {
    this.determineUserMobileCardBackgroundImage();
    this.tiles = {
      userCard: ['userHeader', 'userCard'],
      userMobileCard: ['userMobileHeader', 'userMobileCard'],
      userCalendar: ['userCalendar'],
      userTasks: ['userTask', 'userTasks'],
      userBookings: ['userBookings'],
      userEngage: ['userEngage'],
      userReviews: ['userReviews'],
      userTimeOff: ['userTimeOff'],
      userHealthRisk: ['userHealthRisk'],
      userTimeTracking: ['userTimeTracking'],
      userTeam: ['userMyteam', 'userTeam'],
      userVoting: ['userVoting'],
      userDocuments: ['userDocuments'],
      userFleet: ['userFleet'],
      orgPolicies: ['userPolicies', 'orgPolicies'],
      userEquipment: ['userEquipment'],
      orgLogo: ['orgLogo'],
      userManager: ['userManager'],
      orgBirthdays: ['peopleBirthdays', 'orgBirthdays'],
      userWeather: ['voxWeather', 'userWeather'],
    };
  }

  selectedTile(tileIds: string[], location: Location): boolean {
    return tileIds?.some((tileId) => tileId === this.tile?.id) && this.location === location;
  }

  determineUserMobileCardBackgroundImage() {
    const numberOfUserMobileCardBackgrounds = 10;
    this.userMobileCardBackgroundImage = `https://verdeimages.z1.web.core.windows.net/Images/Verde/Splash/images/Mobile/mobile${CommonHelpers.getRandomInteger(
      numberOfUserMobileCardBackgrounds,
    )}.png`;
  }
}
