/* tslint:disable */
/* eslint-disable */
export interface WebDynamicSubmitDynamicFormDataCommand {
  entityId?: null | string;
  entityName?: null | string;
  model?: null | {
[key: string]: string;
};
  types?: null | {
[key: string]: string;
};
}
