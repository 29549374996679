import { Injectable } from '@angular/core';
import { WorkspaceModel, TileModel } from '../../../../public-api';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceService {
  constructor(private toastr: ToastrService) {}

  updateWorkspaceTiles(workspaceData: WorkspaceModel, tiles: TileModel[]): WorkspaceModel {
    if (!workspaceData) {
      return {} as WorkspaceModel;
    }
    const data = workspaceData;

    // Iterate through each layout in the dynamic dashboard JSON
    data?.layouts?.forEach((layout, layoutIndex) => {
      // Iterate through each column in the layout
      layout?.columns?.forEach((column, columnIndex) => {
        column?.tiles?.forEach((tile, tileIndex) => {
          const updatedTile = tiles.find((t) => t.id === tile.id);
          if (updatedTile) {
            if (
              data.layouts &&
              data.layouts[layoutIndex] &&
              data.layouts[layoutIndex].columns &&
              data.layouts[layoutIndex].columns[columnIndex] &&
              data.layouts[layoutIndex].columns[columnIndex].tiles
            ) {
              data.layouts[layoutIndex].columns[columnIndex].tiles[tileIndex] = {
                ...data.layouts[layoutIndex].columns[columnIndex].tiles[tileIndex],
                ...updatedTile,
              };
            }
          }
        });
      });
    });

    return data;
  }

  handleWorkspaceResponse(tiles: TileModel[] | null, updateCallback: (data: WorkspaceModel) => void) {
    return {
      next: (data: WorkspaceModel | null) => {
        if (data) {
          const updatedData = tiles ? this.updateWorkspaceTiles(data, tiles) : data;
          updateCallback(updatedData); // Always call the callback with the updated data
        }
      },
      error: (error: any) => {
        this.toastr.error(error?.message);
      },
    };
  }
}
