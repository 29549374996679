import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SidePanelOverlayWidth } from '../enums/sidepanel-overlay-width.enum';

@Injectable({
  providedIn: 'root',
})
export class VerdeApprovalOverlayService {
  // Show Sidebar
  private showSidebar$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private sidebarMetadata$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  // Sidebar Type
  private sidebarType$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  // Sidebar size
  private sidebarSize$: BehaviorSubject<number> = new BehaviorSubject<number>(SidePanelOverlayWidth.Quater);

  private selectedJson$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private dynamicFormData$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  private appConfigs$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  private titleTag$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  private titleHeading$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  private useToReload$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  reloadObaerve = this.useToReload$.asObservable();

  constructor() {}

  // Show Sidebar logic
  getShowSidebar() {
    return this.showSidebar$.asObservable();
  }

  setShowSidebar(showHide: boolean) {
    this.showSidebar$.next(showHide);

    if (!showHide) {
      this.sidebarType$.next('');
    }

    if (this.showSidebar$.value == false) {
      setTimeout(() => {
        this.useToReload$.next(!this.useToReload$.value);
      }, 1000);
    }
  }

  // Metadata
  getSidebarMetadata() {
    return this.sidebarMetadata$.asObservable();
  }

  setSidebarMetadata(data: any) {
    this.sidebarMetadata$.next(data);
  }

  // Sidebar state logic
  toggleSidebarState() {
    this.showSidebar$.next(!this.showSidebar$.value);
  }

  isSidebarOpen() {
    return this.showSidebar$.value;
  }

  // Sidebar Type logic
  getSidebarType() {
    return this.sidebarType$.asObservable();
  }

  setSidebarType(type: string) {
    this.sidebarType$.next(type);
  }

  // Sidebar Size logic
  getSidebarSize() {
    return this.sidebarSize$.asObservable();
  }

  setSidebarSize(size: number) {
    this.sidebarSize$.next(size);
  }

  getSelectedDynamicFormJson() {
    return this.selectedJson$.asObservable();
  }

  setSelectedDynamicFormJson(json: string) {
    this.selectedJson$.next(json);
  }

  // Dynamic Form Data
  getDynamicFormData() {
    return this.dynamicFormData$.asObservable();
  }

  setDynamicFormData(data: any) {
    this.dynamicFormData$.next(data);
  }

  getAppConfigs() {
    return this.appConfigs$.asObservable();
  }

  setAppConfigs(item: any) {
    this.appConfigs$.next(item);
  }

  // Title variable
  getTitleTag() {
    return this.titleTag$.asObservable();
  }

  setTitleTag(titleTag: any) {
    this.titleTag$.next(titleTag);
  }

  getTitleHeading() {
    return this.titleHeading$.asObservable();
  }

  setTitleHeading(titleHeading: any) {
    this.titleHeading$.next(titleHeading);
  }
}
