/* tslint:disable */
/* eslint-disable */
export interface BookingsBookingsByCategoryFilteredDto {
  bookingItemId?: null | string;
  bookingItemName?: null | string;
  bookingNo?: null | string;
  bookingReason?: null | string;
  bookingType?: null | string;
  cancelRecurring?: null | boolean;
  colour?: null | string;
  departmental?: null | boolean;
  duration?: null | string;
  employeeName?: null | string;
  fromDate?: null | string;
  icon?: null | string;
  includeWeekends?: null | boolean;
  isProxy?: null | boolean;
  isRecurring?: null | boolean;
  numberOfDays?: null | number;
  office?: null | string;
  primaryLocation?: null | string;
  proxyEmployee?: null | string;
  roomName?: null | string;
  stateCode?: null | number;
  subOffice?: null | string;
  toDate?: null | string;
  verdeBookingsId?: null | string;
}
