import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, InjectionToken, NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { ModuleImportGuard } from './module-import-guard';
import { ApiModule } from '@verde/api';

// Sentry
// import * as Sentry from '@sentry/angular-ivy';

// Authentication
import { AuthenticationModule } from './modules/authentication/authentication.module';

// Lottie
import player from 'lottie-web';
import { LottieModule } from 'ngx-lottie';

// Toastr
import { ToastrModule } from 'ngx-toastr';

// Interceptors
import { HttpCancelInterceptor } from './interceptors/cancel.interceptor';
import { RequestInterceptor } from './interceptors/request.interceptor';

// Redux Store
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import * as coreReducer from './core.reducer';

// Roles & Permissions
import { NgxPermissionsModule } from 'ngx-permissions';

// Loading Bar
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';

export const ENV_TOKEN = new InjectionToken('environment');

export function playerFactory() {
  return player;
}

@NgModule({
  imports: [
    AuthenticationModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    LottieModule.forRoot({ player: playerFactory }),
    StoreRouterConnectingModule.forRoot(),
    StoreModule.forRoot(coreReducer.reducer),
    EffectsModule.forRoot([]),
    NgxPermissionsModule.forRoot(),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-center',
      preventDuplicates: true,
      closeButton: true,
      disableTimeOut: true,
      toastClass: 'verde-toast ngx-toastr',
    }),
    LoadingBarModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [AuthenticationModule, LoadingBarModule, LoadingBarHttpClientModule, LoadingBarRouterModule, NgxPermissionsModule],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    ModuleImportGuard(parentModule, 'CoreModule');
  }

  static forRoot(environment: any) {
    return {
      ngModule: CoreModule,
      imports: [ApiModule.forRoot(environment)],
      exports: [ApiModule.forRoot(environment)],
      providers: [
        ...ApiModule.forRoot(environment).providers,
        {
          provide: 'environment', // To be used with InjectionToken
          useValue: environment,
        },
        {
          provide: ENV_TOKEN,
          useValue: environment,
        },
        ...AuthenticationModule.forRoot(ENV_TOKEN).providers,
        { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpCancelInterceptor,
          multi: true,
        },
        // {
        //   provide: ErrorHandler,
        //   useValue: Sentry.createErrorHandler({
        //     showDialog: false,
        //   }),
        // },
        // {
        //   provide: Sentry.TraceService,
        //   deps: [Router],
        // },
        // {
        //   provide: APP_INITIALIZER,
        //   useFactory: () => () => {},
        //   deps: [Sentry.TraceService],
        //   multi: true,
        // },
      ],
    };
  }
}
