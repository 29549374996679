/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BookingsBaseService } from '../bookings-base-service';
import { BookingsApiConfiguration } from '../bookings-api-configuration';
import { BookingsStrictHttpResponse } from '../bookings-strict-http-response';
import { BookingsRequestBuilder } from '../bookings-request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BookingsBookingAvailabilitySearchDataDto } from '../models/bookings-booking-availability-search-data-dto';
import { BookingsBookingsByCategoryDto } from '../models/bookings-bookings-by-category-dto';
import { BookingsBookingsFilteredDto } from '../models/bookings-bookings-filtered-dto';
import { BookingsBooleanStringValueTuple } from '../models/bookings-boolean-string-value-tuple';
import { BookingsCreateRequestHeaderCommand } from '../models/bookings-create-request-header-command';
import { BookingsGetBookingAvailabilitiesFilteredDto } from '../models/bookings-get-booking-availabilities-filtered-dto';
import { BookingsGetBookingAvailabilitiesQuery } from '../models/bookings-get-booking-availabilities-query';
import { BookingsGetBookingAvailabilitySearchDataQuery } from '../models/bookings-get-booking-availability-search-data-query';
import { BookingsGetBookingItemsFilteredDto } from '../models/bookings-get-booking-items-filtered-dto';
import { BookingsGetBookingItemsFilteredQuery } from '../models/bookings-get-booking-items-filtered-query';
import { BookingsGetBookingsByCategoryFilteredQuery } from '../models/bookings-get-bookings-by-category-filtered-query';
import { BookingsGetBookingsByUserIdQuery } from '../models/bookings-get-bookings-by-user-id-query';
import { BookingsGetBookingsSummaryQuery } from '../models/bookings-get-bookings-summary-query';
import { BookingsPatchBookingCommand } from '../models/bookings-patch-booking-command';
import { BookingsPostBookingsCommand } from '../models/bookings-post-bookings-command';
import { BookingsSaveBoardroomBookingItemsCommand } from '../models/bookings-save-boardroom-booking-items-command';

@Injectable({
  providedIn: 'root',
})
export class BookingsApiBookingsService extends BookingsBaseService {
  constructor(config: BookingsApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation bookingsSummary
   */
  static readonly BookingsSummaryPath = '/api/Bookings/get/bookingSummary';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bookingsSummary()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  bookingsSummary$Response(params?: { body?: BookingsGetBookingsSummaryQuery }): Observable<BookingsStrictHttpResponse<Array<BookingsBookingsFilteredDto>>> {
    const rb = new BookingsRequestBuilder(this.rootUrl, BookingsApiBookingsService.BookingsSummaryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as BookingsStrictHttpResponse<Array<BookingsBookingsFilteredDto>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `bookingsSummary$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  bookingsSummary(params?: { body?: BookingsGetBookingsSummaryQuery }): Observable<Array<BookingsBookingsFilteredDto>> {
    return this.bookingsSummary$Response(params).pipe(
      map((r: BookingsStrictHttpResponse<Array<BookingsBookingsFilteredDto>>) => r.body as Array<BookingsBookingsFilteredDto>),
    );
  }

  /**
   * Path part for operation bookingsByUserId
   */
  static readonly BookingsByUserIdPath = '/api/Bookings/get/bookingsByUserId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bookingsByUserId()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  bookingsByUserId$Response(params?: { body?: BookingsGetBookingsByUserIdQuery }): Observable<BookingsStrictHttpResponse<Array<BookingsBookingsFilteredDto>>> {
    const rb = new BookingsRequestBuilder(this.rootUrl, BookingsApiBookingsService.BookingsByUserIdPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as BookingsStrictHttpResponse<Array<BookingsBookingsFilteredDto>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `bookingsByUserId$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  bookingsByUserId(params?: { body?: BookingsGetBookingsByUserIdQuery }): Observable<Array<BookingsBookingsFilteredDto>> {
    return this.bookingsByUserId$Response(params).pipe(
      map((r: BookingsStrictHttpResponse<Array<BookingsBookingsFilteredDto>>) => r.body as Array<BookingsBookingsFilteredDto>),
    );
  }

  /**
   * Path part for operation getBookingsByCategory
   */
  static readonly GetBookingsByCategoryPath = '/api/Bookings/get/getBookingsByCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBookingsByCategory()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getBookingsByCategory$Response(params?: {
    body?: BookingsGetBookingsByCategoryFilteredQuery;
  }): Observable<BookingsStrictHttpResponse<BookingsBookingsByCategoryDto>> {
    const rb = new BookingsRequestBuilder(this.rootUrl, BookingsApiBookingsService.GetBookingsByCategoryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as BookingsStrictHttpResponse<BookingsBookingsByCategoryDto>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getBookingsByCategory$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getBookingsByCategory(params?: { body?: BookingsGetBookingsByCategoryFilteredQuery }): Observable<BookingsBookingsByCategoryDto> {
    return this.getBookingsByCategory$Response(params).pipe(
      map((r: BookingsStrictHttpResponse<BookingsBookingsByCategoryDto>) => r.body as BookingsBookingsByCategoryDto),
    );
  }

  /**
   * Path part for operation postBookings
   */
  static readonly PostBookingsPath = '/api/Bookings/post/bookings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postBookings()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postBookings$Response(params?: { body?: BookingsPostBookingsCommand }): Observable<BookingsStrictHttpResponse<boolean>> {
    const rb = new BookingsRequestBuilder(this.rootUrl, BookingsApiBookingsService.PostBookingsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as BookingsStrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postBookings$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postBookings(params?: { body?: BookingsPostBookingsCommand }): Observable<boolean> {
    return this.postBookings$Response(params).pipe(map((r: BookingsStrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation updateBooking
   */
  static readonly UpdateBookingPath = '/api/Bookings/patch/updateBooking';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateBooking()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateBooking$Response(params?: { body?: BookingsPatchBookingCommand }): Observable<BookingsStrictHttpResponse<boolean>> {
    const rb = new BookingsRequestBuilder(this.rootUrl, BookingsApiBookingsService.UpdateBookingPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as BookingsStrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateBooking$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateBooking(params?: { body?: BookingsPatchBookingCommand }): Observable<boolean> {
    return this.updateBooking$Response(params).pipe(map((r: BookingsStrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation getBookingAvailabilities
   */
  static readonly GetBookingAvailabilitiesPath = '/api/Bookings/get/getBookingAvailabilities';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBookingAvailabilities()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getBookingAvailabilities$Response(params?: {
    body?: BookingsGetBookingAvailabilitiesQuery;
  }): Observable<BookingsStrictHttpResponse<Array<BookingsGetBookingAvailabilitiesFilteredDto>>> {
    const rb = new BookingsRequestBuilder(this.rootUrl, BookingsApiBookingsService.GetBookingAvailabilitiesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as BookingsStrictHttpResponse<Array<BookingsGetBookingAvailabilitiesFilteredDto>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getBookingAvailabilities$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getBookingAvailabilities(params?: { body?: BookingsGetBookingAvailabilitiesQuery }): Observable<Array<BookingsGetBookingAvailabilitiesFilteredDto>> {
    return this.getBookingAvailabilities$Response(params).pipe(
      map((r: BookingsStrictHttpResponse<Array<BookingsGetBookingAvailabilitiesFilteredDto>>) => r.body as Array<BookingsGetBookingAvailabilitiesFilteredDto>),
    );
  }

  /**
   * Path part for operation saveBoardroomBookingItems
   */
  static readonly SaveBoardroomBookingItemsPath = '/api/Bookings/post/saveBoardroomBookingItems';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveBoardroomBookingItems()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveBoardroomBookingItems$Response(params?: { body?: BookingsSaveBoardroomBookingItemsCommand }): Observable<BookingsStrictHttpResponse<boolean>> {
    const rb = new BookingsRequestBuilder(this.rootUrl, BookingsApiBookingsService.SaveBoardroomBookingItemsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as BookingsStrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveBoardroomBookingItems$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveBoardroomBookingItems(params?: { body?: BookingsSaveBoardroomBookingItemsCommand }): Observable<boolean> {
    return this.saveBoardroomBookingItems$Response(params).pipe(map((r: BookingsStrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation getBookingItemsFiltered
   */
  static readonly GetBookingItemsFilteredPath = '/api/Bookings/get/getBookingItemsFiltered';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBookingItemsFiltered()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getBookingItemsFiltered$Response(params?: {
    body?: BookingsGetBookingItemsFilteredQuery;
  }): Observable<BookingsStrictHttpResponse<Array<BookingsGetBookingItemsFilteredDto>>> {
    const rb = new BookingsRequestBuilder(this.rootUrl, BookingsApiBookingsService.GetBookingItemsFilteredPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as BookingsStrictHttpResponse<Array<BookingsGetBookingItemsFilteredDto>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getBookingItemsFiltered$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getBookingItemsFiltered(params?: { body?: BookingsGetBookingItemsFilteredQuery }): Observable<Array<BookingsGetBookingItemsFilteredDto>> {
    return this.getBookingItemsFiltered$Response(params).pipe(
      map((r: BookingsStrictHttpResponse<Array<BookingsGetBookingItemsFilteredDto>>) => r.body as Array<BookingsGetBookingItemsFilteredDto>),
    );
  }

  /**
   * Path part for operation bookingAvailabilitySearchData
   */
  static readonly BookingAvailabilitySearchDataPath = '/api/Bookings/get/getBookingAvailabilitySearchData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bookingAvailabilitySearchData()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  bookingAvailabilitySearchData$Response(params?: {
    body?: BookingsGetBookingAvailabilitySearchDataQuery;
  }): Observable<BookingsStrictHttpResponse<Array<BookingsBookingAvailabilitySearchDataDto>>> {
    const rb = new BookingsRequestBuilder(this.rootUrl, BookingsApiBookingsService.BookingAvailabilitySearchDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as BookingsStrictHttpResponse<Array<BookingsBookingAvailabilitySearchDataDto>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `bookingAvailabilitySearchData$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  bookingAvailabilitySearchData(params?: {
    body?: BookingsGetBookingAvailabilitySearchDataQuery;
  }): Observable<Array<BookingsBookingAvailabilitySearchDataDto>> {
    return this.bookingAvailabilitySearchData$Response(params).pipe(
      map((r: BookingsStrictHttpResponse<Array<BookingsBookingAvailabilitySearchDataDto>>) => r.body as Array<BookingsBookingAvailabilitySearchDataDto>),
    );
  }

  /**
   * Path part for operation createRequestHeader
   */
  static readonly CreateRequestHeaderPath = '/api/Bookings/post/CreateRequestHeader';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createRequestHeader()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createRequestHeader$Response(params?: {
    body?: BookingsCreateRequestHeaderCommand;
  }): Observable<BookingsStrictHttpResponse<BookingsBooleanStringValueTuple>> {
    const rb = new BookingsRequestBuilder(this.rootUrl, BookingsApiBookingsService.CreateRequestHeaderPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as BookingsStrictHttpResponse<BookingsBooleanStringValueTuple>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createRequestHeader$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createRequestHeader(params?: { body?: BookingsCreateRequestHeaderCommand }): Observable<BookingsBooleanStringValueTuple> {
    return this.createRequestHeader$Response(params).pipe(
      map((r: BookingsStrictHttpResponse<BookingsBooleanStringValueTuple>) => r.body as BookingsBooleanStringValueTuple),
    );
  }

  /**
   * Path part for operation receiveSubscriptionEvent
   */
  static readonly ReceiveSubscriptionEventPath = '/api/Bookings/post/ReceiveSubscriptionEvent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receiveSubscriptionEvent()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  receiveSubscriptionEvent$Response(params?: { validationToken?: string; body?: any }): Observable<BookingsStrictHttpResponse<void>> {
    const rb = new BookingsRequestBuilder(this.rootUrl, BookingsApiBookingsService.ReceiveSubscriptionEventPath, 'post');
    if (params) {
      rb.query('validationToken', params.validationToken, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as BookingsStrictHttpResponse<void>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `receiveSubscriptionEvent$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  receiveSubscriptionEvent(params?: { validationToken?: string; body?: any }): Observable<void> {
    return this.receiveSubscriptionEvent$Response(params).pipe(map((r: BookingsStrictHttpResponse<void>) => r.body as void));
  }
}
