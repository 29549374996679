/* tslint:disable */
/* eslint-disable */
import { UserIBackingStore } from './user-i-backing-store';
export interface UserPhysicalAddress {
  additionalData?: null | {
    [key: string]: any;
  };
  backingStore?: UserIBackingStore;
  city?: null | string;
  countryOrRegion?: null | string;
  odataType?: null | string;
  postalCode?: null | string;
  state?: null | string;
  street?: null | string;
}
