/* tslint:disable */
/* eslint-disable */
import { UserIBackingStore } from './user-i-backing-store';
import { UserRecurrencePattern } from './user-recurrence-pattern';
import { UserRecurrenceRange } from './user-recurrence-range';
export interface UserPatternedRecurrence {
  additionalData?: null | {
    [key: string]: any;
  };
  backingStore?: UserIBackingStore;
  odataType?: null | string;
  pattern?: UserRecurrencePattern;
  range?: UserRecurrenceRange;
}
