<div
     *ngIf="selectedOffice([offices[1]['uat'], offices[1]['prod'], offices[1]['uatSupport'], offices[1]['prodSupport']])">
  <div *ngIf="deviceTypeService.isMobile$ | async">
    <svg xmlns="http://www.w3.org/2000/svg"
         xmlns:xlink="http://www.w3.org/1999/xlink"
         width="400.065"
         height="1711.943"
         viewBox="0 0 2203 9427">
      <defs>
        <style>
          .cls-1,
          .cls-2 {
            font-size: 117.328px;
            font-family: Arial;
            font-weight: 700;
          }

          .cls-1 {
            fill: #0b0b0b;
          }

          .cls-2 {
            fill: #111;
          }
        </style>
        <image id="deskNormalTop"
               width="113"
               height="104"
               xlink:href="https://verdeimages.blob.core.windows.net/$web/Images/Verde/Clients/deskmaps/mineralsc/rosebank/deskNormalT.svg" />
        <image id="deskNormalRight"
               width="104"
               height="113"
               xlink:href="https://verdeimages.blob.core.windows.net/$web/Images/Verde/Clients/deskmaps/mineralsc/rosebank/deskNormalR.svg" />
        <image id="deskNormalBottom"
               width="113"
               height="102"
               xlink:href="https://verdeimages.blob.core.windows.net/$web/Images/Verde/Clients/deskmaps/mineralsc/rosebank/deskNormalB.svg" />
        <image id="deskWideBottom"
               width="153"
               height="94"
               xlink:href="https://verdeimages.blob.core.windows.net/$web/Images/Verde/Clients/deskmaps/mineralsc/rosebank/deskWideB.svg" />
        <image id="deskWideTop"
               width="153"
               height="94"
               xlink:href="https://verdeimages.blob.core.windows.net/$web/Images/Verde/Clients/deskmaps/mineralsc/rosebank/deskWideT.svg" />
        <image id="image6"
               width="153"
               height="195"
               xlink:href="https://verdeimages.blob.core.windows.net/$web/Images/Verde/Clients/deskmaps/mineralsc/rosebank/officeOF18.svg" />
        <image id="image7"
               width="195"
               height="153"
               xlink:href="https://verdeimages.blob.core.windows.net/$web/Images/Verde/Clients/deskmaps/mineralsc/rosebank/officeOF19.svg" />
        <image id="floorPlanMobile"
               width="2203"
               height="9427"
               xlink:href="https://verdeimages.blob.core.windows.net/$web/Images/Verde/Clients/deskmaps/mineralsc/rosebank/floorPlanMobile.svg" />
        <image id="officeOF21"
               width="198"
               height="160"
               xlink:href="https://verdeimages.blob.core.windows.net/$web/Images/Verde/Clients/deskmaps/mineralsc/rosebank/officeOF21.svg" />
        <image id="officeOF22"
               width="199"
               height="161"
               xlink:href="https://verdeimages.blob.core.windows.net/$web/Images/Verde/Clients/deskmaps/mineralsc/rosebank/officeOF22.svg" />
        <image id="officeOF23"
               width="161"
               height="198"
               xlink:href="https://verdeimages.blob.core.windows.net/$web/Images/Verde/Clients/deskmaps/mineralsc/rosebank/officeOF23.svg" />
        <image id="meetingRoom1"
               width="209"
               height="213"
               xlink:href="https://verdeimages.blob.core.windows.net/$web/Images/Verde/Clients/deskmaps/mineralsc/rosebank/meetingRoom1.svg" />
      </defs>
      <a id="layerFloorPlanMobile">
        <use id="imgFloorPlanMobile"
             xlink:href="#floorPlanMobile" />
      </a>
      <a id="interactiveLayerDeskNormal21"
         (click)="bookingsDataService.bookDesk('Desk PA6.6')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk PA6.6')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk PA6.6')"
         kendoTooltip>
        <use id="deskNormal21T"
             x="1471"
             y="8925"
             xlink:href="#deskNormalTop"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk PA6.6')" />
        <text id="deskNormal21N"
              class="cls-1"
              transform="translate(1495.817 9012.485) scale(0.384 0.384)">6.6</text>
      </a>
      <a id="interactiveLayerDeskNormal20"
         (click)="bookingsDataService.bookDesk('Desk PA6.5')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk PA6.5')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk PA6.5')"
         kendoTooltip>
        <use id="deskNormal20T"
             x="1352"
             y="8925"
             xlink:href="#deskNormalTop"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk PA6.5')" />
        <text id="deskNormal20N"
              class="cls-1"
              transform="translate(1378.283 9012.485) scale(0.384 0.384)">6.5</text>
      </a>
      <a id="interactiveLayerDeskNormal19"
         (click)="bookingsDataService.bookDesk('Desk PA6.4')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk PA6.4')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk PA6.4')"
         kendoTooltip>
        <use id="deskNormal19T"
             x="1232"
             y="8925"
             xlink:href="#deskNormalTop"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk PA6.4')" />
        <text id="deskNormal19N"
              class="cls-1"
              transform="translate(1258.426 9012.485) scale(0.384 0.384)">6.4</text>
      </a>
      <a id="interactiveLayerDeskNormal18"
         (click)="bookingsDataService.bookDesk('Desk PA6.3')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk PA6.3')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk PA6.3')"
         kendoTooltip>
        <use id="deskNormal18R"
             x="1158"
             y="8813"
             xlink:href="#deskNormalRight"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk PA6.3')" />
        <text id="deskNormal18N"
              class="cls-1"
              transform="translate(1158.674 8885.692) scale(0.384 0.384)">6.3</text>
      </a>
      <a id="interactiveLayerDeskNormal17"
         (click)="bookingsDataService.bookDesk('Desk PA6.2')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk PA6.2')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk PA6.2')"
         kendoTooltip>
        <use id="deskNormal17R"
             x="1158"
             y="8695"
             xlink:href="#deskNormalRight"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk PA6.2')" />
        <text id="deskNormal17N"
              class="cls-1"
              transform="translate(1158.674 8766.629) scale(0.384 0.384)">6.2</text>
      </a>
      <a id="interactiveLayerDeskNormal16"
         (click)="bookingsDataService.bookDesk('Desk PA6.1')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk PA6.1')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk PA6.1')"
         kendoTooltip>
        <use id="deskNormal16R"
             x="1158"
             y="8577"
             xlink:href="#deskNormalRight"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk PA6.1')" />
        <text id="deskNormal16N"
              class="cls-1"
              transform="translate(1161.767 8648.349) scale(0.384 0.384)">6.1</text>
      </a>
      <a id="interactiveLayerDeskNormal15"
         (click)="bookingsDataService.bookDesk('Desk PA5.4')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk PA5.4')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk PA5.4')"
         kendoTooltip>
        <use id="deskNormal15B"
             x="1086"
             y="5845"
             xlink:href="#deskNormalBottom"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk PA5.4')" />
        <text id="deskNormal15N"
              class="cls-2"
              transform="translate(1110.733 5889.094) scale(0.384 0.384)">5.4</text>
      </a>
      <a id="interactiveLayerDeskNormal14"
         (click)="bookingsDataService.bookDesk('Desk PA5.3')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk PA5.3')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk PA5.3')"
         kendoTooltip>
        <use id="deskNormal14B"
             x="966"
             y="5845"
             xlink:href="#deskNormalBottom"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk PA5.3')" />
        <text id="deskNormal14N"
              class="cls-2"
              transform="translate(990.876 5889.094) scale(0.384 0.384)">5.3</text>
      </a>
      <a id="interactiveLayerDeskNormal13"
         (click)="bookingsDataService.bookDesk('Desk PA5.2')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk PA5.2')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk PA5.2')"
         kendoTooltip>
        <use id="deskNormal13T"
             x="1086"
             y="5734"
             xlink:href="#deskNormalTop"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk PA5.2')" />
        <text id="deskNormal13N"
              class="cls-2"
              transform="translate(1112.279 5821.832) scale(0.384 0.384)">5.2</text>
      </a>
      <a id="interactiveLayerDeskNormal12"
         (click)="bookingsDataService.bookDesk('Desk PA5.1')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk PA5.1')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk PA5.1')"
         kendoTooltip>
        <use id="deskNormal12T"
             x="966"
             y="5734"
             xlink:href="#deskNormalTop"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk PA5.1')" />
        <text id="deskNormal12N"
              class="cls-2"
              transform="translate(990.876 5821.832) scale(0.384 0.384)">5.1</text>
      </a>
      <a id="interactiveLayerDeskWide4"
         (click)="bookingsDataService.bookDesk('Desk OF16B')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk OF16B')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk OF16B')"
         kendoTooltip>
        <use id="deskWide4B"
             x="1546"
             y="7179"
             xlink:href="#deskWideBottom"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk OF16B')" />
        <text id="deskWide4N"
              class="cls-1"
              transform="translate(1605.621 7253.643) scale(0.384 0.384)">B</text>
      </a>
      <a id="interactiveLayerDeskWide3"
         (click)="bookingsDataService.bookDesk('Desk OF16A')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk OF16A')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk OF16A')"
         kendoTooltip>
        <use id="deskWide3T"
             x="1546"
             y="7078"
             xlink:href="#deskWideTop"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk OF16A')" />
        <text id="deskWide3N"
              class="cls-1"
              transform="translate(1605.621 7129.178) scale(0.384 0.384)">A</text>
      </a>
      <a id="interactiveLayerDeskWide6"
         (click)="bookingsDataService.bookDesk('Desk OF17B')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk OF17B')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk OF17B')"
         kendoTooltip>
        <use id="deskWide6B"
             x="1574"
             y="6852"
             xlink:href="#deskWideBottom"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk OF17B')" />
        <text id="deskWide6N"
              class="cls-1"
              transform="translate(1635.778 6925.074) scale(0.384 0.384)">B</text>
      </a>
      <a id="interactiveLayerDeskWide5"
         (click)="bookingsDataService.bookDesk('Desk OF17A')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk OF17A')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk OF17A')"
         kendoTooltip>
        <use id="deskWide5T"
             x="1574"
             y="6751"
             xlink:href="#deskWideTop"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk OF17A')" />
        <text id="deskWide5N"
              class="cls-1"
              transform="translate(1635.778 6800.6) scale(0.384 0.384)">A</text>
      </a>
      <a id="interactiveLayerOfficeOF18"
         (click)="bookingsDataService.bookDesk('Desk OF18')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk OF18')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk OF18')"
         kendoTooltip>
        <use id="imgOfficeOF18"
             x="1613"
             y="6472"
             xlink:href="#image6"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk OF18')" />
      </a>
      <a id="interactiveLayerOfficeOF19"
         (click)="bookingsDataService.bookDesk('Desk OF19')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk OF19')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk OF19')"
         kendoTooltip>
        <use id="imgOfficeOF19"
             x="1577"
             y="6226"
             xlink:href="#image7"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk OF19')" />
      </a>
      <a id="interactiveLayerOfficeOF20"
         (click)="bookingsDataService.bookDesk('Desk OF20')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk OF20')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk OF20')"
         kendoTooltip>
        <use id="imgOfficeOF20"
             x="1641"
             y="5698"
             xlink:href="#image6"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk OF20')" />
      </a>
      <a id="interactiveLayerOfficeOF21"
         (click)="bookingsDataService.bookDesk('Desk OF21')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk OF21')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk OF21')"
         kendoTooltip>
        <use id="imgOfficeOF21"
             x="1500"
             y="5216"
             xlink:href="#officeOF21"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk OF21')" />
      </a>
      <a id="interactiveLayerOfficeOF22"
         (click)="bookingsDataService.bookDesk('Desk OF22')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk OF22')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk OF22')"
         kendoTooltip>
        <use id="imgOfficeOF22"
             x="1186"
             y="5249"
             xlink:href="#officeOF22"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk OF22')" />
      </a>
      <a id="interactiveLayerOfficeOF23"
         (click)="bookingsDataService.bookDesk('Desk OF23')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk OF23')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk OF23')"
         kendoTooltip>
        <use id="imgOfficeOF23"
             x="967"
             y="5354"
             xlink:href="#officeOF23"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk OF23')" />
      </a>
      <a id="interactiveLayerOfficeOF24"
         (click)="bookingsDataService.bookDesk('Desk OF24')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk OF24')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk OF24')"
         kendoTooltip>
        <use id="imgOfficeOF24"
             x="605"
             y="5420"
             xlink:href="#image6"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk OF24')" />
      </a>
      <a id="interactiveLayerOfficeOF25"
         (click)="bookingsDataService.bookDesk('Desk OF25')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk OF25')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk OF25')"
         kendoTooltip>
        <use id="imgOfficeOF25"
             x="605"
             y="5743"
             xlink:href="#image6"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk OF25')" />
      </a>
      <a id="interactiveLayerOfficeOF15"
         (click)="bookingsDataService.bookDesk('Desk OF15')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk OF15')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk OF15')"
         kendoTooltip>
        <use id="imgOfficeOF15"
             x="279"
             y="6105"
             xlink:href="#image6"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk OF15')" />
      </a>
      <a id="interactiveLayerOfficeOF14"
         (click)="bookingsDataService.bookDesk('Desk OF14')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk OF14')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk OF14')"
         kendoTooltip>
        <use id="imgOfficeOF14"
             x="1763"
             y="4032"
             xlink:href="#image7"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk OF14')" />
      </a>
      <a id="interactiveLayerOffice10"
         (click)="bookingsDataService.bookDesk('Office 10')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Office 10')"
         [attr.title]="bookingsDataService.getDeskToolTip('Office 10')"
         kendoTooltip>
        <use id="imgOffice10"
             x="1412"
             y="3712"
             xlink:href="#image6"
             [attr.style]="bookingsDataService.getDeskImageStyle('Office 10')" />
      </a>
      <a id="interactiveLayerOfficeOF8"
         (click)="bookingsDataService.bookDesk('Desk OF8')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk OF8')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk OF8')"
         kendoTooltip>
        <use id="imgOfficeOF8"
             x="1401"
             y="3285"
             xlink:href="#image6"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk OF8')" />
      </a>
      <a id="interactiveLayerDeskWide8"
         (click)="bookingsDataService.bookDesk('Desk OF6B')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk OF6B')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk OF6B')"
         kendoTooltip>
        <use id="deskWide8B"
             x="1394"
             y="3135"
             xlink:href="#deskWideBottom"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk OF6B')" />
        <text id="deskWide8N"
              class="cls-1"
              transform="translate(1453.676 3208.687) scale(0.384 0.384)">B</text>
      </a>
      <a id="interactiveLayerDeskWide7"
         (click)="bookingsDataService.bookDesk('Desk OF6A')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk OF6A')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk OF6A')"
         kendoTooltip>
        <use id="deskWide7T"
             x="1394"
             y="3034"
             xlink:href="#deskWideTop"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk OF6A')" />
        <text id="deskWide7N"
              class="cls-1"
              transform="translate(1453.676 3084.213) scale(0.384 0.384)">A</text>
      </a>
      <a id="interactiveLayerOfficeOF3"
         (click)="bookingsDataService.bookDesk('Desk OF26')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk OF26')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk OF26')"
         kendoTooltip>
        <use id="imgOfficeOF3"
             x="1398"
             y="2446"
             xlink:href="#image6"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk OF26')" />
      </a>
      <a id="interactiveLayerDeskWide2"
         (click)="bookingsDataService.bookDesk('Desk OF13B')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk OF13B')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk OF13B')"
         kendoTooltip>
        <use id="deskWide2B"
             x="604"
             y="4724"
             xlink:href="#deskWideBottom"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk OF13B')" />
        <text id="deskWide2N"
              class="cls-1"
              transform="translate(663.398 4798.984) scale(0.384 0.384)">B</text>
      </a>
      <a id="interactiveLayerDeskWide1"
         (click)="bookingsDataService.bookDesk('Desk OF13A')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk OF13A')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk OF13A')"
         kendoTooltip>
        <use id="deskWide1T"
             x="604"
             y="4624"
             xlink:href="#deskWideTop"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk OF13A')" />
        <text id="deskWide1N"
              class="cls-1"
              transform="translate(663.398 4674.519) scale(0.384 0.384)">A</text>
      </a>
      <a id="interactiveLayerDeskNormal11"
         (click)="bookingsDataService.bookDesk('Desk PA1.3')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk PA1.3')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk PA1.3')"
         kendoTooltip>
        <use id="deskNormal11T"
             x="806"
             y="3001"
             xlink:href="#deskNormalTop"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk PA1.3')" />
        <text id="deskNormal11N"
              class="cls-1"
              transform="translate(829.65 3090.406) scale(0.384 0.384)">1.3</text>
      </a>
      <a id="interactiveLayerDeskNormal10"
         (click)="bookingsDataService.bookDesk('Desk PA1.2')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk PA1.2')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk PA1.2')"
         kendoTooltip>
        <use id="deskNormal10T"
             x="687"
             y="3001"
             xlink:href="#deskNormalTop"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk PA1.2')" />
        <text id="deskNormal10N"
              class="cls-1"
              transform="translate(711.342 3090.406) scale(0.384 0.384)">1.2</text>
      </a>
      <a id="interactiveLayerDeskNormal9"
         (click)="bookingsDataService.bookDesk('Desk PA1.1')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk PA1.1')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk PA1.1')"
         kendoTooltip>
        <use id="deskNormal9T"
             x="570"
             y="3059.843"
             transform="scale(1 0.981)"
             xlink:href="#deskNormalTop"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk PA1.1')" />
        <text id="deskNormal9N"
              class="cls-1"
              transform="translate(596.125 3090.406) scale(0.384 0.384)">1.1</text>
      </a>
      <a id="interactiveLayerMeetingRoom1"
         (click)="bookingsDataService.bookDesk('Meeting Room 1')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Meeting Room 1')"
         [attr.title]="bookingsDataService.getDeskToolTip('Meeting Room 1')"
         kendoTooltip>
        <use id="imgMeetingRoom1"
             x="618"
             y="1923"
             xlink:href="#meetingRoom1"
             [attr.style]="bookingsDataService.getDeskImageStyle('Meeting Room 1')" />
      </a>
    </svg>
  </div>
  <div *ngIf="(deviceTypeService.isMobile$ | async) === false">
    <svg xmlns="http://www.w3.org/2000/svg"
         xmlns:xlink="http://www.w3.org/1999/xlink"
         width="1086.036"
         height="1654.114"
         viewBox="0 0 5179 7888">
      <defs>
        <style>
          .cls-1,
          .cls-2 {
            font-size: 117.328px;
            font-family: Arial;
            font-weight: 700;
          }

          .cls-1 {
            fill: #0b0b0b;
          }

          .cls-2 {
            fill: #111;
          }
        </style>
        <image id="deskNormalTop"
               width="146"
               height="133"
               xlink:href="https://verdeimages.blob.core.windows.net/$web/Images/Verde/Clients/deskmaps/mineralsc/rosebank/deskNormalT.svg" />
        <image id="deskNormalRight"
               width="133"
               height="146"
               xlink:href="https://verdeimages.blob.core.windows.net/$web/Images/Verde/Clients/deskmaps/mineralsc/rosebank/deskNormalR.svg" />
        <image id="deskNormalBottom"
               width="146"
               height="133"
               xlink:href="https://verdeimages.blob.core.windows.net/$web/Images/Verde/Clients/deskmaps/mineralsc/rosebank/deskNormalB.svg" />
        <image id="image4"
               width="197"
               height="251"
               xlink:href="https://verdeimages.blob.core.windows.net/$web/Images/Verde/Clients/deskmaps/mineralsc/rosebank/officeOF18.svg" />
        <image id="image5"
               width="251"
               height="197"
               xlink:href="https://verdeimages.blob.core.windows.net/$web/Images/Verde/Clients/deskmaps/mineralsc/rosebank/officeOF19.svg" />
        <image id="deskWideBottom"
               width="197"
               height="122"
               xlink:href="https://verdeimages.blob.core.windows.net/$web/Images/Verde/Clients/deskmaps/mineralsc/rosebank/deskWideB.svg" />
        <image id="deskWideTop"
               width="197"
               height="122"
               xlink:href="https://verdeimages.blob.core.windows.net/$web/Images/Verde/Clients/deskmaps/mineralsc/rosebank/deskWideT.svg" />
        <image id="floorPlan"
               width="5179"
               height="7888"
               xlink:href="https://verdeimages.blob.core.windows.net/$web/Images/Verde/Clients/deskmaps/mineralsc/rosebank/floorPlan.svg" />
        <image id="officeOF21"
               width="257"
               height="208"
               xlink:href="https://verdeimages.blob.core.windows.net/$web/Images/Verde/Clients/deskmaps/mineralsc/rosebank/officeOF21.svg" />
        <image id="officeOF22"
               width="256"
               height="208"
               xlink:href="https://verdeimages.blob.core.windows.net/$web/Images/Verde/Clients/deskmaps/mineralsc/rosebank/officeOF22.svg" />
        <image id="officeOF23"
               width="208"
               height="257"
               xlink:href="https://verdeimages.blob.core.windows.net/$web/Images/Verde/Clients/deskmaps/mineralsc/rosebank/officeOF23.svg" />
        <image id="meetingRoom1"
               width="270"
               height="276"
               xlink:href="https://verdeimages.blob.core.windows.net/$web/Images/Verde/Clients/deskmaps/mineralsc/rosebank/meetingRoom1.svg" />
      </defs>
      <a id="layerFloorPlan">
        <use id="imgFloorPlan"
             xlink:href="#floorPlan" />
      </a>
      <a id="interactiveLayerDeskNormal21"
         (click)="bookingsDataService.bookDesk('Desk PA6.6')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk PA6.6')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk PA6.6')"
         kendoTooltip>
        <use id="deskNormal21T"
             x="4369"
             y="6875"
             xlink:href="#deskNormalTop"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk PA6.6')" />
        <text id="deskNormal21N"
              class="cls-1"
              transform="translate(4401.228 6989.502) scale(0.497 0.497)">6.6</text>
      </a>
      <a id="interactiveLayerDeskNormal20"
         (click)="bookingsDataService.bookDesk('Desk PA6.5')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk PA6.5')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk PA6.5')"
         kendoTooltip>
        <use id="deskNormal20T"
             x="4215"
             y="6875"
             xlink:href="#deskNormalTop"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk PA6.5')" />
        <text id="deskNormal20N"
              class="cls-1"
              transform="translate(4249.23 6989.502) scale(0.497 0.497)">6.5</text>
      </a>
      <a id="interactiveLayerDeskNormal19"
         (click)="bookingsDataService.bookDesk('Desk PA6.4')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk PA6.4')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk PA6.4')"
         kendoTooltip>
        <use id="deskNormal19T"
             x="4061"
             y="6875"
             xlink:href="#deskNormalTop"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk PA6.4')" />
        <text id="deskNormal19N"
              class="cls-1"
              transform="translate(4094.227 6989.502) scale(0.497 0.497)">6.4</text>
      </a>
      <a id="interactiveLayerDeskNormal18"
         (click)="bookingsDataService.bookDesk('Desk PA6.3')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk PA6.3')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk PA6.3')"
         kendoTooltip>
        <use id="deskNormal18R"
             x="3966"
             y="6732"
             xlink:href="#deskNormalRight"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk PA6.3')" />
        <text id="deskNormal18N"
              class="cls-1"
              transform="translate(3965.229 6825.502) scale(0.497 0.497)">6.3</text>
      </a>
      <a id="interactiveLayerDeskNormal17"
         (click)="bookingsDataService.bookDesk('Desk PA6.2')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk PA6.2')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk PA6.2')"
         kendoTooltip>
        <use id="deskNormal17R"
             x="3966"
             y="6578"
             xlink:href="#deskNormalRight"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk PA6.2')" />
        <text id="deskNormal17N"
              class="cls-1"
              transform="translate(3965.229 6671.505) scale(0.497 0.497)">6.2</text>
      </a>
      <a id="interactiveLayerDeskNormal16"
         (click)="bookingsDataService.bookDesk('Desk PA6.1')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk PA6.1')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk PA6.1')"
         kendoTooltip>
        <use id="deskNormal16R"
             x="3966"
             y="6425"
             xlink:href="#deskNormalRight"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk PA6.1')" />
        <text id="deskNormal16N"
              class="cls-1"
              transform="translate(3969.227 6518.501) scale(0.497 0.497)">6.1</text>
      </a>
      <a id="interactiveLayerDeskNormal15"
         (click)="bookingsDataService.bookDesk('Desk PA5.4')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk PA5.4')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk PA5.4')"
         kendoTooltip>
        <use id="deskNormal15B"
             x="3872"
             y="2891"
             xlink:href="#deskNormalBottom"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk PA5.4')" />
        <text id="deskNormal15N"
              class="cls-2"
              transform="translate(3903.231 2949.505) scale(0.497 0.497)">5.4</text>
      </a>
      <a id="interactiveLayerDeskNormal14"
         (click)="bookingsDataService.bookDesk('Desk PA5.3')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk PA5.3')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk PA5.3')"
         kendoTooltip>
        <use id="deskNormal14B"
             x="3715"
             y="2890"
             xlink:href="#deskNormalBottom"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk PA5.3')" />
        <text id="deskNormal14N"
              class="cls-2"
              transform="translate(3748.229 2949.505) scale(0.497 0.497)">5.3</text>
      </a>
      <a id="interactiveLayerDeskNormal13"
         (click)="bookingsDataService.bookDesk('Desk PA5.2')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk PA5.2')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk PA5.2')"
         kendoTooltip>
        <use id="deskNormal13T"
             x="3872"
             y="2748"
             xlink:href="#deskNormalTop"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk PA5.2')" />
        <text id="deskNormal13N"
              class="cls-2"
              transform="translate(3905.23 2862.5) scale(0.497 0.497)">5.2</text>
      </a>
      <a id="interactiveLayerDeskNormal12"
         (click)="bookingsDataService.bookDesk('Desk PA5.1')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk PA5.1')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk PA5.1')"
         kendoTooltip>
        <use id="deskNormal12T"
             x="3715"
             y="2748"
             xlink:href="#deskNormalTop"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk PA5.1')" />
        <text id="deskNormal12N"
              class="cls-2"
              transform="translate(3748.229 2862.5) scale(0.497 0.497)">5.1</text>
      </a>
      <a id="interactiveLayerDeskWide4"
         (click)="bookingsDataService.bookDesk('Desk OF16B')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk OF16B')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk OF16B')"
         kendoTooltip>
        <use id="deskWide4B"
             x="4465"
             y="4618"
             xlink:href="#deskWideBottom"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk OF16B')" />
        <text id="deskWide4N"
              class="cls-1"
              transform="translate(4543.231 4714.508) scale(0.497 0.497)">B</text>
      </a>
      <a id="interactiveLayerDeskWide3"
         (click)="bookingsDataService.bookDesk('Desk OF16A')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk OF16A')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk OF16A')"
         kendoTooltip>
        <use id="deskWide3T"
             x="4465"
             y="4488"
             xlink:href="#deskWideTop"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk OF16A')" />
        <text id="deskWide3N"
              class="cls-1"
              transform="translate(4543.231 4553.506) scale(0.497 0.497)">A</text>
      </a>
      <a id="interactiveLayerDeskWide6"
         (click)="bookingsDataService.bookDesk('Desk OF17B')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk OF17B')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk OF17B')"
         kendoTooltip>
        <use id="deskWide6B"
             x="4504"
             y="4193"
             xlink:href="#deskWideBottom"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk OF17B')" />
        <text id="deskWide6N"
              class="cls-1"
              transform="translate(4582.229 4289.502) scale(0.497 0.497)">B</text>
      </a>
      <a id="interactiveLayerDeskWide5"
         (click)="bookingsDataService.bookDesk('Desk OF17A')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk OF17A')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk OF17A')"
         kendoTooltip>
        <use id="deskWide5T"
             x="4504"
             y="4063"
             xlink:href="#deskWideTop"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk OF17A')" />
        <text id="deskWide5N"
              class="cls-1"
              transform="translate(4582.229 4128.5) scale(0.497 0.497)">A</text>
      </a>
      <a id="interactiveLayerOfficeOF18"
         (click)="bookingsDataService.bookDesk('Desk OF18')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk OF18')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk OF18')"
         kendoTooltip>
        <use id="imgOfficeOF18"
             x="4553"
             y="3704"
             xlink:href="#image4"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk OF18')" />
      </a>
      <a id="interactiveLayerOfficeOF19"
         (click)="bookingsDataService.bookDesk('Desk OF19')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk OF19')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk OF19')"
         kendoTooltip>
        <use id="imgOfficeOF19"
             x="4509"
             y="3385"
             xlink:href="#image5"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk OF19')" />
      </a>
      <a id="interactiveLayerOfficeOF20"
         (click)="bookingsDataService.bookDesk('Desk OF20')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk OF20')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk OF20')"
         kendoTooltip>
        <use id="imgOfficeOF20"
             x="4590"
             y="2701"
             xlink:href="#image4"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk OF20')" />
      </a>
      <a id="interactiveLayerOfficeOF21"
         (click)="bookingsDataService.bookDesk('Desk OF21')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk OF21')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk OF21')"
         kendoTooltip>
        <use id="imgOfficeOF21"
             x="4406"
             y="2078"
             xlink:href="#officeOF21"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk OF21')" />
      </a>
      <a id="interactiveLayerOfficeOF22"
         (click)="bookingsDataService.bookDesk('Desk OF22')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk OF22')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk OF22')"
         kendoTooltip>
        <use id="imgOfficeOF22"
             x="4001"
             y="2122"
             xlink:href="#officeOF22"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk OF22')" />
      </a>
      <a id="interactiveLayerOfficeOF23"
         (click)="bookingsDataService.bookDesk('Desk OF23')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk OF23')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk OF23')"
         kendoTooltip>
        <use id="imgOfficeOF23"
             x="3717"
             y="2257"
             xlink:href="#officeOF23"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk OF23')" />
      </a>
      <a id="interactiveLayerOfficeOF24"
         (click)="bookingsDataService.bookDesk('Desk OF24')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk OF24')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk OF24')"
         kendoTooltip>
        <use id="imgOfficeOF24"
             x="3250"
             y="2342"
             xlink:href="#image4"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk OF24')" />
      </a>
      <a id="interactiveLayerOfficeOF25"
         (click)="bookingsDataService.bookDesk('Desk OF25')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk OF25')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk OF25')"
         kendoTooltip>
        <use id="imgOfficeOF25"
             x="3250"
             y="2760"
             xlink:href="#image4"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk OF25')" />
      </a>
      <a id="interactiveLayerOfficeOF15"
         (click)="bookingsDataService.bookDesk('Desk OF15')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk OF15')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk OF15')"
         kendoTooltip>
        <use id="imgOfficeOF15"
             x="2827"
             y="3228"
             xlink:href="#image4"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk OF15')" />
      </a>
      <a id="interactiveLayerOfficeOF14"
         (click)="bookingsDataService.bookDesk('Desk OF14')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk OF14')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk OF14')"
         kendoTooltip>
        <use id="imgOfficeOF14"
             x="2159"
             y="3134"
             xlink:href="#image5"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk OF14')" />
      </a>
      <a id="interactiveLayerOffice10"
         (click)="bookingsDataService.bookDesk('Office 10')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Office 10')"
         [attr.title]="bookingsDataService.getDeskToolTip('Office 10')"
         kendoTooltip>
        <use id="imgOffice10"
             x="1703"
             y="2721"
             xlink:href="#image4"
             [attr.style]="bookingsDataService.getDeskImageStyle('Office 10')" />
      </a>
      <a id="interactiveLayerOfficeOF8"
         (click)="bookingsDataService.bookDesk('Desk OF8')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk OF8')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk OF8')"
         kendoTooltip>
        <use id="imgOfficeOF8"
             x="1689"
             y="2167"
             xlink:href="#image4"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk OF8')" />
      </a>
      <a id="interactiveLayerDeskWide8"
         (click)="bookingsDataService.bookDesk('Desk OF6B')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk OF6B')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk OF6B')"
         kendoTooltip>
        <use id="deskWide8B"
             x="1679"
             y="1973"
             xlink:href="#deskWideBottom"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk OF6B')" />
        <text id="deskWide8N"
              class="cls-1"
              transform="translate(1757.23 2069.504) scale(0.497 0.497)">B</text>
      </a>
      <a id="interactiveLayerDeskWide7"
         (click)="bookingsDataService.bookDesk('Desk OF6A')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk OF6A')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk OF6A')"
         kendoTooltip>
        <use id="deskWide7T"
             x="1679"
             y="1843"
             xlink:href="#deskWideTop"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk OF6A')" />
        <text id="deskWide7N"
              class="cls-1"
              transform="translate(1757.23 1908.502) scale(0.497 0.497)">A</text>
      </a>
      <a id="interactiveLayerOfficeOF3"
         (click)="bookingsDataService.bookDesk('Desk OF26')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk OF26')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk OF26')"
         kendoTooltip>
        <use id="imgOfficeOF3"
             x="1685"
             y="1083"
             xlink:href="#image4"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk OF26')" />
      </a>
      <a id="interactiveLayerDeskWide2"
         (click)="bookingsDataService.bookDesk('Desk OF13B')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk OF13B')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk OF13B')"
         kendoTooltip>
        <use id="deskWide2B"
             x="657"
             y="4030"
             xlink:href="#deskWideBottom"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk OF13B')" />
        <text id="deskWide2N"
              class="cls-1"
              transform="translate(735.232 4126.505) scale(0.497 0.497)">B</text>
      </a>
      <a id="interactiveLayerDeskWide1"
         (click)="bookingsDataService.bookDesk('Desk OF13A')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk OF13A')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk OF13A')"
         kendoTooltip>
        <use id="deskWide1T"
             x="657"
             y="3900"
             xlink:href="#deskWideTop"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk OF13A')" />
        <text id="deskWide1N"
              class="cls-1"
              transform="translate(735.232 3965.503) scale(0.497 0.497)">A</text>
      </a>
      <a id="interactiveLayerDeskNormal11"
         (click)="bookingsDataService.bookDesk('Desk PA1.3')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk PA1.3')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk PA1.3')"
         kendoTooltip>
        <use id="deskNormal11T"
             x="919"
             y="1801"
             xlink:href="#deskNormalTop"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk PA1.3')" />
        <text id="deskNormal11N"
              class="cls-1"
              transform="translate(950.227 1916.5) scale(0.497 0.497)">1.3</text>
      </a>
      <a id="interactiveLayerDeskNormal10"
         (click)="bookingsDataService.bookDesk('Desk PA1.2')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk PA1.2')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk PA1.2')"
         kendoTooltip>
        <use id="deskNormal10T"
             x="766"
             y="1801"
             xlink:href="#deskNormalTop"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk PA1.2')" />
        <text id="deskNormal10N"
              class="cls-1"
              transform="translate(797.229 1916.5) scale(0.497 0.497)">1.2</text>
      </a>
      <a id="interactiveLayerDeskNormal9"
         (click)="bookingsDataService.bookDesk('Desk PA1.1')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Desk PA1.1')"
         [attr.title]="bookingsDataService.getDeskToolTip('Desk PA1.1')"
         kendoTooltip>
        <use id="deskNormal9T"
             x="614"
             y="1815.652"
             transform="scale(1 0.992)"
             xlink:href="#deskNormalTop"
             [attr.style]="bookingsDataService.getDeskImageStyle('Desk PA1.1')" />
        <text id="deskNormal9N"
              class="cls-1"
              transform="translate(648.23 1916.5) scale(0.497 0.497)">1.1</text>
      </a>
      <a id="interactiveLayerMeetingRoom1"
         (click)="bookingsDataService.bookDesk('Meeting Room 1')"
         [attr.style]="bookingsDataService.getDeskCursorStyle('Meeting Room 1')"
         [attr.title]="bookingsDataService.getDeskToolTip('Meeting Room 1')"
         kendoTooltip>
        <use id="imgMeetingRoom1"
             x="676"
             y="406"
             xlink:href="#meetingRoom1"
             [attr.style]="bookingsDataService.getDeskImageStyle('Meeting Room 1')" />
      </a>
    </svg>
  </div>
</div>