import { Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldProps } from '@ngx-formly/core';

export interface KendoFormlyFileProps extends FormlyFieldProps {
  allowedExtensions?: ('jpg' | 'jpeg' | 'png' | 'xls' | 'pdf' | 'docx')[];
  maxFileSize?: number;
  multiple?: boolean;
  encrypted?: boolean;
  readOnly?: boolean;
  change?: (field: any, event: any) => void;
}

@Component({
  selector: 'verde-kendo-formly-file',
  template: `
    <kendo-fileselect
      [id]="this.key"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [accept]="accept"
      [disabled]="props.readOnly"
      [multiple]="props.multiple"
      [name]="this.key"
      [restrictions]="{
        allowedExtensions: props.allowedExtensions ?? ['jpg','jpeg','png', 'xls', 'pdf', 'docx'],
        maxFileSize: props.maxFileSize ?? 5242880,
      }"
      [showFileList]="true"
      (valueChange)="fileChanged($event)"
    ></kendo-fileselect>
  `,
})
export class KendoFormlyFile extends FieldType<FieldTypeConfig<KendoFormlyFileProps>> implements OnInit {
  accept: string;

  ngOnInit() {
    this.accept = (this.props.allowedExtensions ?? ['jpg', 'jpeg', 'png', 'xls', 'pdf', 'docx']).map((ext) => `.${ext}`).join(', ');
  }

  fileChanged(data: any) {
    if (this.props.change) {
      this.props.change(this.field, event);
    }
  }
}
