/* tslint:disable */
/* eslint-disable */
export enum WebDynamicBtFormType {
  Query = 'Query',
  Condition = 'Condition',
  Wizard = 'Wizard',
  Card = 'Card',
  Scheduler = 'Scheduler',
  TreeList = 'TreeList',
  SidePanel = 'SidePanel',
  FullWindow = 'FullWindow',
  Grid = 'Grid',
  Lookup = 'Lookup',
  Workspace = 'Workspace',
  TimeLine = 'TimeLine'
}
