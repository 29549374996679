/* eslint-disable no-console */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldProps } from '@ngx-formly/core';
import { SortDescriptor } from '@progress/kendo-data-query';
import { WebDynamicGetDynamicFormLookupQuery, UserSecurityFunctionDto } from '@verde/api';
import { UserService } from '@verde/core';
import {
  DotsMenuItem,
  SidePanelWidth,
  WebDynamicFormType,
  WebDynamicFormAction,
  WebDynamicService,
  WebDynamicSidePanelArgs,
  VerdeApprovalService,
} from '@verde/shared';
import { Observable, of, Subject } from 'rxjs';
import { catchError, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { VerdeApprovalOverlayService } from '../../../layout/verde-approval-panel (2)/services/verde-approval-overlay.service';

interface GridProps extends FormlyFieldProps {
  loading?: boolean;
  lookUp?: WebDynamicGetDynamicFormLookupQuery;
  dynamicGroup?: string;
}

@Component({
  selector: 'verde-kendo-formly-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KendoFormlyGrid extends FieldType<FieldTypeConfig<GridProps>> implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<boolean>();
  private refreshOptions$ = new Subject<void>();
  options$: Observable<any[]>;
  sort: SortDescriptor[];
  dotMenuItems: DotsMenuItem[] = [{}];
  functions: Array<UserSecurityFunctionDto> = new Array<UserSecurityFunctionDto>();
  selectedMenuItem: any;

  constructor(
    private cd: ChangeDetectorRef,
    private userService: UserService,
    private webDynamicService: WebDynamicService,
    private sidebarService: VerdeApprovalService,
    private sidebarOverlayService: VerdeApprovalOverlayService,
  ) {
    super();
  }

  ngOnInit() {
    this.options$ = this.refreshOptions$.pipe(
      startWith([]), // Initial call when component initializes
      switchMap(() => {
        if (this.props.options instanceof Observable) {
          this.props.loading = true; // Set loading state
          return this.props.options.pipe(
            tap((data) => {
              this.props.loading = false;
              this.getThreeDotMenuFunctions();
              this.cd.detectChanges();
            }),
            catchError((error) => {
              console.error('Error loading options:', error);
              this.props.loading = false;
              this.cd.detectChanges();
              return of([]); // Return an empty array on error
            }),
            takeUntil(this.onDestroy$),
          );
        }
      }),
    );

    if (this.props.lookUp.sort) {
      this.sort = this.props.lookUp.sort as SortDescriptor[];
    }
  }

  getVisibleColumns(): any[] {
    return this.props.lookUp.columns.filter((column) => !column.hide);
  }

  isBoolean(value: any): boolean {
    return typeof value === 'boolean';
  }

  isNumber(value: any): boolean {
    return typeof value === 'number' && !isNaN(value);
  }

  isString(value: any): boolean {
    return typeof value === 'string';
  }

  isObjectWithName(value: any): boolean {
    return value && typeof value === 'object' && 'name' in value;
  }

  isDateString(value: string): boolean {
    const date = new Date(value);
    const isDate = !isNaN(date.getTime());
    return isDate;
  }

  convertToDate(value: string): Date {
    return new Date(value);
  }

  updateDateValue(dataItem: any, field: string, newValue: Date): void {
    dataItem[field] = newValue.toISOString(); // or format it as needed
  }

  getThreeDotMenuFunctions() {
    this.functions = this.userService.filterDynamicGroup(this.props.lookUp.dynamicgroup, this.userService.user.legalEntityId).sort((a, b) => {
      if (a.menuName && b.menuName) {
        const nameA = a.menuName.toLowerCase();
        const nameB = b.menuName.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      }

      return 0;
    });

    this.cd.detectChanges();
  }

  refresh() {
    this.refreshOptions$.next(); // This will trigger the API call again
  }

  menuClicked(selectedMenuItem: any) {
    this.selectedMenuItem = selectedMenuItem;
    this.dotMenuItems = [];
    this.functions.forEach((t) => {
      if (t.bt_GridLine == true) {
        this.dotMenuItems.push({
          id: t.menuName,
          text: t.menuName,
          jsonFileName: t.jsonFileName,
          bt_DynamicFormSecurityInput: t.bt_DynamicFormSecurityInput,
          items: [],
          disabled: false,
          cssStyle: '',
        });
      }
    });
  }

  menuItemClicked(menuItem: DotsMenuItem) {
    try {
      const correctedString = menuItem.bt_DynamicFormSecurityInput.replace(/'/g, '"');
      let json = JSON.parse(correctedString);
      json = {
        ...json,
        args: {
          ...json.args,
          model: {
            ...this.selectedMenuItem,
          },
        },
      };

      this.openDynamicForm(json);
    } catch (err) {
      console.error(`Could not load ${menuItem.bt_DynamicFormSecurityInput}`);
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  actionClicked(action: UserSecurityFunctionDto) {
    this.openDynamicForm(JSON.parse(action.bt_DynamicFormSecurityInput.replace(/'/g, '"')));
  }

  openDynamicForm(json: any) {
    const formTypeFormatted = json.formType.split(' ').join('').trim();
    if (formTypeFormatted in WebDynamicFormType) {
      const formType: WebDynamicFormType = WebDynamicFormType[formTypeFormatted as keyof typeof WebDynamicFormType];
      const action: WebDynamicFormAction = WebDynamicFormAction[json.action as keyof typeof WebDynamicFormAction];

      if (formType === WebDynamicFormType.SidePanel || formType === WebDynamicFormType.TimeLine) {
        this.webDynamicService.setDynamicForm({
          ...json,
          formType,
          action,
          args: {
            ...json.args,
            size: SidePanelWidth[json.args.size as keyof typeof SidePanelWidth],
          } as WebDynamicSidePanelArgs,
        });
      } else {
        this.webDynamicService.setDynamicForm({
          ...json,
          formType,
          action,
        });
      }
    }
  }
}
