export type DeviceType = 'desktop' | 'tablet' | 'mobile';
type TileType = 'verde-card' | 'card' | 'custom' | 'menu' | 'grid' | 'compact-card' | 'custom-card' | 'dynamic-card' | 'adaptive-card';
type EmployeeLifeCycle = 'on-boarding' | 'employed' | 'off-boarding';

interface Gap {
  columns?: number;
  rows?: number;
}

interface LayoutConfig {
  numberOfColumns?: number;
  numberOfEnabledColumns?: number;
  customPadding?: boolean;
  autoFlow?: 'none' | 'row' | 'column' | 'row-dense' | 'column-dense';
  columnWidth?: string | number;
  gap?: number | Gap;
  reorderable?: boolean;
  resizable?: boolean;
  rowHeight?: string | number;
}

interface TileItemLayoutConfig {
  col?: number;
  row?: number;
  colSpan?: number;
  rowSpan?: number;
  reorderable?: boolean;
  resizable?: boolean;
}

interface TileNavigation {
  navigable?: boolean;
  navigationId?: string | null;
  isMenuItem?: boolean;
  isExternal?: boolean;
}

export interface TileAdaptiveCardModel {
  template?: any;
  templateFileName?: string;
  templateUrl?: string;
}

export interface TileModel {
  config?: TileItemLayoutConfig;
  enabled?: boolean;
  technicalName?: string;
  id?: string;
  type?: TileType;
  title?: string | null;
  subTitle?: string | null;
  toolTip?: string | null;
  iconUrl?: string | null;
  navigation?: TileNavigation;
  adaptiveCard?: TileAdaptiveCardModel;
  data?: any;
}

interface Column {
  column?: number;
  enabled?: boolean;
  tiles?: TileModel[];
}

interface WorkspaceLayout {
  layout: number;
  config?: LayoutConfig;
  enabled?: boolean;
  columns?: Column[];
}

export interface WorkspaceModel {
  employeeLifeCycle?: EmployeeLifeCycle;
  deviceType?: DeviceType;
  gridTemplateColumns?: string;
  numberOfLayouts?: number;
  layouts?: WorkspaceLayout[];
}
