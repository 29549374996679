import { Component, Input } from '@angular/core';
import { DeviceTypeService } from '@verde/core';
import { Office, BookingsDataService } from '../../../../../../../public-api';

@Component({
  selector: 'verde-minerals-council',
  templateUrl: './minerals-council.component.html',
  styleUrls: ['./minerals-council.component.scss'],
})
export class MineralsCouncilComponent {
  @Input() officeId: string;

  offices: { [key: number]: { [key: string]: Office } } = {};
  constructor(public bookingsDataService: BookingsDataService, public deviceTypeService: DeviceTypeService) {
    this.offices = {
      1: {
        prod: { id: '4139e522-5bfe-ef11-bae3-000d3ab814a4', name: 'Rosebank Towers, 9th Floor', isSubOffice: true },
        uat: { id: '4139e522-5bfe-ef11-bae3-000d3ab814a4', name: 'Rosebank Towers, 9th Floor', isSubOffice: true },
        prodSupport: { id: '7cdc3492-b5fa-ef11-bae2-000d3ab814a4', name: 'Rosebank Towers, 9th Floor', isSubOffice: true },
        uatSupport: { id: '7cdc3492-b5fa-ef11-bae2-000d3ab814a4', name: 'Rosebank Towers, 9th Floor', isSubOffice: true },
      },
    };
  }

  selectedOffice(offices: Office[]): boolean {
    return offices?.some((office) => office?.id === this.officeId);
  }
}
