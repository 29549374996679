/* tslint:disable */
/* eslint-disable */
import { UserIBackingStore } from './user-i-backing-store';
import { UserPhone } from './user-phone';
export interface UserOnlineMeetingInfo {
  additionalData?: null | {
    [key: string]: any;
  };
  backingStore?: UserIBackingStore;
  conferenceId?: null | string;
  joinUrl?: null | string;
  odataType?: null | string;
  phones?: null | Array<UserPhone>;
  quickDial?: null | string;
  tollFreeNumbers?: null | Array<string>;
  tollNumber?: null | string;
}
