<ngx-spinner [disableAnimation]="disableAnimation"
             name="dynamicFormSpinner"
             bdColor="rgb(255, 255, 255)"
             size="default"
             color="#6264a7"
             type="ball-clip-rotate"
             [fullScreen]="false">
  <p>Loading Form</p>
</ngx-spinner>

<form [formGroup]="form"
      (ngSubmit)="openSubmitModal()"
      *ngIf="fields?.length > 0">
  <formly-form [model]="model"
               [fields]="fields"
               [options]="options"
               [form]="form"></formly-form>
  <div class="dynamic-actions">
    <button *ngIf="isEditable()"
            kendoButton
            type="submit"
            id="submit-button"
            themeColor="primary"
            [disabled]="!form?.valid ?? true">Submit</button>
  </div>
</form>

<verde-modal modalTitle="Confirmation"
             [id]="'confirmModal_' + id"
             [hasCancelButton]="true"
             submitButton="Continue"
             (actionButton)="submit()"
             modalMaxWidth="400">
  <div style="position: relative; height: 60px">
    <p>Are you sure you want to submit?</p>
  </div>
</verde-modal>

<verde-modal modalTitle="Confirmation"
             [id]="'submittedModal_' + id"
             [hasCancelButton]="false"
             submitButton="Continue"
             (actionButton)="closeVerdeDynamicModal()"
             modalMaxWidth="400">
  <div style="position: relative; height: 60px">
    <p>Submitted Succesfully</p>
  </div>
</verde-modal>

<verde-modal modalTitle="Error"
             [id]="'errorModal_' + id"
             [hasCancelButton]="false"
             submitButton="Continue"
             modalMaxWidth="400">
  <div style="position: relative; height: 60px">
    <p>{{ errorMessage }}</p>
  </div>
</verde-modal>