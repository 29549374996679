/* tslint:disable */
/* eslint-disable */
import { UserIBackingStore } from './user-i-backing-store';
export interface UserDateTimeTimeZone {
  additionalData?: null | {
    [key: string]: any;
  };
  backingStore?: UserIBackingStore;
  dateTime?: null | string;
  odataType?: null | string;
  timeZone?: null | string;
}
