import { Component } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { environment } from '@environments-verde/environment';

@Component({
  selector: 'verde-admin-consent',
  templateUrl: './admin-consent.component.html',
  styleUrls: ['./admin-consent.component.scss'],
})
export class AdminConsentComponent {
  tenantId: string = '';
  clientId: string = environment.msalConfig.auth.clientId; // Get your client ID from msal config
  redirectUri: string = encodeURIComponent(window.location.origin); // Redirect URI after consent

  constructor(private msalService: MsalService) {}

  ngOnInit(): void {
    const activeAccount = this.msalService.instance.getActiveAccount();
    if (activeAccount && activeAccount.tenantId) {
      this.tenantId = activeAccount.tenantId;
    } else {
      console.error('No active account found or tenant ID is missing.');
    }
  }

  // Redirect to the admin consent URL when the button is clicked
  provideConsent(): void {
    if (this.tenantId) {
      const adminConsentUrl = `https://login.microsoftonline.com/${this.tenantId}/adminconsent?client_id=${this.clientId}&redirect_uri=${this.redirectUri}`;
      window.location.href = adminConsentUrl;
    } else {
      console.error('Tenant ID is missing.');
    }
  }
}
