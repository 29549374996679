<ng-container *ngIf="!loading">
    <ng-container *ngFor="let group of menuGroup; let last = last">
        <kendo-expansionpanel *ngIf="group"
                              [title]="group?.groupName"
                              [expanded]="group?.expanded"
                              [ngClass]="{'expander': true, 'expander-last': last}">
            <div class="quick-menu-group">
                <p *ngFor="let item of group?.items"
                   class="quick-menu-item"
                   (click)="navigateSomewhere(item, true, false)">
                    {{ item.menuName }}
                </p>
            </div>
        </kendo-expansionpanel>
    </ng-container>
</ng-container>
<div class="loading-spinner"
     *ngIf="loading">
    <verde-loader [loading]="loading"></verde-loader>
</div>