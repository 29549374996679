/* tslint:disable */
/* eslint-disable */
import { UserEnvironmentConfigDto } from './user-environment-config-dto';
import { UserLegalEntityConfigDto } from './user-legal-entity-config-dto';
import { UserLegalEntityConfigDtoPeopleManagementConfigDto } from './user-legal-entity-config-dto-people-management-config-dto';
import { UserSecurityDto } from './user-security-dto';
import { UserUserDto } from './user-user-dto';
export interface UserAppConfigDto {
  legalEntityConfig?: UserLegalEntityConfigDto;
  managerTeam?: null | Array<UserUserDto>;
  peopleManagementConfig?: UserLegalEntityConfigDtoPeopleManagementConfigDto;
  security?: UserSecurityDto;
  team?: null | Array<UserUserDto>;
  user?: UserUserDto;
  verdeEnvironmentConfig?: UserEnvironmentConfigDto;
}
